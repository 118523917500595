import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    formData: {
        email: '',
        emailErrorText: null,
        emailValid: false,
        password: '',
        passwordErrorText: null,
        passwordValid: false,
        isValid: false
    }
};

const login = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_LOGIN__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: {$merge: action.data.formData}
            });
            newState = update(newState, {
                formData: {
                    isValid: {$set: newState.formData.emailValid && newState.formData.passwordValid }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_LOGIN__LOGIN_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_LOGIN__LOGIN_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: { $set: newState.formData.emailValid && newState.formData.passwordValid }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default login;