import * as constants from '../constants';

export function setUser(user, token) {
    localStorage.setItem(constants.STORAGE_KEY_USER, JSON.stringify({ user, token }));
    return {
        type: constants.ACTION_TYPE_USER__SET_USER,
        data: { user, token }
    };
}
export function setUserFromStorage() {
    const json = localStorage.getItem(constants.STORAGE_KEY_USER);
    const userToken = json ? JSON.parse(json) : null;
    if (userToken && userToken.user && userToken.token) {
        return {
            type: constants.ACTION_TYPE_USER__SET_USER,
            data: userToken
        };
    } else {
        localStorage.removeItem(constants.STORAGE_KEY_USER);
        return { type: constants.ACTION_TYPE_USER__UNSET_USER }
    }
}
export function unsetUser() {
    localStorage.removeItem(constants.STORAGE_KEY_USER);
    return { type: constants.ACTION_TYPE_USER__UNSET_USER }
}