import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Paper, TableContainer, Table, TableBody, TableCell, TableRow, TableHead, TableFooter } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import Parameter from './Parameter';
import * as constants from '../../constants';

const TableViewTwoColumns = ({ browseConfiguration, updateValue, configuration, pageId, tableId, rowId, cellId, disableCode }) => {
    //const start = moment();
    //let onChangePage = null;

    const { id } = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const loading = useSelector(state => state.global.loading);

    //const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    //const configuration = useSelector(state => state.configuration.configuration);
    //const dirtyParameters = useSelector(state => state.configuration.dirtyParameters);
    //const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    const dispatch = useDispatch();

    const tableRef = useRef(null);
    const [tableLeft, setTableLeft] = useState(0);
    useEffect(() => {
        if (tableRef.current) {
            //console.log("tableRef", tableRef.current);
            setTableLeft(tableRef.current.left);
        }
    }, [tableRef.current]);

    //useEffect(() => {
    //    if (onChangePage)
    //        onChangePage(null, paginationPage);
    //}, [paginationPage]);

    //const pageView = configuration.pages[pageId];
    const tableView = configuration.tables[tableId];
    const colHeaderList = tableView.colHeaderList;
    const frozenColumnCount = tableView.frozenColumnCount;
    const isSplit = tableView.split;
    //const rowViewList = tableView.rowViewList;
    const rowViewList = configuration.rows.filter(r => r.tableId === tableId);

    const count = frozenColumnCount ? colHeaderList.length - frozenColumnCount : Math.max(Math.floor(colHeaderList.length / 2), 1);
    //let paginationPage;
    //if (cellId < configuration.rows[tableView.firstRowId].firstCellId)
    //    paginationPage = 1;
    //else if (cellId > configuration.rows[tableView.lastRowId].lastCellId)
    //    paginationPage = count;
    //else
    //    paginationPage = Math.min(Math.floor((cellId - configuration.rows[rowId].firstCellId) / 2) + 1, count);
    const paginationPage = frozenColumnCount ?
        Math.max(cellId - configuration.rows[rowId].firstCellId, frozenColumnCount) - frozenColumnCount + 1 :
        Math.min(Math.floor((cellId - configuration.rows[rowId].firstCellId) / 2) + 1, count);
    //const end = moment();
    //console.log("start render", end.diff(start));
    console.log({ pageId, tableId, rowId, count, paginationPage, cellId, firstRowId: tableView.firstRowId, lastRowId: tableView.lastRowId })
    return <div>
        <TableContainer ref={tableRef}>
            <Table size="small" padding="checkbox">
                <TableHead>
                    <TableRow>
                        {
                            frozenColumnCount ?
                                colHeaderList
                                    .filter((c, i) => i < frozenColumnCount || i === frozenColumnCount + paginationPage - 1)
                                    .map((c, i) => <TableCell key={i}>{c.value}</TableCell>) :
                                colHeaderList
                                    .slice((paginationPage - 1) * 2, paginationPage === count ? (paginationPage - 1) * 2 + 3 : (paginationPage - 1) * 2 + 2)
                                    .map((c, i) => <TableCell key={i}>{c.value}</TableCell>)
                        }
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        rowViewList
                            //.filter(r => r.tableId === tableView.id && r.id >= rowId && r.id < rowId + take)
                            //.slice(rowId, rowId + take)
                            //.filter(r => r.id >= rowId && r.id < rowId + take)
                            .map(r => <TableRow key={r.id}>
                                {
                                    frozenColumnCount ?
                                        configuration.cells
                                            .filter(c => c.rowId === r.id)
                                            .filter((c, i) => i < frozenColumnCount || i === frozenColumnCount + paginationPage - 1)
                                            .map(c => <TableCell key={c.id}>
                                                <Parameter
                                                    cell={c}
                                                    updateValue={updateValue}
                                                    configuration={configuration}
                                                    disableCode={disableCode} /> />
                                            </TableCell>) :
                                        configuration.cells
                                            .filter(c => c.rowId === r.id)
                                            .slice((paginationPage - 1) * 2, paginationPage === count ? (paginationPage - 1) * 2 + 3 : (paginationPage - 1) * 2 + 2)
                                            .map(c => <TableCell key={c.id}>
                                                <Parameter
                                                    cell={c}
                                                    updateValue={updateValue}
                                                    configuration={configuration}
                                                    disableCode={disableCode} />
                                            </TableCell>)
                                }
                            </TableRow>)
                    }
                </TableBody>
            </Table>
        </TableContainer>

        <div style={{ position: "fixed", bottom: 0, left: tableLeft, zIndex: 100 }}>
            <Paper>
                <Pagination
                    style={{ marginTop: 10, marginBottom: 10 }}
                    size="small"
                    color="primary"
                    disabled={loading}
                    count={count}
                    page={paginationPage}
                    onChange={(event, page) => dispatch(browseConfiguration(history, id, pageId, tableId, tableView.firstRowId,
                        frozenColumnCount ?
                            configuration.rows[tableView.firstRowId].firstCellId + frozenColumnCount + page - 1 :
                            configuration.rows[tableView.firstRowId].firstCellId + (page - 1) * 2))} />
            </Paper>
        </div>
    </div>
}
//const TableView = () => {
//    let onChangePage = null;
//    const { id } = useParams();
//    const history = useHistory();
//    const { t } = useTranslation();
//    const loading = useSelector(state => state.global.loading);
//    const dataView = useSelector(state => state.configuration.dataView);
//    const pageId = useSelector(state => state.configuration.pageId);
//    const tableId = useSelector(state => state.configuration.tableId);
//    const paginationPage = useSelector(state => state.configuration.paginationPage);
//    const alarmSystem = useSelector(state => state.configuration.alarmSystem);
//    const configuration = useSelector(state => state.configuration.configuration);
//    const dirtyParameters = useSelector(state => state.configuration.dirtyParameters);
//    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
//    const dispatch = useDispatch();

//    useEffect(() => {
//        if (onChangePage)
//            onChangePage(null, paginationPage);
//    }, [paginationPage]);

//    const pageView = dataView.pageViewList[pageId];
//    const tableView = pageView.tableViewList[tableId];
//    const colHeaderList = tableView.colHeaderList;
//    const frozenColumnCount = tableView.frozenColumnCount;
//    const rowViewList = tableView.rowViewList;

//    return (
//        <MaterialTable
//            options={{
//                showTitle: false,
//                filtering: false,
//                sorting: false,
//                search: false,
//                draggable: false,
//                pageSize: 10,
//                pageSizeOptions: [],
//                initialPage: 0,
//                emptyRowsWhenPaging: false,
//                paginationType: "normal",
//                tableLayout: "fixed",
//                doubleHorizontalScroll: false,
//                //fixedColumns: {
//                //    left: frozenColumnCount ? frozenColumnCount : 0,
//                //    right: 0
//                //}

//            }}
//            onChangePage={(page) => dispatch(browseConfiguration(history, id, pageId, tableId, page))}
//            components={{
//                Pagination: props => {
//                    onChangePage = props.onChangePage;
//                    return (
//                        <TableCell>
//                            <Pagination
//                                style={{ marginTop: 10, marginBottom: 10 }}
//                                size="small"
//                                color="primary"
//                                disabled={loading}
//                                count={Math.ceil(props.count / props.rowsPerPage)}
//                                page={props.page + 1}
//                                onChange={(event, page) => props.onChangePage(event, page - 1)} />
//                        </TableCell>
//                    );
//                }
//            }}
//            columns={
//                colHeaderList.map((c, i) => ({
//                    field: `field_${i}`,
//                    title: c.value,
//                    cellStyle: { padding: 3 },
//                    //type: (() => {
//                    //    switch (c.type) {
//                    //        case constants.CELL_VIEW_TYPE_EMPTY:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_TEXT:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_CODE:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_DEC_NUMBER:
//                    //            return "numeric";
//                    //        case constants.CELL_VIEW_TYPE_HEX_NUMBER:
//                    //            return "numeric";
//                    //        case constants.CELL_VIEW_TYPE_ESN:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_PHONE_NUMBER:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_BYTE_ARRAY:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_ENCRYPTION_KEY:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_SELECTOR:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_TIME:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_DATE:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_IP_ADDRESS:
//                    //            return "string";
//                    //        case constants.CELL_VIEW_TYPE_FLAG:
//                    //            return "boolean";
//                    //        case constants.CELL_VIEW_TYPE_STATUS:
//                    //            return "boolean";
//                    //        default:
//                    //            return "string";
//                    //    }
//                    //})(),
//                    width: (() => {
//                        switch (c.type) {
//                            case constants.CELL_VIEW_TYPE_EMPTY:
//                                return 50;
//                            case constants.CELL_VIEW_TYPE_TEXT:
//                                return 150;
//                            case constants.CELL_VIEW_TYPE_CODE:
//                                return 100;
//                            case constants.CELL_VIEW_TYPE_DEC_NUMBER:
//                                return 100;
//                            case constants.CELL_VIEW_TYPE_HEX_NUMBER:
//                                return 100;
//                            case constants.CELL_VIEW_TYPE_ESN:
//                                return 150;
//                            case constants.CELL_VIEW_TYPE_PHONE_NUMBER:
//                                return 150;
//                            case constants.CELL_VIEW_TYPE_BYTE_ARRAY:
//                                return 150;
//                            case constants.CELL_VIEW_TYPE_ENCRYPTION_KEY:
//                                return 150;
//                            case constants.CELL_VIEW_TYPE_SELECTOR:
//                                return 150;
//                            case constants.CELL_VIEW_TYPE_TIME:
//                                return 50;
//                            case constants.CELL_VIEW_TYPE_DATE:
//                                return 50;
//                            case constants.CELL_VIEW_TYPE_IP_ADDRESS:
//                                return 150;
//                            case constants.CELL_VIEW_TYPE_FLAG:
//                                return 50;
//                            case constants.CELL_VIEW_TYPE_STATUS:
//                                return 50;
//                            default:
//                                return 150;
//                        }
//                    })(),
//                    render: r => <Parameter cell={r.cellMetadataList[i]} />
//                }))
//            }
//            data={rowViewList || []}
//            title={tableView.title ? `${t(pageView.title)} / ${t(tableView.title)}` : t(pageView.title)}
//        />
//    );
//}

export default TableViewTwoColumns;