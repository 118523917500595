import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, FormControl, Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { deleteAlarmSystem, openDeleteDialog } from '../../actions/editAlarmSystem';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import EditableTextField from '../common/EditableTextField';

const DeleteDialog = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.editAlarmSystem.alarmSystem);
    const deleteDialog = useSelector(state => state.editAlarmSystem.deleteDialog);
    const generalErrorText = useSelector(state => state.editAlarmSystem.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    if (!alarmSystem)
        return null;

    return (
        <Dialog
            disableBackdropClick={loading}
            open={deleteDialog.isOpen}
            onClose={() => dispatch(openDeleteDialog(false))}>
            <DialogTitle>{t("Remove")}</DialogTitle>
            <DialogContent>

                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

                <Typography variant="subtitle1">{t("DeleteAlarmSystemSubtitle")}</Typography>

                <div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                        <EditableTextField readOnly
                            fullWidth
                            type="text"
                            value={alarmSystem.mac}
                            label={t("MAC")}
                        />
                    </FormControl>

                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openDeleteDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={loading}
                    onClick={() => dispatch(deleteAlarmSystem(alarmSystem.id, history, token, unsetUser, t, language))}
                    color="primary">
                    {t("Remove")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteDialog;