import axios from 'axios';
import { Redirect } from 'react-router-dom';

export function getAlarmSystems(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get('/api/alarmSystem/getAlarmSystems', config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.alarmSystems);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getAlarmSystem(id, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/alarmSystem/getAlarmSystem/${id}`, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.alarmSystem);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getDataViewNames(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get('/api/alarmSystem/getDataViewNames', config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.dataViewNames);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function updateAlarmSystem(alarmSystem, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { ...alarmSystem };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/alarmSystem/updateAlarmSystem', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.alarmSystem);
            } else {
                failCallback(response.data.errorCodes);
            }

        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}


export function changeMac(id, mac, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { id, mac };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/alarmSystem/changeMac', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.alarmSystem);
            } else {
                failCallback(response.data.errorCodes);
            }

        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function addAlarmSystem(alarmSystem, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { ...alarmSystem };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/alarmSystem/addAlarmSystem', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.alarmSystem);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback(null);
            }
        });
}

export function deleteAlarmSystem(id, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.delete(`/api/alarmSystem/deleteAlarmSystem/${id}`, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }

        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function pair(id, pairingCode, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { id, pairingCode };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/alarmSystem/pair', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.alarmSystem);
            } else {
                failCallback(response.data.errorCodes, response.data.externalErrorText, response.data.externalErrorCode);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function unpair(id, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { id };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/alarmSystem/unpair', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.alarmSystem);
            } else {
                failCallback(response.data.errorCodes, response.data.externalErrorText, response.data.externalErrorCode);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getLicenseState(id, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/alarmSystem/getLicenseState/${id}`, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                //successCallback(response.data.installerLicenseOptions, response.data.userLicenseOptions);
                successCallback(response.data.license, response.data.vatSettings, response.data.emailsResponse, response.data.freeMonths, response.data.monthlyShekelsAmmountPerSystem, response.data.monthlyDollarsAmmountPerSystem, response.data.monthlyEurosAmmountPerSystem);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
} 
export function enableLicense(id, NumberOfPaidSystems, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { id, NumberOfPaidSystems };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/alarmSystem/addFreeLicense', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.license);
            } else {
                failCallback(response.data.errorCodes);
            }

        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}
export function enableFreeLicense(id, NumberOfPaidSystems, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { id, NumberOfPaidSystems };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/payment/GetForFree', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success)
            {
                successCallback(response.data.updatedNumOfSystems);
            } else {
                failCallback(response.data.errorCodes);
            }

        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}
export function addPimaLicense(email, NumberOfPaidSystems, token, language, successCallback, failCallback, unauthorizedCallback) {
    var id = email;
    const data = { id, NumberOfPaidSystems };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/payment/addPimaLicense', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.updatedNumOfSystems);
            } else {
                failCallback(response.data.errorCodes);
            }

        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}


export function getTransactionResult(id, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/alarmSystem/getTransactionResult/${id}`, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                //successCallback(response.data.installerLicenseOptions, response.data.userLicenseOptions);
                successCallback(response.data.alarmSystem, response.data.license, response.data.permissionName, response.data.paymentSuccess, response.data.confirmationCode);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}


export function SetDiscount(userEmail, discountPrecent, callbackUrl, language, successCallback, failCallback) {
    const data = { userEmail, discountPrecent };
    const config = {
        headers: { "Accept-Language": language }
    };
    axios.post('/api/user/SetDiscount', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            failCallback();
        });
}

export function removeOneSystem(userEmail, discountPrecent, callbackUrl, language, successCallback, failCallback) {
    const data = { userEmail, discountPrecent };
    const config = {
        headers: { "Accept-Language": language }
    };
    axios.post('/api/user/removeOneSystem', data, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                const id = userEmail;
                const NumberOfPaidSystems = discountPrecent;
                const data1 = { id, NumberOfPaidSystems};
                      
                axios.post('/api/payment/removeOneLicensePimaOnly', data1, config)
                    .then(response => {
                        console.log(response);
                        if (response.data.success) {
                            successCallback();
                        } else {
                            failCallback(response.data.errorCodes);
                        }
                    }).catch(error => {
                        console.error("error.response", error.response);
                        failCallback();
                    });
            }
        }).catch(error => {
            console.error("error.response", error.response);
            failCallback();
        });
}