import axios from 'axios';

export function getWebAccountTemplates(alarmSystemId, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/template/getWebAccountTemplates/${alarmSystemId || ""}`, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.templates);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getDefaultTemplates(alarmSystemId, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/template/getDefaultTemplates/${alarmSystemId || ""}`, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.dataViewNames, response.data.languages, response.data.defaultLanguage);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function addTemplate(name, dataViewName, configLanguage, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    const data = {
        name, dataViewName, configLanguage
    }
    axios.post('/api/template/addTemplate', data, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.template);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function loadWebAccountTemplate(alarmSystemId, templateId, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    const data = {
        alarmSystemId, templateId
    }
    axios.post('/api/template/loadWebAccountTemplate', data, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.template, response.data.alarmSystem, response.data.configuration, response.data.dataView);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getTemplate(templateId, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/template/getTemplate/${templateId}`, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.template, response.data.configuration, response.data.dataView);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function loadDefaultTemplate(alarmSystemId, dataViewName, configLanguage, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    const data = {
        alarmSystemId, dataViewName, configLanguage
    }
    axios.post('/api/template/loadDefaultTemplate', data, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.alarmSystem, response.data.configuration, response.data.dataView);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function updateTemplate(templateId, parameters, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/template/updateTemplate', {
        templateId,
        parameters
    }, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes, response.data.parameterValidationErrors);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function deleteTemplate(templateId, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.delete(`/api/template/deleteTemplate/${templateId}`, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }

        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function saveAsTemplate(name, parameters, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    const data = {
        name, parameters
    }
    axios.post('/api/template/saveAsTemplate', data, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.template);
            } else {
                failCallback(response.data.errorCodes, response.data.parameterValidationErrors);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}
