import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';

export function confirmEmail(userId, code, userEmail, history, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.confirmEmail(userId, code, language, () => {
                dispatch({ type: constants.ACTION_TYPE_CONFIRM_EMAIL__CONFIRM_EMAIL_SUCCESS });
                setTimeout(() => {
                    dispatch(setLoading(false));
                    history.push(constants.ROUTE_ACCOUNT_LOGIN + "?userId=" + userEmail);//inbal 29/08
                }, 2000);
            }, (errorCodes) => {
                let data = {
                    generalErrorText: null
                };
                if (errorCodes) {
                    for (let errorCode of errorCodes) {
                        switch (errorCode) {
                            //GeneralError = 1,
                            case 1:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //UnexpectedError = 2,
                            case 2:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //MissingParameters = 3,
                            case 3:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //UserNotFound = 4,
                            case 4:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //IncorrectEmailConfirmationCode = 5
                            case 5:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            default:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                        }
                    }
                } else {
                    data.generalErrorText = t("ErrorGeneral");
                }
                dispatch({ type: constants.ACTION_TYPE_CONFIRM_EMAIL__CONFIRM_EMAIL_FAIL, data });
                dispatch(setLoading(false));
            });
    }
}

//// error codes:
//// GeneralError = 1,
//// UnexpectedError = 2,
//// MissingParameters = 3,
//// UserNotFound = 4,
//// IncorrectEmailConfirmationCode = 5
//export function confirmEmail(userId, code, history) {
//    return (dispatch) => {
//        dispatch({ type: constants.ACTION_TYPE_CONFIRM_EMAIL_START });
//        axios.post('/api/authentication/confirmemail', {
//            userId,
//            code
//        }).then(response => {
//            console.log(response);

//            if (response.data.success) {
//                setTimeout(() => {
//                    dispatch({
//                        type: constants.ACTION_TYPE_CONFIRM_EMAIL_SUCCESS
//                    });
//                    history.push('/account/login');
//                }, 2000);
//            } else {
//                let data = {
//                    generalErrorText: null
//                };
//                for (let errorCode of response.data.errorCodes) {
//                    switch (errorCode) {
//                        case 1:
//                            data.generalErrorText = "localize_GeneralError";
//                            break;
//                        case 2:
//                            data.generalErrorText = "localize_UnexpectedError";
//                            break;
//                        case 3:
//                            data.generalErrorText = "localize_MissingParameters";
//                            break;
//                        case 4:
//                            data.generalErrorText = "localize_UserNotFound";
//                            break;
//                        case 5:
//                            data.generalErrorText = "localize_IncorrectEmailConfirmationCode";
//                            break;
//                        default:
//                            data.generalErrorText = "localize_UnexpectedError";
//                            break;
//                    }
//                }
//                dispatch({ type: constants.ACTION_TYPE_CONFIRM_EMAIL_FAIL, data });
//            }

//        }).catch(error => {
//            console.error("error.response.data", error.response.data);
//            dispatch({
//                type: constants.ACTION_TYPE_CONFIRM_EMAIL_FAIL,
//                data: {
//                    generalErrorText: "localize_UnexpectedError"
//                }
//            });
//        });

//    }
//}
