import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';
import moment from 'moment';

export function getUsers(searchText, sort, userId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getAllUsers(token, language, (users) => {
            dispatch({
                type: constants.ACTION_TYPE_MANAGEMENT_USERS__GET_USERS_SUCCESS,
                data: { users }
            });
            dispatch(updateDisplayUsers(users, searchText, sort, userId, t));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };            
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_USERS__GET_USERS_FAIL, data });
            dispatch(updateDisplayUsers([], searchText, sort, userId, t));
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function updateDisplayUsers(users, searchText, sort, userId, t) {
    return dispatch => {
        const userTypeLookup = {};
        for (let n of constants.USER_TYPES) {
            userTypeLookup[n.type] = t(`UserType${n.name}`);
        }
        console.log("userTypeLookup", userTypeLookup);
        const searchTextLowerCase = searchText ? searchText.toLowerCase() : null;
        let newUsers = searchText ? users.filter(u => {
            //const re = new RegExp(`${searchText}`, "i");
            //return re.test(n.name) ||
            //    re.test(n.mac) ||
            //    re.test(notificationTypeLookup[n.notificationType]) ||
            //    re.test(n.message);

            let status = !u.active ? t("UserNotActive") :
                (!u.emailConfirmed ? t("EmailNotConfirmed") : t("Active"));

            return u.email.toLowerCase().includes(searchTextLowerCase) ||
                u.firstName.toLowerCase().includes(searchTextLowerCase) ||
                u.lastName.toLowerCase().includes(searchTextLowerCase) ||
                u.phoneNumber.toLowerCase().includes(searchTextLowerCase) ||
                userTypeLookup[u.userType].toLowerCase().includes(searchTextLowerCase) ||
                status.toLowerCase().includes(searchTextLowerCase);
        }) : [...users];
        newUsers = newUsers.sort((a, b) => {
            switch (sort.by) {
                case constants.MANAGEMENT_USERS_SORT_BY_EMAIL:
                    return a.email.localeCompare(b.email);
                case constants.MANAGEMENT_USERS_SORT_BY_FIRST_NAME:
                    return a.firstName.localeCompare(b.firstName);
                case constants.MANAGEMENT_USERS_SORT_BY_LAST_NAME:
                    return a.lastName.localeCompare(b.lastName);
                case constants.MANAGEMENT_USERS_SORT_BY_PHONE_NUMBER:
                    return a.phoneNumber.localeCompare(b.phoneNumber);
                case constants.MANAGEMENT_USERS_SORT_BY_USER_TYPE:
                    return userTypeLookup[a.userType].localeCompare(userTypeLookup[b.userType]);
                case constants.MANAGEMENT_USERS_SORT_BY_STATUS:
                    let statusA = !a.active ? t("UserNotActive") :
                        (!a.emailConfirmed ? t("EmailNotConfirmed") : t("Active"));
                    let statusB = !b.active ? t("UserNotActive") :
                        (!b.emailConfirmed ? t("EmailNotConfirmed") : t("Active"));
                    return statusA.localeCompare(statusB);
                case constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE:
                default:
                    const ma = moment(a.lastLoginDate);
                    const mb = moment(b.lastLoginDate);
                    if (ma.isAfter(mb))
                        return 1;
                    else if (ma.isBefore(mb))
                        return -1;
                    else
                        return 0;
            }
        });
        if (sort.direction === "desc")
            newUsers = newUsers.reverse();

        let newUserId;
        if (userId < 0)
            newUserId = 0;
        else if (userId > newUsers.length - 1)
            newUserId = newUsers.length - 1;
        else
            newUserId = userId;

        dispatch({
            type: constants.ACTION_TYPE_MANAGEMENT_USERS__UPDATE_DISPLAY_USERS,
            data: {
                displayUsers: {
                    users: newUsers,
                    userId: newUserId,
                    searchText,
                    sort
                }
            }
        });
    }
}