import React, { useState, useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, IconButton, TextField, Container, Breadcrumbs, Typography, Divider, Link,
    Stepper, MobileStepper, Step, StepLabel, FormControl, FormLabel, InputLabel, Select, MenuItem, FormHelperText,
    FormControlLabel, Switch, FormGroup, Grid, Chip, Tabs, Tab,
    Dialog, DialogTitle, DialogContent, DialogActions,
    List, ListItem, ListItemText, ListItemIcon, Checkbox, AppBar, Toolbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    addTemplate, getDefaultTemplates, updateFormDataViewName, updateFormLanguage, updateFormName
} from '../../actions/addTemplate';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const AddTemplate = () => {

    const { t, i18n } = useTranslation();
    const { token, unsetUser } = useUser();
    const history = useHistory();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.addTemplate.formData);
    const dataViewNames = useSelector(state => state.addTemplate.dataViewNames);
    const languages = useSelector(state => state.addTemplate.languages);
    const generalErrorText = useSelector(state => state.addAlarmSystem.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getDefaultTemplates(history, token, unsetUser, t, language));
    }, [dispatch, token]);

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Link color="inherit" component={RouterLink} to={constants.ROUTE_TEMPLATES}>{t("Templates")}</Link>
                <Typography color="textPrimary">{t("Add")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <AppBar color="inherit" position="static">
                <Toolbar variant="dense">
                    <Button color="inherit" disabled={loading || !formData.isValid}
                        onClick={() => dispatch(addTemplate(formData.name, formData.dataViewName, formData.language, history, token, unsetUser, t, language))}>{t("Save")}</Button>
                </Toolbar>
            </AppBar>

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <div style={{ maxWidth: 300, marginTop: 20 }}>
                {
                    dataViewNames.length ?
                        <React.Fragment>
                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                <TextField
                                    disabled={loading}
                                    fullWidth
                                    type="text"
                                    error={!!formData.nameErrorText}
                                    label={t("Name")}
                                    helperText={formData.nameErrorText}
                                    value={formData.name}
                                    onChange={event => dispatch(updateFormName(event.target.value, t))} />
                            </FormControl>

                            <FormControl fullWidth style={{ marginTop: 10 }} error={!!formData.dataViewNameErrorText}>
                                <InputLabel id="lblDataViewName">{t("Model")}</InputLabel>
                                <Select
                                    fullWidth
                                    disabled={loading}
                                    labelId="lblDataViewName"
                                    value={formData.dataViewName}
                                    onChange={event => dispatch(updateFormDataViewName(event.target.value, t))}>
                                    {
                                        dataViewNames.map((n, i) => (<MenuItem key={i} value={n}>{n}</MenuItem>))
                                    }
                                </Select>
                                <FormHelperText>{formData.dataViewNameErrorText}</FormHelperText>
                            </FormControl>

                            <FormControl fullWidth style={{ marginTop: 10 }} error={!!formData.languageErrorText}>
                                <InputLabel id="lblLanguage">{t("Language")}</InputLabel>
                                <Select
                                    fullWidth
                                    disabled={loading}
                                    labelId="lblLanguage"
                                    value={formData.language}
                                    onChange={event => dispatch(updateFormLanguage(event.target.value, t))}>
                                    {
                                        languages.map((l, i) => (<MenuItem key={i} value={l}>{l}</MenuItem>))
                                    }
                                </Select>
                                <FormHelperText>{formData.languageErrorText}</FormHelperText>
                            </FormControl>
                        </React.Fragment> :
                        null
                }
            </div>

        </Container>
    );
}

export default AddTemplate;