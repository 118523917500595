import React from 'react';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, TextField, Container, Breadcrumbs, Typography, Divider,
    Link, Slider, Paper, List, ListItem, ListItemText, ListSubheader, ListItemIcon
} from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { registerWebAccountUser, updateFormEmail, updateFormLastName, updateFormFirstName, updateFormPhoneNumber, updateFormUserType } from '../../actions/addWebAccountUser';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';
import useUser from '../../user/useUser';

const AddWebAccountUser = () => {
    const { t, i18n } = useTranslation();
    const { user, token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.addWebAccountUser.formData);
    const generalErrorText = useSelector(state => state.addWebAccountUser.generalErrorText);
    const history = useHistory();
    const location = window.location;
    const dispatch = useDispatch();
    const language = i18n.languages[0];
    return (
        <Container>
            <Breadcrumbs style={{ marginTop: 20 }}>
                <Link color="inherit" component={RouterLink} to={constants.ROUTE_ACCOUNT_USERS}>{t("WebAccountUsers")}</Link>
                <Typography color="textPrimary">{t("Add")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <Typography style={{ marginTop: 10 }} variant="subtitle1">{t("AddWebAccountUserSubtitle")}</Typography>

            <form noValidate={true}>

                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        type="text"
                        error={!!formData.firstNameErrorText}
                        label={t("FirstName")}
                        helperText={formData.firstNameErrorText}
                        value={formData.firstName}
                        onChange={event => dispatch(updateFormFirstName(event.target.value, t))} />
                </div>
                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        type="text"
                        error={!!formData.lastNameErrorText}
                        label={t("LastName")}
                        helperText={formData.lastNameErrorText}
                        value={formData.lastName}
                        onChange={event => dispatch(updateFormLastName(event.target.value, t))} />
                </div>
                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        type="text"
                        error={!!formData.phoneNumberErrorText}
                        label={t("PhoneNumber")}
                        helperText={formData.phoneNumberErrorText}
                        value={formData.phoneNumber}
                        onChange={event => dispatch(updateFormPhoneNumber(event.target.value, t))} />
                </div>

                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        type="email"
                        error={!!formData.emailErrorText}
                        label={t("EMail")}
                        helperText={formData.emailErrorText}
                        value={formData.email}
                        onChange={event => dispatch(updateFormEmail(event.target.value, t))} />
                </div>

                <div style={{ marginTop: 10, maxWidth: 600 }}>
                    <Paper>
                        <List dense component="nav" subheader={<ListSubheader>{t("UserPermissions")}</ListSubheader>}>
                            <ListItem button
                                onClick={() => dispatch(updateFormUserType(constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR, t))}>
                                {
                                    formData.userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR ?
                                    <ListItemIcon><CheckIcon /></ListItemIcon> :
                                    null
                                }
                                    <ListItemText
                                    inset={formData.userType < constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR}
                                    primary={t("UserPermissionsMonitor")} />                                    
                            </ListItem>
                            <ListItem button
                                onClick={() => dispatch(updateFormUserType(constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ, t))}>
                                {
                                    formData.userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ ?
                                        <ListItemIcon><CheckIcon /></ListItemIcon> :
                                        null
                                }
                                <ListItemText
                                    inset={formData.userType < constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ}
                                    primary={t("UserPermissionsConfigurationRead")} />
                            </ListItem>
                            <ListItem button
                                onClick={() => dispatch(updateFormUserType(constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE, t))}>
                                {
                                    formData.userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE ?
                                        <ListItemIcon><CheckIcon /></ListItemIcon> :
                                        null
                                }
                                <ListItemText
                                    inset={formData.userType < constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE}
                                    primary={t("UserPermissionsConfigurationWrite")} />
                            </ListItem>
                            <ListItem button
                                onClick={() => dispatch(updateFormUserType(constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM, t))}>
                                {
                                    formData.userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM ?
                                        <ListItemIcon><CheckIcon /></ListItemIcon> :
                                        null
                                }
                                <ListItemText
                                    inset={formData.userType < constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM}
                                    primary={t("UserPermissionsSystem")} />
                            </ListItem>
                        </List>
                    </Paper>

                    {/*<Paper variant="elevation" style={{ padding: 10 }}>
                        <Typography gutterBottom>{t("Permissions")}</Typography>
                        <Slider
                            value={formData.userType}
                            step={null}
                            min={constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR}
                            max={constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM}
                            marks={[
                                { value: constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR },
                                { value: constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ },
                                { value: constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE },
                                { value: constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM }
                            ]}
                            valueLabelDisplay="off"
                            onChange={(event, newValue) => dispatch(updateFormUserType(newValue, t))}
                        />
                        <List dense component="nav">
                            <ListItem button
                                selected={formData.userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR}
                                onClick={() => dispatch(updateFormUserType(constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR, t))}>
                                <ListItemText primary={t("UserPermissionsMonitor")} secondary={t("UserPermissionDetailsMonitor")} />
                            </ListItem>
                            <ListItem button
                                selected={formData.userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ}
                                onClick={() => dispatch(updateFormUserType(constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ, t))}>
                                <ListItemText primary={t("UserPermissionsConfigurationRead")} secondary={t("UserPermissionDetailsConfigurationRead")} />
                            </ListItem>
                            <ListItem button
                                selected={formData.userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE}
                                onClick={() => dispatch(updateFormUserType(constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE, t))}>
                                <ListItemText primary={t("UserPermissionsConfigurationWrite")} secondary={t("UserPermissionDetailsConfigurationWrite")} />
                            </ListItem>
                            <ListItem button
                                selected={formData.userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM}
                                onClick={() => dispatch(updateFormUserType(constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM, t))}>
                                <ListItemText primary={t("UserPermissionsSystem")} secondary={t("UserPermissionDetailsSystem")} />
                            </ListItem>
                        </List>

                    </Paper>*/}
                </div>

                <div style={{ marginTop: 10 }}>
                    <Button variant="contained" color="primary" disabled={loading || !formData.isValid} onClick={() =>
                        dispatch(registerWebAccountUser(formData.email, formData.firstName, formData.lastName, formData.phoneNumber, formData.userType, history, location, token, unsetUser, t, language))}>{t("Save")}</Button>
                </div>
            </form>
        </Container>
    );
}

export default AddWebAccountUser;