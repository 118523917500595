import axios from 'axios';

export function getNotifications(macs, notificationTypes, dateRange, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/notification/findNotifications', {
        macs,
        notificationTypes,
        dateRange
    }, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.notifications);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}
