import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    licenses: [],
    alarmSystems: [],
    generalErrorText: null,
    displayAlarmSystems: {
        alarmSystems: [],
        alarmSystemId: 0,
        searchText: '',
        sort: {
            by: constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC,
            direction: "asc"
        },
    },
};

const managementAlarmSystems = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__UPDATE_DISPLAY_ALARM_SYSTEMS:
            newState = update(state, {
                displayAlarmSystems: { $set: action.data.displayAlarmSystems }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_LICENSES_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                licenses: { $set: action.data.licenses }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_LICENSES_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                licenses: { $set: [] }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                alarmSystems: { $set: action.data.alarmSystems }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                alarmSystems: { $set: [] }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default managementAlarmSystems;