import * as constants from '../constants';
import update from 'immutability-helper';
import moment from 'moment';

const INITIAL_STATE = {
    template: null,
    //configuration: null,
    //dataView: null,
    pageId: 0,
    tableId: 0,
    rowId: 0,
    cellId: 0,
    //rowId: null,
    //columnId: null,


    //const configurationState = {
    //    pages: [],
    //    tables: [],
    //    rows: [],
    //    cells: [],
    //    parameters: [],
    //    modifiedParameterIndices: [],
    //    validationErrorParameterIndices: [],
    //    excludedParameterIndices: [],
    //    errorParameterIndices: []
    //};

    //const parameter = {
    //    identifier: number,
    //    order: number,
    //    bit: number,
    //    value: string,
    //    modified: boolean, // if true, write even if value == newValue
    //    read: boolean,
    //    config: boolean,
    //    paramInfoParameterType: number,
    //    paramInfoLength: number,
    //    pageId: number,
    //    tableId: number,
    //    rowId: number,
    //    cellId: number,
    //    newValue: string,
    //    validationErrorText: string,
    //    excluded: boolean,
    //    errorText: string
    //}
    configuration: null,
    //parameters: [],
    //dirtyParameters: [], // {identifier: 1, order: 1, bit: 0} - parameters to write
    //excludedParameters: [],
    generalErrorText: null,
    //parameterError: null,
    parameterErrorsDialog: {
        isOpen: false
    },
    deleteDialog: {
        isOpen: false
    }
};

const template = (state = INITIAL_STATE, action) => {
    const start = moment();

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_TEMPLATE__OPEN_DELETE_DIALOG:
            newState = update(state, {
                deleteDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        
        case constants.ACTION_TYPE_TEMPLATE__OPEN_PARAMETER_ERRORS_DIALOG:
            newState = update(state, {
                parameterErrorsDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_TEMPLATE__LOAD_TEMPLATE_SUCCESS:
            newState = update(state, {
                template: { $set: action.data.template },
                configuration: { $set: action.data.configuration },
                generalErrorText: { $set: null }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_TEMPLATE__LOAD_TEMPLATE_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_TEMPLATE__UPDATE_TEMPLATE_SUCCESS:
            newState = update(state, {
                //configuration: { $set: action.data.configuration },
                generalErrorText: { $set: null }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        //newState = update(state, {
        //    writeDialog: { $set: INITIAL_STATE.writeDialog },
        //    excludedParameters: { $set: action.data.excludedParameters || [] },
        //    dirtyParameters: { $set: [] },
        //    generalErrorText: { $set: null },
        //    configuration: {
        //        parameters: {
        //            $apply: arr => arr.map(p => {
        //                const dirtyParameter = state.dirtyParameters.find(dp => dp.identifier === p.identifier && dp.order === p.order && dp.bit === p.bit);
        //                if (dirtyParameter) {
        //                    const excluded = action.data.excludedParameters ?
        //                        action.data.excludedParameters.some(ep => ep.identifier === p.identifier && ep.order === p.order && ep.bit === p.bit) :
        //                        false;
        //                    if (!excluded)
        //                        return Object.assign({}, p, { value: dirtyParameter.value });
        //                }
        //                return p;
        //            })
        //        }
        //    }
        //});
        //console.log(action.type, state, newState, moment().diff(start));
        //return newState;
        case constants.ACTION_TYPE_TEMPLATE__UPDATE_TEMPLATE_FAIL:
            newState = update(state, {
                configuration: { $set: action.data.configuration },
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        //newState = update(state, {
        //    generalErrorText: { $set: action.data.generalErrorText },
        //    parameterError: { $set: action.data.parameterError },
        //    dirtyParameters: {
        //        $apply: (arr) => {
        //            if (!action.data.parameterValidationErrors)
        //                return arr;
        //            const newArr = [];
        //            for (const dp of arr) {
        //                const error = action.data.parameterValidationErrors.find(p => p.identifier === dp.identifier &&
        //                    p.order === dp.order && p.bit === dp.bit);
        //                if (error) {
        //                    newArr.push({
        //                        ...dp,
        //                        parameterErrorText: error.parameterErrorText
        //                    })
        //                } else {
        //                    newArr.push(dp);
        //                }
        //            }
        //            return newArr;
        //        }
        //    },
        //    writeDialog: {
        //        formData: { $merge: action.data.formData }
        //    }
        //});
        //newState = update(newState, {
        //    writeDialog: {
        //        formData: {
        //            isValid: { $set: newState.readDialog.formData.codeValid }
        //        }
        //    }
        //});
        //console.log(action.type, state, newState, moment().diff(start));
        //return newState;

        

        case constants.ACTION_TYPE_TEMPLATE__BROWSE_CONFIGURATION:
            newState = update(state, {
                pageId: { $set: action.data.pageId },
                tableId: { $set: action.data.tableId },
                rowId: { $set: action.data.rowId },
                cellId: { $set: action.data.cellId }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_TEMPLATE__UPDATE_VALUE:
            newState = update(state, {
                configuration: { $set: action.data.configuration }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        //const index = state.dirtyParameters.findIndex(p => p.identifier === action.data.identifier &&
        //    p.order === action.data.order && p.bit === action.data.bit);

        //const dirtyParameter = {
        //    identifier: action.data.identifier,
        //    order: action.data.order,
        //    bit: action.data.bit,
        //    value: action.data.value,
        //    parameterErrorText: action.data.parameterErrorText,
        //    pageId: action.data.pageId,
        //    tableId: action.data.tableId,
        //    rowId: action.data.rowId
        //};

        //if (index >= 0) {
        //    newState = update(state, {
        //        dirtyParameters: { [index]: { $set: dirtyParameter } }
        //    });
        //} else {
        //    newState = update(state, {
        //        dirtyParameters: {
        //            $push: [dirtyParameter]
        //        }
        //    });
        //}
        //console.log(action.type, state, newState, moment().diff(start));
        //return newState;

        //case constants.ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_SUCCESS:
        //    return state;
        //case constants.ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_FAIL:
        //    newState = update(state, {
        //        generalErrorText: { $set: action.data.generalErrorText },
        //        dirtyParameters: {
        //            $apply: (arr) => {
        //                if (!action.data.parameterValidationErrors)
        //                    return arr;
        //                const newArr = [];
        //                for (const dp of arr) {
        //                    const error = action.data.parameterValidationErrors.find(p => p.identifier === dp.identifier &&
        //                        p.order === dp.order && p.bit === dp.bit);
        //                    if (error) {
        //                        newArr.push({
        //                            ...dp,
        //                            parameterErrorText: error.parameterErrorText
        //                        })
        //                    } else {
        //                        newArr.push(dp);
        //                    }
        //                }
        //                return newArr;
        //            }
        //        }
        //    });
        //    console.log(action.type, state, newState, moment().diff(start));
        //    return newState;
        //case constants.ACTION_TYPE_CONFIGURATION__CANCEL_UPDATE_PARAMETERS:
        //    newState = update(state, {
        //        dirtyParameters: { $set: [] }
        //    });
        //    console.log(action.type, state, newState, moment().diff(start));
        //    return newState;
        case constants.ACTION_TYPE_TEMPLATE__DELETE_TEMPLATE_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_TEMPLATE__DELETE_TEMPLATE_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }

}

export default template;