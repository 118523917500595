import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

const EditableTextField = (props) => {
    const [isEdit, setIsEdit] = useState(false);

    const { onMouseEnter, onBlur, InputProps, readOnly, ...otherProps } = props;
    if (onMouseEnter)
        console.error("onMouseEnter override");
    if (onBlur)
        console.error("onBlur override");

    return <TextField
        onMouseEnter={() => setIsEdit(true)}
        onMouseLeave={() => setIsEdit(false)}
        InputProps={{ disableUnderline: readOnly || !isEdit, readOnly, ...InputProps }}
        InputLabelProps={{ shrink: true }}
        {...otherProps} />
}

export default EditableTextField;