import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    licenseSettings: [],
    generalErrorText: null,
    formData: {
        videolLicensePrice: 0,
        videoLicensePriceErrorText: null,
        videoLicensePriceValid: false,
        controlLicensePrice: 0,
        controlLicensePriceErrorText: null,
        controlLicensePriceValid: false,
        vat: 0, //value added tax
        vatErrorText: null,
        vatValid: false,
        isValid: false,
        isDirty: false
    }
};

const managementGeneralSettings = (state = INITIAL_STATE,action) => {

    let vat,controlLicensePrice, videoLicensePrice;
    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__UPDATE_FORM_DATA:
            newState = update(state,{
                formData: { $merge: action.data.formData }
            });
            newState = update(newState,{
                formData: {
                    isDirty: { $set: true },
                    isValid: { $set: newState.formData.videoLicensePriceValid && newState.formData.controlLicensePriceValid && newState.formData.vatValid }
                }
            })
            console.log(action.type,state,newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__GET_GENERAL_SETTINGS_SUCCESS:
            vat = action.data.vatSettings;
            controlLicensePrice = action.data.licenseSettings.permissions.find(p => p.name === "Control").priceCents / 100;
            videoLicensePrice = action.data.licenseSettings.permissions.find(p => p.name === "Video").priceCents / 100;
            newState = update(state,{
                generalErrorText: { $set: null },
                formData: {
                    videoLicensePrice: { $set: videoLicensePrice },
                    videoLicensePriceErrorText: { $set: null },
                    videoLicensePriceValid: { $set: true },
                    controlLicensePrice: { $set: controlLicensePrice },
                    controlLicensePriceErrorText: { $set: null },
                    controlLicensePriceValid: { $set: true },
                    vat: {$set: vat},
                    vatErrorText: { $set: null },
                    vatValid: { $set: true },
                    isValid: { $set: true },
                    isDirty: { $set: false }
                }
            });
            console.log(action.type,state,newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__GET_GENERAL_SETTINGS_FAIL:
            newState = update(state,{
                generalErrorText: { $set: action.data.generalErrorText },
            });
            console.log(action.type,state,newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__SET_GENERAL_SETTINGS_SUCCESS:
            newState = update(state,{
                generalErrorText: { $set: null },
                formData: {
                    videoLicensePriceErrorText: { $set: null },
                    videoLicensePriceValid: { $set: true },
                    controlLicensePriceErrorText: { $set: null },
                    controlLicensePriceValid: { $set: true },
                    vatErrorText: { $set: null },
                    vatValid: { $set: true },
                    isValid: { $set: true },
                    isDirty: { $set: false }
                }
            });
            console.log(action.type,state,newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__SET_GENERAL_SETTINGS_FAIL:
            newState = update(state,{
                generalErrorText: { $set: action.data.generalErrorText },
            });
            console.log(action.type,state,newState);
            return newState;
        default:
            return state;
    }
}

export default managementGeneralSettings;