import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    useMediaQuery, Grid, Button, TextField, Container, List, ListSubheader, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction,
    FormControl, FormLabel, FormGroup, FormControlLabel, FormHelperText, Checkbox,
    IconButton, Typography, Breadcrumbs, Link, Divider, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Drawer, Dialog, DialogContent, DialogActions
} from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import * as constants from '../../constants';
import update from 'immutability-helper';
import { getNotifications } from '../../actions/monitoring';
import useUser from '../../user/useUser';
import moment from 'moment';
import MissingLicense from '../common/MissingLicense';

function updateStartDate(dateRange, startDate) {
    if (!startDate)
        return null;
    const date = startDate.startOf('date');
    const range = dateRange || { startDate: null, endDate: null };
    const newRange = update(range, {
        startDate: { $set: date },
        endDate: {
            $apply: endDate => {
                if (endDate) {
                    return moment.max(date, endDate);
                } else {
                    const today = moment().startOf('date');
                    return moment.max(date, today);
                }
            }
        }
    });
    return newRange;
}

function updateEndDate(dateRange, endDate) {
    if (!endDate)
        return null;
    const date = endDate.startOf('date');
    const range = dateRange || { startDate: null, endDate: null };
    const newRange = update(range, {
        startDate: {
            $apply: startDate => {
                if (startDate) {
                    return moment.min(date, startDate);
                } else {
                    return date.clone().subtract(7, 'days');
                }
            }
        },
        endDate: { $set: date }
    });
    return newRange;
}

const MonitoringFilters = () => {
    const [licenseDialogAlarmSystem, setLicenseDialogAlarmSystem] = useState(null);
    const dispatch = useDispatch();

    const loading = useSelector(state => state.global.loading);
    const alarmSystems = useSelector(state => state.monitoring.alarmSystems);
    const notifications = useSelector(state => state.monitoring.notifications);
    const displayNotifications = useSelector(state => state.monitoring.displayNotifications);
    const formData = useSelector(state => state.monitoring.formData);
    const generalErrorText = useSelector(state => state.monitoring.generalErrorText);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const language = i18n.languages[0];
    const licensedAlarmSystems = alarmSystems
        .filter(a => a.license.installerLicenseLevel >= constants.INSTALLER_LICENSE_LEVEL_MONITORING);
    const unlicensedAlarmSystems = alarmSystems
        .filter(a => a.license.installerLicenseLevel < constants.INSTALLER_LICENSE_LEVEL_MONITORING);
    return (
        <React.Fragment>
            {/*<Button style={{position: "fixed", top: 10, left: 10 }} onClick={() => this.setState({ isFilterOpen: false })}>{t("Close")}</Button>*/}
            <Dialog open={!!licenseDialogAlarmSystem} fullWidth maxWidth="xs"
                onClose={() => setLicenseDialogAlarmSystem(null)}>
                {/*<DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>*/}
                <DialogContent>
                    {
                        licenseDialogAlarmSystem ?
                            <MissingLicense alarmSystem={licenseDialogAlarmSystem}
                                isInstaller licenseLevel={constants.INSTALLER_LICENSE_LEVEL_MONITORING} /> :
                            null
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => setLicenseDialogAlarmSystem(null)} color="primary">
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>

            <List dense subheader={<ListSubheader
                style={{ backgroundColor: theme.palette.background.paper }} component="li">{t("DateRange")}</ListSubheader>}>
                <ListItem role={undefined} dense>
                    <DatePicker
                        disabled={loading}
                        autoOk
                        variant="dialog"
                        label={t("StartDate")}
                        clearable
                        disableFuture
                        value={formData.dateRange ? formData.dateRange.startDate : null}
                        onChange={(date) => dispatch(getNotifications(alarmSystems, formData.macs, formData.notificationTypes,
                            updateStartDate(formData.dateRange, date), displayNotifications.searchText, displayNotifications.sort, displayNotifications.notificationId, history, token, unsetUser, t, language))} />
                </ListItem>
                <ListItem role={undefined} dense>
                    <DatePicker
                        disabled={loading}
                        autoOk
                        variant="dialog"
                        label={t("EndDate")}
                        clearable
                        disableFuture
                        value={formData.dateRange ? formData.dateRange.endDate : null}
                        onChange={(date) => dispatch(getNotifications(alarmSystems, formData.macs, formData.notificationTypes,
                            updateEndDate(formData.dateRange, date), displayNotifications.searchText, displayNotifications.sort, displayNotifications.notificationId,  history, token, unsetUser, t, language))} />
                </ListItem>
            </List>

            <List dense subheader={<ListSubheader
                style={{ backgroundColor: theme.palette.background.paper }} component="li">{t("AlarmSystems")}</ListSubheader>}>
                <ListItem role={undefined} dense button
                    onClick={() => dispatch(getNotifications(alarmSystems, update(formData.macs, {
                        $set: licensedAlarmSystems.every(a => formData.macs.includes(a.mac)) ?
                            [] :
                            licensedAlarmSystems.map(a => a.mac)
                    }), formData.notificationTypes, formData.dateRange, displayNotifications.searchText, displayNotifications.sort, displayNotifications.notificationId, history, token, unsetUser, t, language))}>
                    <ListItemIcon>
                        <Checkbox disabled={loading || !licensedAlarmSystems.length}
                            edge="start"
                            checked={licensedAlarmSystems.every(a => formData.macs.includes(a.mac))}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText primary={t("AllAlarmSystems")} />
                </ListItem>
                {
                    licensedAlarmSystems.map((a, i) =>
                        <ListItem key={i} role={undefined} dense button
                            onClick={() => dispatch(getNotifications(alarmSystems, update(formData.macs,
                                formData.macs.includes(a.mac) ?
                                    { $apply: macs => macs.filter(m => m != a.mac) } :
                                    { $push: [a.mac] }
                            ), formData.notificationTypes, formData.dateRange, displayNotifications.searchText, displayNotifications.sort, displayNotifications.notificationId, history, token, unsetUser, t, language))}>
                            <ListItemIcon>
                                <Checkbox disabled={loading}
                                    edge="start"
                                    checked={formData.macs.includes(a.mac)}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={a.name} secondary={a.mac} />
                        </ListItem>)
                }
                {
                    unlicensedAlarmSystems.map((a, i) =>
                        <ListItem key={i} role={undefined} dense button
                            onClick={() => setLicenseDialogAlarmSystem(a)}>
                            
                            <ListItemIcon>
                                <WarningIcon />
                            </ListItemIcon>
                            <ListItemText primary={a.name} secondary={a.mac} />
                        </ListItem>)
                }
            </List>

            <List dense subheader={<ListSubheader
                style={{ backgroundColor: theme.palette.background.paper }} component="li">{t("MonitoringTypes")}</ListSubheader>}>
                <ListItem role={undefined} dense button
                    onClick={() => dispatch(getNotifications(alarmSystems, formData.macs, update(formData.notificationTypes, {
                        $set: constants.NOTIFICATION_TYPES.every(n => formData.notificationTypes.includes(n.type)) ?
                            [] :
                            constants.NOTIFICATION_TYPES.map(n => n.type)
                    }), formData.dateRange, displayNotifications.searchText, displayNotifications.sort, displayNotifications.notificationId, history, token, unsetUser, t, language))}>
                    <ListItemIcon>
                        <Checkbox disabled={loading}
                            edge="start"
                            checked={constants.NOTIFICATION_TYPES.every(n => formData.notificationTypes.includes(n.type))}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText primary={t("AllNotificationTypes")} />
                </ListItem>
                {
                    constants.NOTIFICATION_TYPES.map((n, i) =>
                        <ListItem key={i} role={undefined} dense button
                            onClick={() => dispatch(getNotifications(alarmSystems, formData.macs, update(formData.notificationTypes,
                                formData.notificationTypes.includes(n.type) ?
                                    { $apply: notificationTypes => notificationTypes.filter(nt => nt != n.type) } :
                                    { $push: [n.type] }
                            ), formData.dateRange, displayNotifications.searchText, displayNotifications.sort, displayNotifications.notificationId, history, token, unsetUser, t, language))}>
                            <ListItemIcon>
                                <Checkbox disabled={loading}
                                    edge="start"
                                    checked={formData.notificationTypes.includes(n.type)}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={t(`MonitoringType${n.name}`)} />
                        </ListItem>)
                }
            </List>

            {/*<div style={{ padding: 5 }}>
                                <Button color="primary" disabled={loading || !formData.macs.length || !formData.notificationTypes.length}
                                    onClick={() => getNotifications(formData.macs, formData.notificationTypes, history, token, unsetUser, t)}>{t("Filter")}</Button>
                            </div>*/}

        </React.Fragment>
    );
}

export default MonitoringFilters;