import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Paper, TableContainer, Table, TableBody, TableCell, TableRow, TableHead, TableFooter } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import Parameter from './Parameter';
import * as constants from '../../constants';

const TableViewSplit = ({ browseConfiguration, updateValue, configuration, pageId, tableId, rowId, cellId, disableCode}) => {
    //const start = moment();
    //let onChangePage = null;

    const { id } = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    

    //const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    //const configuration = useSelector(state => state.configuration.configuration);
    //const dirtyParameters = useSelector(state => state.configuration.dirtyParameters);
    //const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    const dispatch = useDispatch();

    const tableRef = useRef(null);
    const [tableLeft, setTableLeft] = useState(0);
    useEffect(() => {
        if (tableRef.current) {
            //console.log("tableRef", tableRef.current);
            setTableLeft(tableRef.current.left);
        }
    }, [tableRef.current]);

    //useEffect(() => {
    //    if (onChangePage)
    //        onChangePage(null, paginationPage);
    //}, [paginationPage]);

    //const pageView = configuration.pages[pageId];
    const tableView = configuration.tables[tableId];
    const colHeaderList = tableView.colHeaderList;
    const frozenColumnCount = tableView.frozenColumnCount;
    const isSplit = tableView.split;
    //const rowViewList = tableView.rowViewList;
    const rowViewList = configuration.rows.filter(r => r.tableId === tableId);

    //const take = 10;
    const count = rowViewList.length;
    let paginationPage;
    if (rowId < tableView.firstRowId)
        paginationPage = 1;
    else if (rowId > tableView.lastRowId)
        paginationPage = count;
    else
        paginationPage = rowId - tableView.firstRowId + 1;

    //const end = moment();
    //console.log("start render", end.diff(start));
    console.log({ pageId, tableId, rowId, count, paginationPage, cellId, firstRowId: tableView.firstRowId, lastRowId: tableView.lastRowId })
    return <div>
        <TableContainer ref={tableRef}>
            <Table size="small" padding="checkbox">
                {/*<TableHead>
                    <TableRow>
                        {colHeaderList.map((c, i) => <TableCell key={i}>{c.value}</TableCell>)}
                    </TableRow>
                </TableHead>*/}

                <TableBody>
                    {
                        colHeaderList.map((c, i) =>
                            <TableRow key={i}>
                                <TableCell>
                                    {c.value}
                                </TableCell>
                                <TableCell>
                                    <Parameter
                                        cell={configuration.cells.filter(c => c.rowId === rowId)[i]}
                                        updateValue={updateValue}
                                        configuration={configuration}
                                        disableCode={disableCode} />
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>

        <div style={{ position: "fixed", bottom: 0, left: tableLeft, zIndex: 100 }}>
            <Paper>
                <Pagination
                    style={{ marginTop: 10, marginBottom: 10 }}
                    size="small"
                    color="primary"
                    disabled={loading}
                    count={count}
                    page={paginationPage}
                    onChange={(event, page) => dispatch(browseConfiguration(history, id, pageId, tableId, tableView.firstRowId + page - 1, configuration.rows[tableView.firstRowId + page - 1].firstCellId))} />
            </Paper>
        </div>
    </div>
}

export default TableViewSplit;