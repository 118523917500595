import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid, Button, TextField, Container, CircularProgress, AppBar, ButtonGroup, Select, MenuItem, Checkbox,
    List, ListItem, ListItemText, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Tooltip, IconButton, Popover, Typography
} from '@material-ui/core';
import { Warning as WarningIcon, Edit as EditIcon, ImportContacts as ImportContactsIcon } from '@material-ui/icons';
import * as constants from '../../constants';
import { useTranslation } from 'react-i18next';

const Parameter = ({ cell, updateValue, configuration, disableCode }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const dispatch = useDispatch();

    if (!cell)
        return null;

    //const identifier = cell.identifier;
    //const order = cell.order;
    //const bit = cell.bitNumber;

    const parameter = configuration.parameters[cell.parameterIndex];
    //const parameter = configuration.parameters.find(p => p.identifier === identifier && p.order === order && p.bit === bit)
    //const parameter = Number.isInteger(paramIndex) ? configurationData.parameters[paramIndex] : null;

    //let value;
    //let isReadOnly;
    //let isModified;
    //let isDirty;
    //let isRead;
    //let paramInfoParameterType;
    //let paramInfoLength;
    //let parameterErrorText;
    //if (parameter) {
    //    const dirtyParameter = dirtyParameters.find(p => p.identifier === identifier &&
    //        p.order === order && p.bit === bit);
    //    value = dirtyParameter ? dirtyParameter.value : parameter.value;
    //    isReadOnly = !parameter.config || cell.isReadOnly; // !Model.ParamInfo.Attributes.HasFlag(ParameterAttributes.Config);
    //    isModified = parameter.modified;
    //    isDirty = dirtyParameter ? true : false;
    //    isRead = !parameter.modified && parameter.read;
    //    paramInfoParameterType = parameter.paramInfoParameterType;
    //    paramInfoLength = parameter.paramInfoLength;
    //    parameterErrorText = dirtyParameter ? dirtyParameter.parameterErrorText : null;
    //} else {
    //    value = cell.value;
    //    isReadOnly = true;
    //    isModified = false;
    //    isDirty = false;
    //    isRead = false;
    //    paramInfoParameterType = 0;
    //    paramInfoLength = 0;
    //    parameterErrorText = null;
    //}
    let value;
    let isReadOnly;
    let isModified;
    let isDirty;
    let isRead;
    let validationErrorText;
    if (parameter) {
        value = parameter.newValue;
        isReadOnly = !parameter.config || cell.isReadOnly; // !Model.ParamInfo.Attributes.HasFlag(ParameterAttributes.Config);
        isModified = parameter.modified;
        isDirty = parameter.value !== parameter.newValue;
        isRead = !parameter.modified && parameter.read;
        validationErrorText = parameter.validationErrorText;
    } else {
        value = cell.value;
        isReadOnly = true;
        isModified = false;
        isDirty = false;
        isRead = false;
        validationErrorText = null;
    }

    let input = null;
    switch (cell.type) {
        case constants.CELL_VIEW_TYPE_PHONE_NUMBER:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading}
                style={{ minWidth: 150, direction: 'ltr' }}
                type="text"
                label=""
                value={value || ''}
                placeholder="--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_DEC_NUMBER:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading}
                style={{ minWidth: 100, direction: 'ltr' }}
                type="number"
                inputProps={{ min: 0 }}
                label=""
                value={value || ''}
                placeholder="--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_SELECTOR:
            //if (isReadOnly) {
            //    let index = 0;
            //    if (value && Number.isInteger(+value))
            //        index = +value - 1;
            //    return strings[cell.selectorList].split(',')[index];
            //    {/*<text>@Localizer[Model.cell.SelectorList].Value.Split(',')[index]</text>*/ }
            //}
            //else {
            let items = t(cell.selectorList).split(',');
            if (Number.isInteger(cell.maxAllowedValue))
                items = items.slice(0, cell.maxAllowedValue);
            input = isReadOnly ? items[Number.parseInt(value) + 1] : <Select
                disabled={isReadOnly || loading}
                style={{ width: 150 }}
                value={value || ''}
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))}>
                {items.map((s, i) => <MenuItem key={i} value={(i + 1).toString()}>{s}</MenuItem>)}
            </Select>
            break;
        //}
        case constants.CELL_VIEW_TYPE_TIME:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading}
                style={{ minWidth: 50, direction: 'ltr' }}
                type="text"
                label=""
                value={value || ''}
                placeholder="--:--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_DATE:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading}
                style={{ minWidth: 50, direction: 'ltr' }}
                type="text"
                label=""
                value={value || ''}
                placeholder="--/--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_IP_ADDRESS:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading}
                style={{ minWidth: 100, direction: 'ltr' }}
                type="text"
                inputProps={{ maxLength: 15 }}
                label=""
                value={value || ''}
                placeholder="--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_FLAG:
        case constants.CELL_VIEW_TYPE_STATUS:
            const isChecked = (value && value.toUpperCase() === 'TRUE');
            input = <Checkbox
                color="primary"
                disabled={loading || isReadOnly}
                checked={isChecked}
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.checked ? 'True' : 'False', configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_CODE:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading || disableCode}
                style={{ minWidth: 50, direction: 'ltr' }}
                type="text"
                inputMode="numeric"
                label=""
                value={value || ''}
                placeholder="--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_HEX_NUMBER:
        case constants.CELL_VIEW_TYPE_ESN:
        case constants.CELL_VIEW_TYPE_BYTE_ARRAY:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading}
                style={{ minWidth: 150, direction: 'ltr' }}
                type="text"
                label=""
                value={value || ''}
                placeholder="--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_ENCRYPTION_KEY:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading || disableCode}
                style={{ minWidth: 150, direction: 'ltr' }}
                type="text"
                label=""
                value={value || ''}
                placeholder="--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
        case constants.CELL_VIEW_TYPE_TEXT:
        default:
            input = isReadOnly ? value : <TextField
                disabled={isReadOnly || loading}
                style={{ minWidth: 150 }}
                type="text"
                label=""
                value={value || ''}
                placeholder="--"
                onChange={event => dispatch(updateValue(cell.parameterIndex, event.target.value, configuration, t))} />
            break;
    }
    return <div>
        <div style={{ display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center" }}>
            <div style={{ flexGrow: 0, overflowX: "hidden" }}>
                {input}
            </div>
            <div style={{ flexGrow: 0 }}>
                {
                    validationErrorText ?
                        <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
                            <WarningIcon style={{ fontSize: 15 }} color="error" />
                        </IconButton> :
                        (isModified || isDirty ?
                            <EditIcon style={{ fontSize: 15 }} color={isDirty ? "inherit" : "disabled"} /> :
                            (isRead ? <ImportContactsIcon style={{ fontSize: 15 }} color="disabled" /> : null))
                }
                {/*<Tooltip title={validationErrorText}>
                            <WarningIcon style={{ fontSize: 15 }} color="error" />
                        </Tooltip>*/}
            </div>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                <Typography style={{ padding: 5 }}>{validationErrorText}</Typography>
            </Popover>
        </div>

        {/*<Grid container spacing={1}>
                <Grid item xs="auto">
                    {input}
                </Grid>
                <Grid item xs="auto">
                    {
                        parameterErrorText ?
                            <Tooltip title={parameterErrorText}>
                                <IconButton>
                                    <WarningIcon fontSize="small" color="error" />
                                </IconButton>
                            </Tooltip> :
                            null
                    }
                </Grid>
            </Grid>*/}
    </div>
}

export default Parameter;