import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    useMediaQuery, ButtonGroup, Button,
    IconButton, TextField, Container, Breadcrumbs, Typography, Divider, Link,
    Stepper, MobileStepper, Step, StepLabel, FormControl, FormLabel, InputLabel, Select, FormHelperText,
    FormControlLabel, Switch, FormGroup, Grid, Chip, Tabs, Tab,
    Dialog, DialogTitle, DialogContent, DialogActions, Paper, Menu, MenuItem,
    List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, ListSubheader, Checkbox, AppBar, Toolbar
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Delete as DeleteIcon, Edit as EditIcon, CheckCircle as CheckCircleIcon, MoreVert as MoreVertIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { getTransactionResult } from '../../actions/alarmSystemTransactionResult';
import equal from 'fast-deep-equal';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import AlarmSystemSummary from './AlarmSystemSummary';
import AlarmSystemWizard from './AlarmSystemWizard';
import useUser from '../../user/useUser';
import DeleteDialog from './DeleteDialog';
import LicenseDialog from './LicenseDialog';
import LicenseTranzilaDialog from './LicenseTranzilaDialog';
import * as constants from '../../constants';
import EditableTextField from '../common/EditableTextField';
import moment from 'moment';
import { flexbox } from '@material-ui/system';
import { getTextsForPermission } from '../../helpers/textHelpers';

const AlarmSystemTransactionResult = () => {
    //const [anchorEl, setAnchorEl] = useState(null);

    const { id, transactionId } = useParams();

    const { t, i18n } = useTranslation();
    const { isWebAccountUserSystem, isWebAccountAdmin, token, unsetUser } = useUser();
    const history = useHistory();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.alarmSystemTransactionResult.alarmSystem);
    const license = useSelector(state => state.alarmSystemTransactionResult.license);
    const permissionName = useSelector(state => state.alarmSystemTransactionResult.permissionName);
    const paymentSuccess = useSelector(state => state.alarmSystemTransactionResult.paymentSuccess);
    const confirmationCode = useSelector(state => state.alarmSystemTransactionResult.confirmationCode);
    //const licenseOptions = useSelector(state => state.alarmSystemTransactionResult.licenseOptions);
    //const installerLicenseOptions = useSelector(state => state.alarmSystemTransactionResult.installerLicenseOptions);
    //const userLicenseOptions = useSelector(state => state.alarmSystemTransactionResult.userLicenseOptions);
    const generalErrorText = useSelector(state => state.alarmSystemTransactionResult.generalErrorText);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getTransactionResult(transactionId, history, token, unsetUser, t, language));
    }, [dispatch, transactionId, token]);

    //const installerLicenseOption = installerLicenseOptions.find(o => o.level === formData.installerLicenseLevel);
    //const userLicenseOption = userLicenseOptions.find(o => o.level === formData.userLicenseLevel);


    return (
        <Container>
            
            <Breadcrumbs style={{ marginTop: 20 }}>
                <Link color="inherit" component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS}>{t("AlarmSystems")}</Link>
                {alarmSystem ? <Link color="inherit" component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS_DETAILS__ID.replace(":id", alarmSystem.id)}>{alarmSystem.name}</Link> : null}
                {alarmSystem ? <Typography color="textPrimary">{t("TransactionResult")}</Typography> : null}
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            {
                alarmSystem && license ?
                    <div style={{ marginTop: 20 }}>
                        {
                            paymentSuccess ?
                                <Alert style={{ marginTop: 10, marginBottom: 10 }} severity="success">{t("TranzilaTransactionSucceeded")}</Alert> :
                                <Alert style={{ marginTop: 10, marginBottom: 10 }} severity="error">{t("TranzilaTransactionFailed")}</Alert>
                        }

                        {
                            paymentSuccess ?
                                <EditableTextField style={{ margin: 20 }} readOnly value={confirmationCode} label={t("PaymentConfirmationCode")} /> :
                                null
                        }

                        {
                            license.permissions.map(p => {
                                if (p.name !== permissionName)
                                    return null;

                                let { statusText } = getTextsForPermission(p, t);

                                {}
                                return <div key={p.name} style={{ marginTop: 20, marginBottom: 20 }}>
                                    <Paper style={{ padding: 20 }}>
                                        <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center" }}>
                                            <div style={{ margin: 20 }}>
                                                {
                                                    p.state === constants.PERMISSION_STATE_VALID_ACTIVATED ||
                                                        p.state === constants.PERMISSION_STATE_VALID_NOT_ACTIVATED ||
                                                        p.state === constants.PERMISSION_STATE_VALID_FREE ?
                                                        <CheckCircleIcon color="primary" /> :
                                                        <CancelIcon color="error" />
                                                }
                                            </div>

                                            <div style={{ margin: 20, flexGrow: 1 }}>
                                                <Typography variant="subtitle1">{t(`License${p.name}`)}</Typography>
                                                <Typography variant="subtitle2">{statusText}</Typography>
                                            </div>
                                          
                                        </div>
                                    </Paper>
                                </div>
                            })
                        }
                    </div> :
                    null
            }
        </Container>
    );
}
export default AlarmSystemTransactionResult;