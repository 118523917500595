import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';
import { validatePositiveInteger } from '../validators';

export function updateFormVat(vat,t) {
    return (dispatch) => {
        let formData = {
            vat: vat,
            vatErrorText: null,
            vatValid: false
        };
        if (!vat)
            formData.vatErrorText = "*";
        else if (vat < 0 || vat > 100)
            formData.vatErrorText = t("ErrorVatInvalid");
        else
            formData.vatValid = true;
        dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__UPDATE_FORM_DATA,data: { formData } });
    }
}

export function updateFormControlLicensePrice(controlLicensePrice,t) {
    return (dispatch) => {
        let formData = {
            controlLicensePrice: controlLicensePrice,
            controlLicensePriceErrorText: null,
            controlLicensePriceValid: false
        };
        if (!controlLicensePrice)
            formData.controlLicensePriceErrorText = "*";
        else if (!validatePositiveInteger(controlLicensePrice))
            formData.controlLicensePriceErrorText = t("ErrorNumberInvalid");
        else
            formData.controlLicensePriceValid = true;
        dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__UPDATE_FORM_DATA,data: { formData } });
    }
}

export function updateFormVideoLicensePrice(videoLicensePrice,t) {
    return (dispatch) => {
        let formData = {
            videoLicensePrice: videoLicensePrice,
            videoLicensePriceErrorText: null,
            videoLicensePriceValid: false
        };
        if (!videoLicensePrice)
            formData.videoLicensePriceErrorText = "*";
        else if (!validatePositiveInteger(videoLicensePrice))
            formData.videoLicensePriceErrorText = t("ErrorNumberInvalid");
        else
            formData.videoLicensePriceValid = true;
        dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__UPDATE_FORM_DATA,data: { formData } });
    }
}

export function getGeneralSettings(history,token,unsetUser,t,language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getGeneralSettings(token,language,(licenseSettings,vatSettings) => {
            dispatch({
                type: constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__GET_GENERAL_SETTINGS_SUCCESS,
                data: { licenseSettings,vatSettings }
            });
            dispatch(setLoading(false));
        },(errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
                }
                dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__GET_GENERAL_SETTINGS_FAIL,data });
            dispatch(setLoading(false));
        },() => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function setGeneralSettings(licenseSettings, vatSettings, history,token,unsetUser,t,language) {
    return (dispatch) => {
        dispatch(setLoading(true));
        network.setGeneralSettings(licenseSettings,vatSettings,token,language,() => {
            dispatch({
                type: constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__SET_GENERAL_SETTINGS_SUCCESS
            });
            dispatch(setLoading(false));
        },(errorCodes) => {
            let data = {
                generalErrorText: null,
                formData: {
                    videoLicensePriceErrorText: null,
                    videoLicensePriceValid: false,
                    controlLicensePriceErrorText: null,
                    controlLicensePriceValid: false,
                    vatErrorText: null,
                    vatValid: false
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //InvalidVATSettings = 3,
                        case 3:
                            data.formData.vatErrorText = t("InvalidVatSettings");
                            data.formData.vatValid = false;
                            break;
                        //InvalidControlLicenseSettings = 4,
                        case 4:
                            data.formData.controlLicensePriceErrorText = t("InvalidLicenseSettings");
                            data.formData.controlLicensePriceValid = false;
                            break;
                        //InvalidVideoLicenseSettings = 5,
                        case 5:
                            data.formData.videoLicensePriceErrorText = t("InvalidVideoLicenseSettings");
                            data.formData.videoLicensePriceValid = false;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__SET_GENERAL_SETTINGS_FAIL,data });
            dispatch(setLoading(false));
        },() => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}