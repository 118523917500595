import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, FormControl
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { write, openWriteDialog, updateWriteFormCode } from '../../actions/configuration';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';

const WriteDialog = () => {
    // disable password managers
    const [isReadOnly, setIsReadOnly] = useState(true);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    const configuration = useSelector(state => state.configuration.configuration);
    const writeDialog = useSelector(state => state.configuration.writeDialog);
    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    if (!alarmSystem)
        return null;
    
    return (
        <Dialog
            disableBackdropClick={loading}
            open={writeDialog.isOpen}
            onClose={() => dispatch(openWriteDialog(false))}>
            <DialogTitle>{t("Write")}</DialogTitle>
            <DialogContent>

                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

                <div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                        <TextField
                            disabled={loading}                            
                            fullWidth
                            type="password"
                            autoComplete="off"
                            onFocus={() => setIsReadOnly(false)}
                            onBlur={() => setIsReadOnly(true)}
                            inputProps={{ readOnly: isReadOnly }}
                            inputMode="numeric"
                            value={writeDialog.formData.code}
                            label={t("Code")}
                            error={!!writeDialog.formData.codeErrorText}
                            helperText={writeDialog.formData.codeErrorText}
                            onChange={event => dispatch(updateWriteFormCode(event.target.value, t))}
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openWriteDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={loading || !writeDialog.formData.isValid}
                    onClick={() => dispatch(write(alarmSystem.id, writeDialog.formData.code, configuration, history, token, unsetUser, t, language))}
                    color="primary">
                    {t("Execute")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default WriteDialog;