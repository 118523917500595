import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    webAccount: null,
    deleteDialog: {
        isOpen: false
    },
    externalPaymentSiteDialog: {
        isOpen: false
    }
};

const paymentMethod = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_PAYMENT_METHOD__OPEN_DELETE_DIALOG:
            newState = update(state, {
                deleteDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PAYMENT_METHOD__OPEN_EXTERNAL_PAYMENT_SITE_DIALOG:
            newState = update(state, {
                externalPaymentSiteDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PAYMENT_METHOD__GET_WEB_ACCOUNT_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccount: { $set: action.data.webAccount }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PAYMENT_METHOD__GET_WEB_ACCOUNT_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                webAccount: { $set: null }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_PAYMENT_METHOD__DELETE_PAYMENT_METHOD_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccount: { $set: action.data.webAccount }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PAYMENT_METHOD__DELETE_PAYMENT_METHOD_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default paymentMethod;