import React, { useEffect, useState } from 'react';
import { useHistory, Link as RouterLink, Redirect, useParams } from 'react-router-dom';
import { Button, Typography, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as constants from '../../constants';
import { useTranslation } from 'react-i18next';
import EditableTextField from '../common/EditableTextField';
import useUser from '../../user/useUser';
import { setUserFromStorage } from '../../actions/user';

const LicenseTranzilaSuccess = () => {
    const { t } = useTranslation();
    //const { ConfirmationCode, id, transactionId } = useParams();
    const { confirmationCode, currentNumberOfSystems } = useParams();
    const { user, token, setUser } = useUser();
    user.numberOfSystems = currentNumberOfSystems;    
    setUser(user, token);
    setUserFromStorage();
  //  window.top.location.replace(constants.ROUTE_ALARM_SYSTEMS_TRANSACTION_RESULT__ID__TRANSACTION_ID.replace(":id", id).replace(":transactionId", transactionId));
 //   return null;
    return <div style={{ padding: 20 }}>
        <Alert style={{ margin: 20 }} severity="success">{t("TranzilaTransactionSucceeded")}</Alert>
        <EditableTextField style={{ margin: 20 }} readOnly value={confirmationCode} label={t("StartBillingDate")} />
    </div>
}

export default LicenseTranzilaSuccess;