import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { Warning as WarningIcon, ExpandLess, ExpandMore } from '@material-ui/icons';

const PageSidebar = ({ onBrowsed, browseConfiguration, configuration, pageId, tableId }) => {

    const [openedPageIndex, setOpenedPageIndex] = useState(-1);
    const { id } = useParams();
    const history = useHistory();
    const loading = useSelector(state => state.global.loading);

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setOpenedPageIndex(pageId);
    }, [pageId]);

    //const browse = (newPageId, newTableId, showFirstError) => {
    //    //if (newPageId === pageId && newTableId === tableId && !showFirstError)
    //    //    return;

    //    let newRowId = 0;
    //    if (showFirstError) {
    //        const firstError = dirtyParameters.find(p => p.pageId === newPageId && p.tableId === newTableId && p.parameterErrorText);
    //        if (firstError)
    //            newRowId = firstError.rowId;
    //    }
    //    console.log("browseConfiguration", { newPageId, newTableId, newRowId });
    //    dispatch(browseConfiguration(history, id, newPageId, newTableId, newRowId));
    //    if (onBrowsed)
    //        onBrowsed();
    //}

    return (
        <List component="nav">
            {
                configuration.pages.map(p => {
                    //const pageHasErrors = dirtyParameters.some(p => p.pageId === i && p.parameterErrorText);
                    return (
                        <React.Fragment key={p.id}>
                            <ListItem disabled={loading} button onClick={() => setOpenedPageIndex(p.id)}>
                                {/*<ListItemIcon>
                                            <InboxIcon />
                                        </ListItemIcon>*/}
                                <ListItemText primary={t(p.title)} />
                                {/*{
                                    openedPageIndex !== i && pageHasErrors ?
                                        <WarningIcon style={{ fontSize: 15 }} color="error" /> :
                                        null
                                }*/}
                                
                                {openedPageIndex === p.id ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openedPageIndex == p.id} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {
                                        configuration.tables
                                            .filter(tb => tb.pageId === p.id)
                                            .map(tb => {
                                            //const tableHasErrors = pageHasErrors && dirtyParameters.some(p => p.pageId === i && p.tableId === j && p.parameterErrorText);
                                            return <ListItem key={tb.id} button
                                                    selected={tableId === tb.id}
                                                    onClick={() => {
                                                        dispatch(browseConfiguration(history, id, p.id, tb.id, tb.firstRowId, configuration.rows[tb.firstRowId].firstCellId));
                                                        if (onBrowsed) onBrowsed();
                                                    }}>
                                                    {/*onClick={() => browse(i, j, tableHasErrors)}*/}
                                                <ListItemText inset primary={t(tb.title || p.title)} hiddensecondary={`split: ${tb.split}, frozen: ${tb.frozenColumnCount}`} />
                                                    {/*{
                                                        openedPageIndex === i && tableHasErrors ?
                                                            <WarningIcon style={{ fontSize: 15 }} color="error" /> :
                                                            null
                                                    }*/}
                                                </ListItem>
                                        })
                                    }

                                </List>
                            </Collapse>
                        </React.Fragment>
                    );
                })
            }
            {/*<ListItem disabled={loading} key={i} button
                                    selected={pageId === i}
                                    onClick={() => browseConfiguration(history, alarmSystem.id, i, 0)}>
                                    <ListItemText primary={t(p.title)} />
                                    {
                                        dirtyParameters.findIndex(p => p.pageId === i && p.parameterErrorText) >= 0 ?
                                            <WarningIcon fontSize="small" color="error" /> :
                                            null
                                    }
                                </ListItem>*/}


        </List>
    );
}

export default PageSidebar;