import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link as RouterLink, Prompt } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    useMediaQuery, Button, TextField, Container, AppBar, ButtonGroup,
    Toolbar, Breadcrumbs, Link, Typography, Divider, IconButton, Badge,
    ListItemIcon, ListItemText,
    Menu, MenuItem
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { Delete as DeleteIcon, ErrorOutline as ErrorIcon, WarningOutlined as WarningIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import {
    getTemplate, browseConfiguration, updateTemplate, updateValue, openDeleteDialog, openParameterErrorsDialog
} from '../../actions/template';
import ParameterErrorsDialog from './ParameterErrorsDialog';
import PagesView from './PagesView';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import DeleteTemplateDialog from './DeleteTemplateDialog';
import * as constants from '../../constants';

const Template = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const template = useSelector(state => state.template.template);
    const configuration = useSelector(state => state.template.configuration);
    //const dataView = useSelector(state => state.configuration.dataView);
    const pageId = useSelector(state => state.template.pageId);
    const tableId = useSelector(state => state.template.tableId);
    const rowId = useSelector(state => state.template.rowId);
    const cellId = useSelector(state => state.template.cellId);
    const parameterErrorsDialog = useSelector(state => state.template.parameterErrorsDialog);

    //const paginationPage = useSelector(state => state.configuration.paginationPage);
    //const dirtyParameters = useSelector(state => state.configuration.dirtyParameters);
    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    //const parameterError = useSelector(state => state.configuration.parameterError);
    //const excludedParameters = useSelector(state => state.configuration.excludedParameters);

    //if (parameterError) {
    //    let newPaginationPage = 0;
    //    if (showFirstError) {
    //        const firstError = dirtyParameters.find(p => p.pageId === newPageId && p.tableId === newTableId && p.parameterErrorText);
    //        if (firstError)
    //            newPaginationPage = firstError.paginationPage;
    //    }
    //    console.log("browseConfiguration", { newPageId, newTableId, newPaginationPage });
    //    dispatch(browseConfiguration(history, id, newPageId, newTableId, newPaginationPage));
    //}

    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getTemplate(id, history, token, unsetUser, t, language));
    }, [dispatch, id, token]);

    //window.onbeforeunload = e => t("ConfigurationWillBeLost");
    useEffect(() => {
        window.onbeforeunload = configuration ?
            e => t("ConfigurationWillBeLost") :
            null;
    }, [configuration]);

    return (
        <React.Fragment>
            <Prompt when={!!configuration} message={t("ConfigurationWillBeLost")} />

            <Container>
                <Breadcrumbs style={{ marginTop: 20 }}>
                    <Link color="inherit" component={RouterLink} to={constants.ROUTE_TEMPLATES}>{t("Templates")}</Link>
                    {
                        template ?
                            <Typography color="inherit">{template.name}</Typography> :
                            null
                    }
                    <Typography color="textPrimary">{t("Config")}</Typography>
                </Breadcrumbs>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Container>

            {
                template ?
                    //<StickyTop>
                    <Container>

                        <AppBar color="inherit" position="static">
                            <Toolbar variant="dense">
                                {/*<Button disabled={loading} color="inherit" component={RouterLink} to={`/configuration/${alarmSystem.id}`}>{t("Config")}</Button>
                                    <Button disabled={loading} color="inherit" component={RouterLink} to={`/monitor/${alarmSystem.id}`}>{t("Monitor")}</Button>*/}

                                <Button color="inherit" disabled={loading || !configuration || !configuration.modifiedParameterIndices.length || !!configuration.validationErrorParameterIndices.length}
                                    onClick={() => dispatch(updateTemplate(id, configuration, history, token, unsetUser, t, language))}>{t("Save")}</Button>
                                <Button color="inherit" disabled={loading || !configuration || !configuration.modifiedParameterIndices.length}
                                    onClick={() => { }}>{t("Cancel")}</Button>

                                <div style={{ flexGrow: 1 }} />
                                {
                                    configuration &&
                                        (configuration.validationErrorParameterIndices.length || configuration.errorParameterIndices.length ||
                                            configuration.excludedParameterIndices.length) ?
                                        <Badge color="primary" badgeContent={configuration.validationErrorParameterIndices.length +
                                            configuration.errorParameterIndices.length +
                                            configuration.excludedParameterIndices.length} max={100}>
                                            <Button color="inherit"
                                                disabled={loading}
                                                onClick={() => dispatch(openParameterErrorsDialog(true))}>
                                                {configuration.validationErrorParameterIndices.length || configuration.errorParameterIndices.length ?
                                                    <ErrorIcon color="error" /> : <WarningIcon color="error" />}
                                            </Button>
                                        </Badge> :
                                        null
                                }
                                {
                                    isMobile ?
                                        <React.Fragment>
                                            <IconButton disabled={loading} edge="end" color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
                                                <MoreVertIcon />
                                            </IconButton>

                                            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)} keepMounted>
                                                <MenuItem disabled={loading} onClick={() => {
                                                    dispatch(openDeleteDialog(true));
                                                    setAnchorEl(null);
                                                }}>
                                                    <ListItemIcon>
                                                        <DeleteIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t("Remove")} />
                                                </MenuItem>
                                            </Menu>
                                        </React.Fragment> :
                                        <Button disabled={loading} color="inherit"
                                            onClick={() => dispatch(openDeleteDialog(true))}
                                            endIcon={<DeleteIcon />}>{t("Remove")}</Button>
                                }
                            </Toolbar>
                        </AppBar>

                    </Container>
                    //</StickyTop>
                    :
                    null
            }

            {generalErrorText ? <Container><Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert></Container> : null}

            {
                template && configuration ?
                    <PagesView
                        browseConfiguration={browseConfiguration}
                        updateValue={updateValue}
                        configuration={configuration}
                        pageId={pageId}
                        tableId={tableId}
                        rowId={rowId}
                        cellId={cellId}
                        disableCode={true} /> :
                    <Container>
                        <Alert style={{ marginTop: 20 }} severity="warning">{t("NoConfigToShow")}</Alert>
                    </Container>
            }

            {
                template ?
                    <React.Fragment>
                        <DeleteTemplateDialog />
                        <ParameterErrorsDialog
                            browseConfiguration={browseConfiguration}
                            openParameterErrorsDialog={openParameterErrorsDialog}
                            configuration={configuration}
                            parameterErrorsDialog={parameterErrorsDialog} />
                    </React.Fragment> :
                    null
            }

        </React.Fragment>
    );
}

export default Template;