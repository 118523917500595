import * as constants from '../constants';
import moment from 'moment';

//export function getTextsForPermission(permission, t) {
//    let statusText, buttonText, showButton;
//    let buyButtonText, buyText, showFreeButton;
//    switch (permission.state) {
//        case constants.PERMISSION_STATE_DISABLED:
//            statusText = t("LicenseDisabled");
//            buttonText = t("LicenseEnable");
//            showButton = true;
//            buyButtonText = t("LicenseBuyPeriod");
//            buyText = t("LicenseBuyPlusFreePeriodDescription", { first: permission.freeMonths });
//            showFreeButton = true;
//            break;
//        case constants.PERMISSION_STATE_VALID_NOT_ACTIVATED:
//            statusText = permission.purchasedMonths > 0 ?
//                t("LicenseEnabledNotActivePurchasedMonths", { first: permission.purchasedMonths, second: permission.freeMonths }) :
//                t("LicenseEnabledNotActiveFreeMonths", { first: permission.freeMonths });
//            buttonText = t("LicenseAddPeriod");
//            showButton = true;
//            buyButtonText = t("LicenseAddPeriod");
//            buyText = t("LicenseAddPeriodDescription");
//            showFreeButton = false;
//            break;
//        case constants.PERMISSION_STATE_VALID_ACTIVATED:
//            statusText = t("LicenseActiveUntilDate", { first: moment(permission.expirationDate).format("DD/MM/YYYY") });
//            buttonText = t("LicenseAddPeriod");
//            showButton = true;
//            buyButtonText = t("LicenseAddPeriod");
//            buyText = t("LicenseAddPeriodDescription");
//            showFreeButton = false;
//            break;
//        case constants.PERMISSION_STATE_EXPIRED:
//            statusText = t("LicenseExpiredOnDate", { first: moment(permission.expirationDate).format("DD/MM/YYYY") });
//            buttonText = t("LicenseEnable");
//            showButton = true;
//            buyButtonText = t("LicenseBuyPeriod");
//            buyText = t("LicenseBuyPeriodDescription");
//            showFreeButton = false;
//            break;
//        case constants.PERMISSION_STATE_VALID_FREE:
//            statusText = t("LicenseEnabledFree");
//            buttonText = "";
//            showButton = false;
//            buyButtonText = "";
//            buyText = "";
//            showFreeButton = false;
//            break;
//        default:
//            break;
//    }
    

//    return {
//        statusText, buttonText, showButton,
//        buyButtonText, buyText, showFreeButton
//    };
//}
export function getTextsForPermission(permission, t) {
    let statusText, buttonText, showButton;
    let buyButtonText, buyText, showFreeButton;
   
            statusText = t("LicenseDisabled");
            buttonText = t("LicenseEnable");
            showButton = true;
            buyButtonText = t("LicenseBuyPeriod");
            buyText = t("LicenseBuyPlusFreePeriodDescription");
            showFreeButton = true;
          

    return {
        statusText, buttonText, showButton,
        buyButtonText, buyText, showFreeButton
    };
}