import React, { useEffect, useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, TextField, Container, Breadcrumbs, Typography, Divider, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
    Tabs, Tab, Paper, IconButton
} from '@material-ui/core';
import { Delete as DeleteIcon,Person as PersonIcon,Warning as WarningIcon,Tablet as TabletIcon,SettingsEthernet as SettingsIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { getManagementSummary } from '../../actions/management';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const Management = () => {
    const { user, isWebAccountAdmin, token, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const userCount = useSelector(state => state.management.userCount);
    const alarmSystemCount = useSelector(state => state.management.alarmSystemCount);
    const generalErrorText = useSelector(state => state.webAccountUsers.generalErrorText);
    const history = useHistory();
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getManagementSummary(history, token, unsetUser, t, language));
    }, [token]);

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("Management")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <Paper style={{ maxWidth: 400 }}>
                <List dense component="nav">
                    <ListItem button
                        component={RouterLink} to={constants.ROUTE_MANAGEMENT_USERS}>
                        <ListItemIcon>
                            <PersonIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={t("Users")} secondary={userCount} />
                    </ListItem>
                    <ListItem button
                        component={RouterLink} to={constants.ROUTE_MANAGEMENT_ALARM_SYSTEMS}>
                        <ListItemIcon>
                            <TabletIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={t("AlarmSystems")} secondary={alarmSystemCount} />
                    </ListItem>
                    <ListItem button
                        component={RouterLink} to={constants.ROUTE_MANAGEMENT_GENERAL_SETTINGS}>
                        <ListItemIcon>
                            <SettingsIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={t("GeneralSettings")} />
                    </ListItem>
                </List>
            </Paper>

        </Container>
    );
}

export default Management;