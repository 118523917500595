import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';
import { validatePairingCode, validateEmail, validateParameter } from '../validators';
import configurationHelper from '../helpers/configurationHelpers';

export function openDeleteDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_TEMPLATE__OPEN_DELETE_DIALOG, data: { isOpen } });
    }
}
export function openParameterErrorsDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_TEMPLATE__OPEN_PARAMETER_ERRORS_DIALOG, data: { isOpen } });
    }
}

export function browseConfiguration(history, id, pageId, tableId, rowId, cellId) {
    return (dispatch) => {
        dispatch({
            type: constants.ACTION_TYPE_TEMPLATE__BROWSE_CONFIGURATION,
            data: { pageId, tableId, rowId, cellId }
        });
        //const url = `/configuration/${id}/${pageId}/${tableId}/${rowId}`;
        //history.push(url);
    }
}

export function getTemplate(templateId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getTemplate(templateId, token, language, (template, configuration, dataView) => {
            const newConfiguration = configurationHelper.initConfiguration(dataView, configuration);
            dispatch({
                type: constants.ACTION_TYPE_TEMPLATE__LOAD_TEMPLATE_SUCCESS,
                data: { template, configuration: newConfiguration }
            });
            //dispatch(getConfiguration(id, history, token, unsetUser));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //TemplateNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_TEMPLATE__LOAD_TEMPLATE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function updateTemplate(templateId, configuration, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));
        const parameters = configurationHelper.getParametersToWrite(configuration);
        network.updateTemplate(templateId, parameters, token, language, () => {
            configurationHelper.clearModifiedParameters(configuration);
            dispatch({
                type: constants.ACTION_TYPE_TEMPLATE__UPDATE_TEMPLATE_SUCCESS
            });
            //dispatch(getConfiguration(id, history, token, unsetUser));
            dispatch(setLoading(false));
        }, (errorCodes, parameterValidationErrors) => {
            let data = {
                configuration: configuration,
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //TemplateNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //ParameterValidationErrors = 5,
                        case 5:
                            data.configuration = configurationHelper.updateValidationErrorParameters(parameterValidationErrors, data.configuration, t);
                            data.configuration = configurationHelper.updateErrorParameters([], data.configuration, t);
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_TEMPLATE__UPDATE_TEMPLATE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function updateValue(parameterIndex, newValue, configuration, t) {
    return (dispatch) => {
        const newConfiguration = configurationHelper.updateParameterValue(parameterIndex, newValue, configuration, t);
        dispatch({
            type: constants.ACTION_TYPE_TEMPLATE__UPDATE_VALUE,
            data: { configuration: newConfiguration }
        });
        //const parameterErrorText = validateParameter(identifier, order, bit, value, paramInfoParameterType, paramInfoLength, cell, t);
        //dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_VALUE, data: { identifier, order, bit, value, pageId, tableId, rowId, parameterErrorText } });
    }
}

export function deleteTemplate(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(false));

        network.deleteTemplate(id, token, language, () => {
            dispatch({
                type: constants.ACTION_TYPE_TEMPLATE__DELETE_TEMPLATE_SUCCESS
            });
            dispatch(setLoading(false));
            history.replace(constants.ROUTE_TEMPLATES);

        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //TemplateNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
                dispatch({ type: constants.ACTION_TYPE_TEMPLATE__DELETE_TEMPLATE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}