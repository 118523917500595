import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    formData: {
        firstName: '',
        firstNameErrorText: null,
        firstNameValid: false,
        lastName: '',
        lastNameErrorText: null,
        lastNameValid: false,
        phoneNumber: '',
        phoneNumberErrorText: null,
        phoneNumberValid: true,
        email: '',
        emailErrorText: null,
        emailValid: false,
        password: '',
        passwordErrorText: null,
        passwordValid: false,
        confirmPassword: '',
        confirmPasswordErrorText: null,
        confirmPasswordValid: false,
        country: '',
        countryErrorText: null,
        countryValid: true,
        state: '',
        stateErrorText: null,
        stateValid: true,
        city: '',
        cityErrorText: null,
        cityValid: true,
        address: '',
        addressErrorText: null,
        addressValid: true,
        Company: '',
        BusinessNumber:'',
        isValid: false
    }
};

const register = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.firstNameValid && newState.formData.lastNameValid && newState.formData.phoneNumberValid &&
                            newState.formData.emailValid && newState.formData.passwordValid && newState.formData.confirmPasswordValid &&
                             newState.formData.countryValid &&
                            newState.formData.stateValid && newState.formData.cityValid && newState.formData.addressValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_REGISTER__REGISTER_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_REGISTER__REGISTER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.firstNameValid && newState.formData.lastNameValid && newState.formData.phoneNumberValid &&
                            newState.formData.emailValid && newState.formData.passwordValid && newState.formData.confirmPasswordValid &&
                             newState.formData.countryValid &&
                            newState.formData.stateValid && newState.formData.cityValid && newState.formData.addressValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default register;