import * as constants from '../constants';
import moment from 'moment';

export function validateMac(mac) {
    if (!mac)
        return false;
    var re = /^[0-9a-fA-F]{12}$/;
    return re.test(mac);
}

export function validateEmail(email) {
    if (!email)
        return false;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validatePhoneNumber(phoneNumber) {
    if (!phoneNumber)
        return false;
    //const re = /^[\d\+][\d]*$/;
    const re = /^\+*[\d\*\#P]*$/;
    return re.test(phoneNumber);
}

export function validatePassword(password) {
    if (!password)
        return false;

    //// ^ The password string will start this way
    //// (?=.* [a - z])	The string must contain at least 1 lowercase alphabetical character
    //// (?=.* [A - Z])	The string must contain at least 1 uppercase alphabetical character
    //// (?=.* [0 - 9])	The string must contain at least 1 numeric character
    //// (?=.* [!@#$ %^&*]) The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
    //// (?=.{ 8, })	The string must be eight characters or longer
    //var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    // ^ The password string will start this way
    // (?=.* [a - z])	The string must contain at least 1 lowercase alphabetical character
    // (?=.* [A - Z])	The string must contain at least 1 uppercase alphabetical character
    // (?=.{ 6, })	The string must be six characters or longer
    var re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.{6,})");
    return re.test(password);
}

export function validatePairingCode(pairingCode) {
    const re = /^[\d]*$/;
    return re.test(pairingCode);
}

export function validatePositiveInteger(number) {
    const re = /^[\d]*$/;
    return re.test(number);
}
export function validateHexadecimalNumber(hex) {
    const re = /^[0-9a-fA-F]*$/;
    return re.test(hex);
}
export function validateIpAddress(ipAddress) {
    const re = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?: 25[0 - 5] | 2[0 - 4][0 - 9] | [01] ? [0 - 9][0 - 9] ?)$/;
    return re.test(ipAddress);
}
export function validateTime(time) {
    const re = /^[0-2][0-3]:[0-5][0-9]$/;
    return re.test(time);
}
// "dd/MM"
export function validateDate(date) {
    const re = /^\d{2}\/\d{2}$/;
    if (!re.test(date))
        return false;  // Invalid format
    const m = moment(`${date}/2000`, "DD/MM/YYYY", true);
    return m.isValid();
}
export function validateBoolean(bl) {
    return bl === "True" || bl === "False";
}

export function validateParameter(value, paramInfoParameterType, paramInfoLength, cell, t) {
    if (!cell)
        return t("ErrorGeneral");

    let ulongDec;
    let maxValue;
    switch (paramInfoParameterType) {
        case constants.PARAM_INFO_TYPE_DEC_NUMBER:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            }
            else if (!validatePositiveInteger(value)) {
                return t("ErrorParameterValidationInvalidNumber");
            }

            maxValue = Math.pow(2, paramInfoLength * 8) - 1;
            if (Number.isInteger(cell.maxAllowedValue) && cell.maxAllowedValue < maxValue)
                maxValue = cell.maxAllowedValue;
            ulongDec = Number.parseInt(value);
            if (ulongDec > maxValue) {
                return t("ErrorParameterValidationNumberTooBig", { first: maxValue });
            }
            else if (Number.isInteger(cell.minAllowedValue) && ulongDec < cell.minAllowedValue) {
                return t("ErrorParameterValidationNumberTooSmall", { first: cell.minAllowedValue });
            }
            else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_HEX_NUMBER:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            }
            else if (!validateHexadecimalNumber(value)) {
                return t("ErrorParameterValidationInvalidHexadecimalNumber");
            }

            maxValue = Math.pow(2, paramInfoLength * 8) - 1;
            if (Number.isInteger(cell.maxAllowedValue) && cell.maxAllowedValue < maxValue)
                maxValue = cell.maxAllowedValue;
            ulongDec = Number.parseInt(value, 16);
            if (ulongDec > maxValue) {
                return t("ErrorParameterValidationNumberTooBig", { first: maxValue });
            } else if (Number.isInteger(cell.minAllowedValue) && ulongDec < cell.minAllowedValue) {
                return t("ErrorParameterValidationNumberTooSmall", { first: cell.minAllowedValue });
                //} else if (value.length > paramInfoLength * 2) {
                //    return ulongDec.ToString($"X{paramInfoLength * 2}");
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_ESN:
            if (!value) {
                return "*"; //cell.defaultValue ? "*" : null;
            } else if (!validateHexadecimalNumber(value)) {
                return t("ErrorParameterValidationInvalidHexadecimalNumber");
            } else if (value.length < paramInfoLength * 2) {
                return t("ErrorParameterValidationTooFewCharacters", { first: paramInfoLength * 2 }); // $"Length cannot be less than {paramInfoLength * 2} characters";
            } else if (value.length > paramInfoLength * 2) {
                return t("ErrorParameterValidationTooManyCharacters", { first: paramInfoLength * 2 }); // $"Length cannot exceed {paramInfoLength * 2} characters";
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_BCD_NUMBER:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            } else if (!validatePositiveInteger(value)) {
                return t("ErrorParameterValidationInvalidNumber");
            }

            maxValue = paramInfoLength * 2;
            if (Number.isInteger(cell.maxAllowedValue) && cell.maxAllowedValue < maxValue)
                maxValue = cell.maxAllowedValue;
            if (value.length > maxValue) {
                return t("ErrorParameterValidationTooManyCharacters", { first: maxValue }); // $"Length cannot exceed {paramInfoLength * 2} characters";
            } else if (Number.isInteger(cell.minAllowedValue) && value.length < cell.minAllowedValue) {
                return t("ErrorParameterValidationTooFewCharacters", { first: cell.minAllowedValue }); // $"Length cannot be less than {paramInfoLength * 2} characters";
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_PHONE_NUMBER:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            } else if (!validatePhoneNumber(value)) {
                return t("ErrorParameterValidationInvalidPhoneNumber");
            }

            maxValue = paramInfoLength * 2;
            if (Number.isInteger(cell.maxAllowedValue) && cell.maxAllowedValue < maxValue)
                maxValue = cell.maxAllowedValue;
            if (value.length > maxValue) {
                return t("ErrorParameterValidationTooManyCharacters", { first: maxValue }); // $"Length cannot exceed {paramInfoLength * 2} characters";
            } else if (Number.isInteger(cell.minAllowedValue) && value.length < cell.minAllowedValue) {
                return t("ErrorParameterValidationTooFewCharacters", { first: cell.minAllowedValue }); // $"Length cannot be less than {paramInfoLength * 2} characters";
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_IP_ADDRESS:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            } else if (!validateIpAddress(value)) {
                return t("ErrorParameterValidationInvalidIP");
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_TIME:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            } else if (!validateTime(value)) {
                return t("ErrorParameterValidationInvalidTime");
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_DATE:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            } else if (!validateDate(value)) {
                return t("ErrorParameterValidationInvalidDate");
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_SELECTOR:
            if (!value) {
                return "*"; //cell.defaultValue ? "*" : null;
            } else if (!validatePositiveInteger(value)) {
                return t("ErrorParameterValidationInvalidNumber");
            }

            let items = t(cell.selectorList).split(',');
            let index = Number.parseInt(value);
            maxValue = items.length;
            if (Number.isInteger(cell.maxAllowedValue) && cell.maxAllowedValue < maxValue)
                maxValue = cell.maxAllowedValue;
            if (index < 1 || index > maxValue) {
                return t("ErrorParameterValidationNumberOutOfRange"); // $"Index {index} out of range";
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_TEXT:
        case constants.PARAM_INFO_TYPE_CHAR_ARRAY:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            }
            maxValue = paramInfoLength;
            if (Number.isInteger(cell.maxAllowedValue) && cell.maxAllowedValue < maxValue)
                maxValue = cell.maxAllowedValue;
            if (value.length > maxValue) {
                return t("ErrorParameterValidationTooManyCharacters", { first: maxValue }); // $"Length cannot exceed {paramInfoLength} characters";
            } else if (Number.isInteger(cell.minAllowedValue) && value.length < cell.minAllowedValue) {
                return t("ErrorParameterValidationTooFewCharacters", { first: cell.minAllowedValue }); // $"Length cannot be less than {paramInfoLength * 2} characters";
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_BIT_FIELD:
        case constants.PARAM_INFO_TYPE_BIT_ARRAY:
        case constants.PARAM_INFO_TYPE_BIT:
            if (!value) {
                return "*"; // cell.defaultValue ? "*" : null;
            } else if (!validateBoolean(value)) {
                return t("ErrorParameterValidationInvalidBoolean");
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_BYTE_ARRAY:
            if (!value) {
                return cell.defaultValue ? "*" : null;
            } else if (!validateHexadecimalNumber(value)) {
                return t("ErrorParameterValidationInvalidHexadecimalNumber");
            }
            maxValue = paramInfoLength * 2;
            if (value.length < maxValue) {
                return t("ErrorParameterValidationTooFewCharacters", { first: maxValue }); // $"Length cannot be less than {pi.Length * 2} characters";
            } else if (value.length > maxValue) {
                return t("ErrorParameterValidationTooManyCharacters", { first: maxValue }); // $"Length cannot exceed {pi.Length * 2} characters";
            } else {
                return null;
            }
        case constants.PARAM_INFO_TYPE_SPECIAL:
        default:
            return t("ErrorGeneral");
    }
}