import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useMediaQuery, Container, Paper, Button, List, ListItem, ListItemText, ListItemIcon,
    TableContainer, Table, TableBody, TableCell, TableRow, TableHead, TableFooter, TableSortLabel, Link
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import * as constants from '../../constants';
import equal from 'fast-deep-equal';
import moment from 'moment';
import { updateDisplayAlarmSystems } from '../../actions/managementAlarmSystems';
import useUser from '../../user/useUser';
import { flexbox } from '@material-ui/system';
import { getTextsForPermission } from '../../helpers/textHelpers';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@material-ui/icons';

const TableViewAlarmSystems = () => {

    const dispatch = useDispatch();
    const loading = useSelector(state => state.global.loading);
    const alarmSystems = useSelector(state => state.managementAlarmSystems.alarmSystems);
    const licenses = useSelector(state => state.managementAlarmSystems.licenses);
    const displayAlarmSystems = useSelector(state => state.managementAlarmSystems.displayAlarmSystems);
    const generalErrorText = useSelector(state => state.managementAlarmSystems.generalErrorText);


    const { token, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    const tableRef = useRef(null);
    const [tableLeft, setTableLeft] = useState(0);
    useEffect(() => {
        if (tableRef.current) {
            //console.log("tableRef", tableRef.current);
            setTableLeft(tableRef.current.left);
        }
    }, [tableRef.current]);

    const macLicenseLookup = {};
    for (let l of licenses) {
        macLicenseLookup[l.mac] = l;
    }
    console.log("macLicenseLookup", macLicenseLookup);

    const take = 20;
    const count = Math.ceil(alarmSystems.length / take);
    const paginationPage = Math.floor(displayAlarmSystems.alarmSystemId / take) + 1;

    //const end = moment();
    //console.log("start render", end.diff(start));

    return <div>
        <TableContainer ref={tableRef}>
            <Table size="small" padding="checkbox">
                <TableHead>
                    <TableRow>

                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayAlarmSystems(alarmSystems, licenses, displayAlarmSystems.searchText, {
                                    by: constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC,
                                    direction: displayAlarmSystems.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC && displayAlarmSystems.sort.direction === "asc" ? "desc" : "asc"
                                }, displayAlarmSystems.alarmSystemId, t))}
                                direction={displayAlarmSystems.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC ? displayAlarmSystems.sort.direction : "asc"}
                                active={displayAlarmSystems.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC}>{t("MAC")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayAlarmSystems(alarmSystems, licenses, displayAlarmSystems.searchText, {
                                    by: constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_NAME,
                                    direction: displayAlarmSystems.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_NAME && displayAlarmSystems.sort.direction === "asc" ? "desc" : "asc"
                                }, displayAlarmSystems.alarmSystemId, t))}
                                direction={displayAlarmSystems.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_NAME ? displayAlarmSystems.sort.direction : "asc"}
                                active={displayAlarmSystems.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_NAME}>{t("Name")}</TableSortLabel>
                        </TableCell>
                        <TableCell>{t("LicenseNotifications")}</TableCell>
                        <TableCell>{t("LicenseControl")}</TableCell>
                        <TableCell>{t("Transactions")}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        displayAlarmSystems.alarmSystems
                            .slice((paginationPage - 1) * take, paginationPage * take)
                            //.filter(r => r.tableId === tableView.id && r.id >= rowId && r.id < rowId + take)
                            //.slice(rowId, rowId + take)
                            //.filter(r => r.id >= rowId && r.id < rowId + take)
                            .map((a, i) => {
                                let license = macLicenseLookup[a.mac];
                                let notificationsPermission = license ? license.permissions.find(p => p.name === "Notifications") : null;
                                let controlPermission = license ? license.permissions.find(p => p.name === "Control") : null;

                                return <TableRow key={i}>
                                    <TableCell style={{ maxWidth: 100 }}>{a.mac}</TableCell>
                                    <TableCell style={{ maxWidth: 100 }}>{a.name}</TableCell>
                                    <TableCell style={{ maxWidth: 200 }}>
                                        {
                                            notificationsPermission ? getTextsForPermission(notificationsPermission, t).statusText : ""
                                        }
                                    </TableCell>
                                    <TableCell style={{ maxWidth: 200 }}>
                                        {
                                            controlPermission ? getTextsForPermission(controlPermission, t).statusText : ""
                                        }
                                    </TableCell>
                                    <TableCell style={{ maxWidth: 100 }}>
                                        <Link component={RouterLink} to={constants.ROUTE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__ID.replace(":id", a.id)}>{t("Transactions")}</Link>
                                    </TableCell>

                                    {/*<TableCell style={{ maxWidth: 200 }}>
                                        <List dense>
                                            {
                                                license ? license.permissions.map(p => {
                                                    let { statusText } = getTextsForPermission(p, t);

                                                    return <ListItem key={p.name}>
                                                        <ListItemText primary={t(`License${p.name}`)} secondary={statusText} />
                                                        <ListItemIcon>
                                                        {
                                                            p.state === constants.PERMISSION_STATE_VALID_ACTIVATED ||
                                                                p.state === constants.PERMISSION_STATE_VALID_NOT_ACTIVATED ||
                                                                p.state === constants.PERMISSION_STATE_VALID_FREE ?
                                                                <CheckCircleIcon color="primary" /> :
                                                                <CancelIcon color="error" />
                                                            }
                                                            </ListItemIcon>
                                                    </ListItem>

                                                    return <div key={p.name} style={{ marginTop: 20, marginBottom: 20 }}>
                                                        <Paper style={{ padding: 20 }}>
                                                            <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center" }}>
                                                                <div style={{ margin: 20 }}>
                                                                    {
                                                                        p.state === constants.PERMISSION_STATE_VALID_ACTIVATED ||
                                                                            p.state === constants.PERMISSION_STATE_VALID_NOT_ACTIVATED ||
                                                                            p.state === constants.PERMISSION_STATE_VALID_FREE ?
                                                                            <CheckCircleIcon color="primary" /> :
                                                                            <CancelIcon color="error" />
                                                                    }
                                                                </div>

                                                                <div style={{ margin: 20, flexGrow: 1 }}>
                                                                    <Typography variant="subtitle1">{t(`License${p.name}`)}</Typography>
                                                                    <Typography variant="subtitle2">{statusText}</Typography>
                                                                </div>
                                                                {
                                                                    showButton ?
                                                                        <div style={{ margin: 20 }}>
                                                                            <Button variant="contained" disabled={loading} color="primary"
                                                                                onClick={() => dispatch(openLicenseDialog(true, p))}>{buttonText}</Button>
                                                                        </div> :
                                                                        null
                                                                }
                                                            </div>
                                                        </Paper>
                                                    </div>
                                                }) :
                                                    null
                                            }
                                        </List>
                        </TableCell>*/}

                                </TableRow>
                            })
                    }
                </TableBody>
            </Table>
        </TableContainer>

        {/*<div style={{position: "fixed", bottom: 0, display: "flex", justifyContent: "center"}}>*/}
        <div style={{ position: "fixed", bottom: 0, left: tableLeft, zIndex: 100 }}>
            <Paper>
                <Pagination
                    style={{ marginTop: 10, marginBottom: 10 }}
                    size="small"
                    color="primary"
                    disabled={loading}
                    count={count}
                    page={paginationPage}
                    onChange={(event, page) => dispatch(updateDisplayAlarmSystems(alarmSystems, licenses, displayAlarmSystems.searchText, displayAlarmSystems.sort, (page - 1) * take, t))} />
            </Paper>
        </div>
    </div>
}

export default TableViewAlarmSystems;