import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';
import moment from 'moment';

export function updateFormNotificationTypes(notificationTypes, t) {
    return (dispatch) => {
        let formData = {
            notificationTypes
        };
        dispatch({ type: constants.ACTION_TYPE_MONITORING__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormMacs(macs, t) {
    return (dispatch) => {
        let formData = {
            macs
        };
        dispatch({ type: constants.ACTION_TYPE_MONITORING__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormDateRange(dateRange, t) {
    return (dispatch) => {
        let formData = {
            dateRange
        };
        dispatch({ type: constants.ACTION_TYPE_MONITORING__UPDATE_FORM_DATA, data: { formData } });
    }
}
//export function updateSearchText(searchText) {
//    return { type: constants.ACTION_TYPE_MONITORING__UPDATE_SEARCH_TEXT, data: { searchText } };
//}
//export function updateSort(by, sort) {
//    const newSort = {
//        by,
//        direction: sort.by === by && sort.direction === "asc" ? "desc" : "asc"
//    };
//    return { type: constants.ACTION_TYPE_MONITORING__UPDATE_SORT, data: { newSort } };
//}

export function getAlarmSystems(id, searchText, sort, notificationId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getAlarmSystems(token, language, (alarmSystems) => {
            dispatch({
                type: constants.ACTION_TYPE_MONITORING__GET_ALARM_SYSTEMS_SUCCESS,
                data: { alarmSystems }
            });

            const licensedAlarmSystems = alarmSystems.filter(a => a.license.installerLicenseLevel >= constants.INSTALLER_LICENSE_LEVEL_MONITORING);
            const selectedAlarmSystem = id ? licensedAlarmSystems.find(a => a.id === id) : null;
            //if (id && !selectedAlarmSystem) {
            //    history.push(constants.ROUTE_MONITORING);
            //    return;
            //}

            const macs = selectedAlarmSystem ?
                [selectedAlarmSystem.mac] :
                licensedAlarmSystems.map(a => a.mac);            
            const allNotificationTypes = constants.NOTIFICATION_TYPES.map(n => n.type);
            
            dispatch(getNotifications(alarmSystems, macs, allNotificationTypes, null, searchText, sort, notificationId, history, token, unsetUser, t));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_MONITORING__GET_ALARM_SYSTEMS_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function getNotifications(alarmSystems, macs, notificationTypes, dateRange, searchText, sort, notificationId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));
        dispatch(updateFormMacs(macs, t));
        dispatch(updateFormNotificationTypes(notificationTypes, t));
        dispatch(updateFormDateRange(dateRange, t));

        if (!macs.length || !notificationTypes.length) {
            dispatch({
                type: constants.ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_SUCCESS,
                data: { notifications: [] }
            });
            dispatch(updateDisplayNotifications(alarmSystems, [], searchText, sort, notificationId, t));
            dispatch(setLoading(false));
            return;
        }
        network.getNotifications(macs, notificationTypes, dateRange, token, language, (notifications) => {
            dispatch({
                type: constants.ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_SUCCESS,
                data: { notifications }
            });
            dispatch(updateDisplayNotifications(alarmSystems, notifications, searchText, sort, notificationId, t));            
            dispatch(setLoading(false));
        }, (errorCodes, externalErrorText, externalErrorCode) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //ExternalErrorText = 4
                        case 4:
                            data.generalErrorText = `${t(externalErrorText)} [${externalErrorCode}]`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_FAIL, data });
            dispatch(updateDisplayNotifications(alarmSystems, [], searchText, sort, notificationId, t));
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function updateDisplayNotifications(alarmSystems, notifications, searchText, sort, notificationId, t) {
    return dispatch => {
        const notificationTypeLookup = {};
        for (let n of constants.NOTIFICATION_TYPES) {
            notificationTypeLookup[n.type] = t(`MonitoringType${n.name}`);
        }
        console.log("notificationTypeLookup", notificationTypeLookup);
        const macNameLookup = {};
        for (let a of alarmSystems) {
            macNameLookup[a.mac] = a.name;
        }
        console.log("macNameLookup", macNameLookup);
        const searchTextLowerCase = searchText ? searchText.toLowerCase() : null;
        let newNotifications = searchText ? notifications.filter(n => {
            //const re = new RegExp(`${searchText}`, "i");
            //return re.test(n.name) ||
            //    re.test(n.mac) ||
            //    re.test(notificationTypeLookup[n.notificationType]) ||
            //    re.test(n.message);
            
            return macNameLookup[n.mac].toLowerCase().includes(searchTextLowerCase) ||
                n.mac.toLowerCase().includes(searchTextLowerCase) ||
                notificationTypeLookup[n.notificationType].toLowerCase().includes(searchTextLowerCase) ||
                n.message.toLowerCase().includes(searchTextLowerCase);
        }) : [...notifications];
        newNotifications = newNotifications.sort((a, b) => {
            switch (sort.by) {
                case constants.MONITORING_SORT_BY_NAME:
                    return macNameLookup[a.mac].localeCompare(macNameLookup[b.mac]);
                case constants.MONITORING_SORT_BY_MAC:
                    return a.mac.localeCompare(b.mac);
                case constants.MONITORING_SORT_BY_NOTIFICATION_TYPE:
                    return notificationTypeLookup[a.notificationType].localeCompare(notificationTypeLookup[b.notificationType]);
                case constants.MONITORING_SORT_BY_MESSAGE:
                    return a.message.localeCompare(b.message);
                case constants.MONITORING_SORT_BY_DATE:
                default:
                    const ma = moment(a.eventDate);
                    const mb = moment(b.eventDate);
                    if (ma.isAfter(mb))
                        return 1;
                    else if (ma.isBefore(mb))
                        return -1;
                    else
                        return 0;
            }
        });
        if (sort.direction === "desc")
            newNotifications = newNotifications.reverse();

        let newNotificationId;
        if (notificationId < 0)
            newNotificationId = 0;
        else if (notificationId > newNotifications.length - 1)
            newNotificationId = newNotifications.length - 1;
        else
            newNotificationId = notificationId;

        dispatch({
            type: constants.ACTION_TYPE_MONITORING__UPDATE_DISPLAY_NOTIFICATIONS,
            data: {
                displayNotifications: {
                    notifications: newNotifications,
                    notificationId: newNotificationId,
                    searchText,
                    sort
                }
            }
        });
    }
}