import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    selectLanguageDialog: {
        isOpen: false
    }
};

const topMenu = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_TOP_MENU__OPEN_SELECT_LANGUAGE_DIALOG:
            newState = update(state, {
                selectLanguageDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default topMenu;