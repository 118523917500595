import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, Container, Breadcrumbs, Typography, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { confirmEmail } from '../../actions/confirmEmail';
import { useTranslation } from 'react-i18next';

const ConfirmEmail = () => {

    const { t, i18n } = useTranslation();
    const location = window.location;
    const history = useHistory();
    const loading = useSelector(state => state.global.loading);
    const generalErrorText = useSelector(state => state.confirmEmail.generalErrorText);
    const success = useSelector(state => state.confirmEmail.success);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    let query = new URLSearchParams(location.search);
    let userId = query.get("userId");
    let code = query.get("code");
    //console.log("location", location);
    let userEmail = query.get("email");
    useEffect(() => {
        console.log("userId", userId);
        console.log("code", code);
        dispatch(confirmEmail(userId, code, userEmail, history, t, language));
    }, [userId, code]);

    return (
        <Container>
            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("ConfirmEmail")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}
            {success ? <Alert style={{ marginTop: 10 }} severity="success">{t("ConfirmEmailSubtitle")}</Alert> : null}
        </Container>
    );
}

export default ConfirmEmail;