import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { CssBaseline, Container, LinearProgress } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import RTL from '../localization/RTL';
import * as constants from '../constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import useUser from '../user/useUser';
import MainSwitch from './routes/MainSwitch';

// pick a date util library
import MomentUtils from '@date-io/moment';
import moment from 'moment';

//import 'moment/locale/en'; preloaded
import 'moment/locale/he';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/ru';
import 'moment/locale/el';

moment.locale("en"); // it is required to select default locale manually

const App = () => {
    //let userType = localStorage.getItem(constants.STORAGE_KEY_USER_TYPE) || constants.USER_TYPE_ANONYMOUS;
    const { t, i18n } = useTranslation();
    const { isUserInitialized, isAuthenticated, isWebAccountAdmin, isWebAccountUser,
        isWebAccountUserMonitor, isWebAccountUserConfigurationRead, isWebAccountUserConfigurationWrite, isWebAccountUserSystem,
        isPortalAdmin, isPortalUser } = useUser();
    const loading = useSelector(state => state.global.loading);

    const languages = i18n.languages;
    const language = languages[0];
    const isRtl = language === "he";

    const theme = createMuiTheme({
        direction: isRtl ? "rtl" : "ltr",
    });

    useEffect(() => {
        document.body.dir = isRtl ? "rtl" : "ltr";
        document.body.lang = language;
        document.documentElement.dir = isRtl ? "rtl" : "ltr";
        document.documentElement.lang = language;
    }, [language, isRtl]);

    return <ThemeProvider theme={theme}>
        <RTL flip={isRtl}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={language}>
                {
                    isUserInitialized ?
                        <div>
                            <CssBaseline />
                            <Router basename="/">
                                <MainSwitch />
                            </Router>
                        </div>
                        :
                        <LinearProgress style={{ height: 3 }} />
                }
            </MuiPickersUtilsProvider>
        </RTL>
    </ThemeProvider>
}

export default App;