import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    userCount: 0,
    alarmSystemCount: 0,
    generalErrorText: null
};

const management = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_MANAGEMENT__GET_MANAGEMENT_SUMMARY_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                userCount: { $set: action.data.userCount },
                alarmSystemCount: { $set: action.data.alarmSystemCount }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT__GET_MANAGEMENT_SUMMARY_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                userCount: { $set: 0 },
                alarmSystemCount: { $set: 0 }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default management;