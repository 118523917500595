import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    alarmSystems: [],
    generalErrorText: null
};

const alarmSystems = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                alarmSystems: { $set: action.data.alarmSystems }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                alarmSystems: { $set: [] }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default alarmSystems;