import axios from 'axios';

//export function getConfiguration(id, token, successCallback, failCallback, unauthorizedCallback) {
//    const config = {
//        headers: { Authorization: `Bearer ${token}` }
//    };
//    axios.get(`/api/configuration/getConfiguration/${id}`, config)
//        .then(response => {
//            console.log(response);
//            if (response.data.success) {
//                successCallback(response.data.alarmSystem, response.data.configuration, response.data.dataView);
//            } else {
//                failCallback(response.data.errorCodes);
//            }
//        }).catch(error => {
//            console.error("error.response", error.response);
//            // if 401 UNAUTHORIZED, log out
//            if (error.response.status === 401) {
//                unauthorizedCallback();
//            } else {
//                failCallback();
//            }
//        });
//}

//export function clear(id, token, successCallback, failCallback, unauthorizedCallback) {
//    const config = {
//        headers: { Authorization: `Bearer ${token}` }
//    };
//    axios.get(`/api/configuration/clearConfiguration/${id}`, config)
//        .then(response => {
//            console.log(response);
//            if (response.data.success) {
//                successCallback();
//            } else {
//                failCallback(response.data.errorCodes);
//            }
//        }).catch(error => {
//            console.error("error.response", error.response);
//            // if 401 UNAUTHORIZED, log out
//            if (error.response.status === 401) {
//                unauthorizedCallback();
//            } else {
//                failCallback();
//            }
//        });
//}

//export function updateParameters(id, dirtyParameters, token, successCallback, failCallback, unauthorizedCallback) {
//    const config = {
//        headers: { Authorization: `Bearer ${token}` }
//    };
//    axios.post('/api/configuration/updateParameters', {
//        id,
//        parameters: dirtyParameters
//    }, config)
//        .then(response => {
//            console.log(response);
//            if (response.data.success) {
//                successCallback();
//            } else {
//                failCallback(response.data.errorCodes, response.data.parameterValidationErrors);
//            }
//        }).catch(error => {
//            console.error("error.response", error.response);
//            // if 401 UNAUTHORIZED, log out
//            if (error.response.status === 401) {
//                unauthorizedCallback();
//            } else {
//                failCallback();
//            }
//        });
//}

export function read(id, code, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/configuration/read', {
        id,
        code
    }, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.alarmSystem, response.data.configuration, response.data.dataView);
            } else {
                failCallback(response.data.errorCodes, response.data.externalErrorText, response.data.externalErrorCode);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

// public bool Success { get; set; }
// public List < ParameterValidationError > ExcludedParameters { get; set; }
// public List < ParameterValidationError > ParameterValidationErrors { get; set; }
// public WriteResponseErrorCode[] ErrorCodes { get; set; }
// public string ExternalErrorText { get; set; }
// public string ExternalErrorCode { get; set; }
export function write(id, code, parameters, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/configuration/write', {
        id,
        code,
        parameters
    }, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.excludedParameters);
            } else {
                failCallback(response.data.errorCodes, response.data.externalErrorText, response.data.externalErrorCode,
                    response.data.parameterValidationErrors, response.data.parameterError);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}
