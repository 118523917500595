import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    success: false
};

const confirmEmail = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_CONFIRM_EMAIL__CONFIRM_EMAIL_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                success: { $set: true }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_CONFIRM_EMAIL__CONFIRM_EMAIL_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default confirmEmail;