import { validateParameter } from '../validators';
import update from 'immutability-helper';
import { number } from 'prop-types';
import * as constants from '../constants';

//const configurationState = {
//    pages: [],
//    tables: [],
//    rows: [],
//    cells: [],
//    parameters: [],
//    modifiedParameterIndices: [],
//    validationErrorParameterIndices: [],
//    excludedParameterIndices: [],
//    errorParameterIndices: []
//};

//const parameter = {
//    identifier: number,
//    order: number,
//    bit: number,
//    value: string,
//    modified: boolean, // if true, write even if value == newValue
//    read: boolean,
//    config: boolean,
//    paramInfoParameterType: number,
//    paramInfoLength: number,
//    pageId: number,
//    tableId: number,
//    rowId: number,
//    cellId: number,
//    newValue: string,
//    validationErrorText: string,
//    errorText: string,
//    excluded: boolean
//}

function initConfiguration(dataView, configuration) {

    const pages = [];
    const tables = [];
    const rows = [];
    const cells = [];
    const parameters = [];
    const modifiedParameterIndices = [];
    const validationErrorParameterIndices = [];
    const excludedParameterIndices = [];
    const errorParameterIndices = [];

    if (dataView && configuration && configuration.parameters) {
        let parameter;
        for (let i = 0; i < configuration.parameters.length; i++) {
            // {
            //  identifier: 1,
            //  order: 1,
            //  bit: 0,
            //  value: "val",
            //  modified: true, // if true, add to dirty parameters even if value == newValue
            //  read: true,
            //  config: true,
            //  paramInfoParameterType: 1,
            //  paramInfoLength: 1,
            //  +
            // }
            parameter = configuration.parameters[i];
            parameter.id = i;
            parameter.pageId = -1;
            parameter.tableId = -1;
            parameter.rowId = -1;
            parameter.cellId = -1;
            parameter.newValue = parameter.value;
            parameter.validationErrorText = null;
            parameter.errorText = null;
            if (parameter.modified)
                modifiedParameterIndices.push(i);

            parameters.push(parameter);
        }

        let page;
        let table;
        let row;
        let cell;
        let parameterIndex;
        for (let p = 0; p < dataView.pageViewList.length; p++) {
            page = dataView.pageViewList[p];
            for (let t = 0; t < page.tableViewList.length; t++) {
                table = page.tableViewList[t];
                table.pageId = pages.length;
                table.firstRowId = rows.length;
                for (let r = 0; r < table.rowViewList.length; r++) {
                    row = table.rowViewList[r];
                    row.pageId = pages.length;
                    row.tableId = tables.length;
                    row.firstCellId = cells.length;
                    for (let c = 0; c < row.cellMetadataList.length; c++) {
                        cell = row.cellMetadataList[c];
                        cell.pageId = pages.length;
                        cell.tableId = tables.length;
                        cell.rowId = rows.length;
                        cell.parameterIndex = -1;
                        if (cell.identifier > 0) {
                            parameterIndex = parameters.findIndex(prm => prm.identifier === cell.identifier && prm.order === cell.order && prm.bit === cell.bitNumber);
                            if (parameterIndex >= 0) {
                                cell.parameterIndex = parameterIndex;
                                if (parameters[parameterIndex].pageId < 0) {
                                    parameters[parameterIndex].pageId = pages.length;
                                    parameters[parameterIndex].tableId = tables.length;
                                    parameters[parameterIndex].rowId = rows.length;
                                    parameters[parameterIndex].cellId = cells.length;
                                }
                            }
                        }
                        cell.id = cells.length;
                        cells.push(cell);
                    }
                    delete row.cellMetadataList;
                    row.id = rows.length;
                    row.lastCellId = cells.length - 1;
                    rows.push(row);
                }
                delete table.rowViewList;
                table.id = tables.length;
                table.lastRowId = rows.length - 1;
                tables.push(table);
            }
            delete page.tableViewList;
            page.id = pages.length;
            pages.push(page);
        }
    }
    
    const configurationState = {
        pages,
        tables,
        rows,
        cells,
        parameters,
        modifiedParameterIndices,
        validationErrorParameterIndices,
        excludedParameterIndices,
        errorParameterIndices
    };
    return configurationState;
}

function clearModifiedParameters(configurationState) {
    //let newParameters = configurationState.parameters;
    //let parameter;
    //for (let parameterIndex = 0; parameterIndex < configurationState.parameters; parameterIndex++) {
    //    parameter = configurationState.parameters[parameterIndex];
    //    newParameters = update(newParameters, {
    //        [parameterIndex]: {
    //            modified: { $set: false },
    //            read: { $set: false },
    //            value: { $set: parameter.newValue }
    //        }
    //    });
    //}

    //let parameter;
    //for (let i = 0; i < configurationState.parameters.length; i++) {
    //    parameter = configurationState.parameters[i];
    //    parameter.modified = false;
    //    parameter.value = parameter.newValue;
    //    parameter.read = false;
    //}

    configurationState.modifiedParameterIndices = [];
    const newConfigurationState = update(configurationState, {
        parameters: {
            $apply: arr => arr.map(p => update(p, {
                modified: { $set: false },
                read: { $set: false },
                value: {$set: p.newValue}
            }))
        },
        modifiedParameterIndices: { $set: [] }
    });
    return newConfigurationState;
    ////const parameterIndices = [];
    ////let parameter;
    ////for (let parameterIndex of configuration.modifiedParameterIndices) {
    ////    if (parameterIndices.includes(parameterIndex))
    ////        continue;

    ////    parameter = configuration.parameters[parameterIndex];
    ////    for (let i = 0; i < configuration.parameters.length; i++) {
    ////        if (configuration.parameters[i].identifier === parameter.identifier && configuration.parameters[i].order === parameter.order)
    ////            parameterIndices.push(i);
    ////    }
    ////}
    ////const distinctParameterIndices = [...new Set(parameterIndices)];
    ////distinctParameterIndices.forEach(i => {
    ////    parameter = configuration.parameters[i];
    ////    parameter.modified = false;
    ////    parameter.value = parameter.newValue;
    ////    parameter.read = false;
    ////});
    ////configuration.modifiedParameterIndices = [];
}

function getParametersToWrite(configuration) {
    const parameterIndices = [];
    let parameter;
    for (let parameterIndex of configuration.modifiedParameterIndices) {
        if (parameterIndices.includes(parameterIndex))
            continue;

        parameter = configuration.parameters[parameterIndex];
        for (let i = 0; i < configuration.parameters.length; i++) {
            if (configuration.parameters[i].identifier === parameter.identifier && configuration.parameters[i].order === parameter.order)
                parameterIndices.push(i);
        }
    }
    const distinctParameterIndices = [...new Set(parameterIndices)];
    const parameters = distinctParameterIndices.map(i => {
        parameter = configuration.parameters[i];
        return {
            identifier: parameter.identifier,
            order: parameter.order,
            bit: parameter.bit,
            value: parameter.newValue,
            modified: true,
            read: false,
            config: parameter.config,
            paramInfoParameterType: parameter.paramInfoParameterType,
            paramInfoLength: parameter.paramInfoLength
        };
    });
    return parameters;
}
function getAllParametersToWrite(configuration) {
    const parameters = configuration.parameters.map(p => {
        return {
            identifier: p.identifier,
            order: p.order,
            bit: p.bit,
            value: p.newValue,
            modified: false,
            read: false,
            config: p.config,
            paramInfoParameterType: p.paramInfoParameterType,
            paramInfoLength: p.paramInfoLength
        };
    });
    return parameters;
}
function updateParameterValue(parameterIndex, newValue, configurationState, t) {
    
    const parameter = configurationState.parameters[parameterIndex];
    const cell = configurationState.cells[parameter.cellId];
    const validationErrorText = validateParameter(newValue, parameter.paramInfoParameterType, parameter.paramInfoLength, cell, t);

    //const configurationState = {
//    pages: [],
//    tables: [],
//    rows: [],
//    cells: [],
//    parameters: [],
//    modifiedParameterIndices: [],
//    validationErrorParameterIndices: [],
//    excludedParameterIndices: [],
//    errorParameterIndices: []
    //};
    const oldModifiedIndex = configurationState.modifiedParameterIndices.findIndex(p => p === parameterIndex);
    const newModified = parameter.modified || newValue !== parameter.value;
    let newModifiedParameterIndices;
    if (newModified && oldModifiedIndex < 0) {
        newModifiedParameterIndices = update(configurationState.modifiedParameterIndices, { $push: [parameterIndex] });
    }else if (!newModified && oldModifiedIndex >= 0) {
        newModifiedParameterIndices = update(configurationState.modifiedParameterIndices, { $splice: [[oldModifiedIndex, 1]] });
    } else {
        newModifiedParameterIndices = configurationState.modifiedParameterIndices;
    }

    const oldValidationErrorIndex = configurationState.validationErrorParameterIndices.findIndex(p => p === parameterIndex);
    let newValidationErrorParameterIndices;
    if (validationErrorText && oldValidationErrorIndex < 0) {
        newValidationErrorParameterIndices = update(configurationState.validationErrorParameterIndices, { $push: [parameterIndex] });
    } else if (!validationErrorText && oldValidationErrorIndex >= 0) {
        newValidationErrorParameterIndices = update(configurationState.validationErrorParameterIndices, { $splice: [[oldValidationErrorIndex, 1]] });
    } else {
        newValidationErrorParameterIndices = configurationState.validationErrorParameterIndices;
    }

    const newConfigurationState = update(configurationState, {
        parameters: {
            [parameterIndex]: {
                validationErrorText: { $set: validationErrorText },
                newValue: { $set: newValue },
                modified: { $set: newModified }
            }
        },
        modifiedParameterIndices: { $set: newModifiedParameterIndices },
        validationErrorParameterIndices: { $set: newValidationErrorParameterIndices}
    });
    return newConfigurationState;
}

function updateExcludedParameters(excludedParameters, configurationState, t) {

    const newExcludedParameterIndices = [];
    let newParameters = configurationState.parameters;
    let parameter;
    let excludedParameter;
    for (let parameterIndex = 0; parameterIndex < configurationState.parameters; parameterIndex++) {
        parameter = configurationState.parameters[parameterIndex];
        excludedParameter = excludedParameters.find(p => p.identifier === parameter.identifier && p.order === parameter.order && p.bit === parameter.bit);
        if (excludedParameter) {
            newExcludedParameterIndices.push(parameterIndex);
            if (!parameter.excluded) {
                newParameters = update(newParameters, {
                    [parameterIndex]: {
                        excluded: { $set: true }
                    }
                });
            }
        } else {
            if (parameter.excluded) {
                newParameters = update(newParameters, {
                    [parameterIndex]: {
                        excluded: { $set: false }
                    }
                });
            }
        }
    }

    //const configurationState = {
    //    pages: [],
    //    tables: [],
    //    rows: [],
    //    cells: [],
    //    parameters: [],
    //    modifiedParameterIndices: [],
    //    validationErrorParameterIndices: [],
    //    excludedParameterIndices: [],
    //    errorParameterIndices: []
    //};
    const newConfigurationState = update(configurationState, {
        parameters: { $set: newParameters },
        excludedParameterIndices: { $set: newExcludedParameterIndices }
    });
    return newConfigurationState;
}

function updateValidationErrorParameters(validationErrorParameters, configurationState, t) {

    const newValidationErrorParameterIndices = [];
    let newParameters = configurationState.parameters;
    let parameter;
    let validationErrorParameter;
    for (let parameterIndex = 0; parameterIndex < configurationState.parameters; parameterIndex++) {
        parameter = configurationState.parameters[parameterIndex];
        validationErrorParameter = validationErrorParameters.find(p => p.identifier === parameter.identifier && p.order === parameter.order && p.bit === parameter.bit);
        if (validationErrorParameter) {
            newValidationErrorParameterIndices.push(parameterIndex);
            if (!parameter.validationErrorText) {
                newParameters = update(newParameters, {
                    [parameterIndex]: {
                        validationErrorText: { $set: validationErrorParameter.parameterErrorText }
                    }
                });
            }
        } else {
            if (parameter.validationErrorText) {
                newParameters = update(newParameters, {
                    [parameterIndex]: {
                        validationErrorText: { $set: null }
                    }
                });
            }
        }
    }

    //const configurationState = {
    //    pages: [],
    //    tables: [],
    //    rows: [],
    //    cells: [],
    //    parameters: [],
    //    modifiedParameterIndices: [],
    //    validationErrorParameterIndices: [],
    //    excludedParameterIndices: [],
    //    errorParameterIndices: []
    //};
    const newConfigurationState = update(configurationState, {
        parameters: { $set: newParameters },
        validationErrorParameterIndices: { $set: newValidationErrorParameterIndices },
    });
    return newConfigurationState;
}


function updateErrorParameters(errorParameters, configurationState, t) {

    const newErrorParameterIndices = [];
    let newParameters = configurationState.parameters;
    let parameter;
    let errorParameter;
    for (let parameterIndex = 0; parameterIndex < configurationState.parameters; parameterIndex++) {
        parameter = configurationState.parameters[parameterIndex];
        errorParameter = errorParameters.find(p => p.identifier === parameter.identifier && p.order === parameter.order && p.bit === parameter.bit);
        
        if (errorParameter) {
            newErrorParameterIndices.push(parameterIndex);
            if (!parameter.errorText) {
                newParameters = update(newParameters, {
                    [parameterIndex]: {
                        errorText: { $set: errorParameter.parameterErrorText }
                    }
                });
            }
        } else {
            if (parameter.errorText) {
                newParameters = update(newParameters, {
                    [parameterIndex]: {
                        errorText: { $set: null }
                    }
                });
            }
        }
    }

    //const configurationState = {
    //    pages: [],
    //    tables: [],
    //    rows: [],
    //    cells: [],
    //    parameters: [],
    //    modifiedParameterIndices: [],
    //    validationErrorParameterIndices: [],
    //    excludedParameterIndices: [],
    //    errorParameterIndices: []
    //};
    const newConfigurationState = update(configurationState, {
        parameters: { $set: newParameters },
        errorParameterIndices: { $set: newErrorParameterIndices }
    });
    return newConfigurationState;
}

export default {
    initConfiguration,
    clearModifiedParameters,
    getParametersToWrite,
    getAllParametersToWrite,
    updateParameterValue,
    updateExcludedParameters,
    updateValidationErrorParameters,
    updateErrorParameters
}