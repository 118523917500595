import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    useTheme, useMediaQuery, Button, TextField, Container, Breadcrumbs, Typography, Divider,
    TableContainer, Table, TableBody, TableRow, TableCell, Link, AppBar, Toolbar, IconButton,
    InputAdornment, FormControl, Paper, List, ListItem, ListItemText, ListItemIcon, ListSubheader, Menu, MenuItem
} from '@material-ui/core';
import { Delete as DeleteIcon, Warning as WarningIcon, Check as CheckIcon, MoreVert as MoreVertIcon} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import {
    getWebAccountUser, updateWebAccountUser, updateFormUserType, resetForm,
    openDeleteDialog, openSendConfirmationEmailDialog, activateWebAccountUser
} from '../../actions/webAccountUserDetails';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';
import useUser from '../../user/useUser';
import { unsetUser } from '../../actions/user';
import EditableTextField from '../common/EditableTextField';
import SendWebAccountUserConfirmationEmailDialog from './SendWebAccountUserConfirmationEmailDialog';
import DeleteWebAccountUserDialog from './DeleteWebAccountUserDialog';
import WebAccountUserPermissions from './WebAccountUserPermissions';

const WebAccountUserDetails = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const { isWebAccountAdmin, token } = useUser();
    const loading = useSelector(state => state.global.loading);
    const webAccountUser = useSelector(state => state.webAccountUserDetails.webAccountUser);
    const generalErrorText = useSelector(state => state.webAccountUserDetails.generalErrorText);
    const formData = useSelector(state => state.webAccountUserDetails.formData);
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getWebAccountUser(id, history, token, unsetUser, t, language));
    }, [id]);

    const appBar = webAccountUser && isWebAccountAdmin ?
        <AppBar color="inherit" position="static">
            <Toolbar variant="dense">
                <Button color="inherit" disabled={loading || !formData.isValid || !formData.isDirty}
                    onClick={() => dispatch(updateWebAccountUser(webAccountUser.id, formData.userType, history, token, unsetUser, t, language))}>{t("Save")}</Button>
                <Button color="inherit" disabled={loading || !formData.isDirty}
                    onClick={() => {
                        dispatch(resetForm(webAccountUser));
                    }}>{t("Cancel")}</Button>
                {
                    isMobile ?
                        <React.Fragment>
                            <div style={{ flex: 1 }} />
                            <IconButton disabled={loading} edge="end" color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
                                <MoreVertIcon />
                            </IconButton>

                            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)} keepMounted>
                                <MenuItem disabled={loading}
                                    onClick={() => {
                                        dispatch(activateWebAccountUser(id, !webAccountUser.active, history, token, unsetUser, t, language));
                                        setAnchorEl(null);
                                    }}>
                                    <ListItemText inset primary={webAccountUser.active ? t("Deactivate") : t("Activate")} />
                                </MenuItem>
                                {
                                    webAccountUser.active && !webAccountUser.emailConfirmed ?
                                        <MenuItem disabled={loading}
                                            onClick={() => {
                                                dispatch(openSendConfirmationEmailDialog(true));
                                                setAnchorEl(null);
                                            }}>
                                            <ListItemText inset primary={t("SendConfirmationEmail")} />
                                        </MenuItem> :
                                        null
                                }
                                <MenuItem disabled={loading} onClick={() => {
                                    dispatch(openDeleteDialog(true));
                                    setAnchorEl(null);
                                }}>
                                    <ListItemIcon>
                                        <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("DeleteUser")} />
                                </MenuItem>
                            </Menu>
                        </React.Fragment> :
                        <React.Fragment>
                            <Button disabled={loading} color="inherit"
                                onClick={() => dispatch(activateWebAccountUser(id, !webAccountUser.active, history, token, unsetUser, t))}>
                                {webAccountUser.active ? t("Deactivate") : t("Activate")}
                            </Button>
                            {
                                webAccountUser.active && !webAccountUser.emailConfirmed ?
                                    <Button disabled={loading} color="inherit"
                                        onClick={() => dispatch(openSendConfirmationEmailDialog(true))}>
                                        {t("SendConfirmationEmail")}
                                    </Button> :
                                    null
                            }
                            <div style={{ flex: 1 }} />
                            <Button disabled={loading} color="inherit"
                                onClick={() => dispatch(openDeleteDialog(true))}
                                endIcon={<DeleteIcon />}>{t("DeleteUser")}</Button>
                        </React.Fragment>
                }
                
            </Toolbar>
        </AppBar> :
        null;

    return (
        <Container>
            {webAccountUser ? <SendWebAccountUserConfirmationEmailDialog /> : null}
            {webAccountUser ? <DeleteWebAccountUserDialog /> : null}

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Link color="inherit" component={RouterLink} to={constants.ROUTE_ACCOUNT_USERS}>{t("WebAccountUsers")}</Link>
                {webAccountUser ? <Typography color="textPrimary">{webAccountUser.email}</Typography> : null}
                <Typography color="textPrimary">{t("Details")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {appBar}

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            {
                webAccountUser ?
                    <React.Fragment>
                        <div style={{ maxWidth: 300, marginTop: 20 }}>
                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                <EditableTextField readOnly
                                    fullWidth
                                    type="text"
                                    label={t("WebAccountUserStatus")}
                                    value={!webAccountUser.active ? t("UserNotActive") :
                                        (!webAccountUser.emailConfirmed ? t("EmailNotConfirmed") : t("Active"))}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!webAccountUser.active ? <WarningIcon color="error" /> :
                                                    (!webAccountUser.emailConfirmed ? <WarningIcon color="error" /> : <CheckIcon color="primary" />)}
                                            </InputAdornment>
                                        ),
                                    }} />
                            </FormControl>
                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                <EditableTextField readOnly
                                    fullWidth
                                    type="text"
                                    label={t("FirstName")}
                                    value={webAccountUser.firstName} />
                            </FormControl>
                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                <EditableTextField readOnly
                                    fullWidth
                                    type="text"
                                    label={t("LastName")}
                                    value={webAccountUser.lastName} />
                            </FormControl>
                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                <EditableTextField readOnly
                                    fullWidth
                                    type="text"
                                    label={t("EMail")}
                                    value={webAccountUser.email} />
                            </FormControl>
                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                <EditableTextField readOnly
                                    fullWidth
                                    type="text"
                                    label={t("PhoneNumber")}
                                    value={webAccountUser.phoneNumber} />
                            </FormControl>
                        </div>

                        <div style={{ marginTop: 10, maxWidth: 600 }}>
                            <WebAccountUserPermissions userType={formData.userType} readOnly={!isWebAccountAdmin}
                                onChange={userType => dispatch(updateFormUserType(userType))} />
                            
                        </div>
                    </React.Fragment> :
                    null
            }
            {/*<TableContainer style={{ marginTop: 20 }}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t("Status")}</TableCell>
                                    <TableCell>
                                        {
                                            !webAccountUser.active ?
                                                <Typography color="error">{t("UserNotActive")}</Typography> :
                                                (
                                                    !webAccountUser.emailConfirmed ?
                                                        <Typography color="error">{t("EmailNotConfirmed")}</Typography> :
                                                        <Typography color="error">{t("Active")}</Typography>
                                                )
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t("FirstName")}</TableCell>
                                    <TableCell>{webAccountUser.firstName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t("LastName")}</TableCell>
                                    <TableCell>{webAccountUser.lastName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t("EMail")}</TableCell>
                                    <TableCell>{webAccountUser.email}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">{t("PhoneNumber")}</TableCell>
                                    <TableCell>{webAccountUser.phoneNumber}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>*/}
        </Container>
    );
}

export default WebAccountUserDetails;