import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';
import { validatePairingCode, validateEmail, validateParameter } from '../validators';
import configurationHelper from '../helpers/configurationHelpers';

export function updateSaveAsTemplateFormName(name, t) {
    return (dispatch) => {
        let formData = {
            name: name,
            nameErrorText: null,
            nameValid: false
        };
        if (!name)
            formData.nameErrorText = "*";
        else
            formData.nameValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_SAVE_AS_TEMPLATE_FORM_DATA, data: { formData } });
    }
}
export function updateLoadTemplateFormDataViewName(dataViewName, t) {
    return (dispatch) => {
        let formData = {
            dataViewName: dataViewName,
            dataViewNameErrorText: null,
            dataViewNameValid: false
        };
        if (!dataViewName)
            formData.dataViewNameErrorText = "*";
        else
            formData.dataViewNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_LOAD_TEMPLATE_FORM_DATA, data: { formData } });
    }
}
export function updateLoadTemplateFormLanguage(language, t) {
    return (dispatch) => {
        let formData = {
            language: language,
            languageErrorText: null,
            languageValid: false
        };
        if (!language)
            formData.languageErrorText = "*";
        else
            formData.languageValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_LOAD_TEMPLATE_FORM_DATA, data: { formData } });
    }
}
export function updateLoadTemplateFormTemplateId(templateId, t) {
    return (dispatch) => {
        let formData = {
            templateId: templateId,
            templateIdErrorText: null,
            templateIdValid: false
        };
        if (!templateId)
            formData.templateIdErrorText = "*";
        else
            formData.templateIdValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_LOAD_TEMPLATE_FORM_DATA, data: { formData } });
    }
}
export function updateReadFormCode(code, t) {
    return (dispatch) => {
        let formData = {
            code: code,
            codeErrorText: null,
            codeValid: false
        };
        if (!code)
            formData.codeErrorText = "*";
        else
            formData.codeValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_READ_FORM_DATA, data: { formData } });
    }
}
export function updateWriteFormCode(code, t) {
    return (dispatch) => {
        let formData = {
            code: code,
            codeErrorText: null,
            codeValid: false
        };
        if (!code)
            formData.codeErrorText = "*";
        else
            formData.codeValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_WRITE_FORM_DATA, data: { formData } });
    }
}
export function updatePairFormPairingCode(pairingCode, t) {
    return (dispatch) => {
        let formData = {
            pairingCode: pairingCode,
            pairingCodeErrorText: null,
            pairingCodeValid: false
        };
        if (!pairingCode)
            formData.pairingCodeErrorText = "*";
        else if (!validatePairingCode(pairingCode))
            formData.pairingCodeErrorText = t("ErrorPairingCodeInvalid");
        else
            formData.pairingCodeValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_PAIR_FORM_DATA, data: { formData } });
    }
}


export function openPairDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__OPEN_PAIR_DIALOG, data: { isOpen } });
    }
}
export function openUnpairDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__OPEN_UNPAIR_DIALOG, data: { isOpen } });
    }
}
export function openReadDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__OPEN_READ_DIALOG, data: { isOpen } });
    }
}
export function openWriteDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__OPEN_WRITE_DIALOG, data: { isOpen } });
    }
}
export function openLoadTemplateDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__OPEN_LOAD_TEMPLATE_DIALOG, data: { isOpen } });
    }
}
export function openSaveAsTemplateDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__OPEN_SAVE_AS_TEMPLATE_DIALOG, data: { isOpen } });
    }
}
export function openClearDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__OPEN_CLEAR_DIALOG, data: { isOpen } });
    }
}
export function openParameterErrorsDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__OPEN_PARAMETER_ERRORS_DIALOG, data: { isOpen } });
    }
}

export function browseConfiguration(history, id, pageId, tableId, rowId, cellId) {
    return (dispatch) => {
        dispatch({
            type: constants.ACTION_TYPE_CONFIGURATION__BROWSE_CONFIGURATION,
            data: { pageId, tableId, rowId, cellId }
        });
        //const url = `/configuration/${id}/${pageId}/${tableId}/${rowId}`;
        //history.push(url);
    }
}
export function goToPage(pageId) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__GO_TO_PAGE, data: { pageId } });
    }
}
export function goToTable(tableId) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__GO_TO_TABLE, data: { tableId } });
    }
}
//export function goToPaginationPage(paginationPage) {
//    return (dispatch) => {
//        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__GO_TO_PAGINATION_PAGE, data: { paginationPage } });
//    }
//}

//export function getConfiguration(id, history, token, unsetUser, t) {
//    return (dispatch) => {
//        dispatch(setLoading(true));

//        network.getConfiguration(id, token, (alarmSystem, configuration, dataView) => {
//            dispatch({
//                type: constants.ACTION_TYPE_CONFIGURATION__GET_CONFIGURATION_SUCCESS,
//                data: { alarmSystem, configuration, dataView }
//            });
//            dispatch(setLoading(false));
//        }, (errorCodes) => {
//            let data = {
//                generalErrorText: null
//            };
//            if (errorCodes) {
//                for (let errorCode of errorCodes) {
//                    switch (errorCode) {
//                        //GeneralError = 1,
//                        case 1:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //UnexpectedError = 2,
//                        case 2:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //MissingParameters = 3,
//                        case 3:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //AlarmSystemNotFound = 4
//                        case 4:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        default:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                    }
//                }
//            } else {
//                data.generalErrorText = t("ErrorGeneral");
//            }
//            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__GET_CONFIGURATION_FAIL, data });
//            dispatch(setLoading(false));
//        }, () => {
//            unsetUser();
//            dispatch(setLoading(false));
//            history.push('/account/login');
//        });
//    }
//}
export function getAlarmSystem(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getAlarmSystem(id, token, language, (alarmSystem) => {
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__GET_ALARM_SYSTEM_SUCCESS,
                data: { alarmSystem }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__GET_ALARM_SYSTEM_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function pair(id, pairingCode, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.pair(id, pairingCode, token, language, (alarmSystem) => {
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__PAIR_SUCCESS,
                data: { alarmSystem }
            });
            dispatch(setLoading(false));
        }, (errorCodes, externalErrorText, externalErrorCode) => {
            let data = {
                generalErrorText: null,
                formData: {
                    pairingCodeErrorText: null,
                    pairingCodeValid: true
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //ExternalErrorText = 5,
                        case 5:
                            data.generalErrorText = `${t(externalErrorText)} [${externalErrorCode}]`;
                            break;
                        //PairingCodeRequired = 6
                        case 6:
                            data.formData.pairingCodeErrorText = "*";
                            data.formData.pairingCodeValid = false;
                            break;
		                //InvalidPairingCode = 7
                        case 7:
                            data.formData.pairingCodeErrorText = t("ErrorPairingCodeInvalid");
                            data.formData.pairingCodeValid = false;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__PAIR_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function unpair(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.unpair(id, token, language, (alarmSystem) => {
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__UNPAIR_SUCCESS,
                data: { alarmSystem }
            });
            dispatch(setLoading(false));
        }, (errorCodes, externalErrorText, externalErrorCode) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //ExternalErrorText = 5,
                        case 5:
                            data.generalErrorText = `${t(externalErrorText)} [${externalErrorCode}]`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UNPAIR_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function getWebAccountTemplates(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getWebAccountTemplates(id, token, language, (templates) => {
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__GET_WEB_ACCOUNT_TEMPLATES_SUCCESS,
                data: { templates }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__GET_WEB_ACCOUNT_TEMPLATES_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function getDefaultTemplates(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getDefaultTemplates(id, token, language, (dataViewNames, languages, defaultLanguage) => {
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__GET_DEFAULT_TEMPLATES_SUCCESS,
                data: { dataViewNames, languages }
            });
            dispatch(updateLoadTemplateFormLanguage(defaultLanguage));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__GET_DEFAULT_TEMPLATES_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function loadDefaultTemplate(id, dataViewName, configLanguage, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.loadDefaultTemplate(id, dataViewName, configLanguage, token, language, (alarmSystem, configuration, dataView) => {
            const newConfiguration = configurationHelper.initConfiguration(dataView, configuration);
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_SUCCESS,
                data: { alarmSystem, configuration: newConfiguration}
            });
            //dispatch(getConfiguration(id, history, token, unsetUser));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function loadWebAccountTemplate(id, templateId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.loadWebAccountTemplate(id, templateId, token, language, (template, alarmSystem, configuration, dataView) => {
            const newConfiguration = configurationHelper.initConfiguration(dataView, configuration);
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_SUCCESS,
                data: { alarmSystem, configuration: newConfiguration}
            });
            //dispatch(getConfiguration(id, history, token, unsetUser));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
		                //TemplateNotFound  = 5
                        case 5:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function read(id, code, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.read(id, code, token, language, (alarmSystem, configuration, dataView) => {
            const newConfiguration = configurationHelper.initConfiguration(dataView, configuration);
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__READ_SUCCESS,
                data: { alarmSystem, configuration: newConfiguration }
            });
            //dispatch(getConfiguration(id, history, token, unsetUser));
            dispatch(setLoading(false));
        }, (errorCodes, externalErrorText, externalErrorCode) => {
            let data = {
                generalErrorText: null,
                formData: {
                    codeErrorText: null,
                    codeValid: true
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotPaired = 5,
                        case 5:
                            data.generalErrorText = t("NotPaired");
                            break;
                        //ExternalErrorText = 6,
                        case 6:
                            data.generalErrorText = `${t(externalErrorText)} [${externalErrorCode}]`;
                            break;
                        //CodeRequired = 7
                        case 7:
                            data.formData.codeErrorText = "*";
                            data.formData.codeValid = false;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__READ_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function write(id, code, configuration, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));
        const parameters = configurationHelper.getParametersToWrite(configuration);
        network.write(id, code, parameters, token, language, (excludedParameters) => {
            let newConfiguration = configurationHelper.updateExcludedParameters(excludedParameters, configuration, t);
            newConfiguration = configurationHelper.updateErrorParameters([], newConfiguration, t);
            newConfiguration = configurationHelper.clearModifiedParameters(newConfiguration);
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__WRITE_SUCCESS,
                data: { configuration: newConfiguration }
            });
            //dispatch(getConfiguration(id, history, token, unsetUser));
            dispatch(setLoading(false));
        }, (errorCodes, externalErrorText, externalErrorCode, parameterValidationErrors, parameterErrors) => {
            let data = {
                configuration: configuration,
                generalErrorText: null,
                formData: {
                    codeErrorText: null,
                    codeValid: true
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotPaired = 5,
                        case 5:
                            data.generalErrorText = t("NotPaired");
                            break;
                        //ParameterValidationErrors = 6,
                        case 6:
                            data.configuration = configurationHelper.updateValidationErrorParameters(parameterValidationErrors, data.configuration, t);
                            data.configuration = configurationHelper.updateErrorParameters([], data.configuration, t);
                            break;
                        //ExternalErrorText = 7,
                        case 7:
                            data.generalErrorText = `${t(externalErrorText)} [${externalErrorCode}]`;
                            break;
                        //CodeRequired = 8
                        case 8:
                            data.formData.codeErrorText = "*";
                            data.formData.codeValid = false;
                            break;
		                //ParameterErrors = 9
                        case 9:
                            data.configuration = configurationHelper.updateErrorParameters(parameterErrors, data.configuration, t);
                            data.configuration = configurationHelper.updateValidationErrorParameters([], data.configuration, t);
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__WRITE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function saveAsTemplate(id, name, configuration, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));
        const parameters = configurationHelper.getAllParametersToWrite(configuration);
        network.saveAsTemplate(name, parameters, token, language, () => {
            //let newConfiguration = configurationHelper.updateExcludedParameters(excludedParameters, configuration, t);
            //newConfiguration = configurationHelper.updateErrorParameters([], newConfiguration, t);
            dispatch({
                type: constants.ACTION_TYPE_CONFIGURATION__SAVE_AS_TEMPLATE_SUCCESS,
                data: { configuration: configuration }
            });
            //dispatch(getConfiguration(id, history, token, unsetUser));
            dispatch(setLoading(false));
        }, (errorCodes, parameterValidationErrors) => {
            let data = {
                configuration: configuration,
                generalErrorText: null,
                formData: {
                    nameErrorText: null,
                    nameValid: true,
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //NameRequired = 4,
                        case 4:
                            data.formData.nameErrorText = "*";
                            data.formData.nameValid = false;
                            break;
                        //NameAlreadyExists = 5,
                        case 5:
                            data.formData.nameErrorText = t("NameAlreadyExists", { first: name });
                            data.formData.nameValid = false;
                            break;
                        //ParameterValidationErrors = 6,
                        case 6:
                            data.configuration = configurationHelper.updateValidationErrorParameters(parameterValidationErrors, data.configuration, t);
                            data.configuration = configurationHelper.updateErrorParameters([], data.configuration, t);
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__SAVE_AS_TEMPLATE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

//export function clear(id, history, token, unsetUser, t) {
//    return (dispatch) => {
//        dispatch(setLoading(true));

//        network.clear(id, token, () => {
//            dispatch({
//                type: constants.ACTION_TYPE_CONFIGURATION__CLEAR_SUCCESS
//            });
//            dispatch(setLoading(false));
//        }, (errorCodes) => {
//            let data = {
//                generalErrorText: null
//            };
//            if (errorCodes) {
//                for (let errorCode of errorCodes) {
//                    switch (errorCode) {
//                        //GeneralError = 1,
//                        case 1:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //UnexpectedError = 2,
//                        case 2:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //MissingParameters = 3,
//                        case 3:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //AlarmSystemNotFound = 4
//                        case 4:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        default:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                    }
//                }
//            } else {
//                data.generalErrorText = t("ErrorGeneral");
//            }
//            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__CLEAR_FAIL, data });
//            dispatch(setLoading(false));
//        }, () => {
//            unsetUser();
//            dispatch(setLoading(false));
//            history.push('/account/login');
//        });
//    }
//}
export function clear(id, history, token, unsetUser, t) {
    return (dispatch) => {
        dispatch({
            type: constants.ACTION_TYPE_CONFIGURATION__CLEAR
        });
    }
}

export function updateValue(parameterIndex, newValue, configuration, t) {
    return (dispatch) => {
        const newConfiguration = configurationHelper.updateParameterValue(parameterIndex, newValue, configuration, t);
        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_VALUE, data: { configuration: newConfiguration } });
        //const parameterErrorText = validateParameter(identifier, order, bit, value, paramInfoParameterType, paramInfoLength, cell, t);
        //dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_VALUE, data: { identifier, order, bit, value, pageId, tableId, rowId, parameterErrorText } });
    }
}

//export function updateParameters(id, dirtyParameters, history, token, unsetUser, t) {
//    return dispatch => {
//        dispatch(setLoading(true));

//        network.updateParameters(id, dirtyParameters, token, () => {
//            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_SUCCESS });
//            dispatch(getConfiguration(id, history, token, unsetUser));
//            //dispatch(setLoading(false));
//        }, (errorCodes, parameterValidationErrors) => {
//            let data = {
//                generalErrorText: null,
//                parameterValidationErrors: null
//            };
//            if (errorCodes) {
//                for (let errorCode of errorCodes) {
//                    switch (errorCode) {
//                        //GeneralError = 1,
//                        case 1:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //UnexpectedError = 2,
//                        case 2:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //MissingParameters = 3,
//                        case 3:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //AlarmSystemNotFound = 4
//                        case 4:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //ParameterValidationErrors = 5
//                        default:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            data.parameterValidationErrors = parameterValidationErrors;
//                            break;
//                    }
//                }
//            } else {
//                data.generalErrorText = t("ErrorGeneral");
//            }
//            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_FAIL, data });
//            dispatch(setLoading(false));
//        }, () => {
//            unsetUser();
//            dispatch(setLoading(false));
//            history.push('/account/login');
//        });
//    }
//}

//export function write(id, code, dirtyParameters, history, token, unsetUser, t) {
//    return dispatch => {
//        dispatch(setLoading(true));

//        network.write(id, code, dirtyParameters, token, () => {
//            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__WRITE_SUCCESS });
//            dispatch(setLoading(false));
//        }, (errorCodes, parameterValidationErrors) => {
//            let data = {
//                generalErrorText: null,
//                parameterValidationErrors: null
//            };
//            if (errorCodes) {
//                for (let errorCode of errorCodes) {
//                    switch (errorCode) {
//                        //GeneralError = 1,
//                        case 1:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //UnexpectedError = 2,
//                        case 2:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //MissingParameters = 3,
//                        case 3:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //AlarmSystemNotFound = 4
//                        case 4:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //ParameterValidationErrors = 5
//                        default:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            data.parameterValidationErrors = parameterValidationErrors;
//                            break;
//                    }
//                }
//            } else {
//                data.generalErrorText = t("ErrorGeneral");
//            }
//            dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__WRITE_FAIL, data });
//            dispatch(setLoading(false));
//        }, () => {
//            unsetUser();
//            dispatch(setLoading(false));
//            history.push('/account/login');
//        });
//    }
//}

//export function cancelUpdateParameters() {
//    return dispatch => {
//        dispatch({ type: constants.ACTION_TYPE_CONFIGURATION__CANCEL_UPDATE_PARAMETERS });
//    }
//}
