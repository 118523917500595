import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    isUserInitialized: false,
    user: null,
    token: null
};

const user = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_USER__SET_USER:
            newState = update(state, {
                isUserInitialized: { $set: true },
                user: { $set: action.data.user },
                token: { $set: action.data.token }
            });
            //newState = { isUserInitialized: true, ...action.data.user };
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_USER__UNSET_USER:
            newState = update(state, {
                isUserInitialized: { $set: true },
                user: { $set: null },
                token: { $set: null }
            });
            //newState = Object.assign({}, INITIAL_STATE, { isUserInitialized: true });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default user;