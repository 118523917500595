import React from 'react'
import { Container, Typography, Box, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    //const theme = useTheme();
    //console.log("theme", theme);
    const { t } = useTranslation();

    return <div style={{ position: "fixed", width: "100%", top: "auto", bottom: 0, backgroundColor: "white" }}>
        <Divider />
        <Container>
            <Typography variant="subtitle2" noWrap>
                {"(" + t("ExcelTextVersion") + ")"+t("CopyrightFooter")}
                {/*© 2020 - PIMA TOTAL MANAGER (1.0.1.39)*/}
            </Typography>
        </Container>
    </div>
    /*<AppBar position="fixed" color="primary" style={{top: "auto", bottom: 0}}>
        <Toolbar>
            <Typography variant="subtitle2" noWrap>
                {strings['SlctrLst8']}
          </Typography>
        </Toolbar>
    </AppBar>
    <div>
        <h2>Footer</h2>
        <h4>{strings['SlctrLst8']}</h4>
        <h4>{strings.SlctrLst8}</h4>
    </div>*/
}

export default Footer;