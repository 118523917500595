import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, FormControl
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { clear, openClearDialog } from '../../actions/configuration';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';

const ClearDialog = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    const clearDialog = useSelector(state => state.configuration.clearDialog);
    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    const dispatch = useDispatch();

    if (!alarmSystem)
        return null;

    return (
        <Dialog
            disableBackdropClick={loading}
            open={clearDialog.isOpen}
            onClose={() => dispatch(openClearDialog(false))}>
            <DialogTitle>{t("Clear")}</DialogTitle>
            <DialogContent>

                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

                <div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                        <TextField
                            disabled
                            fullWidth
                            type="text"
                            value={alarmSystem.mac}
                            label={t("MAC")}
                        />
                    </FormControl>

                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openClearDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={loading}
                    onClick={() => dispatch(clear(alarmSystem.id, history, token, unsetUser, t))}
                    color="primary">
                    {t("Clear")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ClearDialog;