import React, { useEffect, useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    useTheme, useMediaQuery, Button, TextField, Container, Breadcrumbs, Typography, Divider, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
    Tabs, Tab, Paper, IconButton, AppBar, Toolbar, Menu, MenuItem, Grid
} from '@material-ui/core';
import { Delete as DeleteIcon, Person as PersonIcon, Warning as WarningIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Add, AddBoxSharp, Menu as MenuIcon, MenuSharp, AccountCircle as AccountCircleIcon, Settings as SettingsIcon } from '@material-ui/icons';
import { updateUser, resetForm, updateFormLastName, updateFormFirstName, updateFormPhoneNumber,
    updateFormCountry, updateFormBusinessNumber, updateFormCompany} from '../../actions/profile';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import DeleteUserDialog from './DeleteUserDialog';
import * as constants from '../../constants';
import EditableTextField from '../common/EditableTextField';

import countries from 'i18n-iso-countries';
import LicenseTranzilaDialog from '../alarmSystems/LicenseTranzilaDialog';
import moment from 'moment';

const styles = {

    largeIcon: {
        width: 300,
        height: 300,
    },

};
const Profile = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, token, setUser, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.profile.formData);
    const generalErrorText = useSelector(state => state.profile.generalErrorText);
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];
    const countryObject = countries.getNames(language, { select: "official" });
    const countryCodes = Object.keys(countryObject);
  //  setUserFromStorage();
    if (user.lastLoginDate)
    {
        var currentdate = moment(user.lastLoginDate).add(30, 'minutes');
        if (moment().calendar() > currentdate)
        {
        }

    }
    else
    {

    }
    useEffect(() => {
        dispatch(resetForm(user));
    }, [user]);
    
    const appBar = user ?
        <AppBar color="inherit" position="static">
            <Toolbar variant="dense">

                <Button color="inherit" disabled={loading || !formData.isValid || !formData.isDirty}
                    onClick={() => dispatch(updateUser(formData.firstName, formData.lastName, formData.phoneNumber, formData.country, formData.state, formData.city, formData.address, formData.Company, formData.BusinessNumber, history, token, setUser, unsetUser, t, language, formData.numberOfSystems))}>{t("Save")}</Button>
                <Button color="inherit" disabled={loading || !formData.isDirty}
                    onClick={() => {
                        dispatch(resetForm(user));
                    }}>{t("Cancel")}</Button>

                {
                    isMobile ?
                        <React.Fragment>
                            <div style={{ flex: 1 }} />
                            <IconButton disabled={loading} edge="end" color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
                                <MoreVertIcon />
                            </IconButton>

                            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)} keepMounted>
                                <MenuItem disabled={loading} component={RouterLink} to={constants.ROUTE_ACCOUNT_CHANGE_PASSWORD}>
                                    <ListItemText inset primary={t("ChangePassword")} />
                                </MenuItem>
                                
                            </Menu>
                        </React.Fragment> :
                        <React.Fragment>
                            <Button disabled={loading} color="inherit"
                                component={RouterLink} to={constants.ROUTE_ACCOUNT_CHANGE_PASSWORD}>{t("ChangePassword")}</Button>

                            
                        </React.Fragment>
                }

            </Toolbar>
        </AppBar> :
        null;

    return (
        <Container>
            {true ? <LicenseTranzilaDialog /> : null}
            <DeleteUserDialog />
            
            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("Profile")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {appBar}

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            {
                user ?
                    <form noValidate={true}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>

                                <div style={{ marginTop: 10, maxWidth: 300 }}>
                                    <EditableTextField readOnly
                                        fullWidth
                                        type="email"
                                        label={t("EMail")}
                                        value={user.email} />
                                </div>

                                <div style={{ marginTop: 10, maxWidth: 300 }}>
                                    <EditableTextField
                                        disabled={loading}
                                        fullWidth
                                        type="text"
                                        error={!!formData.firstNameErrorText}
                                        label={t("FirstName")}
                                        helperText={formData.firstNameErrorText}
                                        value={formData.firstName}
                                        onChange={event => dispatch(updateFormFirstName(event.target.value, t))} />
                                </div>
                                <div style={{ marginTop: 10, maxWidth: 300 }}>
                                    <EditableTextField
                                        disabled={loading}
                                        fullWidth
                                        type="text"
                                        error={!!formData.lastNameErrorText}
                                        label={t("LastName")}
                                        helperText={formData.lastNameErrorText}
                                        value={formData.lastName}
                                        onChange={event => dispatch(updateFormLastName(event.target.value, t))} />
                                </div>
                                <div style={{ marginTop: 10, maxWidth: 300 }}>
                                    <EditableTextField
                                        disabled={loading}
                                        fullWidth
                                        type="text"
                                        error={!!formData.phoneNumberErrorText}
                                        label={t("PhoneNumber")}
                                        helperText={formData.phoneNumberErrorText}
                                        value={formData.phoneNumber}
                                        onChange={event => dispatch(updateFormPhoneNumber(event.target.value, t))} />
                                </div>

                                <div style={{ marginTop: 10, maxWidth: 300 }}>
                                    <Autocomplete
                                        options={countryCodes}
                                        getOptionLabel={option => (option ? countryObject[option] : '')}
                                        disabled={loading}
                                        value={formData.country}
                                        onChange={(event, newValue) => dispatch(updateFormCountry(newValue, t))}
                                        renderInput={(params) => <EditableTextField
                                            {...params}
                                            fullWidth
                                            type="text"
                                            error={!!formData.countryErrorText}
                                            label={t("Country")}
                                            helperText={formData.countryErrorText} />
                                        }
                                    />
                                </div>                               
                                <div style={{ marginTop: 10, maxWidth: 300 }}>
                                    <EditableTextField
                                        disabled={loading}
                                        fullWidth
                                        type="text"                                       
                                        label={t("Company")}                                       
                                        value={formData.Company}
                                        onChange={event => dispatch(updateFormCompany(event.target.value, t))} />
                                </div>
                                <div style={{ marginTop: 10, maxWidth: 300 }}>
                                    <EditableTextField
                                        disabled={loading}
                                        fullWidth
                                        type="text"                                      
                                        label={t("CompanyNumber")}
                                        value={formData.BusinessNumber}
                                        onChange={event => dispatch(updateFormBusinessNumber(event.target.value, t))} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div style={{ marginTop: 10, maxWidth: 300 }}>
                                    <EditableTextField readOnly
                                        disabled={loading}
                                        fullWidth
                                        type="text"
                                        error={!!formData.numberOfSystemsErrorText}
                                        label={t("CurrentNumberOfSystems")}
                                        helperText={formData.lastNameErrorText}
                                        value={user.numberOfSystems} />                                      
                                </div>
                                <IconButton disabled={loading} edge="end" style={{ fontWeight: "bold",fontColor:"black" }} component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS_LICENSE__ID.replace(":id", user.id)}>
                                    
                                    <Add fontSize={"large"} />
                                    {t("AddLiscense")}
                                </IconButton>
                               
                            </Grid>
                        </Grid>
                    </form> :
                    null
            }
        </Container>
    );
}

export default Profile;