import React, { useEffect, useState } from 'react';
import { useHistory, Link as RouterLink, Redirect, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as constants from '../../constants';
import { useTranslation } from 'react-i18next';
import EditableTextField from '../common/EditableTextField';
const LicenseTranzilaFail = () => {
    const { t } = useTranslation();
    const { id, transactionId } = useParams();
    
        return <div style={{ padding: 20 }}>
            {(id == 1) ? <Alert style={{ margin: 20 }} severity="error">{t("TranzilaTransactionFailed")}</Alert> :
                <Alert style={{ margin: 20 }} severity="error">{t("CloudConnectionFaild")}</Alert>}
                <EditableTextField style={{ margin: 50 }} readOnly value={transactionId} label={t("transactionId")} />
            </div>
   
        }
    


    //window.top.location.replace(constants.ROUTE_ALARM_SYSTEMS_TRANSACTION_RESULT__ID__TRANSACTION_ID.replace(":id", id).replace(":transactionId", transactionId));
    //return null;
    //return <div style={{ padding: 20 }}>
    //    <Alert style={{ margin: 20 }} severity="warning">{t("TranzilaTransactionFailed")}</Alert>        
    //</div>


export default LicenseTranzilaFail;
