import { Redirect } from 'react-router-dom';
import * as constants from '../constants';
import network from '../network';
import { validateEmail, validatePhoneNumber, validateMac, validatePositiveInteger } from '../validators';
import { setLoading } from './global';

//// license
//export function updateFormInstallerLicenseLevel(level, licenseOptions, t) {
//    return (dispatch) => {
//        let formData = {
//            installerLicenseLevel: level,
//            installerLicenseLevelErrorText: null,
//            installerLicenseLevelValid: false
//        };
//        const option = licenseOptions.find(o => o.level === level);
//        if (!option)
//            formData.installerLicenseLevelErrorText = "*";
//        else
//            formData.installerLicenseLevelValid = true;

//        dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_FORM_DATA, data: { formData } });
//    }
//}
//export function updateFormUserLicenseLevel(level, licenseOptions, t) {
//    return (dispatch) => {
//        let formData = {
//            userLicenseLevel: level,
//            userLicenseLevelErrorText: null,
//            userLicenseLevelValid: false
//        };
//        const option = licenseOptions.find(o => o.level === level);
//        if (!option)
//            formData.userLicenseLevelErrorText = "*";
//        else
//            formData.userLicenseLevelValid = true;

//        dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_FORM_DATA, data: { formData } });
//    }
//}

//export function openLicenseDialog(isOpen, permission) {
//    return (dispatch) => {
//        dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_DIALOG, data: { isOpen, permission } });
//    }
//}

export function updateFormLicenseNumberOfSystems(a_numberOfSystems, a_userDiscountPrecent, priceperpanel, a_additionalComment, a_isForYear, a_UserAprroveAlreadyBought, a_userApprovedMoreThen1, t) {

   
        var pricePerPanelAfterDiscount = a_userDiscountPrecent ? priceperpanel * (1 - (a_userDiscountPrecent / 100)) : priceperpanel;
        pricePerPanelAfterDiscount = a_isForYear ? (pricePerPanelAfterDiscount * 12) : pricePerPanelAfterDiscount
    return (dispatch) => {
        if (!validatePositiveInteger(a_numberOfSystems)) {
            a_numberOfSystems = 0;
        }
        let formData = {
         
                NumberOfSystems: a_numberOfSystems > 0 ? a_numberOfSystems : 0,
                TotalPriceToPay: Math.round(a_numberOfSystems > 0 ? (a_numberOfSystems * pricePerPanelAfterDiscount) : 0),
                AdditionalInvoiceData: a_additionalComment,
                PricePerPanel: Math.round(pricePerPanelAfterDiscount),
            isChecked: a_isForYear,
            UserAprroveAlreadyBought: a_UserAprroveAlreadyBought,
            userApprovedMoreThen1: a_userApprovedMoreThen1

            };

            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_NUMBER_OF_REQUIRED_SYSTEMS, data: { formData } });

        
    }
}


export function openLicenseDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_DIALOG, data: { isOpen } });
    }
}
/*export function openLicenseTranzilaDialog(isOpen, permission) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_TRANZILA_DIALOG, data: { isOpen, permission } });
    }
}*/
export function openLicenseTranzilaDialog(isOpen) {
 
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_TRANZILA_DIALOG, data: { isOpen } });
    }
}
export function getAlarmSystem(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getAlarmSystem(id, token, language, (alarmSystem) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_ALARM_SYSTEM_SUCCESS,
                data: { alarmSystem }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_ALARM_SYSTEM_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function getLicense(id, history, token, unsetUser, t, language, a_emailResponse) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getLicenseState(id, token, language, (license, vat, a_emailResponse, freeMonths, monthlyShekelsAmmountPerSystem,
            monthlyDollarsAmmountPerSystem , monthlyEurosAmmountPerSystem) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSE_SUCCESS,
                data: { license, vat, a_emailResponse, freeMonths, monthlyShekelsAmmountPerSystem,
                    monthlyDollarsAmmountPerSystem, monthlyEurosAmmountPerSystem }

            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

//export function getLicensingOptions(history, token, unsetUser, t, language) {
//    return (dispatch) => {
//        dispatch(setLoading(true));

//        network.getLicensingOptions(token, language, (licenseOptions) => {
//            dispatch({
//                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSING_OPTIONS_SUCCESS,
//                //data: { installerLicenseOptions, userLicenseOptions }
//                data: { licenseOptions }
//            });
//            dispatch(setLoading(false));
//        }, (errorCodes) => {
//            let data = {
//                generalErrorText: null
//            };
//            if (errorCodes) {
//                for (let errorCode of errorCodes) {
//                    switch (errorCode) {
//                        //GeneralError = 1,
//                        case 1:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //UnexpectedError = 2,
//                        case 2:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        default:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                    }
//                }
//            } else {
//                data.generalErrorText = t("ErrorGeneral");
//            }
//            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSING_OPTIONS_FAIL, data });
//            dispatch(setLoading(false));
//        }, () => {
//            unsetUser();
//            dispatch(setLoading(false));
//            history.push(constants.ROUTE_ACCOUNT_LOGIN);
//        });
//    }
//}


export function enableFreeLicense(id, NumberOfPaidSystems, history, token, unsetUser, t, language, updatedNumOfSystems) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.enableFreeLicense(id, NumberOfPaidSystems, token, language, (updatedNumOfSystems) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FREE_SUCCESS,
                data: { updatedNumOfSystems }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}




export function enableLicense(id, NumberOfPaidSystems, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.enableLicense(id, NumberOfPaidSystems/*permission.name*/, token, language, (license) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_SUCCESS,
                data: { license }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}



export function addPimaLicense(email, NumberOfPaidSystems, history, token, unsetUser, t, language, updatedNumOfSystems) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.addPimaLicense(email, NumberOfPaidSystems, token, language, (updatedNumOfSystems) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FREE_SUCCESS,
                data: { updatedNumOfSystems }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function SetDiscount(email, NumberOfPaidSystems, history, token, unsetUser, t, language, updatedNumOfSystems) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.SetDiscount(email, NumberOfPaidSystems, token, language, (updatedNumOfSystems) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FREE_SUCCESS,
                data: { updatedNumOfSystems }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function removeOneSystem(email, NumberOfPaidSystems, history, token, unsetUser, t, language, updatedNumOfSystems) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.removeOneSystem(email, NumberOfPaidSystems, token, language, (updatedNumOfSystems) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FREE_SUCCESS,
                data: { updatedNumOfSystems }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
       
    }
}

