import axios from 'axios';

export function getWebAccount(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/webAccount/getWebAccount`, config)
        .then(response => {
            console.log(response);
            if (response.data.success) {
                successCallback(response.data.webAccount);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function deletePaymentMethod(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.delete("/api/webAccount/deletePaymentMethod", config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.webAccount);
            } else {
                failCallback(response.data.errorCodes);
            }

        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}