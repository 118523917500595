import { combineReducers } from 'redux';
import global from './global';
import topMenu from './topMenu';
import user from './user';
import register from './register';
import forgotPassword from './forgotPassword';
import confirmEmail from './confirmEmail';
import login from './login';
import profile from './profile';
import paymentMethod from './paymentMethod';
import changePassword from './changePassword';
import resetPassword from './resetPassword';
import webAccountUserDetails from './webAccountUserDetails';
import webAccountUsers from './webAccountUsers';
import confirmWebAccountUserEmail from './confirmWebAccountUserEmail';
import addWebAccountUser from './addWebAccountUser';
import alarmSystems from './alarmSystems';
import addAlarmSystem from './addAlarmSystem';
import editAlarmSystem from './editAlarmSystem';
import alarmSystemLicense from './alarmSystemLicense';
import alarmSystemTransactionResult from './alarmSystemTransactionResult';
import configuration from './configuration';
import templates from './templates';
import addTemplate from './addTemplate';
import template from './template';
import monitoring from './monitoring';
import management from './management';
import managementUsers from './managementUsers';
import managementAlarmSystems from './managementAlarmSystems';
import managementAlarmSystemTransactions from './managementAlarmSystemTransactions';
import managementGeneralSettings from './managementGeneralSettings';

export default combineReducers({
    global,
    topMenu,
    user,
    register,
    forgotPassword,
    confirmEmail,
    login,
    profile,
    paymentMethod,
    changePassword,
    resetPassword,
    webAccountUserDetails,
    webAccountUsers,
    confirmWebAccountUserEmail,
    addWebAccountUser,
    alarmSystems,
    addAlarmSystem,
    editAlarmSystem,
    alarmSystemLicense,
    alarmSystemTransactionResult,
    configuration,
    monitoring,
    templates,
    addTemplate,
    template,
    management,
    managementUsers,
    managementAlarmSystems,
    managementAlarmSystemTransactions,
    managementGeneralSettings
})