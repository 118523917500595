import * as constants from '../constants';
import { validateEmail, validatePassword, validatePhoneNumber } from '../validators';
import network from '../network';
import { setLoading } from './global';

export function updateFormFirstName(firstName, t) {
    return (dispatch) => {
        let formData = {
            firstName: firstName,
            firstNameErrorText: null,
            firstNameValid: false
        };
        if (!firstName)
            formData.firstNameErrorText = "*";
        else
            formData.firstNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormLastName(lastName, t) {
    return (dispatch) => {
        let formData = {
            lastName: lastName,
            lastNameErrorText: null,
            lastNameValid: false
        };
        if (!lastName)
            formData.lastNameErrorText = "*";
        else
            formData.lastNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormPhoneNumber(phoneNumber, t) {
    return (dispatch) => {
        let formData = {
            phoneNumber: phoneNumber,
            phoneNumberErrorText: null,
            phoneNumberValid: false
        };
        if (phoneNumber && !validatePhoneNumber(phoneNumber))
            formData.phoneNumberErrorText = t("ErrorPhoneNumberInvalidCharacters");
        else
            formData.phoneNumberValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormPassword(password, t) {
    return (dispatch) => {
        let formData = {
            password: password,
            passwordErrorText: null,
            passwordValid: false
        };
        if (!password)
            formData.passwordErrorText = "*";
        else if (!validatePassword(password))
            formData.passwordErrorText = t("PasswordInstructions");
        else
            formData.passwordValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormConfirmPassword(password, confirmPassword, t) {
    return (dispatch) => {
        let formData = {
            confirmPassword: confirmPassword,
            confirmPasswordErrorText: null,
            confirmPasswordValid: false
        };
        if (!confirmPassword)
            formData.confirmPasswordErrorText = "*";
        else if (password !== confirmPassword)
            formData.confirmPasswordErrorText = t("ErrorPasswordConfirmMismatch");
        else
            formData.confirmPasswordValid = true;
        dispatch({ type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__UPDATE_FORM_DATA, data: { formData } });
    }
}

export function getWebAccountUser(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getWebAccountUser(id, token, language, (user) => {
            dispatch({
                type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__GET_WEB_ACCOUNT_USER_SUCCESS,
                data: { user }
            });
            dispatch(updateFormFirstName(user.firstName));
            dispatch(updateFormLastName(user.lastName));
            dispatch(updateFormPhoneNumber(user.phoneNumber || ''));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UserNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__GET_WEB_ACCOUNT_USER_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function confirmEmail(userId, code, firstName, lastName, phoneNumber, password, confirmPassword, history, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.confirmWebAccountUserEmail(userId, code, firstName, lastName, phoneNumber, password, confirmPassword, language, () => {
                dispatch({ type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__CONFIRM_EMAIL_SUCCESS });
                setTimeout(() => {
                    dispatch(setLoading(false));
                    history.push(constants.ROUTE_ACCOUNT_LOGIN);
                }, 2000);
            }, (errorCodes) => {
                let data = {
                    generalErrorText: null,
                    formData: {
                        firstNameErrorText: null,
                        firstNameValid: true,
                        lastNameErrorText: null,
                        lastNameValid: true,
                        phoneNumberErrorText: null,
                        phoneNumberValid: true,
                        passwordErrorText: null,
                        passwordValid: true,
                        confirmPasswordErrorText: null,
                        confirmPasswordValid: true,
                    }
                };
                if (errorCodes) {
                    for (let errorCode of errorCodes) {
                        switch (errorCode) {
                            //GeneralError = 1,
                            case 1:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //UnexpectedError = 2,
                            case 2:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //MissingParameters = 3,
                            case 3:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //UserNotFound = 4,
                            case 4:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //IncorrectEmailConfirmationCode = 5
                            case 5:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                            //PasswordRequired = 6,
                            case 6:
                                data.formData.passwordErrorText = "*";
                                data.formData.passwordValid = false;
                                break;
                            //ConfirmPasswordRequired = 7,
                            case 7:
                                data.formData.confirmPasswordErrorText = "*";
                                data.formData.confirmPasswordValid = false;
                                break;
                            //FirstNameRequired = 8,
                            case 8:
                                data.formData.firstNameErrorText = "*";
                                data.formData.firstNameValid = false;
                                break;
                            //LastNameRequired = 9,
                            case 9:
                                data.formData.lastNameErrorText = "*";
                                data.formData.lastNameValid = false;
                                break;
                            //InvalidPhoneNumber = 10,
                            case 10:
                                data.formData.phoneNumberErrorText = t("ErrorPhoneNumberInvalidCharacters");
                                data.formData.phoneNumberValid = false;
                                break;
                            //ConfirmPasswordMismatch = 11
                            case 11:
                                data.formData.confirmPasswordErrorText = t("ErrorPasswordConfirmMismatch");
                                data.formData.confirmPasswordValid = false;
                                break;
                            default:
                                data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                                break;
                        }
                    }
                } else {
                    data.generalErrorText = t("ErrorGeneral");
                }
                dispatch({ type: constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__CONFIRM_EMAIL_FAIL, data });
                dispatch(setLoading(false));
            });
    }
}