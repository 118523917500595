import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    useMediaQuery, Grid, Button, Fab, TextField, Container, List, ListSubheader, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction,
    FormControl, FormLabel, FormGroup, FormControlLabel, FormHelperText, Checkbox,
    IconButton, Typography, Breadcrumbs, Link, Divider, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Drawer, TablePagination, InputLabel, Input, InputAdornment
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Alert, Pagination } from '@material-ui/lab';
import { Edit as EditIcon, FilterList as FilterListIcon, Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import { getAlarmSystems, updateDisplayNotifications } from '../../actions/monitoring';
import * as constants from '../../constants';
import MonitoringFilters from './MonitoringFilters';
import useUser from '../../user/useUser';
import TableViewFull from './TableViewFull';
import TableViewSmall from './TableViewSmall';

const Monitoring = () => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [paginationPage, setPaginationPage] = useState(0);

    const dispatch = useDispatch();

    const loading = useSelector(state => state.global.loading);
    const alarmSystems = useSelector(state => state.monitoring.alarmSystems);
    const notifications = useSelector(state => state.monitoring.notifications);
    const displayNotifications = useSelector(state => state.monitoring.displayNotifications);
    const formData = useSelector(state => state.monitoring.formData);
    const generalErrorText = useSelector(state => state.monitoring.generalErrorText);

    const { token, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    useEffect(() => {
        if (isMobile)
            setIsFilterOpen(false);
    }, [isMobile]);

    useEffect(() => {
        dispatch(getAlarmSystems(id, displayNotifications.searchText, displayNotifications.sort, displayNotifications.notificationId, history, token, unsetUser, t, language));
    }, [id]);

    
    //const notificationTypeLookup = Object.assign({}, constants.NOTIFICATION_TYPES.map(n => ({ [n.type]: t(`MonitoringType${n.name}`) })));
    const notificationTypeLookup = {};
    for (let n of constants.NOTIFICATION_TYPES) {
        notificationTypeLookup[n.type] = t(`MonitoringType${n.name}`);
    }
    console.log("notificationTypeLookup", notificationTypeLookup);
    const macNameLookup = {};
    for (let a of alarmSystems) {
        macNameLookup[a.mac] = a.name;
    }
    console.log("macNameLookup", macNameLookup);

    return (
        <React.Fragment>
            <Container>
                <Breadcrumbs style={{ marginTop: 20 }}>
                    <Link color="inherit" component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS}>{t("AlarmSystems")}</Link>
                    <Typography color="textPrimary">{t("Monitoring")}</Typography>
                </Breadcrumbs>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}
            </Container>

            {
                isMobile ?
                    <Drawer anchor="left" open={isFilterOpen} onClose={() => setIsFilterOpen(false)}>
                        <MonitoringFilters />
                    </Drawer> :
                    null
            }

            <Container style={{ marginTop: 10 }} maxWidth={false}>
                {/*{
                    isMobile ?
                        <IconButton style={{ marginTop: 5, marginBottom: 5 }} onClick={() => setIsFilterOpen(true)}>
                            <FilterListIcon />
                        </IconButton> :
                        null
                }*/}

                {/*<Grid container spacing={3} wrap="nowrap">
                        <Grid item xs="auto">
                            <Paper>
                                <div style={{ padding: 5 }}>
                                    <List dense subheader={<ListSubheader
                                        style={{ backgroundColor: theme.palette.background.paper }} component="li">{t("DateRange")}</ListSubheader>}>
                                        <ListItem role={undefined} dense>
                                            <DatePicker
                                                disabled={loading}
                                                autoOk
                                                variant="dialog"
                                                label={t("StartDate")}
                                                clearable
                                                disableFuture
                                                value={formData.dateRange ? formData.dateRange.startDate : null}
                                                onChange={(date) => getNotifications(formData.macs, formData.notificationTypes,
                                                    this.updateStartDate(formData.dateRange, date), history, token, unsetUser, t)} />
                                        </ListItem>
                                        <ListItem role={undefined} dense>
                                            <DatePicker
                                                disabled={loading}
                                                autoOk
                                                variant="dialog"
                                                label={t("EndDate")}
                                                clearable
                                                disableFuture
                                                value={formData.dateRange ? formData.dateRange.endDate : null}
                                                onChange={(date) => getNotifications(formData.macs, formData.notificationTypes,
                                                    this.updateEndDate(formData.dateRange, date), history, token, unsetUser, t)} />
                                        </ListItem>
                                    </List>

                                </div>
                                <div style={{ padding: 5 }}>
                                    <List dense subheader={<ListSubheader
                                        style={{ backgroundColor: theme.palette.background.paper }} component="li">{t("AlarmSystems")}</ListSubheader>}>
                                        <ListItem role={undefined} dense button
                                            onClick={() => getNotifications(update(formData.macs, {
                                                $set: alarmSystems.every(a => formData.macs.includes(a.mac)) ?
                                                    [] :
                                                    alarmSystems.map(a => a.mac)
                                            }), formData.notificationTypes, formData.dateRange, history, token, unsetUser, t)}>
                                            <ListItemIcon>
                                                <Checkbox disabled={loading}
                                                    edge="start"
                                                    checked={alarmSystems.every(a => formData.macs.includes(a.mac))}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={t("AllAlarmSystems")} />
                                        </ListItem>
                                        {
                                            alarmSystems.map((a, i) =>
                                                <ListItem key={i} role={undefined} dense button
                                                    onClick={() => getNotifications(update(formData.macs,
                                                        formData.macs.includes(a.mac) ?
                                                            { $apply: macs => macs.filter(m => m != a.mac) } :
                                                            { $push: [a.mac] }
                                                    ), formData.notificationTypes, formData.dateRange, history, token, unsetUser, t)}>
                                                    <ListItemIcon>
                                                        <Checkbox disabled={loading}
                                                            edge="start"
                                                            checked={formData.macs.includes(a.mac)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={a.name} secondary={a.mac} />
                                                </ListItem>)
                                        }
                                    </List>
                                </div>
                                <div style={{ padding: 5 }}>
                                    <List dense subheader={<ListSubheader
                                        style={{ backgroundColor: theme.palette.background.paper }} component="li">{t("MonitoringTypes")}</ListSubheader>}>
                                        <ListItem role={undefined} dense button
                                            onClick={() => getNotifications(formData.macs, update(formData.notificationTypes, {
                                                $set: constants.NOTIFICATION_TYPES.every(n => formData.notificationTypes.includes(n.type)) ?
                                                    [] :
                                                    constants.NOTIFICATION_TYPES.map(n => n.type)
                                            }), formData.dateRange, history, token, unsetUser, t)}>
                                            <ListItemIcon>
                                                <Checkbox disabled={loading}
                                                    edge="start"
                                                    checked={constants.NOTIFICATION_TYPES.every(n => formData.notificationTypes.includes(n.type))}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={t("AllNotificationTypes")} />
                                        </ListItem>
                                        {
                                            constants.NOTIFICATION_TYPES.map((n, i) =>
                                                <ListItem key={i} role={undefined} dense button
                                                    onClick={() => getNotifications(formData.macs, update(formData.notificationTypes,
                                                        formData.notificationTypes.includes(n.type) ?
                                                            { $apply: notificationTypes => notificationTypes.filter(nt => nt != n.type) } :
                                                            { $push: [n.type] }
                                                    ), formData.dateRange, history, token, unsetUser, t)}>
                                                    <ListItemIcon>
                                                        <Checkbox disabled={loading}
                                                            edge="start"
                                                            checked={formData.notificationTypes.includes(n.type)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t(`MonitoringType${n.name}`)} />
                                                </ListItem>)
                                        }
                                    </List>
                                </div>

                            </Paper>
                        </Grid>
                        <Grid item xs="auto">*/}

                <div style={{ display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "stretch" }}>
                    {
                        !isMobile ?
                            <div style={{ flexGrow: 0, marginInlineEnd: 3 }}>
                                {/*<div style={{ backgroundColor: 'green', width: 200, height: 500 }}>Is Mobile: {JSON.stringify(isMobile)}</div>*/}
                                <Paper>
                                    <MonitoringFilters />
                                </Paper>
                            </div> :
                            null
                    }

                    <div style={{ flexGrow: 1, overflowX: "auto" }}>
                        <Paper style={{ maxWidth: '100%', padding: 5 }}>

                            <div style={{ display: "flex", flexGrow: 1, justifyContent: "space-between", alignItems: "stretch" }}>
                                <div style={{ flexGrow: 0 }}>
                                    {
                                        isMobile ?
                                            <IconButton color="inherit" onClick={() => setIsFilterOpen(true)}>
                                                <FilterListIcon />
                                            </IconButton> :
                                            null
                                    }
                                </div>
                                <div style={{ width: 150, flexGrow: 0 }}>
                                    <FormControl>
                                        <Input
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={displayNotifications.searchText ?
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => dispatch(updateDisplayNotifications(alarmSystems, notifications, '', displayNotifications.sort, displayNotifications.notificationId, t))}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </InputAdornment> :
                                                null
                                            }
                                            value={displayNotifications.searchText}
                                            onChange={event => dispatch(updateDisplayNotifications(alarmSystems, notifications, event.target.value, displayNotifications.sort, displayNotifications.notificationId, t))}
                                        />
                                    </FormControl>
                                </div>
                            </div>

                            {
                                isMobile ? <TableViewSmall /> : <TableViewFull />
                            }
                        </Paper>
                    </div>

                    {/*<div style={{ display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "stretch" }}>
                    {
                        !isMobile ?
                            <div style={{ flexGrow: 0, marginInlineEnd: 3 }}>
                                <Paper>
                                    <MonitoringFilters />
                                </Paper>
                            </div> :
                            null
                    }


                    <div style={{ flexGrow: 1, overflowX: "auto" }}>
                        <div style={{ position: "relative", maxWidth: '100%' }}>
                            <TableViewFull />
                            <MaterialTable
                                options={{
                                    showTitle: false,
                                    filtering: false,
                                    sorting: true,
                                    search: true,
                                    draggable: false,
                                    pageSize: 20,
                                    pageSizeOptions: [],
                                    emptyRowsWhenPaging: false,
                                    paginationType: "normal",
                                    tableLayout: "fixed",
                                    doubleHorizontalScroll: false
                                }}
                                page={paginationPage}
                                onChangePage={(page) => setPaginationPage(page)}
                                components={{
                                    Pagination: props => {
                                        return (
                                            <TableCell>
                                                <Pagination
                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                    size="small"
                                                    color="primary"
                                                    disabled={loading}
                                                    count={Math.ceil(props.count / props.rowsPerPage)}
                                                    page={props.page + 1}
                                                    onChange={(event, page) => props.onChangePage(event, page - 1)} />
                                            </TableCell>
                                        );
                                    },
                                    Toolbar: props => (
                                        <div style={{ display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center" }}>
                                            {
                                                isMobile ?
                                                    <div style={{ flexGrow: 0, marginInlineEnd: 3 }}>
                                                        <IconButton color="primary" onClick={() => setIsFilterOpen(true)}>
                                                            <FilterListIcon />
                                                        </IconButton>
                                                    </div> :
                                                    null
                                            }
                                            <div style={{ flexGrow: 1, overflowX: "auto" }}>
                                                <MTableToolbar {...props} />
                                            </div>
                                        </div>
                                    ),
                                }}
                                columns={[
                                    { field: "eventDate", title: t("Date"), type: "date", defaultSort: "desc", width: 100 },
                                    { field: "eventDate", title: t("Time"), type: "time", sorting: false, width: 100 },
                                    {
                                        field: "mac",
                                        title: t("Name"),
                                        lookup: macNameLookup,
                                        width: 130,
                                        render: r => <Link
                                            onClick={() => dispatch(getNotifications([r.mac], formData.notificationTypes, formData.dateRange, history, token, unsetUser, t))}>{macNameLookup[r.mac]}</Link>
                                    },
                                    {
                                        field: "mac",
                                        title: t("MAC"),
                                        width: 130,
                                        render: r => <Link
                                            onClick={() => dispatch(getNotifications([r.mac], formData.notificationTypes, formData.dateRange, history, token, unsetUser, t))}>{r.mac}</Link>
                                    },
                                    {
                                        field: "notificationType", title: t("MonitoringType"), lookup: notificationTypeLookup, width: 150
                                        //render: r => {
                                        //    const nt = constants.NOTIFICATION_TYPES.find(n => n.type === r.notificationType);
                                        //    return nt ? `MonitoringType${nt.name}` : "--";
                                        //},
                                        //customFilterAndSearch: (term, rowData) => term == rowData.name.length
                                    },
                                    { field: "message", title: t("Message"), width: 300 },
                                    //{ field: "isAlarmRestore", title: t("Restore")}
                                    //{ field: "sentDate", title: t("SentDate"), type: "date" },
                                    //{ field: "pictureUrls", title: t("PictureUrls"), type: "string" },
                                    //{ field: "eventQualifier", title: t("EventQualifier"), type: "numeric" },
                                    //{ field: "alarmCode", title: t("AlarmCode"), type: "numeric" },
                                    //{ field: "zoneNumber", title: t("ZoneNumber"), type: "numeric" }
                                ]}
                                data={notifications || []}
                                title=""
                            />
                
                        </div>
                    </div>
                </div>*/}

                    {/*
                        </Grid>
                    </Grid>*/}
                </div>
            </Container>

            {/*<div style={{ marginTop: 10 }}>
                    {
                        notifications && notifications.length ?
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t("EventDate")}</TableCell>
                                            <TableCell>{t("MAC")}</TableCell>
                                            <TableCell>{t("Message")}</TableCell>
                                            <TableCell>{t("SentDate")}</TableCell>
                                            <TableCell>{t("PictureUrls")}</TableCell>
                                            <TableCell>{t("EventQualifier")}</TableCell>
                                            <TableCell>{t("AlarmCode")}</TableCell>
                                            <TableCell>{t("ZoneNumber")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {notifications.map((n, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">{n.eventDate}</TableCell>
                                                <TableCell>{n.mac}</TableCell>
                                                <TableCell>{n.message}</TableCell>
                                                <TableCell>{n.sentDate}</TableCell>
                                                <TableCell>{n.pictureUrls}</TableCell>
                                                <TableCell>{n.eventQualifier}</TableCell>
                                                <TableCell>{n.alarmCode}</TableCell>
                                                <TableCell>{n.zoneNumber}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <Alert severity="warning">{t("NoNotificationsToShow")}</Alert>
                    }
                </div>*/}

            {/*<div style={{ marginTop: 10 }}>
                    <Button variant="contained" color="primary" disabled={loading}
                        component={RouterLink} to="/alarmsystems/add">{t("Add")}</Button>
                </div>*/}
        </React.Fragment>
    );
}

export default Monitoring;