import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, Container, AppBar, ButtonGroup, Toolbar, Breadcrumbs, Link, Typography, Divider, IconButton, Badge, Menu, MenuItem } from '@material-ui/core';
import { Delete as DeleteIcon, ErrorOutline as ErrorIcon, WarningOutlined as WarningIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
    openPairDialog, openUnpairDialog, openLoadTemplateDialog,
    openReadDialog, openWriteDialog, openClearDialog, openParameterErrorsDialog, openSaveAsTemplateDialog
} from '../../actions/configuration';


const ToolbarSmall = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    const configuration = useSelector(state => state.configuration.configuration);
    //const dataView = useSelector(state => state.configuration.dataView);
    //const pageId = useSelector(state => state.configuration.pageId);
    //const tableId = useSelector(state => state.configuration.tableId);
    //const paginationPage = useSelector(state => state.configuration.paginationPage);
    //const rowId = useSelector(state => state.configuration.rowId);
    //const dirtyParameters = useSelector(state => state.configuration.dirtyParameters);
    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    //const parameterError = useSelector(state => state.configuration.parameterError);
    //const excludedParameters = useSelector(state => state.configuration.excludedParameters);

    //if (parameterError) {
    //    let newPaginationPage = 0;
    //    if (showFirstError) {
    //        const firstError = dirtyParameters.find(p => p.pageId === newPageId && p.tableId === newTableId && p.parameterErrorText);
    //        if (firstError)
    //            newPaginationPage = firstError.paginationPage;
    //    }
    //    console.log("browseConfiguration", { newPageId, newTableId, newPaginationPage });
    //    dispatch(browseConfiguration(history, id, newPageId, newTableId, newPaginationPage));
    //}

    const dispatch = useDispatch();


    return <Toolbar variant="dense">
        

        {/*<div style={{ flexGrow: 1 }} />
                                        <IconButton edge="end" color="inherit" disabled={loading} title={t("Clear")}
                                            onClick={() => openClearDialog(true)}>
                                            <DeleteIcon />
                                        </IconButton>*/}
        {/*{
                                    configuration && configuration.validationErrorParameterIndices.length &&
                                    <Badge color="primary" badgeContent={configuration.validationErrorParameterIndices.length} max={100000}>
                                        <Button color="inherit"
                                            disabled={loading}
                                            onClick={() => dispatch(openValidationErrorParametersDialog(true))}>{t("ValidationErrors")}</Button>
                                    </Badge>
                                }
                                {
                                    configuration && configuration.errorParameterIndices.length &&
                                    <Badge color="primary" badgeContent={configuration.errorParameterIndices.length} max={100000}>
                                        <Button color="inherit"
                                            disabled={loading}
                                            onClick={() => dispatch(openErrorParametersDialog(true))}>{t("ParameterErrors")}</Button>
                                    </Badge>
                                }*/}

        {
            alarmSystem.pairing ?
                <React.Fragment>
                    <Button color="inherit" disabled={loading} onClick={() => dispatch(openReadDialog(true))}>{t("Read")}</Button>
                    <Badge color="primary" badgeContent={configuration ? configuration.modifiedParameterIndices.length : 0} max={100000}>
                        <Button color="inherit"
                            disabled={loading || !configuration || !configuration.modifiedParameterIndices.length || !!configuration.validationErrorParameterIndices.length}
                            onClick={() => dispatch(openWriteDialog(true))}>{t("Write")}</Button>
                    </Badge>
                    {/*<Button color="inherit" disabled={loading || !dirtyParameters.length} onClick={() => cancelUpdateParameters()}>{t("UndoChanges")}</Button>*/}
                </React.Fragment> :
                <Button color="inherit" disabled={loading} onClick={() => dispatch(openPairDialog(true))}>{t("Pair")}</Button>
        }

        <div style={{ flexGrow: 1 }} />
        {
            configuration &&
                (configuration.validationErrorParameterIndices.length || configuration.errorParameterIndices.length ||
                    configuration.excludedParameterIndices.length) ?
                <Badge color="primary" badgeContent={configuration.validationErrorParameterIndices.length +
                    configuration.errorParameterIndices.length +
                    configuration.excludedParameterIndices.length} max={100}>
                    <Button color="inherit"
                        disabled={loading}
                        onClick={() => dispatch(openParameterErrorsDialog(true))}>
                        {configuration.validationErrorParameterIndices.length || configuration.errorParameterIndices.length ?
                            <ErrorIcon color="error" /> : <WarningIcon color="error" />}
                    </Button>
                </Badge> :
                null
        }
        <IconButton disabled={loading} edge="end" color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
            <MoreVertIcon />
        </IconButton>


        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)} keepMounted>
            {
                alarmSystem.pairing ?
                    <MenuItem onClick={() => {
                        dispatch(openUnpairDialog(true));
                        setAnchorEl(null);
                    }}>{t("Unpair")}</MenuItem> :
                    null
            }
            <MenuItem onClick={() => {
                dispatch(openLoadTemplateDialog(true));
                setAnchorEl(null);
            }}>{t("LoadFromTemplate")}</MenuItem>
            {
                configuration ?
                    <MenuItem onClick={() => {
                        dispatch(openSaveAsTemplateDialog(true));
                        setAnchorEl(null);
                    }}>{t("SaveAsTemplate")}</MenuItem> :
                    null
            }
            {
                configuration ?
                    <MenuItem onClick={() => {
                        dispatch(openClearDialog(true));
                        setAnchorEl(null);
                    }}>{t("Clear")}</MenuItem> :
                    null
            }
        </Menu>

    </Toolbar>
}

export default ToolbarSmall;