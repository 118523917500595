import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';

const MissingLicense = ({ isInstaller, licenseLevel, alarmSystem }) => {
    const { t } = useTranslation();

    const licenseName = t(`${isInstaller ? "Installer" : "User"}License${licenseLevel}`);
    return <Alert style={{ marginTop: 20 }} severity="error">
        <AlertTitle>{t("MissingLicense", { first: licenseName })}</AlertTitle>
        <Link component={RouterLink}
            to={constants.ROUTE_ALARM_SYSTEMS_DETAILS__ID__TAB_INDEX
                .replace(":id", alarmSystem.id)
            .replace(":tabIndex", 3)}>
            {t("ChangeLicense")}
        </Link>
    </Alert>
}
export default MissingLicense;