import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
   // alarmSystem: null,
    license: null,
    permissionName: null,
    paymentSuccess: false,
    confirmationCode: null
};

const alarmSystemTransactionResult = (state = INITIAL_STATE, action) => {

    let newState;
    let a;
    switch (action.type) {
        case constants.ACTION_TYPE_ALARM_SYSTEM_TRANSACTION_RESULT__GET_TRANSACTION_RESULT_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
              //  alarmSystem: { $set: action.data.alarmSystem },
                license: { $set: action.data.license },
                permissionName: { $set: action.data.permissionName },
                paymentSuccess: { $set: action.data.paymentSuccess },
                confirmationCode: { $set: action.data.confirmationCode }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_TRANSACTION_RESULT__GET_TRANSACTION_RESULT_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
               // alarmSystem: { $set: null },
                license: { $set: null },
                permissionName: { $set: null },
                paymentSuccess: { $set: false },
                confirmationCode: { $set: null }
            });
            console.log(action.type, state, newState);
            return newState;
        
        default:
            return state;
    }
}

export default alarmSystemTransactionResult;