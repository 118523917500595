import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, FormControl, Typography, List, ListItem, ListItemText, ListItemIcon
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { getWebAccountUsers, deleteUser, openDeleteUserDialog } from '../../actions/profile';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const DeleteUserDialog = () => {
    const [newWebAccountAdminId, setNewWebAccountAdminId] = useState(null);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { user, token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const webAccountUsers = useSelector(state => state.profile.webAccountUsers);
    const deleteUserDialog = useSelector(state => state.profile.deleteUserDialog);
    const generalErrorText = useSelector(state => state.profile.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];
    const otherUsers = webAccountUsers.filter(u => u.id !== user.id);
    const needNewWebAccountAdmin = user.userType === constants.USER_TYPE_WEB_ACCOUNT_ADMIN && otherUsers.length;

    useEffect(() => {
        dispatch(getWebAccountUsers(history, token, unsetUser, t, language));
    }, [token]);

    return (
        <Dialog
            disableBackdropClick={loading}
            open={deleteUserDialog.isOpen}
            onClose={() => dispatch(openDeleteUserDialog(false))}>
            <DialogTitle>{t("Remove")}</DialogTitle>
            <DialogContent>

                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

                <Typography variant="subtitle1">{t("DeleteAccountSubtitle")}</Typography>
                <Typography variant="subtitle2">{t("DeleteAccountSubtitle2")}</Typography>

                <div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                        <TextField
                            disabled
                            fullWidth
                            type="text"
                            value={user.email}
                            label={t("EMail")}
                        />
                    </FormControl>

                </div>

                {
                    needNewWebAccountAdmin ?
                    <div style={{ marginTop: 20 }}>
                        <Typography variant="subtitle1">{t("SelectNewWebAccountAdmin")}</Typography>

                        <List dense component="nav">
                            {
                                otherUsers.map(u => <ListItem key={u.id} button
                                    selected={u.id === newWebAccountAdminId}
                                    onClick={() => setNewWebAccountAdminId(u.id)}>
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${u.firstName} ${u.lastName}`} secondary={u.email} />
                                </ListItem>)
                            }
                        </List>
                    </div> :
                    null
                }

            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openDeleteUserDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={loading || (needNewWebAccountAdmin && !newWebAccountAdminId)}
                    onClick={() => dispatch(deleteUser(newWebAccountAdminId, history, token, unsetUser, t, language))}
                    color="primary">
                    {t("Remove")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteUserDialog;