import React, { useEffect, useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, TextField, Container, Breadcrumbs, Typography, Divider, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
    Tabs, Tab, Paper, IconButton
} from '@material-ui/core';
import { Delete as DeleteIcon, Person as PersonIcon, Warning as WarningIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { getWebAccountUsers } from '../../actions/webAccountUsers';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const WebAccountUsers = () => {
    const { user, isWebAccountAdmin, token, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const webAccountUsers = useSelector(state => state.webAccountUsers.webAccountUsers);
    const generalErrorText = useSelector(state => state.webAccountUsers.generalErrorText);
    const history = useHistory();
    const dispatch = useDispatch();   
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getWebAccountUsers(history, token, unsetUser, t, language));
    }, [token]);

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("WebAccountUsers")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <Paper style={{ maxWidth: 400 }}>
                {
                    webAccountUsers.length ?
                        <List dense component="nav">
                            {
                                webAccountUsers.map(u => <ListItem key={u.id} button disabled={u.id === user.id}
                                    component={RouterLink} to={constants.ROUTE_ACCOUNT_USERS_DETAILS__ID.replace(":id", u.id)}>
                                    <ListItemIcon>
                                        <PersonIcon color={u.userType === constants.USER_TYPE_WEB_ACCOUNT_ADMIN ? "primary" : "inherit"} />
                                    </ListItemIcon>
                                    <ListItemText primary={`${u.firstName} ${u.lastName}`} secondary={u.email} />
                                    {!u.active || !u.emailConfirmed ? <ListItemIcon><WarningIcon fontSize="small" /></ListItemIcon> : null}
                                    {/*{
                                                                    user.userType === constants.USER_TYPE_WEB_ACCOUNT_ADMIN ?
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton edge="end" onClick={() => dispatch(openDeleteWebAccountUserDialog(true))}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction> :
                                                                        null
                                                                }*/}
                                </ListItem>)
                            }
                        </List> :
                        <Alert severity="warning">{t("NoUsersToShow")}</Alert>
                }
            </Paper>
            {
                isWebAccountAdmin ?
                    <div style={{ marginTop: 10 }}>
                        <Button variant="contained" color="primary" disabled={loading}
                            component={RouterLink} to={constants.ROUTE_ACCOUNT_USERS_ADD}>{t("Add")}</Button>
                    </div> :
                    null
            }
        </Container>
    );
}

export default WebAccountUsers;