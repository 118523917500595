import * as constants from '../constants';
import network from '../network';
import { validateEmail } from '../validators';
import { setLoading } from './global';

export function updateFormEmail(email, t) {
    return (dispatch) => {
        let formData = {
            email: email,
            emailErrorText: null,
            emailValid: false
        };
        if (!email)
            formData.emailErrorText = "*";
        else if (!validateEmail(email))
            formData.emailErrorText = t("ErrorEmailInvalid");
        else
            formData.emailValid = true;
        dispatch({ type: constants.ACTION_TYPE_LOGIN__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormPassword(password, t) {
    return (dispatch) => {
        let formData = {
            password: password,
            passwordErrorText: null,
            passwordValid: false
        };
        if (!password)
            formData.passwordErrorText = "*";
        else
            formData.passwordValid = true;
        dispatch({ type: constants.ACTION_TYPE_LOGIN__UPDATE_FORM_DATA, data: { formData } });
    }
}

//export function updateFormData(email, password, t) {
//    return (dispatch) => {
//        let formDataEmail = validateFormEmail(email, t); 
//        let formDataPassword = validateFormPassword(password, t); 
//        const formData = {
//            ...formDataEmail,
//            ...formDataPassword
//        };
//        dispatch({ type: constants.ACTION_TYPE_LOGIN__UPDATE_FORM_DATA, data: { formData } });
//    }
//}

export function login(email, password, history, setUser, location, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));
        const callbackurl = `${location.origin}${constants.ROUTE_ACCOUNT_CONFIRM_EMAIL}`;
        ///INBAL -COPY FROM HERE
        network.login(email, password,callbackurl, language, (user, token) => {
            setUser(user, token);
            dispatch({ type: constants.ACTION_TYPE_LOGIN__LOGIN_SUCCESS });
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ROOT);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null,
                formData: {
                    emailErrorText: null,
                    emailValid: true,
                    passwordErrorText: null,
                    passwordValid: true
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //EmailRequired = 4,
                        case 4:
                            data.formData.emailErrorText = "*";
                            data.formData.emailValid = false;
                            break;
                        //PasswordRequired = 5,
                        case 5:
                            data.formData.passwordErrorText = "*";
                            data.formData.passwordValid = false;
                            break;
                        //InvalidEmail = 6,
                        case 6:
                            data.formData.emailErrorText = t("ErrorEmailInvalid");
                            data.formData.emailValid = false;
                            break;
                        //IncorrectEmailOrPassword = 7,
                        case 7:
                            data.generalErrorText = t("ErrorInvalidLoginAttempt");
                            break;
                        //EmailNotConfirmed = 8
                        case 8:
                            data.generalErrorText = t("EmailNotConfirmed");
                            break;
                        //UserNotActive = 9
                        case 9:
                            data.generalErrorText = t("UserNotActive");
                            break
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_LOGIN__LOGIN_FAIL, data });
            dispatch(setLoading(false));
        });
    }
}