import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    formData: {
        password: '',
        passwordErrorText: null,
        passwordValid: false,
        confirmPassword: '',
        confirmPasswordErrorText: null,
        confirmPasswordValid: false,
        isValid: false
    }
};

const changePassword = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_CHANGE_PASSWORD__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.passwordValid && newState.formData.confirmPasswordValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_CHANGE_PASSWORD__CHANGE_PASSWORD_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_CHANGE_PASSWORD__CHANGE_PASSWORD_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.passwordValid && newState.formData.confirmPasswordValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default changePassword;