import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, Container, Breadcrumbs, Typography, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { resetPassword, updateFormPassword, updateFormConfirmPassword } from '../../actions/resetPassword';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
    const { t, i18n } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.resetPassword.formData);
    const generalErrorText = useSelector(state => state.resetPassword.generalErrorText);
    const history = useHistory();
    const location = window.location;
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    const query = new URLSearchParams(location.search);
    const userId = query.get("userId");
    const code = query.get("code");
    const email = query.get("email");
    //console.log("location", location);

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("ResetPassword")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <Typography style={{ marginTop: 10 }} variant="subtitle1">{t("ResetPasswordSubtitle")}</Typography>

            <form noValidate={true}>

                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled
                        fullWidth
                        type="email"
                        label={t("EMail")}
                        value={email} />
                </div>

                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        error={!!formData.passwordErrorText}
                        type="password"
                        label={t("Password")}
                        helperText={formData.passwordErrorText || t("PasswordInstructions")}
                        value={formData.password}
                        onChange={event => dispatch(updateFormPassword(event.target.value, t))} />
                </div>

                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        error={!!formData.confirmPasswordErrorText}
                        type="password"
                        label={t("ConfirmPassword")}
                        helperText={formData.confirmPasswordErrorText}
                        value={formData.confirmPassword}
                        onChange={event => dispatch(updateFormConfirmPassword(formData.password, event.target.value, t))} />
                </div>

                <div style={{ marginTop: 10 }}>
                    <Button variant="contained" color="primary" disabled={loading || !formData.isValid} onClick={() =>
                        dispatch(resetPassword(userId, code, formData.password, formData.confirmPassword, history, t, language))}>{t("Submit")}</Button>
                </div>
            </form>
        </Container>
    );
}

export default ResetPassword;