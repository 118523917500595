import React from 'react';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, Container, Breadcrumbs, Link, Typography, Divider, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { changePassword, updateFormPassword, updateFormConfirmPassword } from '../../actions/changePassword';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const ChangePassword = () => {
    const { t, i18n } = useTranslation();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.changePassword.formData);
    const generalErrorText = useSelector(state => state.changePassword.generalErrorText);
    const history = useHistory();
    const location = window.location;
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Link color="inherit" component={RouterLink} to={constants.ROUTE_ACCOUNT_PROFILE}>{t("Profile")}</Link>
                <Typography color="textPrimary">{t("ChangePassword")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}
            
            <form noValidate={true}>
                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        error={!!formData.passwordErrorText}
                        type="password"
                        label={t("Password")}
                        helperText={formData.passwordErrorText || t("PasswordInstructions")}
                        value={formData.password}
                        onChange={event => dispatch(updateFormPassword(event.target.value, t))} />
                </div>

                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        error={!!formData.confirmPasswordErrorText}
                        type="password"
                        label={t("ConfirmPassword")}
                        helperText={formData.confirmPasswordErrorText}
                        value={formData.confirmPassword}
                        onChange={event => dispatch(updateFormConfirmPassword(formData.password, event.target.value, t))} />
                </div>

                <div style={{ marginTop: 10 }}>
                    <Button variant="contained" color="primary" disabled={loading || !formData.isValid} onClick={() =>
                        dispatch(changePassword(formData.password, formData.confirmPassword, history, token, unsetUser, t, language))}>{t("LogIn")}</Button>
                </div>
            </form>
        </Container>
    );
}

export default ChangePassword;