import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { LinearProgress } from '@material-ui/core';
import App from './components/App';
import rootReducer from './reducers';
import registerServiceWorker from './registerServiceWorker';
import unregister from './registerServiceWorker';
// localization: import i18n (needs to be bundled ;)) 
import './localization/i18n';

import countries from 'i18n-iso-countries';
import countries_en from 'i18n-iso-countries/langs/en.json';
import countries_he from 'i18n-iso-countries/langs/he.json';
import countries_es from 'i18n-iso-countries/langs/es.json';
import countries_de from 'i18n-iso-countries/langs/de.json';
import countries_ru from 'i18n-iso-countries/langs/ru.json';

countries.registerLocale(countries_en);
countries.registerLocale(countries_he);
countries.registerLocale(countries_es);
countries.registerLocale(countries_de);
countries.registerLocale(countries_ru);

const store = applyMiddleware(reduxThunk)(createStore)(rootReducer);

ReactDOM.render(
    <Suspense fallback={<LinearProgress style={{ height: 3 }} />}>
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>,
    document.getElementById('root')
);

//registerServiceWorker();
unregister()
