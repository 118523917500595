import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    users: [],
    generalErrorText: null,
    displayUsers: {
        users: [],
        userId: 0,
        searchText: '',
        sort: {
            by: constants.MANAGEMENT_USERS_SORT_BY_EMAIL,
            direction: "asc"
        },
    },
};

const managementUsers = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_MANAGEMENT_USERS__UPDATE_DISPLAY_USERS:
            newState = update(state, {
                displayUsers: { $set: action.data.displayUsers }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_USERS__GET_USERS_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                users: { $set: action.data.users }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_USERS__GET_USERS_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                users: { $set: [] }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default managementUsers;