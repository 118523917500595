
export const STORAGE_KEY_USER = "STORAGE_KEY_USER";

export const ACTIVE_COMPONENT_EDIT_ALARM_SYSTEM = "ACTIVE_COMPONENT_EDIT_ALARM_SYSTEM";
export const ACTIVE_COMPONENT_ALARM_SYSTEMS = "ACTIVE_COMPONENT_ALARM_SYSTEMS";
export const ACTIVE_COMPONENT_CONFIGURATION = "ACTIVE_COMPONENT_CONFIGURATION";
export const ACTIVE_COMPONENT_MONITORING = "ACTIVE_COMPONENT_MONITORING";

export const ACTION_TYPE_GLOBAL__SET_LOADING = "ACTION_TYPE_GLOBAL__SET_LOADING";
export const ACTION_TYPE_GLOBAL__SET_ALARM_SYSTEMS = "ACTION_TYPE_GLOBAL__SET_ALARM_SYSTEMS";

export const ACTION_TYPE_TOP_MENU__OPEN_SELECT_LANGUAGE_DIALOG = "ACTION_TYPE_TOP_MENU__OPEN_SELECT_LANGUAGE_DIALOG";

export const ACTION_TYPE_USER__SET_USER = "ACTION_TYPE_USER__SET_USER";
export const ACTION_TYPE_USER__UNSET_USER = "ACTION_TYPE_USER__UNSET_USER";

export const ACTION_TYPE_WEB_ACCOUNT_USERS__GET_WEB_ACCOUNT_USERS_SUCCESS = "ACTION_TYPE_WEB_ACCOUNT_USERS__GET_WEB_ACCOUNT_USERS_SUCCESS";
export const ACTION_TYPE_WEB_ACCOUNT_USERS__GET_WEB_ACCOUNT_USERS_FAIL = "ACTION_TYPE_WEB_ACCOUNT_USERS__GET_WEB_ACCOUNT_USERS_FAIL";

export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_FORM_DATA = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_FORM_DATA";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_WEB_ACCOUNT_USER_SUCCESS = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__REGISTER_WEB_ACCOUNT_USER_SUCCESS";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_WEB_ACCOUNT_USER_FAIL = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__REGISTER_WEB_ACCOUNT_USER_FAIL";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__OPEN_DELETE_DIALOG = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__OPEN_DELETE_DIALOG";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__OPEN_SEND_EMAIL_CONFIRMATION_DIALOG = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__OPEN_SEND_EMAIL_CONFIRMATION_DIALOG";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_SUCCESS = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_SUCCESS";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_FAIL = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_FAIL";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__SEND_EMAIL_CONFIRMATION_SUCCESS = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__SEND_EMAIL_CONFIRMATION_SUCCESS";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__SEND_EMAIL_CONFIRMATION_FAIL = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__SEND_EMAIL_CONFIRMATION_FAIL";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__ACTIVATE_WEB_ACCOUNT_USER_SUCCESS = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__ACTIVATE_WEB_ACCOUNT_USER_SUCCESS";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__ACTIVATE_WEB_ACCOUNT_USER_FAIL = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__ACTIVATE_WEB_ACCOUNT_USER_FAIL";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__DELETE_WEB_ACCOUNT_USER_SUCCESS = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__DELETE_WEB_ACCOUNT_USER_SUCCESS";
export const ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__DELETE_WEB_ACCOUNT_USER_FAIL = "ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__DELETE_WEB_ACCOUNT_USER_FAIL";

export const ACTION_TYPE_PROFILE__OPEN_DELETE_USER_DIALOG = "ACTION_TYPE_PROFILE__OPEN_DELETE_USER_DIALOG";
export const ACTION_TYPE_PROFILE__UPDATE_FORM_DATA = "ACTION_TYPE_PROFILE__UPDATE_FORM_DATA";
export const ACTION_TYPE_PROFILE__SET_USER = "ACTION_TYPE_PROFILE__SET_USER";
export const ACTION_TYPE_PROFILE__UPDATE_USER_SUCCESS = "ACTION_TYPE_PROFILE__UPDATE_USER_SUCCESS";
export const ACTION_TYPE_PROFILE__UPDATE_USER_FAIL = "ACTION_TYPE_PROFILE__UPDATE_USER_FAIL";
export const ACTION_TYPE_PROFILE__DELETE_USER_SUCCESS = "ACTION_TYPE_PROFILE__DELETE_USER_SUCCESS";
export const ACTION_TYPE_PROFILE__DELETE_USER_FAIL = "ACTION_TYPE_PROFILE__DELETE_USER_FAIL";
export const ACTION_TYPE_PROFILE__GET_WEB_ACCOUNT_USERS_SUCCESS = "ACTION_TYPE_PROFILE__GET_WEB_ACCOUNT_USERS_SUCCESS";
export const ACTION_TYPE_PROFILE__GET_WEB_ACCOUNT_USERS_FAIL = "ACTION_TYPE_PROFILE__GET_WEB_ACCOUNT_USERS_FAIL";

export const ACTION_TYPE_PAYMENT_METHOD__OPEN_DELETE_DIALOG = "ACTION_TYPE_PAYMENT_METHOD__OPEN_DELETE_DIALOG";
export const ACTION_TYPE_PAYMENT_METHOD__OPEN_EXTERNAL_PAYMENT_SITE_DIALOG = "ACTION_TYPE_PAYMENT_METHOD__OPEN_EXTERNAL_PAYMENT_SITE_DIALOG";
export const ACTION_TYPE_PAYMENT_METHOD__GET_WEB_ACCOUNT_SUCCESS = "ACTION_TYPE_PAYMENT_METHOD__GET_WEB_ACCOUNT_SUCCESS";
export const ACTION_TYPE_PAYMENT_METHOD__GET_WEB_ACCOUNT_FAIL = "ACTION_TYPE_PAYMENT_METHOD__GET_WEB_ACCOUNT_FAIL";
export const ACTION_TYPE_PAYMENT_METHOD__DELETE_PAYMENT_METHOD_SUCCESS = "ACTION_TYPE_PAYMENT_METHOD__DELETE_PAYMENT_METHOD_SUCCESS";
export const ACTION_TYPE_PAYMENT_METHOD__DELETE_PAYMENT_METHOD_FAIL = "ACTION_TYPE_PAYMENT_METHOD__DELETE_PAYMENT_METHOD_FAIL";

export const ACTION_TYPE_CHANGE_PASSWORD__UPDATE_FORM_DATA = "ACTION_TYPE_CHANGE_PASSWORD__UPDATE_FORM_DATA";
export const ACTION_TYPE_CHANGE_PASSWORD__CHANGE_PASSWORD_SUCCESS = "ACTION_TYPE_CHANGE_PASSWORD__CHANGE_PASSWORD_SUCCESS";
export const ACTION_TYPE_CHANGE_PASSWORD__CHANGE_PASSWORD_FAIL = "ACTION_TYPE_CHANGE_PASSWORD__CHANGE_PASSWORD_FAIL";

export const ACTION_TYPE_REGISTER__UPDATE_FORM_DATA = "ACTION_TYPE_REGISTER__UPDATE_FORM_DATA";
export const ACTION_TYPE_REGISTER__REGISTER_SUCCESS = "ACTION_TYPE_REGISTER__REGISTER_SUCCESS";
export const ACTION_TYPE_REGISTER__REGISTER_FAIL = "ACTION_TYPE_REGISTER__REGISTER_FAIL";

export const ACTION_TYPE_FORGOT_PASSWORD__UPDATE_FORM_DATA = "ACTION_TYPE_FORGOT_PASSWORD__UPDATE_FORM_DATA";
export const ACTION_TYPE_FORGOT_PASSWORD__SEND_RESET_PASSWORD_EMAIL_SUCCESS = "ACTION_TYPE_FORGOT_PASSWORD__SEND_RESET_PASSWORD_EMAIL_SUCCESS";
export const ACTION_TYPE_FORGOT_PASSWORD__SEND_RESET_PASSWORD_EMAIL_FAIL = "ACTION_TYPE_FORGOT_PASSWORD__SEND_RESET_PASSWORD_EMAIL_FAIL";

export const ACTION_TYPE_CONFIRM_EMAIL__CONFIRM_EMAIL_SUCCESS = "ACTION_TYPE_CONFIRM_EMAIL__CONFIRM_EMAIL_SUCCESS";
export const ACTION_TYPE_CONFIRM_EMAIL__CONFIRM_EMAIL_FAIL = "ACTION_TYPE_CONFIRM_EMAIL__CONFIRM_EMAIL_FAIL";

export const ACTION_TYPE_RESET_PASSWORD__UPDATE_FORM_DATA = "ACTION_TYPE_RESET_PASSWORD__UPDATE_FORM_DATA";
export const ACTION_TYPE_RESET_PASSWORD__RESET_PASSWORD_SUCCESS = "ACTION_TYPE_RESET_PASSWORD__RESET_PASSWORD_SUCCESS";
export const ACTION_TYPE_RESET_PASSWORD__RESET_PASSWORD_FAIL = "ACTION_TYPE_RESET_PASSWORD__RESET_PASSWORD_FAIL";

export const ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__UPDATE_FORM_DATA = "ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__UPDATE_FORM_DATA";
export const ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__GET_WEB_ACCOUNT_USER_SUCCESS = "ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__GET_WEB_ACCOUNT_USER_SUCCESS";
export const ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__GET_WEB_ACCOUNT_USER_FAIL = "ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__GET_WEB_ACCOUNT_USER_FAIL";
export const ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__CONFIRM_EMAIL_SUCCESS = "ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__CONFIRM_EMAIL_SUCCESS";
export const ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__CONFIRM_EMAIL_FAIL = "ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__CONFIRM_EMAIL_FAIL";

export const ACTION_TYPE_ADD_WEB_ACCOUNT_USER__UPDATE_FORM_DATA = "ACTION_TYPE_ADD_WEB_ACCOUNT_USER__UPDATE_FORM_DATA";
export const ACTION_TYPE_ADD_WEB_ACCOUNT_USER__REGISTER_WEB_ACCOUNT_USER_SUCCESS = "ACTION_TYPE_ADD_WEB_ACCOUNT_USER__REGISTER_WEB_ACCOUNT_USER_SUCCESS";
export const ACTION_TYPE_ADD_WEB_ACCOUNT_USER__REGISTER_WEB_ACCOUNT_USER_FAIL = "ACTION_TYPE_ADD_WEB_ACCOUNT_USER__REGISTER_WEB_ACCOUNT_USER_FAIL";

export const ACTION_TYPE_LOGIN__UPDATE_FORM_DATA = "ACTION_TYPE_LOGIN__UPDATE_FORM_DATA";
export const ACTION_TYPE_LOGIN__LOGIN_SUCCESS = "ACTION_TYPE_LOGIN__LOGIN_SUCCESS";
export const ACTION_TYPE_LOGIN__LOGIN_FAIL = "ACTION_TYPE_LOGIN__LOGIN_FAIL";

export const ACTION_TYPE_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_SUCCESS = "ACTION_TYPE_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_SUCCESS";
export const ACTION_TYPE_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_FAIL = "ACTION_TYPE_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_FAIL";

export const ACTION_TYPE_ADD_ALARM_SYSTEM__GET_WEB_ACCOUNT_SUCCESS = "ACTION_TYPE_ADD_ALARM_SYSTEM__GET_WEB_ACCOUNT_SUCCESS"
export const ACTION_TYPE_ADD_ALARM_SYSTEM__GET_WEB_ACCOUNT_FAIL = "ACTION_TYPE_ADD_ALARM_SYSTEM__GET_WEB_ACCOUNT_FAIL"
export const ACTION_TYPE_ADD_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_SUCCESS = "ACTION_TYPE_ADD_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_SUCCESS";
export const ACTION_TYPE_ADD_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_FAIL = "ACTION_TYPE_ADD_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_FAIL";
export const ACTION_TYPE_ADD_ALARM_SYSTEM__UPDATE_FORM_DATA = "ACTION_TYPE_ADD_ALARM_SYSTEM__UPDATE_FORM_DATA";
export const ACTION_TYPE_ADD_ALARM_SYSTEM__ADD_ALARM_SYSTEM_SUCCESS = "ACTION_TYPE_ADD_ALARM_SYSTEM__ADD_ALARM_SYSTEM_SUCCESS";
export const ACTION_TYPE_ADD_ALARM_SYSTEM__ADD_ALARM_SYSTEM_FAIL = "ACTION_TYPE_ADD_ALARM_SYSTEM__ADD_ALARM_SYSTEM_FAIL";

//export const ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_SUCCESS = "ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_SUCCESS";
//export const ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_FAIL = "ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_FAIL";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__OPEN_DELETE_DIALOG = "ACTION_TYPE_EDIT_ALARM_SYSTEM__OPEN_DELETE_DIALOG";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__OPEN_CHANGE_MAC_DIALOG = "ACTION_TYPE_EDIT_ALARM_SYSTEM__OPEN_CHANGE_MAC_DIALOG";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_ALARM_SYSTEM_SUCCESS = "ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_ALARM_SYSTEM_SUCCESS";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_ALARM_SYSTEM_FAIL = "ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_ALARM_SYSTEM_FAIL";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_LICENSE_SUCCESS = "ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_LICENSE_SUCCESS";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_LICENSE_FAIL = "ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_LICENSE_FAIL";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_FORM_DATA = "ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_FORM_DATA";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_CHANGE_MAC_FORM_DATA = "ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_CHANGE_MAC_FORM_DATA";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_ALARM_SYSTEM_SUCCESS = "ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_ALARM_SYSTEM_SUCCESS";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_ALARM_SYSTEM_FAIL = "ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_ALARM_SYSTEM_FAIL";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__DELETE_ALARM_SYSTEM_SUCCESS = "ACTION_TYPE_EDIT_ALARM_SYSTEM__DELETE_ALARM_SYSTEM_SUCCESS";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__DELETE_ALARM_SYSTEM_FAIL = "ACTION_TYPE_EDIT_ALARM_SYSTEM__DELETE_ALARM_SYSTEM_FAIL";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__CHANGE_MAC_SUCCESS = "ACTION_TYPE_EDIT_ALARM_SYSTEM__CHANGE_MAC_SUCCESS";
export const ACTION_TYPE_EDIT_ALARM_SYSTEM__CHANGE_MAC_FAIL = "ACTION_TYPE_EDIT_ALARM_SYSTEM__CHANGE_MAC_FAIL";

export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_DIALOG = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_DIALOG";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_TRANZILA_DIALOG = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_TRANZILA_DIALOG";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSE_SUCCESS = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSE_SUCCESS";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSE_FAIL = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSE_FAIL";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_ALARM_SYSTEM_SUCCESS = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_ALARM_SYSTEM_SUCCESS";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_ALARM_SYSTEM_FAIL = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_ALARM_SYSTEM_FAIL";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_SUCCESS = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_SUCCESS";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_NUMBER_OF_REQUIRED_SYSTEMS = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_NUMBER_OF_REQUIRED_SYSTEMS";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_GENERALCOMMENT = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_GENERALCOMMENT";
export const ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FREE_SUCCESS = "ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FREE_SUCCESS";

export const ACTION_TYPE_ALARM_SYSTEM_TRANSACTION_RESULT__GET_TRANSACTION_RESULT_SUCCESS = "ACTION_TYPE_ALARM_SYSTEM_TRANSACTION_RESULT__GET_TRANSACTION_RESULT_SUCCESS";
export const ACTION_TYPE_ALARM_SYSTEM_TRANSACTION_RESULT__GET_TRANSACTION_RESULT_FAIL = "ACTION_TYPE_ALARM_SYSTEM_TRANSACTION_RESULT__GET_TRANSACTION_RESULT_FAIL";

export const ACTION_TYPE_CONFIGURATION__OPEN_PAIR_DIALOG = "ACTION_TYPE_CONFIGURATION__OPEN_PAIR_DIALOG";
export const ACTION_TYPE_CONFIGURATION__OPEN_UNPAIR_DIALOG = "ACTION_TYPE_CONFIGURATION__OPEN_UNPAIR_DIALOG";
export const ACTION_TYPE_CONFIGURATION__OPEN_READ_DIALOG = "ACTION_TYPE_CONFIGURATION__OPEN_READ_DIALOG";
export const ACTION_TYPE_CONFIGURATION__OPEN_WRITE_DIALOG = "ACTION_TYPE_CONFIGURATION__OPEN_WRITE_DIALOG";
export const ACTION_TYPE_CONFIGURATION__OPEN_LOAD_TEMPLATE_DIALOG = "ACTION_TYPE_CONFIGURATION__OPEN_LOAD_TEMPLATE_DIALOG";
export const ACTION_TYPE_CONFIGURATION__OPEN_CLEAR_DIALOG = "ACTION_TYPE_CONFIGURATION__OPEN_CLEAR_DIALOG";
export const ACTION_TYPE_CONFIGURATION__OPEN_SAVE_AS_TEMPLATE_DIALOG = "ACTION_TYPE_CONFIGURATION__OPEN_SAVE_AS_TEMPLATE_DIALOG";
export const ACTION_TYPE_CONFIGURATION__OPEN_PARAMETER_ERRORS_DIALOG = "ACTION_TYPE_CONFIGURATION__OPEN_PARAMETER_ERRORS_DIALOG";

//export const ACTION_TYPE_CONFIGURATION__GET_CONFIGURATION_SUCCESS = "ACTION_TYPE_CONFIGURATION__GET_CONFIGURATION_SUCCESS";
//export const ACTION_TYPE_CONFIGURATION__GET_CONFIGURATION_FAIL = "ACTION_TYPE_CONFIGURATION__GET_CONFIGURATION_FAIL";
//export const ACTION_TYPE_CONFIGURATION__CLEAR_SUCCESS = "ACTION_TYPE_CONFIGURATION__CLEAR_SUCCESS";
//export const ACTION_TYPE_CONFIGURATION__CLEAR_FAIL = "ACTION_TYPE_CONFIGURATION__CLEAR_FAIL";
export const ACTION_TYPE_CONFIGURATION__GET_ALARM_SYSTEM_SUCCESS = "ACTION_TYPE_CONFIGURATION__GET_ALARM_SYSTEM_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__GET_ALARM_SYSTEM_FAIL = "ACTION_TYPE_CONFIGURATION__GET_ALARM_SYSTEM_FAIL";
export const ACTION_TYPE_CONFIGURATION__CLEAR = "ACTION_TYPE_CONFIGURATION__CLEAR";
export const ACTION_TYPE_CONFIGURATION__GET_WEB_ACCOUNT_TEMPLATES_SUCCESS = "ACTION_TYPE_CONFIGURATION__GET_WEB_ACCOUNT_TEMPLATES_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__GET_WEB_ACCOUNT_TEMPLATES_FAIL = "ACTION_TYPE_CONFIGURATION__GET_WEB_ACCOUNT_TEMPLATES_FAIL";
export const ACTION_TYPE_CONFIGURATION__GET_DEFAULT_TEMPLATES_SUCCESS = "ACTION_TYPE_CONFIGURATION__GET_DEFAULT_TEMPLATES_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__GET_DEFAULT_TEMPLATES_FAIL = "ACTION_TYPE_CONFIGURATION__GET_DEFAULT_TEMPLATES_FAIL";
export const ACTION_TYPE_CONFIGURATION__UPDATE_PAIR_FORM_DATA = "ACTION_TYPE_CONFIGURATION__UPDATE_PAIRING_FORM_DATA";
export const ACTION_TYPE_CONFIGURATION__UPDATE_READ_FORM_DATA = "ACTION_TYPE_CONFIGURATION__UPDATE_READ_FORM_DATA";
export const ACTION_TYPE_CONFIGURATION__UPDATE_WRITE_FORM_DATA = "ACTION_TYPE_CONFIGURATION__UPDATE_WRITE_FORM_DATA";
export const ACTION_TYPE_CONFIGURATION__UPDATE_LOAD_TEMPLATE_FORM_DATA = "ACTION_TYPE_CONFIGURATION__UPDATE_LOAD_TEMPLATE_FORM_DATA";
export const ACTION_TYPE_CONFIGURATION__UPDATE_SAVE_AS_TEMPLATE_FORM_DATA = "ACTION_TYPE_CONFIGURATION__UPDATE_SAVE_AS_TEMPLATE_FORM_DATA";
export const ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_SUCCESS = "ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_FAIL = "ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_FAIL";
export const ACTION_TYPE_CONFIGURATION__SAVE_AS_TEMPLATE_SUCCESS = "ACTION_TYPE_CONFIGURATION__SAVE_AS_TEMPLATE_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__SAVE_AS_TEMPLATE_FAIL = "ACTION_TYPE_CONFIGURATION__SAVE_AS_TEMPLATE_FAIL";
export const ACTION_TYPE_CONFIGURATION__READ_SUCCESS = "ACTION_TYPE_CONFIGURATION__READ_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__READ_FAIL = "ACTION_TYPE_CONFIGURATION__READ_FAIL";
export const ACTION_TYPE_CONFIGURATION__WRITE_SUCCESS = "ACTION_TYPE_CONFIGURATION__WRITE_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__WRITE_FAIL = "ACTION_TYPE_CONFIGURATION__WRITE_FAIL";
export const ACTION_TYPE_CONFIGURATION__PAIR_SUCCESS = "ACTION_TYPE_CONFIGURATION__PAIR_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__PAIR_FAIL = "ACTION_TYPE_CONFIGURATION__PAIR_FAIL";
export const ACTION_TYPE_CONFIGURATION__UNPAIR_SUCCESS = "ACTION_TYPE_CONFIGURATION__UNPAIR_SUCCESS";
export const ACTION_TYPE_CONFIGURATION__UNPAIR_FAIL = "ACTION_TYPE_CONFIGURATION__UNPAIR_FAIL";
export const ACTION_TYPE_CONFIGURATION__BROWSE_CONFIGURATION = "ACTION_TYPE_CONFIGURATION__BROWSE_CONFIGURATION";
export const ACTION_TYPE_CONFIGURATION__GO_TO_PAGE = "ACTION_TYPE_CONFIGURATION__GO_TO_PAGE";
export const ACTION_TYPE_CONFIGURATION__GO_TO_TABLE = "ACTION_TYPE_CONFIGURATION__GO_TO_TABLE";
export const ACTION_TYPE_CONFIGURATION__GO_TO_PAGINATION_PAGE = "ACTION_TYPE_CONFIGURATION__GO_TO_PAGINATION_PAGE";
export const ACTION_TYPE_CONFIGURATION__UPDATE_VALUE = "ACTION_TYPE_CONFIGURATION__UPDATE_VALUE";
//export const ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_SUCCESS = "ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_SUCCESS";
//export const ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_FAIL = "ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_FAIL";
//export const ACTION_TYPE_CONFIGURATION__CANCEL_UPDATE_PARAMETERS = "ACTION_TYPE_CONFIGURATION__CANCEL_UPDATE_PARAMETERS";

export const ACTION_TYPE_TEMPLATE__OPEN_PARAMETER_ERRORS_DIALOG = "ACTION_TYPE_TEMPLATE__OPEN_PARAMETER_ERRORS_DIALOG";
export const ACTION_TYPE_TEMPLATE__OPEN_DELETE_DIALOG = "ACTION_TYPE_TEMPLATE__OPEN_DELETE_DIALOG";
export const ACTION_TYPE_TEMPLATE__LOAD_TEMPLATE_SUCCESS = "ACTION_TYPE_TEMPLATE__LOAD_TEMPLATE_SUCCESS";
export const ACTION_TYPE_TEMPLATE__LOAD_TEMPLATE_FAIL = "ACTION_TYPE_TEMPLATE__LOAD_TEMPLATE_FAIL";
export const ACTION_TYPE_TEMPLATE__UPDATE_TEMPLATE_SUCCESS = "ACTION_TYPE_TEMPLATE__UPDATE_TEMPLATE_SUCCESS";
export const ACTION_TYPE_TEMPLATE__UPDATE_TEMPLATE_FAIL = "ACTION_TYPE_TEMPLATE__UPDATE_TEMPLATE_FAIL";
export const ACTION_TYPE_TEMPLATE__BROWSE_CONFIGURATION = "ACTION_TYPE_TEMPLATE__BROWSE_CONFIGURATION";
export const ACTION_TYPE_TEMPLATE__UPDATE_VALUE = "ACTION_TYPE_TEMPLATE__UPDATE_VALUE";
export const ACTION_TYPE_TEMPLATE__DELETE_TEMPLATE_SUCCESS = "ACTION_TYPE_EDIT_TEMPLATE__DELETE_TEMPLATE_SUCCESS";
export const ACTION_TYPE_TEMPLATE__DELETE_TEMPLATE_FAIL = "ACTION_TYPE_EDIT_TEMPLATE__DELETE_TEMPLATE_FAIL";

export const ACTION_TYPE_TEMPLATES__GET_WEB_ACCOUNT_TEMPLATES_SUCCESS = "ACTION_TYPE_TEMPLATES__GET_WEB_ACCOUNT_TEMPLATES_SUCCESS";
export const ACTION_TYPE_TEMPLATES__GET_WEB_ACCOUNT_TEMPLATES_FAIL = "ACTION_TYPE_TEMPLATES__GET_WEB_ACCOUNT_TEMPLATES_FAIL";

export const ACTION_TYPE_ADD_TEMPLATE__UPDATE_FORM_DATA = "ACTION_TYPE_ADD_TEMPLATE__UPDATE_FORM_DATA";
export const ACTION_TYPE_ADD_TEMPLATE__GET_DEFAULT_TEMPLATES_SUCCESS = "ACTION_TYPE_ADD_TEMPLATE__GET_DEFAULT_TEMPLATES_SUCCESS";
export const ACTION_TYPE_ADD_TEMPLATE__GET_DEFAULT_TEMPLATES_FAIL = "ACTION_TYPE_ADD_TEMPLATE__GET_DEFAULT_TEMPLATES_FAIL";
export const ACTION_TYPE_ADD_TEMPLATE__ADD_TEMPLATE_SUCCESS = "ACTION_TYPE_ADD_TEMPLATE__ADD_TEMPLATE_SUCCESS";
export const ACTION_TYPE_ADD_TEMPLATE__ADD_TEMPLATE_FAIL = "ACTION_TYPE_ADD_TEMPLATE__ADD_TEMPLATE_FAIL";

export const ACTION_TYPE_MONITORING__UPDATE_FORM_DATA = "ACTION_TYPE_MONITORING__UPDATE_FORM_DATA";
export const ACTION_TYPE_MONITORING__UPDATE_FORM_SELECTED_ALARM_SYSTEMS = "ACTION_TYPE_MONITORING__UPDATE_FORM_SELECTED_ALARM_SYSTEMS";
export const ACTION_TYPE_MONITORING__UPDATE_DISPLAY_NOTIFICATIONS = "ACTION_TYPE_MONITORING__UPDATE_DISPLAY_NOTIFICATIONS";
//export const ACTION_TYPE_MONITORING__UPDATE_SEARCH_TEXT = "ACTION_TYPE_MONITORING__UPDATE_SEARCH_TEXT";
//export const ACTION_TYPE_MONITORING__UPDATE_SORT = "ACTION_TYPE_MONITORING__UPDATE_SORT";
export const ACTION_TYPE_MONITORING__GET_ALARM_SYSTEMS_SUCCESS = "ACTION_TYPE_MONITORING__GET_ALARM_SYSTEMS_SUCCESS";
export const ACTION_TYPE_MONITORING__GET_ALARM_SYSTEMS_FAIL = "ACTION_TYPE_MONITORING__GET_ALARM_SYSTEMS_FAIL";
export const ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_SUCCESS = "ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_SUCCESS";
export const ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_FAIL = "ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_FAIL";

export const ACTION_TYPE_MANAGEMENT__GET_MANAGEMENT_SUMMARY_SUCCESS = "ACTION_TYPE_MANAGEMENT__GET_MANAGEMENT_SUMMARY_SUCCESS";
export const ACTION_TYPE_MANAGEMENT__GET_MANAGEMENT_SUMMARY_FAIL = "ACTION_TYPE_MANAGEMENT__GET_MANAGEMENT_SUMMARY_FAIL";

export const ACTION_TYPE_MANAGEMENT_USERS__UPDATE_DISPLAY_USERS = "ACTION_TYPE_MANAGEMENT_USERS__UPDATE_DISPLAY_USERS";
export const ACTION_TYPE_MANAGEMENT_USERS__GET_USERS_SUCCESS = "ACTION_TYPE_MANAGEMENT_USERS__GET_USERS_SUCCESS";
export const ACTION_TYPE_MANAGEMENT_USERS__GET_USERS_FAIL = "ACTION_TYPE_MANAGEMENT_USERS__GET_USERS_FAIL";

export const ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__UPDATE_DISPLAY_ALARM_SYSTEMS = "ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__UPDATE_DISPLAY_ALARM_SYSTEMS";
export const ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_SUCCESS = "ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_SUCCESS";
export const ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_FAIL = "ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_FAIL";
export const ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_LICENSES_SUCCESS = "ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_LICENSES_SUCCESS";
export const ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_LICENSES_FAIL = "ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_LICENSES_FAIL";

export const ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__UPDATE_FORM_DATA = "ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__UPDATE_FORM_DATA";
export const ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__GET_GENERAL_SETTINGS_SUCCESS = "ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__GET_GENERAL_SETTINGS_SUCCESS";
export const ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__GET_GENERAL_SETTINGS_FAIL = "ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__GET_GENERAL_SETTINGS_FAIL";
export const ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__SET_GENERAL_SETTINGS_SUCCESS = "ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__SET_GENERAL_SETTINGS_SUCCESS";
export const ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__SET_GENERAL_SETTINGS_FAIL = "ACTION_TYPE_MANAGEMENT_GENERAL_SETTINGS__SET_GENERAL_SETTINGS_FAIL";

export const ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__UPDATE_DISPLAY_TRANSACTIONS = "ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__UPDATE_DISPLAY_TRANSACTIONS";
export const ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__GET_TRANSACTIONS_SUCCESS = "ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__GET_TRANSACTIONS_SUCCESS";
export const ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__GET_TRANSACTIONS_FAIL = "ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__GET_TRANSACTIONS_FAIL";

export const CELL_VIEW_TYPE_EMPTY = 0;
export const CELL_VIEW_TYPE_TEXT = 1;
export const CELL_VIEW_TYPE_CODE = 2;
export const CELL_VIEW_TYPE_DEC_NUMBER = 3;
export const CELL_VIEW_TYPE_HEX_NUMBER = 4;
export const CELL_VIEW_TYPE_ESN = 5;
export const CELL_VIEW_TYPE_PHONE_NUMBER = 6;
export const CELL_VIEW_TYPE_BYTE_ARRAY = 7;
export const CELL_VIEW_TYPE_ENCRYPTION_KEY = 8;
export const CELL_VIEW_TYPE_SELECTOR = 9;
export const CELL_VIEW_TYPE_TIME = 10;
export const CELL_VIEW_TYPE_DATE = 11;
export const CELL_VIEW_TYPE_IP_ADDRESS = 12;
export const CELL_VIEW_TYPE_FLAG = 13;
export const CELL_VIEW_TYPE_STATUS = 14;

export const PARAM_INFO_TYPE_DEC_NUMBER = 0;    // DecNumber,      //Fixed Length
export const PARAM_INFO_TYPE_HEX_NUMBER = 1;    // HexNumber,      //Fixed Length
export const PARAM_INFO_TYPE_ESN = 2;           // ESN,            //Fixed Length
export const PARAM_INFO_TYPE_BCD_NUMBER = 3;    // BCDNumber,      //Variable Length
export const PARAM_INFO_TYPE_PHONE_NUMBER = 4;  // PhoneNumber,    //Variable Length
export const PARAM_INFO_TYPE_IP_ADDRESS = 5;    // IPAddress,      //Constant Length 4 Byte
export const PARAM_INFO_TYPE_TIME = 6;          // Time,           //Constant Length 2 Byte
export const PARAM_INFO_TYPE_DATE = 7;          // Date,           //Constant Length 2 Byte
export const PARAM_INFO_TYPE_SELECTOR = 8;      // Selector,       //Constant Length 4 Byte
export const PARAM_INFO_TYPE_TEXT = 9;          // Text,           //Variable Length
export const PARAM_INFO_TYPE_BIT_FIELD = 10;    // BitField,       //Fixed Length
export const PARAM_INFO_TYPE_BIT_ARRAY = 11;    // BitArray,       //Fixed Length
export const PARAM_INFO_TYPE_BYTE_ARRAY = 12;   // ByteArray,      //Fixed Length
export const PARAM_INFO_TYPE_CHAR_ARRAY = 13;   // CharArray,      //Fixed Length ASCII Char Only
export const PARAM_INFO_TYPE_BIT = 14;          // Bit,            //Constant Length 1 Byte
export const PARAM_INFO_TYPE_SPECIAL = 15;      // Special         //

export const ROWS_PER_TABLE = 10;

export const ALARM_CODE_PANIC = 120;
export const ALARM_CODE_PANIC_DURESS = 121;
export const ALARM_CODE_PANIC_SILENT = 122;
export const ALARM_CODE_PANIC_AUDIBLE = 123;
export const ALARM_CODE_BURGLAR = 130;
export const ALARM_CODE_BURGLAR_PERIMETER = 131;
export const ALARM_CODE_BURGLAR_INTERIOR = 132;
export const ALARM_CODE_BURGLAR_24HOUR = 133;
export const ALARM_CODE_BURGLAR_ENTRY_EXIT = 134;
export const ALARM_CODE_BURGLAR_DAY_NIGHT = 135;
export const ALARM_CODE_BURGLAR_OUTDOOR = 136;
export const ALARM_CODE_BURGLAR_TAMPER = 137;
export const ALARM_CODE_BURGLAR_NEAR = 138;

export const USER_TYPE_ANONYMOUS = 0;
export const USER_TYPE_WEB_ACCOUNT_ADMIN = 1;
export const USER_TYPE_WEB_ACCOUNT_USER_MONITOR = 10;
export const USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ = 11;
export const USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE = 12;
export const USER_TYPE_WEB_ACCOUNT_USER_SYSTEM = 13;
export const USER_TYPE_PORTAL_ADMIN = 100;
export const USER_TYPE_PORTAL_USER = 101;

export const USER_TYPES = [
    { name: "Anonymous", type: USER_TYPE_ANONYMOUS },
    { name: "WebAccountAdmin", type: USER_TYPE_WEB_ACCOUNT_ADMIN },
    { name: "WebAccountUserMonitor", type: USER_TYPE_WEB_ACCOUNT_USER_MONITOR },
    { name: "WebAccountUserConfigurationRead", type: USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ },
    { name: "WebAccountUserConfigurationWrite", type: USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE },
    { name: "WebAccountUserSystem", type: USER_TYPE_WEB_ACCOUNT_USER_SYSTEM },
    { name: "PortalAdmin", type: USER_TYPE_PORTAL_ADMIN },
    { name: "PortalUser", type: USER_TYPE_PORTAL_USER }
]

export const NOTIFICATION_TYPES = [
    //EnabledAll = 0,
    { name: "Burglary", type: 1 },
    { name: "Panic", type: 2 },
    { name: "Fire", type: 3 },
    { name: "Duress", type: 4 },
    { name: "Medical", type: 5 },
    { name: "Tamper", type: 6 },
    { name: "Faults", type: 7 },
    { name: "ArmDisarm", type: 8 },
    { name: "AlarmRestore", type: 20 },
    { name: "Picture", type: 90 },
    { name: "General", type: 100 },
    { name: "Part1", type: 101 },
    { name: "Part2", type: 102 },
    { name: "Part3", type: 103 },
    { name: "Part4", type: 104 },
    { name: "Part5", type: 105 },
    { name: "Part6", type: 106 },
    { name: "Part7", type: 107 },
    { name: "Part8", type: 108 },
    { name: "Part9", type: 109 },
    { name: "Part10", type: 110 },
    { name: "Part11", type: 111 },
    { name: "Part12", type: 112 },
    { name: "Part13", type: 113 },
    { name: "Part14", type: 114 },
    { name: "Part15", type: 115 },
    { name: "Part16", type: 116 }
		//SpecialSound = 200,
]

export const ROUTE_ALARM_SYSTEMS_ADD = "/alarmsystems/add";
export const ROUTE_ALARM_SYSTEMS_LICENSE__ID = "/alarmsystems/license/:id";
export const ROUTE_ALARM_SYSTEMS_DETAILS__ID = "/alarmsystems/details/:id";
export const ROUTE_ALARM_SYSTEMS_DETAILS__ID__TAB_INDEX = "/alarmsystems/details/:id/:tabIndex";
export const ROUTE_ALARM_SYSTEMS_DETAILS__ID__OPTIONAL_TAB_INDEX = "/alarmsystems/details/:id/:tabIndex?";
export const ROUTE_ALARM_SYSTEMS = "/alarmsystems";
//export const ROUTE_ALARM_SYSTEMS_LICENSE_TRANZILA_SUCCESS__ID__TRANSACTION_ID = "/alarmsystems/paymentsuccess/:id/:transactionId";
export const ROUTE_ALARM_SYSTEMS_LICENSE_TRANZILA_FAIL__ID__TRANSACTION_ID = "/alarmsystems/paymentfail/:id/:transactionId";
export const ROUTE_ALARM_SYSTEMS_TRANSACTION_RESULT__ID__TRANSACTION_ID = "/alarmsystems/transactionresults/:id/:transactionId";
export const ROUTE_ALARM_SYSTEMS_LICENSE_TRANZILA_SUCCESS__ID_CONFIRMATION_CODE = "/alarmsystems/paymentsuccess/:confirmationCode/:currentNumberOfSystems";

export const ROUTE_CONFIGURATION__ID = "/configuration/:id";
export const ROUTE_TEMPLATES = "/templates";
export const ROUTE_TEMPLATES_ADD = "/templates/add";
export const ROUTE_TEMPLATES_CONFIGURATION__ID = "/templates/configuration/:id";
export const ROUTE_MONITORING__ID = "/monitoring/:id";
export const ROUTE_MONITORING__OPTIONAL_ID = "/monitoring/:id?";
export const ROUTE_MONITORING = "/monitoring";
export const ROUTE_ACCOUNT_REGISTER = "/account/register";
export const ROUTE_ACCOUNT_REGISTER_CONFIRM = "/account/registerconfirm";
export const ROUTE_ACCOUNT_CONFIRM_EMAIL = "/account/confirmemail";
export const ROUTE_ACCOUNT_RESET_PASSWORD = "/account/resetpassword";
export const ROUTE_ACCOUNT_CHANGE_PASSWORD = "/account/changepassword";
export const ROUTE_ACCOUNT_FORGOT_PASSWORD = "/account/forgotpassword";
export const ROUTE_ACCOUNT_FORGOT_PASSWORD_CONFIRM = "/account/forgotpasswordconfirm";
export const ROUTE_ACCOUNT_CONFIRM_WEB_ACCOUNT_USER_EMAIL = "/account/confirmwebaccountuseremail";
export const ROUTE_ACCOUNT_LOGIN = "/account/login";
export const ROUTE_ACCOUNT_PROFILE = "/account/profile";
export const ROUTE_ACCOUNT_USERS_ADD = "/account/users/add";
export const ROUTE_ACCOUNT_USERS_DETAILS__ID = "/account/users/details/:id";
export const ROUTE_ACCOUNT_USERS = "/account/users";
export const ROUTE_ACCOUNT_PAYMENT_METHOD = "/account/paymentmethod";
//export const ROUTE_EXTERNAL_PAYMENT_SITE = "/account/externalpaymentmethod";
export const ROUTE_ACCOUNT_PAYMENT_METHOD_TRANZILA = "/account/paymentmethodtranzila";
export const ROUTE_ACCOUNT_PAYMENT_METHOD_SUCCESS = "/account/paymentmethodsuccess";
export const ROUTE_ACCOUNT_PAYMENT_METHOD_FAIL = "/account/paymentmethodfail";
export const ROUTE_MANAGEMENT = "/management";
export const ROUTE_MANAGEMENT_USERS = "/management/users";
export const ROUTE_MANAGEMENT_ALARM_SYSTEMS = "/management/alarmsystems";
export const ROUTE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__ID = "/management/alarmsystems/transactions/:id";
export const ROUTE_MANAGEMENT_GENERAL_SETTINGS = "/management/generalsettings";

export const ROUTE_ROOT = "/";

export const MONITORING_SORT_BY_DATE = 1;
export const MONITORING_SORT_BY_NAME = 2;
export const MONITORING_SORT_BY_MAC = 3;
export const MONITORING_SORT_BY_NOTIFICATION_TYPE = 4;
export const MONITORING_SORT_BY_MESSAGE = 5;

export const MANAGEMENT_USERS_SORT_BY_EMAIL = 1;
export const MANAGEMENT_USERS_SORT_BY_FIRST_NAME = 2;
export const MANAGEMENT_USERS_SORT_BY_LAST_NAME = 3;
export const MANAGEMENT_USERS_SORT_BY_PHONE_NUMBER = 4;
export const MANAGEMENT_USERS_SORT_BY_USER_TYPE = 5;
export const MANAGEMENT_USERS_SORT_BY_STATUS = 6;
export const MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE = 7;

export const MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC = 1;
export const MANAGEMENT_ALARM_SYSTEMS_SORT_BY_NAME = 2;

export const MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE = 1;
export const MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_PERMISSION_NAME = 2;
export const MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_CONFIRMATION_STATUS = 3;

export const USER_LICENSE_LEVEL_NONE = 0;
export const USER_LICENSE_LEVEL_NOTIFICATIONS = 1;
export const USER_LICENSE_LEVEL_CONTROLLING = 2;
export const USER_LICENSE_LEVEL_VIDEO_VERIFICATION = 3;
export const INSTALLER_LICENSE_LEVEL_NONE = 0;
export const INSTALLER_LICENSE_LEVEL_MONITORING = 1;
export const INSTALLER_LICENSE_LEVEL_CONFIGURATION = 2;

export const PERMISSION_STATE_DISABLED = 0;
export const PERMISSION_STATE_VALID_NOT_ACTIVATED = 1;
export const PERMISSION_STATE_VALID_ACTIVATED = 2;
export const PERMISSION_STATE_EXPIRED = 3;
export const PERMISSION_STATE_VALID_FREE = 4;


export const NOTIFY_CONFIRMATION_STATUS_NONE = 0;
export const NOTIFY_CONFIRMATION_STATUS_CONFIRMED_SUCCESS = 1;
export const NOTIFY_CONFIRMATION_STATUS_CONFIRMED_FAIL = 2;
export const NOTIFY_CONFIRMATION_STATUS_MISMATCH = 3;
export const NOTIFY_CONFIRMATION_STATUS_NO_CLIENT_TRANSACTION_ID = 4;
export const NOTIFY_CONFIRMATION_STATUS_NO_MATCHING_NOTIFY_TRANSACTION = 5;
export const NOTIFY_CONFIRMATION_STATUS_NO_MATCHING_SUCCESS_OR_FAIL_TRANSACTION = 6;

