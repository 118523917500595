import * as constants from '../constants';
import network from '../network';
import { validateEmail, validatePhoneNumber, validateMac } from '../validators';
import { setLoading } from './global';

export function getTransactionResult(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getTransactionResult(id, token, language, (alarmSystem, license, permissionName, paymentSuccess, confirmationCode) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_TRANSACTION_RESULT__GET_TRANSACTION_RESULT_SUCCESS,
                data: { alarmSystem, license, permissionName, paymentSuccess, confirmationCode }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //TransactionNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
                }
                dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_TRANSACTION_RESULT__GET_TRANSACTION_RESULT_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

//export function getLicensingOptions(history, token, unsetUser, t, language) {
//    return (dispatch) => {
//        dispatch(setLoading(true));

//        network.getLicensingOptions(token, language, (licenseOptions) => {
//            dispatch({
//                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSING_OPTIONS_SUCCESS,
//                //data: { installerLicenseOptions, userLicenseOptions }
//                data: { licenseOptions }
//            });
//            dispatch(setLoading(false));
//        }, (errorCodes) => {
//            let data = {
//                generalErrorText: null
//            };
//            if (errorCodes) {
//                for (let errorCode of errorCodes) {
//                    switch (errorCode) {
//                        //GeneralError = 1,
//                        case 1:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        //UnexpectedError = 2,
//                        case 2:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                        default:
//                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
//                            break;
//                    }
//                }
//            } else {
//                data.generalErrorText = t("ErrorGeneral");
//            }
//            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSING_OPTIONS_FAIL, data });
//            dispatch(setLoading(false));
//        }, () => {
//            unsetUser();
//            dispatch(setLoading(false));
//            history.push(constants.ROUTE_ACCOUNT_LOGIN);
//        });
//    }
//}

export function enableFreeLicense(id, permission, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.enableFreeLicense(id, permission.name, token, language, (license, updatedNumOfSystems) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FREE_SUCCESS,
                data: { license }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}
export function enableLicense(id, permission, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.enableLicense(id, permission.name, token, language, (license) => {
            dispatch({
                type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_SUCCESS,
                data: { license }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}