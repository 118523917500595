import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, FormControl, Typography, List, ListItem, ListItemText, ListItemIcon
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { deleteWebAccountUser, openDeleteDialog } from '../../actions/webAccountUserDetails';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const DeleteWebAccountUserDialog = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { user, token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const webAccountUser = useSelector(state => state.webAccountUserDetails.webAccountUser);
    const deleteDialog = useSelector(state => state.webAccountUserDetails.deleteDialog);
    const generalErrorText = useSelector(state => state.webAccountUserDetails.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    return (
        <Dialog
            disableBackdropClick={loading}
            open={deleteDialog.isOpen}
            onClose={() => dispatch(openDeleteDialog(false))}>
            <DialogTitle>{t("Remove")}</DialogTitle>
            <DialogContent>

                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

                <Typography variant="subtitle1">{t("DeleteWebAccountUserSubtitle")}</Typography>

                <div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                        <TextField
                            disabled
                            fullWidth
                            type="text"
                            value={webAccountUser.email}
                            label={t("EMail")}
                        />
                    </FormControl>
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openDeleteDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={loading}
                    onClick={() => dispatch(deleteWebAccountUser(webAccountUser.id, history, token, unsetUser, t, language))}
                    color="primary">
                    {t("Remove")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteWebAccountUserDialog;