import {
    getAlarmSystems, getAlarmSystem, getDataViewNames, SetDiscount, removeOneSystem,
    addAlarmSystem, updateAlarmSystem, changeMac, deleteAlarmSystem, pair, unpair,
    getLicenseState, enableLicense, enableFreeLicense, getTransactionResult, addPimaLicense
} from './alarmSystem';
import { read, write } from './configuration';

import {
    getWebAccountTemplates, getDefaultTemplates, addTemplate,
    loadDefaultTemplate, getTemplate, loadWebAccountTemplate, updateTemplate,
    deleteTemplate, saveAsTemplate
} from './template';
import { getNotifications } from './notification';
import { getWebAccount, deletePaymentMethod } from './webAccount';
import {
    login, register, registerWebAccountUser,
    sendConfirmationEmail, sendResetPasswordEmail,
    confirmEmail, confirmWebAccountUserEmail,
    resetPassword, changePassword, 
    getWebAccountUsers, getWebAccountUser, updateUser, updateWebAccountUser, activateWebAccountUser, deleteUser, deleteWebAccountUser
} from './user';
import { getManagementSummary, getAllUsers, getAllAlarmSystems, getAllLicenses, getAlarmSystemTransactions } from './management';

import { getGeneralSettings,setGeneralSettings } from './generalSettings';

export default {
    getAlarmSystems, getAlarmSystem, getDataViewNames,
    addAlarmSystem, updateAlarmSystem, changeMac, deleteAlarmSystem, pair, unpair,
    getLicenseState, enableLicense, enableFreeLicense, getTransactionResult, addPimaLicense, SetDiscount, removeOneSystem,

    getWebAccountTemplates, getDefaultTemplates, addTemplate,
    loadDefaultTemplate, getTemplate, loadWebAccountTemplate, updateTemplate,
    deleteTemplate, saveAsTemplate,

    //getConfiguration,
    //updateParameters,
    read,
    write,
    //clear,

    login, register, registerWebAccountUser,
    sendConfirmationEmail, sendResetPasswordEmail,
    confirmEmail, confirmWebAccountUserEmail, 
    resetPassword, changePassword,
    getWebAccountUsers, getWebAccountUser, updateUser, updateWebAccountUser, activateWebAccountUser, deleteUser, deleteWebAccountUser,

    getWebAccount, deletePaymentMethod,

    getNotifications,

    getManagementSummary,getAllUsers,getAllAlarmSystems,getAllLicenses,getAlarmSystemTransactions,

    getGeneralSettings,setGeneralSettings
}
