import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useMediaQuery, AppBar, Toolbar, Typography, Button, IconButton, Select, Menu, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
//import logoLtr from '../images/ptmlogo.jpg';
import logoEng from '../../images/pimalogo_transparent_en.png';
import logoHeb from '../../images/pimalogo_transparent_he.png';
import logo from '../../images/ptmlogo.jpg';
import SelectLanguageDialog from './SelectLanguageDialog';
import * as constants from '../../constants';
import useUser from '../../user/useUser';
import { openSelectLanguageDialog } from '../../actions/topMenu';
import { useDispatch } from 'react-redux';
import ToolbarSmall from './ToolbarSmall';

import ToolbarFull from './ToolbarFull';

const TopMenu = ({ activeComponent }) => {

    const [isSelectLanguageDialogOpen, setIsSelectLanguageDialogOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const { user, isAuthenticated, isWebAccountAdmin, isWebAccountUser, setUser, unsetUser } = useUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const language = i18n.languages[0];
    const isHebrew = language === "he";
    //const language = strings.getLanguage();
    //const languages = ["en", "de", "el", "es", "he", "ru"];
    //console.log("match", match);
    //console.log("params", match.params);
    //console.log("location", location);    
    return (
        <div style={{ flexGrow: 1 }}>
            <SelectLanguageDialog />
            <AppBar color="inherit" position="static">
                {
                    /*isMobile ? <ToolbarSmall /> :*/ <ToolbarFull />
                }
            </AppBar>
        </div>
    );
}

export default TopMenu;
