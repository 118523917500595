import React, { useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, TextField, Container, List, ListItem, ListItemText, ListItemSecondaryAction,
    IconButton, Typography, Breadcrumbs, Divider, Paper
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Edit as EditIcon } from '@material-ui/icons';
import { getWebAccountTemplates } from '../../actions/templates';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const Templates = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { isWebAccountUserConfigurationWrite, token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const templates = useSelector(state => state.templates.templates);
    const generalErrorText = useSelector(state => state.templates.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getWebAccountTemplates(history, token, unsetUser, t, language));
    }, [dispatch, token]);

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("Templates")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <div style={{ marginTop: 10 }}>
                <Paper variant="elevation" style={{ maxWidth: 400 }}>
                    {
                        templates && templates.length ?
                            <List component="nav">
                                {
                                    templates.map((tm, i) => (
                                        <ListItem disabled={loading} key={i} button
                                            component={RouterLink} to={constants.ROUTE_TEMPLATES_CONFIGURATION__ID.replace(":id", tm.id)}>
                                            <ListItemText primary={tm.name} secondary={tm.dataViewName} />
                                            {/*<ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="edit">
                                                        <EditIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>*/}
                                        </ListItem>
                                    ))
                                }
                            </List> :
                            <Alert severity="warning">{t("NoTemplatesToShow")}</Alert>
                    }
                </Paper>
            </div>

            {
                isWebAccountUserConfigurationWrite ?
                    <div style={{ marginTop: 10 }}>
                        <Button variant="contained" color="primary" disabled={loading}
                            component={RouterLink} to={constants.ROUTE_TEMPLATES_ADD}>{t("Add")}</Button>
                    </div> :
                    null
            }

        </Container>

    );
}

export default Templates;