import * as constants from '../constants';
import network from '../network';
import { validateEmail, validatePassword, validatePhoneNumber } from '../validators';
import { setLoading } from './global';

export function getWebAccountUsers(history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getWebAccountUsers(token, language, (webAccountUsers) => {
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USERS__GET_WEB_ACCOUNT_USERS_SUCCESS,
                data: { webAccountUsers}
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USERS__GET_WEB_ACCOUNT_USERS_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}