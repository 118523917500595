import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, Container, Breadcrumbs, Typography, Divider, Grid } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
    register, updateFormEmail, updateFormLastName, updateFormFirstName, updateFormPhoneNumber, updateFormPassword, updateFormConfirmPassword,
    updateFormCountry, updateFormState, updateFormCity, updateFormAddress, updateFormBusinessNumber, updateFormCompany} from '../../actions/register';
import { useTranslation } from 'react-i18next';
import countries from 'i18n-iso-countries';

const Register = () => {
    const { t, i18n } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.register.formData);
    const generalErrorText = useSelector(state => state.register.generalErrorText);
    const history = useHistory();
    const location = window.location;
    const dispatch = useDispatch();
    const language = i18n.languages[0];
    const countryObject = countries.getNames(language, { select: "official" });
    const countryCodes = Object.keys(countryObject);
    //const countryKeyValuePairs = [];
    //for (let key of Object.keys(countryObject)) {
    //    countryKeyValuePairs.push({ key: key, value: countryObject[key] });
    //}
    if ((!formData.country) || (formData.country == ""))
        formData.country = "IL";
    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("Register")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <Typography style={{ marginTop: 10 }} variant="subtitle1">{t("RegisterSubtitle")}</Typography>

            <form noValidate={true}>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="text"
                                error={!!formData.firstNameErrorText}
                                label={`* ${t("FirstName")}`}
                                helperText={formData.firstNameErrorText}
                                value={formData.firstName}
                                onChange={event => dispatch(updateFormFirstName(event.target.value, t))} />
                        </div>
                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="text"
                                error={!!formData.lastNameErrorText}
                                label={`* ${t("LastName")}`}
                                helperText={formData.lastNameErrorText}
                                value={formData.lastName}
                                onChange={event => dispatch(updateFormLastName(event.target.value, t))} />
                        </div>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <Autocomplete
                                options={countryCodes}
                                getOptionLabel={option => (option ? countryObject[option] : '')}
                                disabled={loading}
                                value={formData.country}
                                onChange={(event, newValue) => dispatch(updateFormCountry(newValue, t))}
                                renderInput={(params) => <TextField
                                    {...params}
                                    fullWidth
                                    type="text"
                                    error={!!formData.countryErrorText}
                                    label={`* ${t("Country")}`}
                                    helperText={formData.countryErrorText} />
                                }
                            />
                        </div>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="email"
                                error={!!formData.emailErrorText}
                                label={`* ${t("EMail")}`}
                                helperText={formData.emailErrorText}
                                value={formData.email}
                                onChange={event => dispatch(updateFormEmail(event.target.value, t))} />
                        </div>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                error={!!formData.passwordErrorText}
                                type="password"
                                label={`* ${t("Password")}`}
                                helperText={formData.passwordErrorText || t("PasswordInstructions")}
                                value={formData.password}
                                onChange={event => dispatch(updateFormPassword(event.target.value, t))} />
                        </div>

                        <div style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                error={!!formData.confirmPasswordErrorText}
                                type="password"
                                label={`* ${t("ConfirmPassword")}`}
                                helperText={formData.confirmPasswordErrorText}
                                value={formData.confirmPassword}
                                onChange={event => dispatch(updateFormConfirmPassword(formData.password, event.target.value, t))} />
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="text"
                                error={!!formData.phoneNumberErrorText}
                                label={t("PhoneNumber")}
                                helperText={formData.phoneNumberErrorText}
                                value={formData.phoneNumber}
                                onChange={event => dispatch(updateFormPhoneNumber(event.target.value, t))} />
                        </div>
                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("Company")}
                                value={formData.Company}
                                onChange={event => dispatch(updateFormCompany(event.target.value, t))} />
                        </div>
                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("CompanyNumber")}
                                value={formData.BusinessNumber}
                                onChange={event => dispatch(updateFormBusinessNumber(event.target.value, t))} />
                        </div>
                    </Grid>
                </Grid>

                <div style={{ marginTop: 10 }}>
                    <Button type="submit" variant="contained" color="primary" disabled={loading || !formData.isValid} onClick={() =>
                        dispatch(register(formData.firstName, formData.lastName, formData.phoneNumber,
                            formData.email, formData.password, formData.confirmPassword, formData.country,
                            formData.state, formData.city, formData.address, formData.Company,formData.BusinessNumber,
                            history, location, t, language))}>{t("Register")}</Button>
                </div>
            </form>
        </Container>
    );
}

export default Register;