import React,{ useEffect,useState } from 'react';
import { useHistory,Link as RouterLink } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
    Button,TextField,Container,Breadcrumbs,Typography,Divider,
    List,ListItem,ListItemText,ListItemIcon,ListItemSecondaryAction,
    Tabs,Tab,Paper,IconButton,Link,
    TableContainer,Table,TableBody,TableHead,TableCell,TableRow,AppBar,Toolbar
} from '@material-ui/core';
import { Delete as DeleteIcon,Person as PersonIcon,Warning as WarningIcon,Tablet as TabletIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { getGeneralSettings,setGeneralSettings,updateFormVideoLicensePrice,updateFormControlLicensePrice,updateFormVat } from '../../actions/managementGeneralSettings';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const ManagementGeneralSettings = () => {
    const { user,isPortalAdmin,token,unsetUser } = useUser();
    const { t,i18n } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.managementGeneralSettings.formData);
    const licenseSettings = useSelector(state => state.managementGeneralSettings.licenseSettings);
    const generalErrorText = useSelector(state => state.managementGeneralSettings.generalErrorText);
    const history = useHistory();
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getGeneralSettings(history,token,unsetUser,t,language));
    },[token]);

    return (
        <Container>
            <Breadcrumbs style={{ marginTop: 20 }}>
                <Link color="inherit" component={RouterLink} to={constants.ROUTE_MANAGEMENT}>{t("Management")}</Link>
                <Typography color="textPrimary">{t("GeneralSettings")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10,marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <AppBar color="inherit" position="static">
                <Toolbar variant="dense">
                    <Button color="inherit" disabled={loading || !formData.isValid || !formData.isDirty}
                        onClick={() => {

                            dispatch(setGeneralSettings(Object.assign({},licenseSettings,{
                                Permissions: [
                                        {
                                            Name: "Video",
                                            PriceCents: formData.videoLicensePrice * 100,
                                            FreeMonths: 3
                                        },
                                        {
                                            Name: "Control",
                                            PriceCents: formData.controlLicensePrice * 100,
                                            FreeMonths: 3
                                        },
                                        {
                                            Name: "Notifications",
                                            PriceCents: 0,
                                            FreeMonths: 12000
                                        }
                                    ]
                             }),formData.vat,history,token,unsetUser,t,language));}}>{t("Save")}</Button>
                    <Button color="inherit" disabled={loading || !formData.isDirty}
                        onClick={() => {
                            dispatch(getGeneralSettings(history,token,unsetUser,t,language));
                        }}>{t("Cancel")}</Button>
                </Toolbar>
            </AppBar>

            <Paper style={{ marginTop: 10, maxWidth: 400 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Property")}</TableCell>
                                <TableCell>{t("Value")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">{t("VAT")}</TableCell>
                                <TableCell>
                                    <div style={{ marginTop: 10,maxWidth: 300 }}>
                                        <TextField
                                            readOnly={!isPortalAdmin}
                                            disabled={loading}
                                            fullWidth
                                            error={!!formData.vatErrorText}
                                            type="number"
                                            helperText={formData.vatErrorText}
                                            value={formData.vat}
                                            onChange={event => dispatch(updateFormVat(event.target.value,t))} />
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{t("ControlLicensePrice")}</TableCell>
                                <TableCell>
                                    <div style={{ marginTop: 10,maxWidth: 300 }}>
                                        <TextField
                                            readOnly={!isPortalAdmin}
                                            disabled={loading}
                                            fullWidth
                                            error={!!formData.controlLicensePriceErrorText}
                                            type="number"
                                            helperText={formData.controlLicensePriceErrorText}
                                            value={formData.controlLicensePrice}
                                            onChange={event => dispatch(updateFormControlLicensePrice(event.target.value,t))} />
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">{t("VideoLicensePrice")}</TableCell>
                                <TableCell>
                                    <div style={{ marginTop: 10,maxWidth: 300 }}>
                                        <TextField
                                            readOnly={!isPortalAdmin}
                                            disabled={loading}
                                            fullWidth
                                            error={!!formData.videoLicensePriceErrorText}
                                            type="number"
                                            helperText={formData.videoLicensePriceErrorText}
                                            value={formData.videoLicensePrice}
                                            onChange={event => dispatch(updateFormVideoLicensePrice(event.target.value,t))} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
}

export default ManagementGeneralSettings;