import React, { useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, IconButton, TextField, Container, Breadcrumbs, Typography, Divider, Link,
    Stepper, MobileStepper, Step, StepLabel, FormControl, FormLabel, InputLabel, Select, MenuItem, FormHelperText,
    FormControlLabel, Switch, FormGroup, Grid, Chip, Tabs, Tab,
    Dialog, DialogTitle, DialogContent, DialogActions,
    List, ListItem, ListItemText, ListItemIcon, Checkbox, AppBar, Toolbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    addAlarmSystem,
    updateFormName, updateFormMac,
    updateFormOwnerFirstName, updateFormOwnerLastName, updateFormOwnerCompany,
    updateFormOwnerPhoneNumber1, updateFormOwnerPhoneNumber2, updateFormOwnerEmail1, updateFormOwnerEmail2,
    updateFormOwnerAddressCountry, updateFormOwnerAddressState, updateFormOwnerAddressCity, updateFormOwnerAddressAddress,
    updateFormInstallationAddressCountry, updateFormInstallationAddressState, updateFormInstallationAddressCity, updateFormInstallationAddressAddress
} from '../../actions/addAlarmSystem';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const AddAlarmSystem = () => {
    const [isNotificationTypesDialogOpen, setIsNotificationTypesDialogOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const { t, i18n } = useTranslation();
    const { token, unsetUser } = useUser();
    const history = useHistory();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.addAlarmSystem.formData);
    const generalErrorText = useSelector(state => state.addAlarmSystem.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Link color="inherit" component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS}>{t("AlarmSystems")}</Link>
                <Typography color="textPrimary">{t("Add")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <AppBar color="inherit" position="static">
                <Toolbar variant="dense">
                    <Button color="inherit" disabled={loading || !formData.isValid}
                        onClick={() => dispatch(addAlarmSystem({
                            //id: null,
                            //webAccountId: null,
                            name: formData.name,
                            mac: formData.mac,
                            //dataViewName: formData.dataViewName,

                            ownerFirstName: formData.ownerFirstName,
                            ownerLastName: formData.ownerLastName,
                            ownerCompany: formData.ownerCompany,
                            ownerPhoneNumber1: formData.ownerPhoneNumber1,
                            ownerPhoneNumber2: formData.ownerPhoneNumber2,
                            ownerEmail1: formData.ownerEmail1,
                            ownerEmail2: formData.ownerEmail2,
                            ownerAddressCountry: formData.ownerAddressCountry,
                            ownerAddressState: formData.ownerAddressState,
                            ownerAddressCity: formData.ownerAddressCity,
                            ownerAddressAddress: formData.ownerAddressAddress,
                            installationAddressCountry: formData.installationAddressCountry,
                            installationAddressState: formData.installationAddressState,
                            installationAddressCity: formData.installationAddressCity,
                            installationAddressAddress: formData.installationAddressAddress,
                            //license: {
                            //    purchasingDate: null,
                            //    activationDate: null,
                            //    billingDate: null,
                            //    expirationDate: null,
                            //    userLicenseType: null,
                            //    installerLicenseType: null,
                            //    userLicenseCostPerMonth: null,
                            //    installerLicenseCostPerMonth: null
                            //},
                            //notificationTypes: formData.notificationTypes,
                            //pairing: {
                            //    VersionLine: null,
                            //    pairId: null,
                            //    esn: null,
                            //    pairDate: null
                            //},
                        }, history, token, unsetUser, t, language))}>{t("Save")}</Button>
                </Toolbar>
            </AppBar>

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}


            <div style={{ marginTop: 20 }}>
                <Tabs variant="scrollable" value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                    <Tab label={formData.nameValid && formData.macValid ? t("AlarmSystemDetails") :
                        <Typography variant="inherit" color="error">{t("AlarmSystemDetails")} *</Typography>} />
                    <Tab label={formData.ownerFirstNameValid && formData.ownerLastNameValid && formData.ownerCompanyValid &&
                        formData.ownerPhoneNumber1Valid && formData.ownerPhoneNumber2Valid && formData.ownerEmail1Valid && formData.ownerEmail2Valid &&
                        formData.ownerAddressCountryValid && formData.ownerAddressStateValid &&
                        formData.ownerAddressCityValid && formData.ownerAddressAddressValid ? t("OwnerDetails") :
                        <Typography variant="inherit" color="error">{t("OwnerDetails")} *</Typography>} />
                    <Tab label={formData.installationAddressCountryValid && formData.installationAddressStateValid &&
                        formData.installationAddressCityValid && formData.installationAddressAddressValid ? t("InstallationDetails") :
                        <Typography variant="inherit" color="error">{t("InstallationDetails")} *</Typography>} />
                </Tabs>

                {(i => {
                    switch (i) {
                        case 0:
                            return (
                                <div style={{ maxWidth: 300, marginTop: 20 }}>
                                    <FormControl fullWidth style={{ marginTop: 10 }}>
                                        <TextField
                                            disabled={loading}
                                            fullWidth
                                            type="text"
                                            error={!!formData.nameErrorText}
                                            label={t("Name")}
                                            helperText={formData.nameErrorText}
                                            value={formData.name}
                                            onChange={event => dispatch(updateFormName(event.target.value, t))} />
                                    </FormControl>
                                    <FormControl fullWidth style={{ marginTop: 10 }}>
                                        <TextField
                                            disabled={loading}
                                            fullWidth
                                            error={!!formData.macErrorText}
                                            type="text"
                                            label={t("MAC")}
                                            helperText={formData.macErrorText}
                                            value={formData.mac}
                                            onChange={event => dispatch(updateFormMac(event.target.value, t))} />
                                    </FormControl>
                                </div>
                            );
                        case 1:
                            return (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <div style={{ maxWidth: 300, marginTop: 20 }}>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerFirstNameErrorText}
                                                    label={t("FirstName")}
                                                    helperText={formData.ownerFirstNameErrorText}
                                                    value={formData.ownerFirstName}
                                                    onChange={event => dispatch(updateFormOwnerFirstName(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerLastNameErrorText}
                                                    label={t("LastName")}
                                                    helperText={formData.ownerLastNameErrorText}
                                                    value={formData.ownerLastName}
                                                    onChange={event => dispatch(updateFormOwnerLastName(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerCompanyErrorText}
                                                    label={t("Company")}
                                                    helperText={formData.ownerCompanyErrorText}
                                                    value={formData.ownerCompany}
                                                    onChange={event => dispatch(updateFormOwnerCompany(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    error={!!formData.ownerPhoneNumber1ErrorText}
                                                    type="text"
                                                    label={t("Phone1")}
                                                    helperText={formData.ownerPhoneNumber1ErrorText}
                                                    value={formData.ownerPhoneNumber1}
                                                    onChange={event => dispatch(updateFormOwnerPhoneNumber1(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    error={!!formData.ownerPhoneNumber2ErrorText}
                                                    type="text"
                                                    label={t("Phone2")}
                                                    helperText={formData.ownerPhoneNumber2ErrorText}
                                                    value={formData.ownerPhoneNumber2}
                                                    onChange={event => dispatch(updateFormOwnerPhoneNumber2(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerEmail1ErrorText}
                                                    label={t("EMail1")}
                                                    helperText={formData.ownerEmail1ErrorText}
                                                    value={formData.ownerEmail1}
                                                    onChange={event => dispatch(updateFormOwnerEmail1(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerEmail2ErrorText}
                                                    label={t("EMail2")}
                                                    helperText={formData.ownerEmail2ErrorText}
                                                    value={formData.ownerEmail2}
                                                    onChange={event => dispatch(updateFormOwnerEmail2(event.target.value, t))} />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div style={{ maxWidth: 300, marginTop: 20 }}>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerAddressCountryErrorText}
                                                    label={t("Country")}
                                                    helperText={formData.ownerAddressCountryErrorText}
                                                    value={formData.ownerAddressCountry}
                                                    onChange={event => dispatch(updateFormOwnerAddressCountry(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerAddressStateErrorText}
                                                    label={t("State")}
                                                    helperText={formData.ownerAddressStateErrorText}
                                                    value={formData.ownerAddressState}
                                                    onChange={event => dispatch(updateFormOwnerAddressState(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerAddressCityErrorText}
                                                    label={t("City")}
                                                    helperText={formData.ownerAddressCityErrorText}
                                                    value={formData.ownerAddressCity}
                                                    onChange={event => dispatch(updateFormOwnerAddressCity(event.target.value, t))} />
                                            </FormControl>
                                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                                <TextField
                                                    disabled={loading}
                                                    fullWidth
                                                    type="text"
                                                    error={!!formData.ownerAddressAddressErrorText}
                                                    label={t("Address")}
                                                    helperText={formData.ownerAddressAddressErrorText}
                                                    value={formData.ownerAddressAddress}
                                                    onChange={event => dispatch(updateFormOwnerAddressAddress(event.target.value, t))} />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>
                            );
                        case 2:
                            return (
                                <div style={{ maxWidth: 300, marginTop: 20 }}>
                                    <FormControl fullWidth style={{ marginTop: 10 }}>
                                        <TextField
                                            disabled={loading}
                                            fullWidth
                                            type="text"
                                            error={!!formData.installationAddressCountryErrorText}
                                            label={t("Country")}
                                            helperText={formData.installationAddressCountryErrorText}
                                            value={formData.installationAddressCountry}
                                            onChange={event => dispatch(updateFormInstallationAddressCountry(event.target.value, t))} />
                                    </FormControl>
                                    <FormControl fullWidth style={{ marginTop: 10 }}>
                                        <TextField
                                            disabled={loading}
                                            fullWidth
                                            type="text"
                                            error={!!formData.installationAddressStateErrorText}
                                            label={t("State")}
                                            helperText={formData.installationAddressStateErrorText}
                                            value={formData.installationAddressState}
                                            onChange={event => dispatch(updateFormInstallationAddressState(event.target.value, t))} />
                                    </FormControl>
                                    <FormControl fullWidth style={{ marginTop: 10 }}>
                                        <TextField
                                            disabled={loading}
                                            fullWidth
                                            type="text"
                                            error={!!formData.installationAddressCityErrorText}
                                            label={t("City")}
                                            helperText={formData.installationAddressCityErrorText}
                                            value={formData.installationAddressCity}
                                            onChange={event => dispatch(updateFormInstallationAddressCity(event.target.value, t))} />
                                    </FormControl>
                                    <FormControl fullWidth style={{ marginTop: 10 }}>
                                        <TextField
                                            disabled={loading}
                                            fullWidth
                                            type="text"
                                            error={!!formData.installationAddressAddressErrorText}
                                            label={t("Address")}
                                            helperText={formData.installationAddressAddressErrorText}
                                            value={formData.installationAddressAddress}
                                            onChange={event => dispatch(updateFormInstallationAddressAddress(event.target.value, t))} />
                                    </FormControl>
                                </div>
                            );
                        default:
                    }
                })(tabIndex)}

            </div>
        </Container>
    );
}

export default AddAlarmSystem;