import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    formData: {
        firstName: '',
        firstNameErrorText: null,
        firstNameValid: false,
        lastName: '',
        lastNameErrorText: null,
        lastNameValid: false,
        phoneNumber: '',
        phoneNumberErrorText: null,
        phoneNumberValid: true,
        email: '',
        emailErrorText: null,
        emailValid: false,
        userType: constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR,
        userTypeErrorText: null,
        userTypeValid: true,
        Company: '',
        BusinessNumber:'',
        isValid: false
    }
};

const addWebAccountUser = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.firstNameValid && newState.formData.lastNameValid && newState.formData.phoneNumberValid &&
                            newState.formData.emailValid && newState.formData.userTypeValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__REGISTER_WEB_ACCOUNT_USER_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__REGISTER_WEB_ACCOUNT_USER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.firstNameValid && newState.formData.lastNameValid && newState.formData.phoneNumberValid &&
                            newState.formData.emailValid && newState.formData.userTypeValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default addWebAccountUser;