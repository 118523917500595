import axios from 'axios';

export function login(email, password,callbackurl, language, successCallback, failCallback) {
    const data = { email, password, callbackurl};
    const config = {
        headers: { "Accept-Language": language }
    };
    axios.post('/api/user/login', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.user, response.data.token);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response.data", error.response.data);
            failCallback();
        });
}

export function register(firstName, lastName, phoneNumber, email, password, confirmPassword,
    country, state, city, address,  Company,  BusinessNumber, callbackUrl, language, successCallback, failCallback) {
    const data = { firstName, lastName, phoneNumber, email, password, confirmPassword, country, state, city, address, Company, BusinessNumber, callbackUrl };
    const config = {
        headers: { "Accept-Language": language }
    };
    axios.post('/api/user/register', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response.data", error.response.data);
            failCallback();
        });
}

export function registerWebAccountUser(email, firstName, lastName, phoneNumber, userType, callbackUrl, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { email, firstName, lastName, phoneNumber, userType, callbackUrl };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/user/registerWebAccountUser', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.user);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function sendConfirmationEmail(userId, callbackUrl, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { userId, callbackUrl };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/user/sendConfirmationEmail', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function sendResetPasswordEmail(email, callbackUrl, language, successCallback, failCallback) {
    const data = { email, callbackUrl };
    const config = {
        headers: { "Accept-Language": language }
    };
    axios.post('/api/user/sendResetPasswordEmail', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            failCallback();
        });
}

export function confirmEmail(userId, code, language, successCallback, failCallback) {
    const data = { userId, code };
    const config = {
        headers: { "Accept-Language": language }
    };
    axios.post('/api/user/confirmemail', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error(error);
            failCallback();
        });
}

export function confirmWebAccountUserEmail(userId, code, firstName, lastName, phoneNumber, password, confirmPassword, language, successCallback, failCallback) {
    const data = { userId, code, firstName, lastName, phoneNumber, password, confirmPassword };
    const config = {
        headers: { "Accept-Language": language }
    };
    axios.post('/api/user/confirmwebaccountuseremail', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error(error);
            failCallback();
        });
}

export function resetPassword(userId, code, password, confirmPassword, language, successCallback, failCallback) {
    const data = { userId, code, password, confirmPassword };
    const config = {
        headers: { "Accept-Language": language }
    };
    axios.post('/api/user/resetPassword', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            failCallback();
        });
}

export function changePassword(password, confirmPassword, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { password, confirmPassword };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/user/changePassword', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getWebAccountUsers(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get('/api/user/getWebAccountUsers', config)
        .then(response => {
        console.log(response);

        if (response.data.success) {
            successCallback(response.data.users);
        } else {
            failCallback(response.data.errorCodes);
        }
    }).catch(error => {
        console.error("error.response", error.response);
        // if 401 UNAUTHORIZED, log out
        if (error.response.status === 401) {
            unauthorizedCallback();
        } else {
            failCallback();
        }
    });
}

export function getWebAccountUser(userId, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/user/getWebAccountUser/${userId}`, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.user);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function updateUser(firstName, lastName, phoneNumber, country, state, city, address, Company, BusinessNumber,
    token, language, numberOfSystems, successCallback, failCallback, unauthorizedCallback) {
    const data = { firstName, lastName, phoneNumber, country, state, city, address, Company, BusinessNumber, numberOfSystems };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/user/updateUser', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                
                successCallback(response.data.user);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function updateWebAccountUser(userId, userType, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { userId, userType };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/user/updateWebAccountUser', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.user);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function activateWebAccountUser(userId, active, token, language, successCallback, failCallback, unauthorizedCallback) {
    const data = { userId, active };
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.post('/api/user/activateWebAccountUser', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.user);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function deleteUser(newWebAccountAdminId, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.delete(`/api/user/deleteUser/${newWebAccountAdminId}`, config)
        .then(response => {
        console.log(response);

        if (response.data.success) {
            successCallback();
        } else {
            failCallback(response.data.errorCodes);
        }
    }).catch(error => {
        console.error("error.response", error.response);
        // if 401 UNAUTHORIZED, log out
        if (error.response.status === 401) {
            unauthorizedCallback();
        } else {
            failCallback();
        }
    });
}

export function deleteWebAccountUser(userId, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.delete(`/api/user/deleteWebAccountUser/${userId}`, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}