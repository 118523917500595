import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AlarmSystems from '../alarmSystems/AlarmSystems';
import TopMenu from '../topMenu/TopMenu';
import Login from '../account/Login';
import Register from '../account/Register';
import Profile from '../account/Profile';
import ResetPassword from '../account/ResetPassword';
import ChangePassword from '../account/ChangePassword';
import ForgotPassword from '../account/ForgotPassword';
import ForgotPasswordConfirm from '../account/ForgotPasswordConfirm';
import WebAccountUsers from '../account/WebAccountUsers';
import AddWebAccountUser from '../account/AddWebAccountUser';
import WebAccountUserDetails from '../account/WebAccountUserDetails';
import RegisterConfirm from '../account/RegisterConfirm';
import ConfirmEmail from '../account/ConfirmEmail';
import ConfirmWebAccountUserEmail from '../account/ConfirmWebAccountUserEmail';
import AddAlarmSystem from '../alarmSystems/AddAlarmSystem';
import AlarmSystemLicense from '../alarmSystems/AlarmSystemLicense'

import AlarmSystemTransactionResult from '../alarmSystems/AlarmSystemTransactionResult'
import AlarmSystemDetails from '../alarmSystems/AlarmSystemDetails';
import Configuration from '../configuration/Configuration';
import Templates from '../configuration/Templates';
import AddTemplate from '../configuration/AddTemplate';
import Template from '../configuration/Template';
import Monitoring from '../monitoring/Monitoring';
import Management from '../management/Management';
import ManagementUsers from '../management/ManagementUsers';
import ManagementAlarmSystems from '../management/ManagementAlarmSystems';
import ManagementGeneralSettings from '../management/ManagementGeneralSettings';
import ManagementAlarmSystemTransactions from '../management/ManagementAlarmSystemTransactions';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';
import useUser from '../../user/useUser';
//import PaymentMethod from '../account/PaymentMethod';
//import PaymentMethodTranzila from '../account/PaymentMethodTranzila';
import moment from 'moment';
const WebAccountSwitch = () => {
    const { isUserInitialized, isAuthenticated, isWebAccountAdmin, isWebAccountUser,
        isWebAccountUserMonitor, isWebAccountUserConfigurationRead, isWebAccountUserConfigurationWrite, isWebAccountUserSystem,
        user, isPortalAdmin, isPortalUser, unsetUser } = useUser();
    const features = useSelector(state => state.global.features);
    var try1111 = moment();//.calendar();
    var tmpIsAuth = isAuthenticated;
    if (tmpIsAuth) {
        if (user) {
            if (user.lastLoginDate)
            {
                try1111 = moment((user.lastLoginDate)).add(30, 'minutes').format("DD-MM-YYYY HH:mm");
                //try1111 = try1111.add(30, 'minutes');
                var try2222 = moment().format("DD-MM-YYYY HH:mm");
                
                if (try1111 < try2222) {
                    tmpIsAuth = false

                    unsetUser();
                   
                }
            }

        }
    }
    return <Switch>      
        <Route path={constants.ROUTE_ALARM_SYSTEMS_LICENSE__ID}>
            {
                tmpIsAuth ?
                    <AlarmSystemLicense /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>            
        <Route path={constants.ROUTE_ALARM_SYSTEMS_TRANSACTION_RESULT__ID__TRANSACTION_ID}>
            {
                tmpIsAuth ?
                    <AlarmSystemTransactionResult /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>      
        {
            features.templates ?
                <Route path={constants.ROUTE_TEMPLATES_ADD}>
                    {
                        isWebAccountUserConfigurationWrite ?
                            <AddTemplate /> :
                            <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
                    }
                </Route> :
                null
        }

        {
            features.templates ?
                <Route path={constants.ROUTE_TEMPLATES_CONFIGURATION__ID}>
                    {
                        isWebAccountUserConfigurationWrite ?
                            <Template /> :
                            <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
                    }
                </Route> :
                null
        }

        {
            features.templates ?
                <Route path={constants.ROUTE_TEMPLATES}>
                    {
                        isWebAccountUserConfigurationWrite ?
                            <Templates /> :
                            <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
                    }
                </Route> :
                null
        }

        {
            features.configuration ?
                <Route path={constants.ROUTE_CONFIGURATION__ID}>
                    {
                        isWebAccountUserConfigurationRead ?
                            <Configuration /> :
                            <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
                    }
                </Route> :
                null
        }

        {
            features.monitoring ?
                <Route path={constants.ROUTE_MONITORING__OPTIONAL_ID}>
                    {
                        isWebAccountUserMonitor ?
                            <Monitoring /> :
                            <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
                    }
                </Route> :
                null
        }

        <Route path={constants.ROUTE_ACCOUNT_LOGIN}>
            <Login />
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_REGISTER_CONFIRM}>
            <RegisterConfirm />
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_REGISTER}>
            <Register />
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_CONFIRM_EMAIL}>
            <ConfirmEmail />
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_CONFIRM_WEB_ACCOUNT_USER_EMAIL}>
            <ConfirmWebAccountUserEmail />
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_RESET_PASSWORD}>
            <ResetPassword />
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_FORGOT_PASSWORD}>
            <ForgotPassword />
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_FORGOT_PASSWORD_CONFIRM}>
            <ForgotPasswordConfirm />
        </Route>

        <Route path={constants.ROUTE_ACCOUNT_USERS_ADD}>
            {
                isWebAccountAdmin ?
                    <AddWebAccountUser /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_USERS_DETAILS__ID}>
            {
                tmpIsAuth ?
                    <WebAccountUserDetails /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_USERS}>
            {
                tmpIsAuth ?
                    <WebAccountUsers /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>
        <Route path={constants.ROUTE_ACCOUNT_PROFILE}>
            {
                tmpIsAuth ?
                    <Profile /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>

        {/*{
            features.paymentMethod ?
                <Route path={constants.ROUTE_ACCOUNT_PAYMENT_METHOD}>
                    {
                        isWebAccountAdmin ?
                            <PaymentMethod /> :
                            <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
                    }
                </Route> :
                null
        }

        {
            features.paymentMethod ?
                <Route path={constants.ROUTE_ACCOUNT_PAYMENT_METHOD_TRANZILA}>
                    {
                        isWebAccountAdmin ?
                            <PaymentMethodTranzila /> :
                            <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
                    }
                </Route> :
                null
        }*/}

        <Route path={constants.ROUTE_ACCOUNT_CHANGE_PASSWORD}>
            {
                tmpIsAuth ?
                    <ChangePassword /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>

        {/*<Route path="/home">
                                            <React.Fragment>
                                                <TopMenu />
                                                <Home />
                                            </React.Fragment>
                                        </Route>
                                        <Route path="/">
                                            <React.Fragment>
                                                <TopMenu />
                                                <Home />
                                            </React.Fragment>
                                        </Route>*/}

        <Route path={constants.ROUTE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__ID}>
            {
                (isPortalAdmin || isPortalUser) ?
                    <ManagementAlarmSystemTransactions /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>
        <Route path={constants.ROUTE_MANAGEMENT_USERS}>
            {
                (isPortalAdmin || isPortalUser) ?
                    <ManagementUsers /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>
        <Route path={constants.ROUTE_MANAGEMENT_ALARM_SYSTEMS}>
            {
                (isPortalAdmin || isPortalUser) ?
                    <ManagementAlarmSystems /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>
        <Route path={constants.ROUTE_MANAGEMENT_GENERAL_SETTINGS}>
            {
                (isPortalAdmin || isPortalUser) ?
                    <ManagementGeneralSettings /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>
        <Route path={constants.ROUTE_MANAGEMENT}>
            {
                (isPortalAdmin || isPortalUser) ?
                    <Management /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>

        <Route path={constants.ROUTE_ROOT}>
            {
                tmpIsAuth ?
                    <Redirect to={constants.ROUTE_ACCOUNT_PROFILE} /> :
                    <Redirect to={constants.ROUTE_ACCOUNT_LOGIN} />
            }
        </Route>
    </Switch>;
}

export default WebAccountSwitch;