import * as constants from '../constants';
import network from '../network';
import { validateEmail, validatePassword, validatePhoneNumber } from '../validators';
import { setLoading } from './global';

export function updateFormFirstName(firstName, t) {
    return (dispatch) => {
        let formData = {
            firstName: firstName,
            firstNameErrorText: null,
            firstNameValid: false
        };
        if (!firstName)
            formData.firstNameErrorText = "*";
        else
            formData.firstNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormLastName(lastName, t) {
    return (dispatch) => {
        let formData = {
            lastName: lastName,
            lastNameErrorText: null,
            lastNameValid: false
        };
        if (!lastName)
            formData.lastNameErrorText = "*";
        else
            formData.lastNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}

export function updateFormPhoneNumber(phoneNumber, t) {
    return (dispatch) => {
        let formData = {
            phoneNumber: phoneNumber,
            phoneNumberErrorText: null,
            phoneNumberValid: false
        };
        if (phoneNumber && !validatePhoneNumber(phoneNumber))
            formData.phoneNumberErrorText = t("ErrorPhoneNumberInvalidCharacters");
        else
            formData.phoneNumberValid = true;
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}


export function updateFormCountry(country, t) {
    return (dispatch) => {
        let formData = {
            country: country,
            countryErrorText: null,
            countryValid: false
        };
        if (!country)
            formData.countryErrorText = "*";
        else
            formData.countryValid = true;
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormState(state, t) {
    return (dispatch) => {
        let formData = {
            state: state,
            stateErrorText: null,
            stateValid: true
        };
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormCity(city, t) {
    return (dispatch) => {
        let formData = {
            city: city,
            cityErrorText: null,
            cityValid: true
        };
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormAddress(address, t) {
    return (dispatch) => {
        let formData = {
            address: address,
            addressErrorText: null,
            addressValid: true
        };
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormCompany(a_Company, t) {
    return (dispatch) => {
        let formData = {
            Company: a_Company
        };
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormBusinessNumber(a_BusinessNumber, t) {
    return (dispatch) => {
        let formData = {
            BusinessNumber: a_BusinessNumber,
        };
        dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function openDeleteUserDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_PROFILE__OPEN_DELETE_USER_DIALOG, data: { isOpen } });
    }
}

export function resetForm(user) {//when pressing profile
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_PROFILE__SET_USER, data: { user } });
    }
}
export function getWebAccountUsers(history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getWebAccountUsers(token, language, (webAccountUsers) => {
            dispatch({
                type: constants.ACTION_TYPE_PROFILE__GET_WEB_ACCOUNT_USERS_SUCCESS,
                data: {webAccountUsers}
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_PROFILE__GET_WEB_ACCOUNT_USERS_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function updateUser(firstName, lastName, phoneNumber,  country, state, city, address,Company,BusinessNumber, history, token, setUser, unsetUser, t, language, numberOfSystems) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.updateUser(firstName, lastName, phoneNumber, country, state, city, address, Company, BusinessNumber, token, language, numberOfSystems,(user) => {
            setUser(user, token);
            dispatch({ type: constants.ACTION_TYPE_PROFILE__UPDATE_USER_SUCCESS });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null,
                formData: {
                    firstNameErrorText: null,
                    firstNameValid: true,
                    lastNameErrorText: null,
                    lastNameValid: true,
                    phoneNumberErrorText: null,
                    phoneNumberValid: true,                    
                    countryErrorText: null,
                    countryValid: true,
                    stateErrorText: null,
                    stateValid: true,
                    cityErrorText: null,
                    cityValid: true,
                    addressErrorText: null,
                    addressValid: true,
                    numberOfSystemsValid: true,
                    numberOfSystemsErrorText:null,

                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //FirstNameRequired = 4,
                        case 4:
                            data.formData.firstNameErrorText = "*";
                            data.formData.firstNameValid = false;
                            break;
                        //LastNameRequired = 5,
                        case 5:
                            data.formData.lastNameErrorText = "*";
                            data.formData.lastNameValid = false;
                            break;
		                //CountryRequired = 6,
                        case 6:
                            data.formData.countryErrorText = "*";
                            data.formData.countryValid = false;
                            break;
                        //InvalidPhoneNumber = 7,
                        case 7:
                            data.formData.phoneNumberErrorText = t("ErrorPhoneNumberInvalidCharacters");
                            data.formData.phoneNumberValid = false;
                            break;
                        //InvalidEmailForInvoice = 8
                       
                        //InvalidCountryCode = 9
                        case 9:
                            data.formData.countryErrorText = t("ErrorCountryCodeInvalid");
                            data.formData.countryValid = false;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_PROFILE__UPDATE_USER_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function deleteUser(newWebAccountAdminId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.deleteUser(newWebAccountAdminId, token, language, () => {
            unsetUser();
            dispatch({ type: constants.ACTION_TYPE_PROFILE__DELETE_USER_SUCCESS });
            dispatch(setLoading(false));
            history.replace(constants.ROUTE_ACCOUNT_LOGIN);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_PROFILE__DELETE_USER_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}
