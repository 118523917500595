import * as constants from '../constants';
import network from '../network';
import { validateEmail, validateMac, validatePhoneNumber } from '../validators';
import { setLoading } from './global';

// alarm system details
export function updateFormName(name, t) {
    return (dispatch) => {
        let formData = {
            name: name,
            nameErrorText: null,
            nameValid: false
        };
        if (!name)
            formData.nameErrorText = "*";
        else
            formData.nameValid = true;
        dispatch({ type: constants.ACTION_TYPE_ADD_TEMPLATE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormDataViewName(dataViewName, t) {
    return (dispatch) => {
        let formData = {
            dataViewName: dataViewName,
            dataViewNameErrorText: null,
            dataViewNameValid: false
        };
        if (!dataViewName)
            formData.dataViewNameErrorText = "*";
        else
            formData.dataViewNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_ADD_TEMPLATE__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormLanguage(language, t) {
    return (dispatch) => {
        let formData = {
            language: language,
            languageErrorText: null,
            languageValid: false
        };
        if (!language)
            formData.languageErrorText = "*";
        else
            formData.languageValid = true;
        dispatch({ type: constants.ACTION_TYPE_ADD_TEMPLATE__UPDATE_FORM_DATA, data: { formData } });
    }
}

export function getDefaultTemplates(history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getDefaultTemplates(null, token, language, (dataViewNames, languages, defaultLanguage) => {
            dispatch({
                type: constants.ACTION_TYPE_ADD_TEMPLATE__GET_DEFAULT_TEMPLATES_SUCCESS,
                data: { dataViewNames, languages }
            });
            dispatch(updateFormLanguage(defaultLanguage));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
                dispatch({ type: constants.ACTION_TYPE_ADD_TEMPLATE__GET_DEFAULT_TEMPLATES_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function addTemplate(name, dataViewName, configLanguage, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.addTemplate(name, dataViewName, configLanguage, token, language, (template) => {
            dispatch({
                type: constants.ACTION_TYPE_ADD_TEMPLATE__ADD_TEMPLATE_SUCCESS
            });
            //dispatch(getConfiguration(id, history, token, unsetUser));
            dispatch(setLoading(false));
            history.replace(constants.ROUTE_TEMPLATES);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null,
                formData: {
                    nameErrorText: null,
                    nameValid: true
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //NameRequired = 4,
                        case 4:
                            data.formData.nameErrorText = "*";
                            data.formData.nameValid = false;
                            break;
                        //NameAlreadyExists = 5
                        case 5:
                            data.formData.nameErrorText = t("NameAlreadyExists", { first: name });
                            data.formData.nameValid = false;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_ADD_TEMPLATE__ADD_TEMPLATE_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

