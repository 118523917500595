import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import Backend from 'i18next-http-backend';
//import Cache from 'i18next-localstorage-cache';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend'; // fallback
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
    //// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    //// learn more: https://github.com/i18next/i18next-http-backend
    //.use(Backend)
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: ['en', 'he', 'es', 'de', 'ru', 'el'],
        whitelist: ['en', 'he', 'es', 'de', 'ru', 'el'],
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            // order and from where user language should be detected
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: 'myDomain',

            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,

            // only detect languages that are in the whitelist
            checkWhitelist: true,

            // fallback to a similar whitelist language
            // Example 1: Browser language is 'es'
            // if 'es' is not found in whitelist, first fallback to any whitelist language that starts with 'es-', then fallback to fallbackLng ('es' -> 'es-*' -> fallbackLng)
            // Example 2: Browser language is 'es-MX'
            // if 'es-MX' is not found in whitelist, first fallback to 'es', then fallback to 'es-*', then fallback to fallbackLng ('es-MX' -> 'es' -> 'es-*' -> fallbackLng)
            checkForSimilarInWhitelist: false,

            // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
            cookieOptions: { path: '/' }
        },
        backend: {
            // array of existing i18next backends from https://www.i18next.com/plugins-and-utils.html#backends
            backends: [
                LocalStorageBackend,  // primary
                HttpApi
            ],
            // array of options in order of backends above
            backendOptions: [
                {
                    // prefix for stored languages
                    prefix: 'i18next_res_',
                    // expiration
                    expirationTime: 7 * 24 * 60 * 60 * 1000,

                    // Version applied to all languages, can be overriden using the option `versions`
                    defaultVersion: '',

                    // language versions
                    versions: {},

                    // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
                    store: window.localStorage
                },
                {
                    // path where resources get loaded from, or a function
                    // returning a path:
                    // function(lngs, namespaces) { return customPath; }
                    // the returned path will interpolate lng, ns if provided like giving a static path
                    loadPath: '/api/localization/{{lng}}.json', //'/locales/{{lng}}/{{ns}}.json',

                    // path to post missing resources
                    //addPath: '/locales/add/{{lng}}/{{ns}}',

                    // your backend server supports multiloading
                    // /locales/resources.json?lng=de+en&ns=ns1+ns2
                    // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
                    // Returned JSON structure in this case is
                    // {
                    //  lang : {
                    //   namespaceA: {},
                    //   namespaceB: {},
                    //   ...etc
                    //  }
                    // }
                    allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

                    // parse data after it has been fetched
                    // in example use https://www.npmjs.com/package/json5
                    // here it removes the letter a from the json (bad idea)
                    //parse: function (data) { return data.replace(/a/g, ''); },

                    //parse data before it has been sent by addPath
                    //parsePayload: function (namespace, key, fallbackValue) { return { key } },

                    // allow cross domain requests
                    crossDomain: false,

                    // allow credentials on cross domain requests
                    withCredentials: false,

                    // overrideMimeType sets request.overrideMimeType("application/json")
                    overrideMimeType: false,

                    // custom request headers sets request.setRequestHeader(key, value)
                    //customHeaders: {
                    //    authorization: 'foo',
                    //    // ...
                    //},

                    //requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
                    //    mode: 'cors',
                    //    credentials: 'same-origin',
                    //    cache: 'default'
                    //},

                    // define a custom request function
                    // can be used to support XDomainRequest in IE 8 and 9
                    //
                    // 'url' will be passed the value of 'loadPath'
                    // 'options' will be this entire options object
                    // 'callback' is a function that takes two parameters, 'data' and 'HttpApi'.
                    //            'data' should be the key:value translation pairs for the
                    //            requested language and namespace, or null in case of an error.
                    //            'HttpApi' should be a status object, e.g. { status: 200 }
                    // 'payload' will be a key:value object used when saving missing translations
                    //request: function (options, url, payload, callback) { },

                    // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
                    //queryStringParams: { v: '1.3.5' },

                    reloadInterval: false // can be used to reload resources in a specific interval (useful in server environments)
                }
            ]
        }
    }, (err, t) => {
        if (err) return console.error('something went wrong loading', err);
        t('key'); // -> same as i18next.t
    });


export default i18n;