import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    user: null,
    webAccountUsers: [],
    formData: {
        firstName: '',
        firstNameErrorText: null,
        firstNameValid: false,
        lastName: '',
        lastNameErrorText: null,
        lastNameValid: false,
        phoneNumber: '',
        phoneNumberErrorText: null,
        phoneNumberValid: true,      
        country: '',
        countryErrorText: null,
        countryValid: true,
        state: '',
        stateErrorText: null,
        stateValid: true,
        city: '',
        cityErrorText: null,
        cityValid: true,
        address: '',
        addressErrorText: null,
        addressValid: true,
        isValid: false,
        isDirty: false,
        numberOfSystems: '',
        numberOfSystemsErrorText: null,
        numberOfSystemsValid: false,
        pricePerMonth: '0',
        DiscountPrecent: '',
        Company: '',
        BusinessNumber:''
    },
    deleteUserDialog: {
        isOpen: false
    },
    licenseTranzilaDialog:
    {
        isOpen: false,
     //   isfree_2311: false
    }
};

const profile = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_PROFILE__OPEN_DELETE_USER_DIALOG:
            newState = update(state, {
                deleteUserDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_TRANZILA_DIALOG:
            newState = update(state, {
                licenseTranzilaDialog: {
                    isOpen: { $set: action.data.isOpen }
                   // isfree_2311: { $set: action.data.isfree_2311}
                    //permission: { $set: action.data.permission }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PROFILE__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isDirty: { $set: true },
                    isValid: {
                        $set: newState.formData.firstNameValid && newState.formData.lastNameValid && newState.formData.phoneNumberValid &&
                              newState.formData.countryValid &&
                            newState.formData.stateValid && newState.formData.cityValid && newState.formData.addressValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PROFILE__SET_USER:
            newState = update(state, {
                user: { $set: action.data.user },
                formData: {
                    $set: {
                        firstName: action.data.user.firstName,
                        firstNameErrorText: null,
                        firstNameValid: true,
                        lastName: action.data.user.lastName,
                        lastNameErrorText: null,
                        lastNameValid: true,
                        phoneNumber: action.data.user.phoneNumber,
                        phoneNumberErrorText: null,
                        phoneNumberValid: true,                        
                       
                        country: action.data.user.country,
                        countryErrorText: null,
                        countryValid: true,
                        state: action.data.user.state,
                        stateErrorText: null,
                        stateValid: true,
                        city: action.data.user.city,
                        cityErrorText: null,
                        cityValid: true,
                        address: action.data.user.address,
                        addressErrorText: null,
                        addressValid: true,
                        isValid: false,
                        isDirty: false,
                        numberOfSystems: action.data.user.numberOfSystems,
                        numberOfSystemsErrorText: null,
                        numberOfSystemsValid: true,
                        pricePerMonth: action.data.user.pricePerMonth,
                        DiscountPrecent: action.data.user.DiscountPrecent,
                        BusinessNumber: action.data.user.businessNumber,
                        Company: action.data.user.company

                    }
                }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.firstNameValid && newState.formData.lastNameValid && newState.formData.phoneNumberValid &&
                            newState.formData.countryValid &&
                            newState.formData.stateValid && newState.formData.cityValid && newState.formData.addressValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PROFILE__GET_WEB_ACCOUNT_USERS_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccountUsers: { $set: action.data.webAccountUsers }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PROFILE__GET_WEB_ACCOUNT_USERS_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                users: { $set: [] }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_PROFILE__UPDATE_USER_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PROFILE__UPDATE_USER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.firstNameValid && newState.formData.lastNameValid && newState.formData.phoneNumberValid &&
                            newState.formData.countryValid &&
                            newState.formData.stateValid && newState.formData.cityValid && newState.formData.addressValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_PROFILE__DELETE_USER_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_PROFILE__DELETE_USER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default profile;