import React, { useEffect } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, TextField, Container, Breadcrumbs, Typography, Divider,
    TableContainer, Table, TableBody, TableRow, TableCell, Link, AppBar, Toolbar,
    InputAdornment, FormControl, Paper, List, ListItem, ListItemText, ListItemIcon, ListSubheader
} from '@material-ui/core';
import { Delete as DeleteIcon, Warning as WarningIcon, Check as CheckIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';

const WebAccountUserPermissions = ({ userType, readOnly, onChange }) => {
    const { t, i18n } = useTranslation();
    const loading = useSelector(state => state.global.loading);

    return <Paper>
        <List dense component="nav" subheader={<ListSubheader>{t("UserPermissions")}</ListSubheader>}>
            {
                (userType === constants.USER_TYPE_WEB_ACCOUNT_ADMIN
                    || userType === constants.USER_TYPE_PORTAL_ADMIN
                    || userType === constants.USER_TYPE_PORTAL_USER) ?
                    <ListItem>
                        <ListItemIcon><CheckIcon /></ListItemIcon>
                        <ListItemText primary={t("UserPermissionsAdmin")} />
                    </ListItem> :
                    <React.Fragment>
                        <ListItem disabled={loading} button={!readOnly}
                            onClick={readOnly ? null : () => onChange(constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR)}>
                            {
                                userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR ?
                                    <ListItemIcon><CheckIcon /></ListItemIcon> :
                                    null
                            }
                            <ListItemText
                                inset={userType < constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR}
                                primary={t("UserPermissionsMonitor")} />
                        </ListItem>
                        <ListItem disabled={loading} button={!readOnly}
                            onClick={readOnly ? null : () => onChange(constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ)}>
                            {
                                userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ ?
                                    <ListItemIcon><CheckIcon /></ListItemIcon> :
                                    null
                            }
                            <ListItemText
                                inset={userType < constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ}
                                primary={t("UserPermissionsConfigurationRead")} />
                        </ListItem>
                        <ListItem disabled={loading} button={!readOnly}
                            onClick={readOnly ? null : () => onChange(constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE)}>
                            {
                                userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE ?
                                    <ListItemIcon><CheckIcon /></ListItemIcon> :
                                    null
                            }
                            <ListItemText
                                inset={userType < constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE}
                                primary={t("UserPermissionsConfigurationWrite")} />
                        </ListItem>
                        <ListItem disabled={loading} button={!readOnly}
                            onClick={readOnly ? null : () => onChange(constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM)}>
                            {
                                userType >= constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM ?
                                    <ListItemIcon><CheckIcon /></ListItemIcon> :
                                    null
                            }
                            <ListItemText
                                inset={userType < constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM}
                                primary={t("UserPermissionsSystem")} />
                        </ListItem>
                    </React.Fragment>
            }
        </List>
    </Paper>
}

export default WebAccountUserPermissions;