import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';
import moment from 'moment';

export function getTransactions(id, searchText, sort, transactionId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getAlarmSystemTransactions(id, token, language, (transactions, alarmSystem, license) => {
            dispatch({
                type: constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__GET_TRANSACTIONS_SUCCESS,
                data: { transactions, alarmSystem, license }
            });
            dispatch(updateDisplayTransactions(transactions, searchText, sort, transactionId, t));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__GET_TRANSACTIONS_FAIL, data });
            dispatch(updateDisplayTransactions([], searchText, sort, transactionId, t));
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function updateDisplayTransactions(transactions, searchText, sort, transactionId, t) {
    return dispatch => {
        //const macLicenseLookup = {};
        //for (let l of licenses) {
        //    macLicenseLookup[l.mac] = l;
        //}
        //console.log("macLicenseLookup", macLicenseLookup);
        const searchTextLowerCase = searchText ? searchText.toLowerCase() : null;
        let newTransactions = searchText ? transactions.filter(a => {
            //const re = new RegExp(`${searchText}`, "i");
            //return re.test(n.name) ||
            //    re.test(n.mac) ||
            //    re.test(notificationTypeLookup[n.notificationType]) ||
            //    re.test(n.message);

            //let status = !u.active ? t("UserNotActive") :
            //    (!u.emailConfirmed ? t("EmailNotConfirmed") : t("Active"));

            return t(`License${a.permissionName}`).toLowerCase().includes(searchTextLowerCase);
        }) : [...transactions];
        newTransactions = newTransactions.sort((a, b) => {
            switch (sort.by) {
                case constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_PERMISSION_NAME:
                    return a.permissionName.localeCompare(b.permissionName);
                case constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_CONFIRMATION_STATUS:
                    return a.notifyConfirmationStatus > b.notifyConfirmationStatus;
                case constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE:
                default:
                    const ma = moment(a.eventDate);
                    const mb = moment(b.eventDate);
                    if (ma.isAfter(mb))
                        return 1;
                    else if (ma.isBefore(mb))
                        return -1;
                    else
                        return 0;
            }
        });
        if (sort.direction === "desc")
            newTransactions = newTransactions.reverse();

        let newTransactionId;
        if (transactionId < 0)
            newTransactionId = 0;
        else if (transactionId > newTransactions.length - 1)
            newTransactionId = newTransactions.length - 1;
        else
            newTransactionId = transactionId;

        dispatch({
            type: constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__UPDATE_DISPLAY_TRANSACTIONS,
            data: {
                displayTransactions: {
                    transactions: newTransactions,
                    transactionId: newTransactionId,
                    searchText,
                    sort
                }
            }
        });
    }
}