import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    webAccountUsers: [],
    generalErrorText: null
};

const webAccountUsers = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_WEB_ACCOUNT_USERS__GET_WEB_ACCOUNT_USERS_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccountUsers: { $set: action.data.webAccountUsers }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USERS__GET_WEB_ACCOUNT_USERS_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                webAccountUsers: { $set: [] }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default webAccountUsers;