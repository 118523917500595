import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useMediaQuery, Paper, Button, TableContainer, Table, TableBody, TableCell, TableRow, TableHead, TableFooter, TableSortLabel,
    List, ListItem, ListItemText
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import * as constants from '../../constants';
import equal from 'fast-deep-equal';
import moment from 'moment';
import { getNotifications, updateDisplayNotifications } from '../../actions/monitoring';
import useUser from '../../user/useUser';

const TableViewSmall = () => {
    
    const dispatch = useDispatch();

    const loading = useSelector(state => state.global.loading);
    const alarmSystems = useSelector(state => state.monitoring.alarmSystems);
    const notifications = useSelector(state => state.monitoring.notifications);
    const displayNotifications = useSelector(state => state.monitoring.displayNotifications);
    const formData = useSelector(state => state.monitoring.formData);
    const generalErrorText = useSelector(state => state.monitoring.generalErrorText);


    const { token, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    const tableRef = useRef(null);
    const [tableLeft, setTableLeft] = useState(0);
    useEffect(() => {
        if (tableRef.current) {
            //console.log("tableRef", tableRef.current);
            setTableLeft(tableRef.current.left);
        }
    }, [tableRef.current]);

    //const notificationTypeLookup = Object.assign({}, constants.NOTIFICATION_TYPES.map(n => ({ [n.type]: t(`MonitoringType${n.name}`) })));
    const notificationTypeLookup = {};
    for (let n of constants.NOTIFICATION_TYPES) {
        notificationTypeLookup[n.type] = t(`MonitoringType${n.name}`);
    }
    console.log("notificationTypeLookup", notificationTypeLookup);
    const macNameLookup = {};
    for (let a of alarmSystems) {
        macNameLookup[a.mac] = a.name;
    }
    console.log("macNameLookup", macNameLookup);


    const take = 20;
    const count = Math.ceil(displayNotifications.notifications.length / take);
    const paginationPage = Math.floor(displayNotifications.notificationId / take) + 1;

    //const end = moment();
    //console.log("start render", end.diff(start));
    return <div>
        <TableContainer ref={tableRef}>
            <Table size="small" padding="checkbox">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayNotifications(alarmSystems, notifications, displayNotifications.searchText, {
                                    by: constants.MONITORING_SORT_BY_DATE,
                                    direction: displayNotifications.sort.by === constants.MONITORING_SORT_BY_DATE && displayNotifications.sort.direction === "asc" ? "desc" : "asc"
                                }, displayNotifications.notificationId, t))}
                                direction={displayNotifications.sort.by === constants.MONITORING_SORT_BY_DATE ? displayNotifications.sort.direction : "asc"}
                                active={displayNotifications.sort.by === constants.MONITORING_SORT_BY_DATE}>{t("Date")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayNotifications(alarmSystems, notifications, displayNotifications.searchText, {
                                    by: constants.MONITORING_SORT_BY_NAME,
                                    direction: displayNotifications.sort.by === constants.MONITORING_SORT_BY_NAME && displayNotifications.sort.direction === "asc" ? "desc" : "asc"
                                }, displayNotifications.notificationId, t))}
                                direction={displayNotifications.sort.by === constants.MONITORING_SORT_BY_NAME ? displayNotifications.sort.direction : "asc"}
                                active={displayNotifications.sort.by === constants.MONITORING_SORT_BY_NAME}>{t("Name")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayNotifications(alarmSystems, notifications, displayNotifications.searchText, {
                                    by: constants.MONITORING_SORT_BY_MESSAGE,
                                    direction: displayNotifications.sort.by === constants.MONITORING_SORT_BY_MESSAGE && displayNotifications.sort.direction === "asc" ? "desc" : "asc"
                                }, displayNotifications.notificationId, t))}
                                direction={displayNotifications.sort.by === constants.MONITORING_SORT_BY_MESSAGE ? displayNotifications.sort.direction : "asc"}
                                active={displayNotifications.sort.by === constants.MONITORING_SORT_BY_MESSAGE}>{t("Message")}</TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        displayNotifications.notifications
                            .slice((paginationPage - 1) * take, paginationPage * take)
                            //.filter(r => r.tableId === tableView.id && r.id >= rowId && r.id < rowId + take)
                            //.slice(rowId, rowId + take)
                            //.filter(r => r.id >= rowId && r.id < rowId + take)
                            .map((n, i) => <TableRow key={i}>
                                <TableCell style={{ maxWidth: 100 }}>
                                    <List dense>
                                        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <ListItemText primary={moment(n.eventDate).format("DD/MM/YYYY")}
                                                secondary={moment(n.eventDate).format("HH:mm:ss")} />
                                        </ListItem>
                                    </List>
                                </TableCell>
                                <TableCell style={{ maxWidth: 100 }}>
                                    <List dense>
                                        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }} button
                                            onClick={() => dispatch(getNotifications(alarmSystems, [n.mac], formData.notificationTypes, formData.dateRange, displayNotifications.searchText, displayNotifications.sort, displayNotifications.notificationId, history, token, unsetUser, t, language))}>
                                            <ListItemText primary={macNameLookup[n.mac]}
                                                secondary={n.mac} />
                                        </ListItem>
                                    </List>
                                </TableCell>
                                <TableCell style={{ minWidth: 150 }}>
                                    <List dense>
                                        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <ListItemText primary={n.message}
                                                secondary={notificationTypeLookup[n.notificationType]} />
                                        </ListItem>
                                    </List>
                                </TableCell>
                            </TableRow>)
                    }
                </TableBody>
            </Table>

        </TableContainer>

        {/*<div style={{ width: "100%", position: "fixed", bottom: 0, display: "flex", justifyContent: "center", zIndex: 100 }}>*/}
        <div style={{position: "fixed", bottom: 0, left: tableLeft, zIndex: 100}}>
            <Paper>
                <Pagination
                    style={{ marginTop: 10, marginBottom: 10 }}
                    size="small"
                    color="primary"
                    disabled={loading}
                    count={count}
                    page={paginationPage}
                    onChange={(event, page) => dispatch(updateDisplayNotifications(alarmSystems, notifications, displayNotifications.searchText, displayNotifications.sort, (page - 1) * take, t))} />
            </Paper>
        </div>
    </div>
}

export default TableViewSmall;