import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, FormControl
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { read, openReadDialog, updateReadFormCode } from '../../actions/configuration';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';

const ReadDialog = () => {
    // disable password managers
    const [isReadOnly, setIsReadOnly] = useState(true);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    const readDialog = useSelector(state => state.configuration.readDialog);
    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    if (!alarmSystem)
        return null;

    return (
        <Dialog
            disableBackdropClick={loading}
            open={readDialog.isOpen}
            onClose={() => dispatch(openReadDialog(false))}>
            <DialogTitle>{t("Read")}</DialogTitle>
            <DialogContent>

                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

                <div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                        <TextField
                            disabled={loading}
                            fullWidth
                            type="password"
                            autoComplete="off"
                            onFocus={() => setIsReadOnly(false)}
                            onBlur={() => setIsReadOnly(true)}
                            inputProps={{ readOnly: isReadOnly }}
                            inputMode="numeric"
                            value={readDialog.formData.code}
                            label={t("Code")}
                            error={!!readDialog.formData.codeErrorText}
                            helperText={readDialog.formData.codeErrorText}
                            onChange={event => dispatch(updateReadFormCode(event.target.value, t))}
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openReadDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={loading || !readDialog.formData.isValid}
                    onClick={() => dispatch(read(alarmSystem.id, readDialog.formData.code, history, token, unsetUser, t, language))}
                    color="primary">
                    {t("Execute")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReadDialog;