import * as constants from '../constants';
import network from '../network';
import { validateEmail, validatePassword, validatePhoneNumber } from '../validators';
import { setLoading } from './global';

export function updateFormUserType(userType, t) {
    return (dispatch) => {
        let formData = {
            userType,
            userTypeErrorText: null,
            userTypeValid: false
        };
        if (![
            constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE,
            constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM
        ].includes(userType))
            formData.userTypeErrorText = "*";
        else
            formData.userTypeValid = true;
        dispatch({ type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_FORM_DATA, data: { formData } });
    }
}

export function openDeleteDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__OPEN_DELETE_DIALOG, data: { isOpen } });
    }
}
export function openSendConfirmationEmailDialog(isOpen) {
    return (dispatch) => {
        dispatch({ type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__OPEN_SEND_EMAIL_CONFIRMATION_DIALOG, data: { isOpen } });
    }
}


export function resetForm(webAccountUser) {
    return (dispatch) => {
        dispatch({
            type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_SUCCESS,
            data: { webAccountUser }
        });
    }
}

export function getWebAccountUser(userId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getWebAccountUser(userId, token, language, (webAccountUser) => {
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_SUCCESS,
                data: { webAccountUser }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UserNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function updateWebAccountUser(userId, userType, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.updateWebAccountUser(userId, userType, token, language, (webAccountUser) => {
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_WEB_ACCOUNT_USER_SUCCESS,
                data: { webAccountUser }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UserNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_WEB_ACCOUNT_USER_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function sendConfirmationEmail(id, history, location, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        const callbackUrl = `${location.origin}${constants.ROUTE_ACCOUNT_CONFIRM_WEB_ACCOUNT_USER_EMAIL}`;
        network.sendConfirmationEmail(id, callbackUrl, token, language, () => {
            dispatch({ type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__SEND_EMAIL_CONFIRMATION_SUCCESS });
            setTimeout(() => {
                dispatch(setLoading(false));
                dispatch(openSendConfirmationEmailDialog(false));
            }, 2000);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UserNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__SEND_EMAIL_CONFIRMATION_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function activateWebAccountUser(id, active, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.activateWebAccountUser(id, active, token, language, (webAccountUser) => {
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__ACTIVATE_WEB_ACCOUNT_USER_SUCCESS,
                data: { webAccountUser }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__ACTIVATE_WEB_ACCOUNT_USER_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function deleteWebAccountUser(id, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.deleteWebAccountUser(id, token, language, () => {
            dispatch({ type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__DELETE_WEB_ACCOUNT_USER_SUCCESS, data: { id } });
            dispatch(setLoading(false));
            history.replace(constants.ROUTE_ACCOUNT_USERS);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UserNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__DELETE_WEB_ACCOUNT_USER_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}