import React, { useEffect } from 'react';
import { useHistory, useParams, Link as RouterLink, Prompt } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery, Button, TextField, Container, AppBar, ButtonGroup, Toolbar, Breadcrumbs, Link, Typography, Divider, IconButton, Badge } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { Delete as DeleteIcon, ErrorOutline as ErrorIcon, WarningOutlined as WarningIcon } from '@material-ui/icons';
import {
    getAlarmSystem, browseConfiguration, updateValue, openParameterErrorsDialog
} from '../../actions/configuration';
import PairDialog from './PairDialog';
import UnpairDialog from './UnpairDialog';
import ReadDialog from './ReadDialog';
import WriteDialog from './WriteDialog';
import LoadTemplateDialog from './LoadTemplateDialog';
import ClearDialog from './ClearDialog';
import ParameterErrorsDialog from './ParameterErrorsDialog';
import PagesView from './PagesView';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import ToolbarFull from './ToolbarFull';
import ToolbarSmall from './ToolbarSmall';
import * as constants from '../../constants';
import MissingLicense from '../common/MissingLicense';
import SaveAsTemplateDialog from './SaveAsTemplateDialog';

const Configuration = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    const configuration = useSelector(state => state.configuration.configuration);
    //const dataView = useSelector(state => state.configuration.dataView);
    const pageId = useSelector(state => state.configuration.pageId);
    const tableId = useSelector(state => state.configuration.tableId);
    const rowId = useSelector(state => state.configuration.rowId);
    const cellId = useSelector(state => state.configuration.cellId);
    const parameterErrorsDialog = useSelector(state => state.configuration.parameterErrorsDialog);
    
    //const paginationPage = useSelector(state => state.configuration.paginationPage);
    //const dirtyParameters = useSelector(state => state.configuration.dirtyParameters);
    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    //const parameterError = useSelector(state => state.configuration.parameterError);
    //const excludedParameters = useSelector(state => state.configuration.excludedParameters);

    //if (parameterError) {
    //    let newPaginationPage = 0;
    //    if (showFirstError) {
    //        const firstError = dirtyParameters.find(p => p.pageId === newPageId && p.tableId === newTableId && p.parameterErrorText);
    //        if (firstError)
    //            newPaginationPage = firstError.paginationPage;
    //    }
    //    console.log("browseConfiguration", { newPageId, newTableId, newPaginationPage });
    //    dispatch(browseConfiguration(history, id, newPageId, newTableId, newPaginationPage));
    //}

    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getAlarmSystem(id, history, token, unsetUser, t, language));
    }, [dispatch, id, token]);

    //window.onbeforeunload = e => t("ConfigurationWillBeLost");
    useEffect(() => {
        window.onbeforeunload = configuration ?
            e => t("ConfigurationWillBeLost") :
            null;
    }, [configuration]);

    const missingLicense = alarmSystem &&
        alarmSystem.license.installerLicenseLevel < constants.INSTALLER_LICENSE_LEVEL_CONFIGURATION;

    return (
        <React.Fragment>
            <Prompt when={!!configuration} message={t("ConfigurationWillBeLost")} />

            <Container>
                <Breadcrumbs style={{ marginTop: 20 }}>
                    <Link color="inherit" component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS}>{t("AlarmSystems")}</Link>
                    {
                        alarmSystem ?
                            <Link color="inherit" component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS_DETAILS__ID.replace(":id", alarmSystem.id)}>
                                {alarmSystem.name}
                            </Link> :
                            null
                    }
                    <Typography color="textPrimary">{t("Config")}</Typography>
                </Breadcrumbs>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Container>
            {
                missingLicense ?
                    <Container>
                        <MissingLicense alarmSystem={alarmSystem} isInstaller={true}
                            level={constants.INSTALLER_LICENSE_LEVEL_CONFIGURATION} />
                    </Container> :
                    <React.Fragment>
                        {
                            alarmSystem ?
                                //<StickyTop>
                                <Container>

                                    <AppBar color="inherit" position="static">
                                        {isMobile ? <ToolbarSmall /> : <ToolbarFull />}
                                    </AppBar>

                                </Container>
                                //</StickyTop>
                                :
                                null
                        }

                        {generalErrorText ? <Container><Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert></Container> : null}
                        {/*{configuration && configuration.errorParameterIndices.length ? <Container><Alert style={{ marginTop: 10 }} severity="error">{JSON.stringify(configuration.errorParameterIndices)}</Alert></Container> : null}
            {
                configuration && configuration.errorParameterIndices.length &&
                <Container>
                    <Alert style={{ marginTop: 10 }} severity="error"
                        action={<Button color="inherit" size="small" onClick={() => openErrorParametersDialog(true)}>{t("View")}</Button>}>
                        {t("ErrorParametersMessage", { first: configuration.errorParameterIndices.length })}
                    </Alert>
                </Container>
            }
            {
                configuration && configuration.excludedParameterIndices.length &&
                <Container>
                    <Alert style={{ marginTop: 10 }} severity="warning"
                        action={<Button color="inherit" size="small" onClick={() => openExcludedParametersDialog(true)}>{t("View")}</Button>}>
                        {t("ExcludedParametersMessage", { first: configuration.excludedParameterIndices.length})}
                    </Alert>
                </Container>
            }*/}

                        {
                            alarmSystem && configuration ?
                                <PagesView
                                    browseConfiguration={browseConfiguration}
                                    updateValue={updateValue}
                                    configuration={configuration}
                                    pageId={pageId}
                                    tableId={tableId}
                                    rowId={rowId}
                                    cellId={cellId}
                                    disableCode={false} /> :
                                <Container>
                                    <Alert style={{ marginTop: 20 }} severity="warning">{t("NoConfigToShow")}</Alert>
                                </Container>
                        }

                        {
                            alarmSystem ?
                                <React.Fragment>
                                    <PairDialog />
                                    <UnpairDialog />
                                    <ClearDialog />
                                    <ReadDialog />
                                    <WriteDialog />
                                    <LoadTemplateDialog />
                                    <ParameterErrorsDialog
                                        browseConfiguration={browseConfiguration}
                                        openParameterErrorsDialog={openParameterErrorsDialog}
                                        configuration={configuration}
                                        parameterErrorsDialog={parameterErrorsDialog} />
                                    <SaveAsTemplateDialog />
                                </React.Fragment> :
                                null
                        }
                    </React.Fragment>
            }

        </React.Fragment>
    );

    //return (
    //    <div>
    //        <PairDialog />
    //        <LoadTemplateDialog />

    //        <Container>
    //            <div style={{ height: "10px" }} />

    //            <ButtonGroup color="primary" variant="contained">
    //                <Button disabled={dirtyParameterCount} onClick={() => openPairDialog()}>Pair</Button>
    //                <Button disabled={dirtyParameterCount} onClick={() => openLoadTemplateDialog()}>Load template</Button>
    //            </ButtonGroup>
    //            {/*<AppBar position="sticky">
    //                <Toolbar>
    //                    {
    //                        <Toolbar>
    //                            <Button disabled={dirtyParameterCount} color="inherit" onClick={() => openPairDialog()}>Pair</Button>
    //                            <Button disabled={dirtyParameterCount} color="inherit" onClick={() => openLoadTemplateDialog()}>Load template</Button>
    //                        </Toolbar>
    //                    }
    //                </Toolbar>
    //            </AppBar>
    //            {
    //            dirtyParameterCount ?
    //                <ButtonGroup>
    //                    <Button onClick={() => updateParameters(configurationData.alarmSystem.id, configurationData.dirtyParameters, history, token, unsetUser)}>Save</Button>
    //                    <Button onClick={() => cancelUpdateParameters()}>Cancel</Button>
    //                </ButtonGroup> :
    //                <ButtonGroup>
    //                    <Button onClick={() => openPairDialog()}>Pair</Button>
    //                    <Button onClick={() => openLoadTemplateDialog()}>Load template</Button>
    //                </ButtonGroup>
    //        }*/}

    //            <div>
    //                <h4>{generalErrorText}</h4>
    //            </div>

    //            <h4>Modified count: {configurationData.dirtyParameters.length}</h4>

    //            <div style={{ height: "10px" }} />
    //            <div>
    //                <TextField disabled
    //                    type="text"
    //                    label="MAC"
    //                    value={configurationData.alarmSystem.mac} />
    //            </div>

    //            <div>
    //                {loading ? <CircularProgress /> : null}
    //            </div>
    //        </Container>

    //        <PagesView />

    //    </div>
    //);
}

export default Configuration;