import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, List, ListItem, ListItemText, DialogContent, DialogActions, Button } from '@material-ui/core';
import { openSelectLanguageDialog } from '../../actions/topMenu';
import { useTranslation } from 'react-i18next';

/*const LANGUAGES = [
    { value: "en", name: "English" },
    { value: "he", name: "עברית" },
    { value: "es", name: "Español" },
    { value: "de", name: "Deutsch" },
    { value: "ru", name: "Русский" },
    { value: "el", name: "Ελληνικά" }
];*/

const LANGUAGES = [
    { value: "en", name: "English" },
    { value: "he", name: "עברית" },
    { value: "es", name: "Español" },    
    { value: "ru", name: "Русский" }
];
const SelectLanguageDialog = () => {

    const selectLanguageDialog = useSelector(state => state.topMenu.selectLanguageDialog);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const languages = i18n.languages;
    const language = languages[0];

    return <Dialog open={selectLanguageDialog.isOpen} fullWidth maxWidth="xs"
        onClose={() => dispatch(openSelectLanguageDialog(false))}>
        {/*<DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>*/}
        <DialogContent>
            <List style={{ margin: 20 }}>
                {LANGUAGES.map((l, i) => (
                    <ListItem key={i} button
                        selected={l.value === language}
                        onClick={() => {
                            i18n.changeLanguage(l.value);
                            dispatch(openSelectLanguageDialog(false));
                        }}>
                        <ListItemText primary={l.name} />
                    </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => dispatch(openSelectLanguageDialog(false))} color="primary">
                {t("Close")}
            </Button>
        </DialogActions>
    </Dialog>
}

export default SelectLanguageDialog;