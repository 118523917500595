import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';

export function getWebAccountTemplates(history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getWebAccountTemplates(null, token, language, (templates) => {
            dispatch({
                type: constants.ACTION_TYPE_TEMPLATES__GET_WEB_ACCOUNT_TEMPLATES_SUCCESS,
                data: { templates }
            });
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //AlarmSystemNotFound = 4
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_TEMPLATES__GET_WEB_ACCOUNT_TEMPLATES_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}
