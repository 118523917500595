import * as constants from '../constants';
import network from '../network';
import { validateEmail, validatePassword, validatePhoneNumber } from '../validators';
import { setLoading } from './global';

export function updateFormEmail(email, t) {
    return (dispatch) => {
        let formData = {
            email: email,
            emailErrorText: null,
            emailValid: false
        };
        if (!email)
            formData.emailErrorText = "*";
        else if (!validateEmail(email))
            formData.emailErrorText = t("ErrorEmailInvalid");
        else
            formData.emailValid = true;
        dispatch({ type: constants.ACTION_TYPE_FORGOT_PASSWORD__UPDATE_FORM_DATA, data: { formData } });
    }
}

export function sendResetPasswordEmail(email, history, location, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        const callbackUrl = `${location.origin}${constants.ROUTE_ACCOUNT_RESET_PASSWORD}`;
        network.sendResetPasswordEmail(email, callbackUrl, language, () => {
            dispatch({ type: constants.ACTION_TYPE_FORGOT_PASSWORD__SEND_RESET_PASSWORD_EMAIL_SUCCESS });
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_FORGOT_PASSWORD_CONFIRM);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null,
                formData: {
                    emailErrorText: null,
                    emailValid: true,
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //EmailRequired = 4,
                        case 4:
                            data.formData.emailErrorText = "*";
                            data.formData.emailValid = false;
                            break;
                        //InvalidEmail = 5,
                        case 5:
                            data.formData.emailErrorText = t("ErrorEmailInvalid");
                            data.formData.emailValid = false;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_FORGOT_PASSWORD__SEND_RESET_PASSWORD_EMAIL_FAIL,
                data
            });
            dispatch(setLoading(false));
        });
    }
}