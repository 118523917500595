import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    dataViewNames: [],
    languages: [],
    formData: {
        name: '',
        nameErrorText: null,
        nameValid: false,
        dataViewName: '',
        dataViewNameErrorText: null,
        dataViewNameValid: false,
        language: '',
        languageErrorText: null,
        languageValid: false,
        isValid: false
    }
};

const addTemplate = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {

        case constants.ACTION_TYPE_ADD_TEMPLATE__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.nameValid &&
                            newState.formData.dataViewNameValid &&
                            newState.formData.languageValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_ADD_TEMPLATE__GET_DEFAULT_TEMPLATES_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                dataViewNames: { $set: action.data.dataViewNames },
                languages: { $set: action.data.languages }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ADD_TEMPLATE__GET_DEFAULT_TEMPLATES_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                dataViewNames: { $set: [] },
                languages: { $set: [] },
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_ADD_TEMPLATE__ADD_TEMPLATE_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ADD_TEMPLATE__ADD_TEMPLATE_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.nameValid &&
                            newState.formData.dataViewNameValid &&
                            newState.formData.languageValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default addTemplate;