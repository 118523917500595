import * as constants from '../constants';
import network from '../network';
import { setLoading } from './global';

export function getLicenses(searchText, sort, alarmSystemId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getAllLicenses(token, language, (licenses) => {
            dispatch({
                type: constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_LICENSES_SUCCESS,
                data: { licenses }
            });
            dispatch(getAlarmSystems(licenses, searchText, sort, alarmSystemId, history, token, unsetUser, t, language));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_LICENSES_FAIL, data });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}


function getAlarmSystems(licenses, searchText, sort, alarmSystemId, history, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.getAllAlarmSystems(token, language, (alarmSystems) => {
            dispatch({
                type: constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_SUCCESS,
                data: { alarmSystems }
            });
            dispatch(updateDisplayAlarmSystems(alarmSystems, licenses, searchText, sort, alarmSystemId, t));
            dispatch(setLoading(false));
        }, (errorCodes) => {
            let data = {
                generalErrorText: null
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({ type: constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__GET_ALARM_SYSTEMS_FAIL, data });
            dispatch(updateDisplayAlarmSystems([], licenses, searchText, sort, alarmSystemId, t));
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

export function updateDisplayAlarmSystems(alarmSystems, licenses, searchText, sort, alarmSystemId, t) {
    return dispatch => {
        const macLicenseLookup = {};
        for (let l of licenses) {
            macLicenseLookup[l.mac] = l;
        }
        console.log("macLicenseLookup", macLicenseLookup);
        const searchTextLowerCase = searchText ? searchText.toLowerCase() : null;
        let newAlarmSystems = searchText ? alarmSystems.filter(a => {
            //const re = new RegExp(`${searchText}`, "i");
            //return re.test(n.name) ||
            //    re.test(n.mac) ||
            //    re.test(notificationTypeLookup[n.notificationType]) ||
            //    re.test(n.message);

            //let status = !u.active ? t("UserNotActive") :
            //    (!u.emailConfirmed ? t("EmailNotConfirmed") : t("Active"));

            return a.name.toLowerCase().includes(searchTextLowerCase) ||
                a.mac.toLowerCase().includes(searchTextLowerCase);
        }) : [...alarmSystems];
        newAlarmSystems = newAlarmSystems.sort((a, b) => {
            switch (sort.by) {
                case constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC:
                    return a.mac.localeCompare(b.mac);
                case constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_NAME:
                default:
                    return a.name.localeCompare(b.name);
            }
        });
        if (sort.direction === "desc")
            newAlarmSystems = newAlarmSystems.reverse();

        let newAlarmSystemId;
        if (alarmSystemId < 0)
            newAlarmSystemId = 0;
        else if (alarmSystemId > newAlarmSystems.length - 1)
            newAlarmSystemId = newAlarmSystems.length - 1;
        else
            newAlarmSystemId = alarmSystemId;

        dispatch({
            type: constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEMS__UPDATE_DISPLAY_ALARM_SYSTEMS,
            data: {
                displayAlarmSystems: {
                    alarmSystems: newAlarmSystems,
                    alarmSystemId: newAlarmSystemId,
                    searchText,
                    sort
                }
            }
        });
    }
}