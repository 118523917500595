import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, FormControl
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { pair, openPairDialog, updatePairFormPairingCode } from '../../actions/configuration';
import equal from 'fast-deep-equal';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';

const PairDialog = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    const pairDialog = useSelector(state => state.configuration.pairDialog);
    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    if (!alarmSystem)
        return null;

    return (
        <Dialog
            disableBackdropClick={loading}
            open={pairDialog.isOpen}
            onClose={() => dispatch(openPairDialog(false))}>
            <DialogTitle>{t("Pair")}</DialogTitle>
            <DialogContent>

                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

                <div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                        <TextField
                            disabled={loading}
                            autoFocus
                            fullWidth
                            type="text"
                            value={pairDialog.formData.pairingCode}
                            label={t("PairCode")}
                            error={!!pairDialog.formData.pairingCodeErrorText}
                            helperText={pairDialog.formData.pairingCodeErrorText}
                            onChange={event => dispatch(updatePairFormPairingCode(event.target.value, t))}
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openPairDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={loading || !pairDialog.formData.isValid}
                    onClick={() => dispatch(pair(alarmSystem.id, pairDialog.formData.pairingCode, history, token, unsetUser, t, language))}
                    color="primary">
                    {t("Pair")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PairDialog;