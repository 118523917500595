import * as constants from '../constants';
import network from '../network';
import { validateEmail, validatePassword, validatePhoneNumber } from '../validators';
import { setLoading } from './global';

export function updateFormFirstName(firstName, t) {
    return (dispatch) => {
        let formData = {
            firstName: firstName,
            firstNameErrorText: null,
            firstNameValid: false
        };
        if (!firstName)
            formData.firstNameErrorText = "*";
        else
            formData.firstNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormLastName(lastName, t) {
    return (dispatch) => {
        let formData = {
            lastName: lastName,
            lastNameErrorText: null,
            lastNameValid: false
        };
        if (!lastName)
            formData.lastNameErrorText = "*";
        else
            formData.lastNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormPhoneNumber(phoneNumber, t) {
    return (dispatch) => {
        let formData = {
            phoneNumber: phoneNumber,
            phoneNumberErrorText: null,
            phoneNumberValid: false
        };
        if (phoneNumber && !validatePhoneNumber(phoneNumber))
            formData.phoneNumberErrorText = t("ErrorPhoneNumberInvalidCharacters");
        else
            formData.phoneNumberValid = true;
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormEmail(email, t) {
    return (dispatch) => {
        let formData = {
            email: email,
            emailErrorText: null,
            emailValid: false
        };
        if (!email)
            formData.emailErrorText = "*";
        else if (!validateEmail(email))
            formData.emailErrorText = t("ErrorEmailInvalid");
        else
            formData.emailValid = true;
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormPassword(password, t) {
    return (dispatch) => {
        let formData = {
            password: password,
            passwordErrorText: null,
            passwordValid: false
        };
        if (!password)
            formData.passwordErrorText = "*";
        else if (!validatePassword(password))
            formData.passwordErrorText = t("PasswordInstructions");
        else
            formData.passwordValid = true;
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormConfirmPassword(password, confirmPassword, t) {
    return (dispatch) => {
        let formData = {
            confirmPassword: confirmPassword,
            confirmPasswordErrorText: null,
            confirmPasswordValid: false
        };
        if (!confirmPassword)
            formData.confirmPasswordErrorText = "*";
        else if (password !== confirmPassword)
            formData.confirmPasswordErrorText = t("ErrorPasswordConfirmMismatch");
        else
            formData.confirmPasswordValid = true;
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormCountry(country, t) {
    return (dispatch) => {
        let formData = {
            country: country,
            countryErrorText: null,
            countryValid: false
        };
        if (!country)
            formData.countryErrorText = "*";
        else
            formData.countryValid = true;
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormState(state, t) {
    return (dispatch) => {
        let formData = {
            state: state,
            stateErrorText: null,
            stateValid: true
        };
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormCity(city, t) {
    return (dispatch) => {
        let formData = {
            city: city,
            cityErrorText: null,
            cityValid: true
        };
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormCompany(a_Company, t) {
    return (dispatch) => {
        let formData = {
            Company: a_Company          
        };
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormBusinessNumber(a_BusinessNumber, t) {
    return (dispatch) => {
        let formData = {
            BusinessNumber: a_BusinessNumber,
        };
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormAddress(address, t) {
    return (dispatch) => {
        let formData = {
            address: address,
            addressErrorText: null,
            addressValid: true
        };
        dispatch({ type: constants.ACTION_TYPE_REGISTER__UPDATE_FORM_DATA, data: { formData } });
    }
}

export function register(firstName, lastName, phoneNumber, email, password, confirmPassword, country, state, city, address,Company, BusinessNumber, history, location, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        const callbackUrl = `${location.origin}${constants.ROUTE_ACCOUNT_CONFIRM_EMAIL}`;
        network.register(firstName, lastName, phoneNumber, email, password, confirmPassword, country, state, city, address, Company, BusinessNumber, callbackUrl, language, () => {
            dispatch({ type: constants.ACTION_TYPE_REGISTER__REGISTER_SUCCESS });
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_REGISTER_CONFIRM);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null,
                formData: {
                    firstNameErrorText: null,
                    firstNameValid: true,
                    lastNameErrorText: null,
                    lastNameValid: true,
                    phoneNumberErrorText: null,
                    phoneNumberValid: true,
                    emailErrorText: null,
                    emailValid: true,
                    passwordErrorText: null,
                    passwordValid: true,
                    confirmPasswordErrorText: null,
                    confirmPasswordValid: true,                    
                    countryErrorText: null,
                    countryValid: true,
                    stateErrorText: null,
                    stateValid: true,
                    cityErrorText: null,
                    cityValid: true,
                    addressErrorText: null,
                    addressValid: true,
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //EmailRequired = 4,
                        case 4:
                            data.formData.emailErrorText = "*";
                            data.formData.emailValid = false;
                            break;
                        //PasswordRequired = 5,
                        case 5:
                            data.formData.passwordErrorText = "*";
                            data.formData.passwordValid = false;
                            break;
                        //ConfirmPasswordRequired = 6,
                        case 6:
                            data.formData.confirmPasswordErrorText = "*";
                            data.formData.confirmPasswordValid = false;
                            break;
                        //FirstNameRequired = 7,
                        case 7:
                            data.formData.firstNameErrorText = "*";
                            data.formData.firstNameValid = false;
                            break;
                        //LastNameRequired = 8,
                        case 8:
                            data.formData.lastNameErrorText = "*";
                            data.formData.lastNameValid = false;
                            break;
                        //CountryRequired = 9,
                        case 9:
                            data.formData.countryErrorText = "*";
                            data.formData.countryValid = false;
                            break;
                        //EmailAlreadyExists = 10,
                        case 10:
                            data.generalErrorText = t("ErrorIdentityDuplicateUserName", { first: email });
                            break;
                        //InvalidEmail = 11,
                        case 11:
                            data.formData.emailErrorText = t("ErrorEmailInvalid");
                            data.formData.emailValid = false;
                            break;
                        //InvalidPhoneNumber = 12,
                        case 12:
                            data.formData.phoneNumberErrorText = t("ErrorPhoneNumberInvalidCharacters");
                            data.formData.phoneNumberValid = false;
                            break;
                        //ConfirmPasswordMismatch = 13
                        case 13:
                            data.formData.confirmPasswordErrorText = t("ErrorPasswordConfirmMismatch");
                            data.formData.confirmPasswordValid = false;
                            break;
                        //InvalidEmailForInvoice = 14
                      
                        //InvalidCountryCode = 15
                        case 15:
                            data.formData.countryErrorText = t("ErrorCountryCodeInvalid");
                            data.formData.countryValid = false;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_REGISTER__REGISTER_FAIL,
                data
            });
            dispatch(setLoading(false));
        });
    }
}