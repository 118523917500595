import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, Tabs, Tab, AppBar, IconButton,
    FormControl, InputLabel, Select, MenuItem, FormHelperText, List, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Visibility as VisibilityIcon, Warning as WarningIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';

const ParameterErrorsDialog = ({ openParameterErrorsDialog, browseConfiguration, configuration, parameterErrorsDialog }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (configuration) {
            const allowedTabIndices = [];
            if (configuration.validationErrorParameterIndices.length)
                allowedTabIndices.push(0);
            if (configuration.errorParameterIndices.length)
                allowedTabIndices.push(1);
            if (configuration.excludedParameterIndices.length)
                allowedTabIndices.push(2);

            if (allowedTabIndices.length && !allowedTabIndices.includes(tabIndex))
                setTabIndex(allowedTabIndices[0]);
        }
    }, [configuration]);

    if (!configuration)
        return null;

    return (
        <Dialog maxWidth="sm" fullWidth
            disableBackdropClick={loading}
            open={parameterErrorsDialog.isOpen}
            onClose={() => dispatch(openParameterErrorsDialog(false))}>
            <DialogTitle>{t("ParameterErrors")}</DialogTitle>
            <DialogContent style={{ height: 360 }}>

                <AppBar color="inherit" position="static">
                    <Tabs variant="scrollable" value={tabIndex} onChange={(event, value) => setTabIndex(value)}>
                        {
                            configuration.validationErrorParameterIndices.length ?
                                <Tab label={t("ValidationParameterErrors")} disabled={loading} /> :
                                null
                        }
                        {
                            configuration.errorParameterIndices.length ?
                                <Tab label={t("ParameterErrors")} disabled={loading} /> :
                                null
                        }
                        {
                            configuration.excludedParameterIndices.length ?
                                <Tab label={t("ExcludedParameters")} disabled={loading} /> :
                                null
                        }
                    </Tabs>
                </AppBar>

                <div style={{ marginTop: 20 }}>
                    {
                        tabIndex === 0 ?

                            <List component="nav">
                                {
                                    configuration.validationErrorParameterIndices.map(i => {
                                        const parameter = configuration.parameters[i];
                                        const page = configuration.pages[parameter.pageId];
                                        const table = configuration.tables[parameter.tableId];
                                        const path = `${page.title}/${table.title || page.title}`;
                                        return <ListItem button key={i}
                                            onClick={() => {
                                                dispatch(browseConfiguration(history, id, parameter.pageId, parameter.tableId, parameter.rowId, parameter.cellId));
                                                dispatch(openParameterErrorsDialog(false));
                                            }}>
                                            <ListItemIcon>
                                                <WarningIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={path} secondary={parameter.validationErrorText} />

                                        </ListItem>
                                    }
                                    )
                                }
                            </List> :
                            (
                                tabIndex === 1 ?
                                    <List component="nav">
                                        {
                                            configuration.errorParameterIndices.map(i => {
                                                const parameter = configuration.parameters[i];
                                                const page = configuration.pages[parameter.pageId];
                                                const table = configuration.tables[parameter.tableId];
                                                const path = `${page.title}/${table.title || page.title}`;
                                                return <ListItem button key={i}
                                                    onClick={() => {
                                                        dispatch(browseConfiguration(history, id, parameter.pageId, parameter.tableId, parameter.rowId, parameter.cellId))
                                                        dispatch(openParameterErrorsDialog(false))
                                                    }}>
                                                    <ListItemIcon>
                                                        <WarningIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={path} secondary={parameter.errorText} />

                                                </ListItem>
                                            }
                                            )
                                        }
                                    </List> :
                                    <List component="nav">
                                        {
                                            configuration.excludedParameterIndices.map(i => {
                                                const parameter = configuration.parameters[i];
                                                const page = configuration.pages[parameter.pageId];
                                                const table = configuration.tables[parameter.tableId];
                                                const path = `${t(page.title)}/${t(table.title || page.title)}`;
                                                return <ListItem button key={i}
                                                    onClick={() => {
                                                        dispatch(browseConfiguration(history, id, parameter.pageId, parameter.tableId, parameter.rowId, parameter.cellId))
                                                        dispatch(openParameterErrorsDialog(false))
                                                    }}>
                                                    <ListItemIcon>
                                                        <WarningIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={path} secondary={t("Excluded")} />

                                                </ListItem>
                                            }
                                            )
                                        }
                                    </List>
                            )
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(openParameterErrorsDialog(false))} color="primary" autoFocus>{t("Close")}</Button>
                {/*<Button disabled={loading} onClick={() => dispatch(openLoadTemplateDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                {
                    tabIndex === 0 ?
                        <Button disabled={loading || !loadTemplateDialog.formData.isDefaultTemplateValid}
                            onClick={() => dispatch(loadDefaultTemplate(alarmSystem.id, loadTemplateDialog.formData.dataViewName, loadTemplateDialog.formData.language, history, token, unsetUser, t))}
                            color="primary">
                            {t("LoadFromTemplate")}
                        </Button> :
                        <Button disabled={loading || !loadTemplateDialog.formData.isWebAccountTemplateValid}
                            onClick={() => dispatch(loadWebAccountTemplate(alarmSystem.id, loadTemplateDialog.formData.templateId, history, token, unsetUser, t))}
                            color="primary">
                            {t("LoadFromTemplate")}
                        </Button>
                }*/}
            </DialogActions>
        </Dialog>
    );
}

export default ParameterErrorsDialog;