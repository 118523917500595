import React from 'react';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, Container, Breadcrumbs, Typography, Divider, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { sendResetPasswordEmail, updateFormEmail } from '../../actions/forgotPassword';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';

const ForgotPassword = () => {
    const { t, i18n } = useTranslation();
    const loading = useSelector(state => state.global.loading);
    const formData = useSelector(state => state.forgotPassword.formData);
    const generalErrorText = useSelector(state => state.forgotPassword.generalErrorText);
    const history = useHistory();
    const location = window.location;
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("ForgotPassword")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <Typography style={{ marginTop: 10 }} variant="subtitle1">{t("ForgotPasswordSubtitle")}</Typography>

            <form noValidate={true}>
                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        type="email"
                        error={!!formData.emailErrorText}
                        label={t("EMail")}
                        helperText={formData.emailErrorText}
                        value={formData.email}
                        onChange={event => dispatch(updateFormEmail(event.target.value, t))} />
                </div>

                <div style={{ marginTop: 10 }}>
                    <Button type="submit" variant="contained" color="primary" disabled={loading || !formData.isValid} onClick={() =>
                        dispatch(sendResetPasswordEmail(formData.email, history, location, t, language))}>{t("Submit")}</Button>
                </div>
            </form>
        </Container>
    );
}

export default ForgotPassword;