import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    //installerLicenseOptions: [],
    //userLicenseOptions: [],
    licenseOptions: null,
    alarmSystem: null,
    license: null,
    vat: 0,
    //dataViewNames: [],
    formData: {
        //id: null,
        //webAccountId: null,

        // alarm system details
        name: '',
        nameErrorText: null,
        nameValid: false,
        //mac: '',
        //macErrorText: null,
        //macValid: false,
        //dataViewName: '',
        //dataViewNameErrorText: null,
        //dataViewNameValid: false,

        // owner details
        ownerFirstName: '',
        ownerFirstNameErrorText: null,
        ownerFirstNameValid: true,
        ownerLastName: '',
        ownerLastNameErrorText: null,
        ownerLastNameValid: true,
        ownerCompany: '',
        ownerCompanyErrorText: null,
        ownerCompanyValid: true,
        ownerPhoneNumber1: '',
        ownerPhoneNumber1ErrorText: null,
        ownerPhoneNumber1Valid: true,
        ownerPhoneNumber2: '',
        ownerPhoneNumber2ErrorText: null,
        ownerPhoneNumber2Valid: true,
        ownerEmail1: '',
        ownerEmail1ErrorText: null,
        ownerEmail1Valid: true,
        ownerEmail2: '',
        ownerEmail2ErrorText: null,
        ownerEmail2Valid: true,
        //ownerAddress: {
        ownerAddressCountry: '',
        ownerAddressCountryErrorText: null,
        ownerAddressCountryValid: true,
        ownerAddressState: '',
        ownerAddressStateErrorText: null,
        ownerAddressStateValid: true,
        ownerAddressCity: '',
        ownerAddressCityErrorText: null,
        ownerAddressCityValid: true,
        ownerAddressAddress: '',
        ownerAddressAddressErrorText: null,
        ownerAddressAddressValid: true,
        //},

        // installation details
        //installationAddress: {
        installationAddressCountry: '',
        installationAddressCountryErrorText: null,
        installationAddressCountryValid: true,
        installationAddressState: '',
        installationAddressStateErrorText: null,
        installationAddressStateValid: true,
        installationAddressCity: '',
        installationAddressCityErrorText: null,
        installationAddressCityValid: true,
        installationAddressAddress: '',
        installationAddressAddressErrorText: null,
        installationAddressAddressValid: true,
        //},

        // license
        //license: {
        //    purchasingDate: null,
        //    activationDate: null,
        //    billingDate: null,
        //    expirationDate: null,
        //    userLicenseType: null,
        //    installerLicenseType: null,
        //    userLicenseCostPerMonth: null,
        //    installerLicenseCostPerMonth: null
        //},

        // monitoring types
        //notificationTypes: [],
        //monitoringTypes: {
        //    burglary: false,
        //    panic: false,
        //    fire: false,
        //    duress: false,
        //    medical: false,
        //    tamper: false,
        //    faults: false,
        //    armingDisarming: false,
        //    alarmRestore: false,
        //    general: false,
        //    partition1: false,
        //    partition2: false,
        //    partition3: false,
        //    partition4: false,
        //    partition5: false,
        //    partition6: false,
        //    partition7: false,
        //    partition8: false,
        //    partition9: false,
        //    partition10: false,
        //    partition11: false,
        //    partition12: false,
        //    partition13: false,
        //    partition14: false,
        //    partition15: false,
        //    partition16: false,
        //},
        // pairing
        //pairing: {
        //    VersionLine: null,
        //    pairId: null,
        //    esn: null,
        //    pairDate: null
        //},
        //installerLicenseLevel: 0,
        //installerLicenseLevelErrorText: null,
        //installerLicenseLevelValid: true,
        //installerLicenseRequiresPaymentMethod: false,
        //userLicenseLevel: 0,
        //userLicenseLevelErrorText: null,
        //userLicenseLevelValid: true,
        //userLicenseRequiresPaymentMethod: false,
        isValid: false,
        isDirty: false
    },
    deleteDialog: {
        isOpen: false
    },
    changeMacDialog: {
        isOpen: false,
        formData: {
            mac: '',
            macErrorText: null,
            macValid: false,
            isValid: false,
            isDirty: false
        }
    },
    licenseDialog: {
        isOpen: false,
        permission: null
    },
    licenseTranzilaDialog: {
        isOpen: false,
        //isfree_2311: false,
        permission: null
    }
};

const editAlarmSystem = (state = INITIAL_STATE, action) => {

    let newState;
    let a;
    switch (action.type) {
        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__OPEN_DELETE_DIALOG:
            newState = update(state, {
                deleteDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__OPEN_CHANGE_MAC_DIALOG:
            newState = update(state, {
                changeMacDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        //case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_SUCCESS:
        //    newState = update(state, {
        //        generalErrorText: { $set: null },
        //        dataViewNames: { $set: action.data.dataViewNames }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        //case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_FAIL:
        //    newState = update(state, {
        //        generalErrorText: { $set: action.data.generalErrorText },
        //        dataViewNames: { $set: [] }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_LICENSE_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                //installerLicenseOptions: { $set: action.data.installerLicenseOptions },
                //userLicenseOptions: { $set: action.data.userLicenseOptions }
                license: { $set: action.data.license },
                vat: { $set: action.data.vat }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_LICENSE_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                //installerLicenseOptions: { $set: null },
                //userLicenseOptions: { $set: null }
                license: { $set: null },
                vat: { $set: 0 }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_ALARM_SYSTEM_SUCCESS:
            a = action.data.alarmSystem;
            newState = update(state, {
                generalErrorText: { $set: null },
                alarmSystem: { $set: a },
                formData: {
                    $set: {
                        //id: null,
                        //webAccountId: null,

                        // alarm system details
                        name: a.name || '',
                        nameErrorText: null,
                        nameValid: true,
                        //mac: a.mac || '',
                        //macErrorText: null,
                        //macValid: true,
                        //dataViewName: a.dataViewName || '',
                        //dataViewNameErrorText: null,
                        //dataViewNameValid: true,

                        // owner details
                        ownerFirstName: a.ownerFirstName || '',
                        ownerFirstNameErrorText: null,
                        ownerFirstNameValid: true,
                        ownerLastName: a.ownerLastName || '',
                        ownerLastNameErrorText: null,
                        ownerLastNameValid: true,
                        ownerCompany: a.ownerCompany || '',
                        ownerCompanyErrorText: null,
                        ownerCompanyValid: true,
                        ownerPhoneNumber1: a.ownerPhoneNumber1 || '',
                        ownerPhoneNumber1ErrorText: null,
                        ownerPhoneNumber1Valid: true,
                        ownerPhoneNumber2: a.ownerPhoneNumber2 || '',
                        ownerPhoneNumber2ErrorText: null,
                        ownerPhoneNumber2Valid: true,
                        ownerEmail1: a.ownerEmail1 || '',
                        ownerEmail1ErrorText: null,
                        ownerEmail1Valid: true,
                        ownerEmail2: a.ownerEmail2 || '',
                        ownerEmail2ErrorText: null,
                        ownerEmail2Valid: true,
                        //ownerAddress: {
                        ownerAddressCountry: a.ownerAddressCountry || '',
                        ownerAddressCountryErrorText: null,
                        ownerAddressCountryValid: true,
                        ownerAddressState: a.ownerAddressState || '',
                        ownerAddressStateErrorText: null,
                        ownerAddressStateValid: true,
                        ownerAddressCity: a.ownerAddressCity || '',
                        ownerAddressCityErrorText: null,
                        ownerAddressCityValid: true,
                        ownerAddressAddress: a.ownerAddressAddress || '',
                        ownerAddressAddressErrorText: null,
                        ownerAddressAddressValid: true,
                        //},

                        // installation details
                        //installationAddress: {
                        installationAddressCountry: a.installationAddressCountry || '',
                        installationAddressCountryErrorText: null,
                        installationAddressCountryValid: true,
                        installationAddressState: a.installationAddressState || '',
                        installationAddressStateErrorText: null,
                        installationAddressStateValid: true,
                        installationAddressCity: a.installationAddressCity || '',
                        installationAddressCityErrorText: null,
                        installationAddressCityValid: true,
                        installationAddressAddress: a.installationAddressAddress || '',
                        installationAddressAddressErrorText: null,
                        installationAddressAddressValid: true,
                        //},

                        // license
                        //license: {
                        //    purchasingDate: null,
                        //    activationDate: null,
                        //    billingDate: null,
                        //    expirationDate: null,
                        //    userLicenseType: null,
                        //    installerLicenseType: null,
                        //    userLicenseCostPerMonth: null,
                        //    installerLicenseCostPerMonth: null
                        //},

                        // monitoring types
                        //notificationTypes: a.notificationTypes,
                        // pairing
                        //pairing: {
                        //    VersionLine: null,
                        //    pairId: null,
                        //    esn: null,
                        //    pairDate: null
                        //},
                        //installerLicenseLevel: a.license.installerLicenseLevel,
                        //installerLicenseLevelErrorText: null,
                        //installerLicenseLevelValid: true,
                        //userLicenseLevel: a.license.userLicenseLevel,
                        //userLicenseLevelErrorText: null,
                        //userLicenseLevelValid: true,
                        isValid: true,
                        isDirty: false
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_ALARM_SYSTEM_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                alarmSystem: { $set: null }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isDirty: { $set: true },
                    isValid: {
                        $set: newState.formData.nameValid &&
                            //newState.formData.macValid &&
                            //newState.formData.dataViewNameValid &&
                            newState.formData.ownerFirstNameValid &&
                            newState.formData.ownerLastNameValid &&
                            newState.formData.ownerCompanyValid &&
                            newState.formData.ownerPhoneNumber1Valid &&
                            newState.formData.ownerPhoneNumber2Valid &&
                            newState.formData.ownerEmail1Valid &&
                            newState.formData.ownerEmail2Valid &&
                            newState.formData.ownerAddressCountryValid &&
                            newState.formData.ownerAddressStateValid &&
                            newState.formData.ownerAddressCityValid &&
                            newState.formData.ownerAddressAddressValid &&
                            newState.formData.installationAddressCountryValid &&
                            newState.formData.installationAddressStateValid &&
                            newState.formData.installationAddressCityValid &&
                            newState.formData.installationAddressAddressValid
                            //newState.formData.installerLicenseLevelValid &&
                            //newState.formData.userLicenseLevelValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_CHANGE_MAC_FORM_DATA:
            newState = update(state, {
                changeMacDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                changeMacDialog: {
                    formData: {
                        isDirty: { $set: true },
                        isValid: {
                            $set: newState.changeMacDialog.formData.macValid
                        }
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_ALARM_SYSTEM_SUCCESS:
            a = action.data.alarmSystem;
            newState = update(state, {
                generalErrorText: { $set: null },
                alarmSystem: { $set: a },
                formData: {
                    $set: {
                        //id: null,
                        //webAccountId: null,

                        // alarm system details
                        name: a.name || '',
                        nameErrorText: null,
                        nameValid: true,
                        //mac: a.mac || '',
                        //macErrorText: null,
                        //macValid: true,
                        //dataViewName: a.dataViewName || '',
                        //dataViewNameErrorText: null,
                        //dataViewNameValid: true,

                        // owner details
                        ownerFirstName: a.ownerFirstName || '',
                        ownerFirstNameErrorText: null,
                        ownerFirstNameValid: true,
                        ownerLastName: a.ownerLastName || '',
                        ownerLastNameErrorText: null,
                        ownerLastNameValid: true,
                        ownerCompany: a.ownerCompany || '',
                        ownerCompanyErrorText: null,
                        ownerCompanyValid: true,
                        ownerPhoneNumber1: a.ownerPhoneNumber1 || '',
                        ownerPhoneNumber1ErrorText: null,
                        ownerPhoneNumber1Valid: true,
                        ownerPhoneNumber2: a.ownerPhoneNumber2 || '',
                        ownerPhoneNumber2ErrorText: null,
                        ownerPhoneNumber2Valid: true,
                        ownerEmail1: a.ownerEmail1 || '',
                        ownerEmail1ErrorText: null,
                        ownerEmail1Valid: true,
                        ownerEmail2: a.ownerEmail2 || '',
                        ownerEmail2ErrorText: null,
                        ownerEmail2Valid: true,
                        //ownerAddress: {
                        ownerAddressCountry: a.ownerAddressCountry || '',
                        ownerAddressCountryErrorText: null,
                        ownerAddressCountryValid: true,
                        ownerAddressState: a.ownerAddressState || '',
                        ownerAddressStateErrorText: null,
                        ownerAddressStateValid: true,
                        ownerAddressCity: a.ownerAddressCity || '',
                        ownerAddressCityErrorText: null,
                        ownerAddressCityValid: true,
                        ownerAddressAddress: a.ownerAddressAddress || '',
                        ownerAddressAddressErrorText: null,
                        ownerAddressAddressValid: true,
                        //},

                        // installation details
                        //installationAddress: {
                        installationAddressCountry: a.installationAddressCountry || '',
                        installationAddressCountryErrorText: null,
                        installationAddressCountryValid: true,
                        installationAddressState: a.installationAddressState || '',
                        installationAddressStateErrorText: null,
                        installationAddressStateValid: true,
                        installationAddressCity: a.installationAddressCity || '',
                        installationAddressCityErrorText: null,
                        installationAddressCityValid: true,
                        installationAddressAddress: a.installationAddressAddress || '',
                        installationAddressAddressErrorText: null,
                        installationAddressAddressValid: true,
                        //},

                        // license
                        //license: {
                        //    purchasingDate: null,
                        //    activationDate: null,
                        //    billingDate: null,
                        //    expirationDate: null,
                        //    userLicenseType: null,
                        //    installerLicenseType: null,
                        //    userLicenseCostPerMonth: null,
                        //    installerLicenseCostPerMonth: null
                        //},

                        // monitoring types
                        //notificationTypes: a.notificationTypes,
                        // pairing
                        //pairing: {
                        //    VersionLine: null,
                        //    pairId: null,
                        //    esn: null,
                        //    pairDate: null
                        //},
                        //installerLicenseLevel: a.license.installerLicenseLevel,
                        //installerLicenseLevelErrorText: null,
                        //installerLicenseLevelValid: true,
                        //userLicenseLevel: a.license.userLicenseLevel,
                        //userLicenseLevelErrorText: null,
                        //userLicenseLevelValid: true,
                        isValid: true,
                        isDirty: false
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__UPDATE_ALARM_SYSTEM_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.nameValid &&
                            //newState.formData.macValid &&
                            //newState.formData.dataViewNameValid &&
                            newState.formData.ownerFirstNameValid &&
                            newState.formData.ownerLastNameValid &&
                            newState.formData.ownerCompanyValid &&
                            newState.formData.ownerPhoneNumber1Valid &&
                            newState.formData.ownerPhoneNumber2Valid &&
                            newState.formData.ownerEmail1Valid &&
                            newState.formData.ownerEmail2Valid &&
                            newState.formData.ownerAddressCountryValid &&
                            newState.formData.ownerAddressStateValid &&
                            newState.formData.ownerAddressCityValid &&
                            newState.formData.ownerAddressAddressValid &&
                            newState.formData.installationAddressCountryValid &&
                            newState.formData.installationAddressStateValid &&
                            newState.formData.installationAddressCityValid &&
                            newState.formData.installationAddressAddressValid
                            //newState.formData.installerLicenseLevelValid &&
                            //newState.formData.userLicenseLevelValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__DELETE_ALARM_SYSTEM_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__DELETE_ALARM_SYSTEM_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                deleteDialog: { $set: INITIAL_STATE.deleteDialog }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__CHANGE_MAC_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                alarmSystem: { $set: action.data.alarmSystem },
                changeMacDialog: { $set: INITIAL_STATE.changeMacDialog }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__CHANGE_MAC_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                changeMacDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                changeMacDialog: {
                    formData: {
                        isValid: {
                            $set: newState.formData.macValid
                        }
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;

        default:
            return state;
    }
}

export default editAlarmSystem;