import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, Tabs, Tab, AppBar,
    FormControl, InputLabel, Select, MenuItem, FormHelperText
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    getWebAccountTemplates, getDefaultTemplates, loadDefaultTemplate, loadWebAccountTemplate, openLoadTemplateDialog,
    updateLoadTemplateFormDataViewName, updateLoadTemplateFormLanguage, updateLoadTemplateFormTemplateId
} from '../../actions/configuration';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';

const LoadTemplateDialog = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const alarmSystem = useSelector(state => state.configuration.alarmSystem);
    const loadTemplateDialog = useSelector(state => state.configuration.loadTemplateDialog);
    const generalErrorText = useSelector(state => state.configuration.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getWebAccountTemplates(id, history, token, unsetUser, t, language));
        dispatch(getDefaultTemplates(id, history, token, unsetUser, t, language));
    }, [dispatch, id, token]);

    if (!alarmSystem)
        return null;

    return (
        <Dialog maxWidth="sm" fullWidth
            disableBackdropClick={loading}
            open={loadTemplateDialog.isOpen}
            onClose={() => dispatch(openLoadTemplateDialog(false))}>
            <DialogTitle>{t("LoadFromTemplate")}</DialogTitle>
            <DialogContent style={{ height: 360 }}>
                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

                <AppBar color="inherit" position="static">
                    <Tabs variant="scrollable" value={tabIndex} onChange={(event, value) => setTabIndex(value)}>
                        <Tab label={t("BuiltInTemplates")} disabled={loading} />
                        <Tab label={t("InstallerTemplates")} disabled={loading} />
                    </Tabs>
                </AppBar>
                {
                    tabIndex === 0 ?
                        (
                            <div style={{ maxWidth: 300, marginTop: 20 }}>
                                {
                                    loadTemplateDialog.dataViewNames.length && loadTemplateDialog.languages.length ?
                                        <React.Fragment>
                                            <FormControl fullWidth style={{ marginTop: 10 }} error={!!loadTemplateDialog.formData.dataViewNameErrorText}>
                                                <InputLabel id="lblDataViewName">{t("Model")}</InputLabel>
                                                <Select
                                                    fullWidth
                                                    disabled={loading}
                                                    labelId="lblDataViewName"
                                                    value={loadTemplateDialog.formData.dataViewName}
                                                    onChange={event => dispatch(updateLoadTemplateFormDataViewName(event.target.value, t))}>
                                                    {
                                                        loadTemplateDialog.dataViewNames.map((n, i) => (<MenuItem key={i} value={n}>{n}</MenuItem>))
                                                    }
                                                </Select>
                                                <FormHelperText>{loadTemplateDialog.formData.dataViewNameErrorText}</FormHelperText>
                                            </FormControl>

                                            <FormControl fullWidth style={{ marginTop: 10 }} error={!!loadTemplateDialog.formData.languageErrorText}>
                                                <InputLabel id="lblLanguage">{t("Language")}</InputLabel>
                                                <Select
                                                    fullWidth
                                                    disabled={loading}
                                                    labelId="lblLanguage"
                                                    value={loadTemplateDialog.formData.language}
                                                    onChange={event => dispatch(updateLoadTemplateFormLanguage(event.target.value, t))}>
                                                    {
                                                        loadTemplateDialog.languages.map((l, i) => (<MenuItem key={i} value={l}>{l}</MenuItem>))
                                                    }
                                                </Select>
                                                <FormHelperText>{loadTemplateDialog.formData.languageErrorText}</FormHelperText>
                                            </FormControl>
                                        </React.Fragment> :
                                        <Alert style={{ marginTop: 10 }} severity="warning">{t("NoTemplatesToShow")}</Alert>
                                }
                            </div>
                        ) :
                        (
                            <div style={{ maxWidth: 300, marginTop: 20 }}>
                                {
                                    loadTemplateDialog.templates.length ?
                                        <FormControl fullWidth style={{ marginTop: 10 }} error={!!loadTemplateDialog.formData.templateIdErrorText}>
                                            <InputLabel id="lblTemplate">{t("Name")}</InputLabel>
                                            <Select
                                                fullWidth
                                                disabled={loading}
                                                labelId="lblTemplate"
                                                value={loadTemplateDialog.formData.templateId}
                                                onChange={event => dispatch(updateLoadTemplateFormTemplateId(event.target.value, t))}>
                                                {
                                                    loadTemplateDialog.templates.map((tm, i) => (<MenuItem key={i} value={tm.id}>{tm.name}</MenuItem>))
                                                }
                                            </Select>
                                            <FormHelperText>{loadTemplateDialog.formData.templateIdErrorText}</FormHelperText>
                                        </FormControl> :
                                        <Alert style={{ marginTop: 10 }} severity="warning">{t("NoCompatibleTemplatesToShow")}</Alert>
                                }
                            </div>
                        )
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openLoadTemplateDialog(false))} color="primary">
                    {t("Cancel")}
                </Button>
                {
                    tabIndex === 0 ?
                        <Button disabled={loading || !loadTemplateDialog.formData.isDefaultTemplateValid}
                            onClick={() => dispatch(loadDefaultTemplate(alarmSystem.id, loadTemplateDialog.formData.dataViewName, loadTemplateDialog.formData.language, history, token, unsetUser, t, language))}
                            color="primary">
                            {t("LoadFromTemplate")}
                        </Button> :
                        <Button disabled={loading || !loadTemplateDialog.formData.isWebAccountTemplateValid}
                            onClick={() => dispatch(loadWebAccountTemplate(alarmSystem.id, loadTemplateDialog.formData.templateId, history, token, unsetUser, t, language))}
                            color="primary">
                            {t("LoadFromTemplate")}
                        </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default LoadTemplateDialog;