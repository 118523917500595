import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    webAccountUser: null,
    generalErrorText: null,
    success: false,
    formData: {
        firstName: '',
        firstNameErrorText: null,
        firstNameValid: false,
        lastName: '',
        lastNameErrorText: null,
        lastNameValid: false,
        phoneNumber: '',
        phoneNumberErrorText: null,
        phoneNumberValid: true,
        password: '',
        passwordErrorText: null,
        passwordValid: false,
        confirmPassword: '',
        confirmPasswordErrorText: null,
        confirmPasswordValid: false,
        isValid: false
    }
};

const confirmEmail = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.firstNameValid && newState.formData.lastNameValid && newState.formData.phoneNumberValid &&
                            newState.formData.passwordValid && newState.formData.confirmPasswordValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__GET_WEB_ACCOUNT_USER_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccountUser: {$set: action.data.user}
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__GET_WEB_ACCOUNT_USER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                webAccountUser: {$set: null}
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__CONFIRM_EMAIL_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                success: { $set: true }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_CONFIRM_WEB_ACCOUNT_USER_EMAIL__CONFIRM_EMAIL_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default confirmEmail;