import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    license: null,
    alarmSystem: null,
    transactions: [],
    generalErrorText: null,
    displayTransactions: {
        transactions: [],
        transactionId: 0,
        searchText: '',
        sort: {
            by: constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE,
            direction: "desc"
        },
    },
};

const managementAlarmSystemTransactions = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__UPDATE_DISPLAY_TRANSACTIONS:
            newState = update(state, {
                displayTransactions: { $set: action.data.displayTransactions }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__GET_TRANSACTIONS_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                transactions: { $set: action.data.transactions },
                alarmSystem: { $set: action.data.alarmSystem },
                license: { $set: action.data.license }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS__GET_TRANSACTIONS_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                transactions: { $set: [] },
                alarmSystem: { $set: null },
                license: { $set: null }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default managementAlarmSystemTransactions;