import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useMediaQuery, Button, IconButton, TextField, Container, CircularProgress, AppBar, ButtonGroup, Typography,
    List, ListItem, ListItemText, Grid, Tabs, Tab, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Drawer, Collapse
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Warning as WarningIcon, List as ListIcon, ExpandLess, ExpandMore } from '@material-ui/icons';
import PageSidebar from './PageSidebar';
import TableViewFull from './TableViewFull';
import TableViewSplit from './TableViewSplit';
import TableViewTwoColumns from './TableViewTwoColumns';
import moment from 'moment';

const PagesView = ({ browseConfiguration, updateValue, configuration, pageId, tableId, rowId, cellId, disableCode }) => {
    const start = moment();
    const [isPageMenuOpen, setIsPageMenuOpen] = useState(false);
    //const { id, pageId: queryPageId, tableId: queryTableId, rowId: queryRowId } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    //const history = useHistory();
    const { t } = useTranslation();

    const loading = useSelector(state => state.global.loading);

    //useEffect(() => {
    //    const qPageId = queryPageId && !isNaN(queryPageId) ? Number.parseInt(queryPageId) : 0;
    //    const qTableId = queryTableId && !isNaN(queryTableId) ? Number.parseInt(queryTableId) : 0;
    //    //const qRowId = queryRowId && !isNaN(queryRowId) ? Number.parseInt(queryRowId) : 0;

    //    if (!equal(qPageId, pageId) ||
    //        !equal(qTableId, tableId) ||
    //        !equal(qRowId, rowId)) {
    //        //!equal(queryPaginationPage, paginationPage)) {
    //        console.log("browseConfiguration", { qPageId, qTableId, qRowId });
    //        dispatch(browseConfiguration(history, id, qPageId, qTableId, qRowId));
    //    }
    //}, [dispatch, queryPageId, queryTableId, queryRowId]);

    //if (!(alarmSystem && dataView && dataView.pageViewList && dataView.pageViewList.length &&
    //    Number.isInteger(pageId) && Number.isInteger(tableId) && // Number.isInteger(rowId) &&
    //    dataView.pageViewList[pageId] &&
    //    dataView.pageViewList[pageId].tableViewList &&
    //    dataView.pageViewList[pageId].tableViewList.length &&
    //    dataView.pageViewList[pageId].tableViewList[tableId])) {
    //    console.error("PagesView not rendered");
    //    return null;
    //}

    const pageView = configuration.pages[pageId];
    const tableView = configuration.tables[tableId];
    const isSplit = tableView.split;
    console.log("PagesView start render", moment().diff(start));

    return (
        <React.Fragment>
            {
                isMobile ?
                    <Drawer anchor="left" open={isPageMenuOpen} onClose={() => setIsPageMenuOpen(false)}>
                        <PageSidebar
                            browseConfiguration={browseConfiguration}
                            configuration={configuration}
                            pageId={pageId}
                            tableId={tableId}
                            onBrowsed={() => setIsPageMenuOpen(false)} />
                    </Drawer> :
                    null
            }

            <Container style={{ marginTop: 10 }} maxWidth={false}>
                {/*{
                    isMobile ?
                        <Button style={{ marginTop: 5, marginBottom: 5 }}
                            onClick={() => setIsPageMenuOpen(true)}
                            startIcon={<ListIcon />}
                            endIcon={dirtyParameters.some(p => p.parameterErrorText) ? <WarningIcon style={{ fontSize: 15 }} color="error" /> : null}>
                            {t("BrowsePages")}
                        </Button> :
                        null
                }*/}

                <div style={{ display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "stretch" }}>
                    {
                        !isMobile ?
                            <div style={{ flexGrow: 0, marginInlineEnd: 3 }}>
                                {/*<div style={{ backgroundColor: 'green', width: 200, height: 500 }}>Is Mobile: {JSON.stringify(isMobile)}</div>*/}
                                <Paper>
                                    <PageSidebar
                                        browseConfiguration={browseConfiguration}
                                        configuration={configuration}
                                        pageId={pageId}
                                        tableId={tableId}
                                        onBrowsed={() => setIsPageMenuOpen(false)} />
                                </Paper>
                            </div> :
                            null
                    }


                    <div style={{ flexGrow: 1, overflowX: "auto" }}>
                        <Paper style={{ maxWidth: '100%', padding: 5 }}>
                            {
                                isMobile ?
                                    <Button color="primary" style={{ marginTop: 10, marginBottom: 5 }}
                                        onClick={() => setIsPageMenuOpen(true)}
                                        startIcon={<ListIcon />}>
                                        {`${t(pageView.title)}/${t(tableView.title || pageView.title)}`}
                                    </Button> :
                                    null
                            }

                            {
                                isMobile ?
                                    (
                                        isSplit ?
                                            <TableViewSplit
                                                browseConfiguration={browseConfiguration}
                                                updateValue={updateValue}
                                                configuration={configuration}
                                                pageId={pageId}
                                                tableId={tableId}
                                                rowId={rowId}
                                                cellId={cellId}
                                                disableCode={disableCode} /> :
                                            <TableViewTwoColumns
                                                browseConfiguration={browseConfiguration}
                                                updateValue={updateValue}
                                                configuration={configuration}
                                                pageId={pageId}
                                                tableId={tableId}
                                                rowId={rowId}
                                                cellId={cellId}
                                                disableCode={disableCode} />
                                    ) :
                                    <TableViewFull
                                        browseConfiguration={browseConfiguration}
                                        updateValue={updateValue}
                                        configuration={configuration}
                                        pageId={pageId}
                                        tableId={tableId}
                                        rowId={rowId}
                                        cellId={cellId}
                                        disableCode={disableCode} />
                            }
                        </Paper>
                    </div>
                </div>

            </Container>
        </React.Fragment>
    );
}

export default PagesView;