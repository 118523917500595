import React from 'react';
import { Container, Typography, Breadcrumbs, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withTranslation, useTranslation } from 'react-i18next';

const ForgotPasswordConfirm = () => {
    const { t, i18n } = useTranslation();
    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("ForgotPasswordConfirmation")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <Alert style={{ marginTop: 10 }} severity="success">{t("ForgotPasswordConfirmationSubtitle")}</Alert>
        </Container>
    );
}

export default ForgotPasswordConfirm;