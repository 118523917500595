import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    //dataViewNames: [],
    webAccount: null,
    formData: {
        //id: null,
        //webAccountId: null,
                
		// alarm system details
        name: '',
        nameErrorText: null,
        nameValid: false,
        mac: '',
        macErrorText: null,
        macValid: false,
        //dataViewName: '',
        //dataViewNameErrorText: null,
        //dataViewNameValid: false,

		// owner details
        ownerFirstName: '',
        ownerFirstNameErrorText: null,
        ownerFirstNameValid: true,
        ownerLastName: '',
        ownerLastNameErrorText: null,
        ownerLastNameValid: true,
        ownerCompany: '',
        ownerCompanyErrorText: null,
        ownerCompanyValid: true,
        ownerPhoneNumber1: '',
        ownerPhoneNumber1ErrorText: null,
        ownerPhoneNumber1Valid: true,
        ownerPhoneNumber2: '',
        ownerPhoneNumber2ErrorText: null,
        ownerPhoneNumber2Valid: true,
        ownerEmail1: '',
        ownerEmail1ErrorText: null,
        ownerEmail1Valid: true,
        ownerEmail2: '',
        ownerEmail2ErrorText: null,
        ownerEmail2Valid: true,
        //ownerAddress: {
        ownerAddressCountry: '',
        ownerAddressCountryErrorText: null,
        ownerAddressCountryValid: true,
        ownerAddressState: '',
        ownerAddressStateErrorText: null,
        ownerAddressStateValid: true,
        ownerAddressCity: '',
        ownerAddressCityErrorText: null,
        ownerAddressCityValid: true,
        ownerAddressAddress: '',
        ownerAddressAddressErrorText: null,
        ownerAddressAddressValid: true,
        //},

		// installation details
        //installationAddress: {
        installationAddressCountry: '',
        installationAddressCountryErrorText: null,
        installationAddressCountryValid: true,
        installationAddressState: '',
        installationAddressStateErrorText: null,
        installationAddressStateValid: true,
        installationAddressCity: '',
        installationAddressCityErrorText: null,
        installationAddressCityValid: true,
        installationAddressAddress: '',
        installationAddressAddressErrorText: null,
        installationAddressAddressValid: true,
        //},

		// license
        //license: {
        //    purchasingDate: null,
        //    activationDate: null,
        //    billingDate: null,
        //    expirationDate: null,
        //    userLicenseType: null,
        //    installerLicenseType: null,
        //    userLicenseCostPerMonth: null,
        //    installerLicenseCostPerMonth: null
        //},

		// monitoring types
        //notificationTypes: [],
        //monitoringTypes: {
        //    burglary: false,
        //    panic: false,
        //    fire: false,
        //    duress: false,
        //    medical: false,
        //    tamper: false,
        //    faults: false,
        //    armingDisarming: false,
        //    alarmRestore: false,
        //    general: false,
        //    partition1: false,
        //    partition2: false,
        //    partition3: false,
        //    partition4: false,
        //    partition5: false,
        //    partition6: false,
        //    partition7: false,
        //    partition8: false,
        //    partition9: false,
        //    partition10: false,
        //    partition11: false,
        //    partition12: false,
        //    partition13: false,
        //    partition14: false,
        //    partition15: false,
        //    partition16: false,
        //},

		// pairing
        //pairing: {
        //    VersionLine: null,
        //    pairId: null,
        //    esn: null,
        //    pairDate: null
        //},
        isValid: false
    }
};

const addAlarmSystem = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_ADD_ALARM_SYSTEM__GET_WEB_ACCOUNT_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccount: { $set: action.data.webAccount }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ADD_ALARM_SYSTEM__GET_WEB_ACCOUNT_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                webAccount: { $set: null }
            });
            console.log(action.type, state, newState);
            return newState;
        //case constants.ACTION_TYPE_ADD_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_SUCCESS:
        //    newState = update(state, {
        //        generalErrorText: { $set: null },
        //        dataViewNames: { $set: action.data.dataViewNames }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        //case constants.ACTION_TYPE_ADD_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_FAIL:
        //    newState = update(state, {
        //        generalErrorText: { $set: action.data.generalErrorText },
        //        dataViewNames: { $set: [] }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        case constants.ACTION_TYPE_ADD_ALARM_SYSTEM__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.nameValid &&
                            newState.formData.macValid &&
                            //newState.formData.dataViewNameValid &&
                            newState.formData.ownerFirstNameValid &&
                            newState.formData.ownerLastNameValid &&
                            newState.formData.ownerCompanyValid &&
                            newState.formData.ownerPhoneNumber1Valid &&
                            newState.formData.ownerPhoneNumber2Valid &&
                            newState.formData.ownerEmail1Valid &&
                            newState.formData.ownerEmail2Valid &&
                            newState.formData.ownerAddressCountryValid &&
                            newState.formData.ownerAddressStateValid &&
                            newState.formData.ownerAddressCityValid &&
                            newState.formData.ownerAddressAddressValid &&
                            newState.formData.installationAddressCountryValid &&
                            newState.formData.installationAddressStateValid &&
                            newState.formData.installationAddressCityValid &&
                            newState.formData.installationAddressAddressValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        
        case constants.ACTION_TYPE_ADD_ALARM_SYSTEM__ADD_ALARM_SYSTEM_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ADD_ALARM_SYSTEM__ADD_ALARM_SYSTEM_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.nameValid &&
                            newState.formData.macValid &&
                            //newState.formData.dataViewNameValid &&
                            newState.formData.ownerFirstNameValid &&
                            newState.formData.ownerLastNameValid &&
                            newState.formData.ownerCompanyValid &&
                            newState.formData.ownerPhoneNumber1Valid &&
                            newState.formData.ownerPhoneNumber2Valid &&
                            newState.formData.ownerEmail1Valid &&
                            newState.formData.ownerEmail2Valid &&
                            newState.formData.ownerAddressCountryValid &&
                            newState.formData.ownerAddressStateValid &&
                            newState.formData.ownerAddressCityValid &&
                            newState.formData.ownerAddressAddressValid &&
                            newState.formData.installationAddressCountryValid &&
                            newState.formData.installationAddressStateValid &&
                            newState.formData.installationAddressCityValid &&
                            newState.formData.installationAddressAddressValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default addAlarmSystem;