import * as constants from '../constants';
import network from '../network';
import { validateEmail, validatePassword, validatePhoneNumber } from '../validators';
import { setLoading } from './global';

export function updateFormPassword(password, t) {
    return (dispatch) => {
        let formData = {
            password: password,
            passwordErrorText: null,
            passwordValid: false
        };
        if (!password)
            formData.passwordErrorText = "*";
        else if (!validatePassword(password))
            formData.passwordErrorText = t("PasswordInstructions");
        else
            formData.passwordValid = true;
        dispatch({ type: constants.ACTION_TYPE_RESET_PASSWORD__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormConfirmPassword(password, confirmPassword, t) {
    return (dispatch) => {
        let formData = {
            confirmPassword: confirmPassword,
            confirmPasswordErrorText: null,
            confirmPasswordValid: false
        };
        if (!confirmPassword)
            formData.confirmPasswordErrorText = "*";
        else if (password !== confirmPassword)
            formData.confirmPasswordErrorText = t("ErrorPasswordConfirmMismatch");
        else
            formData.confirmPasswordValid = true;
        dispatch({ type: constants.ACTION_TYPE_RESET_PASSWORD__UPDATE_FORM_DATA, data: { formData } });
    }
}

export function resetPassword(userId, code, password, confirmPassword, history, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        network.resetPassword(userId, code, password, confirmPassword, language, () => {
            dispatch({ type: constants.ACTION_TYPE_RESET_PASSWORD__RESET_PASSWORD_SUCCESS });
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null,
                formData: {
                    passwordErrorText: null,
                    passwordValid: true,
                    confirmPasswordErrorText: null,
                    confirmPasswordValid: true,
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UserNotFound = 4,
                        case 4:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //IncorrectResetPasswordCode = 5,
                        case 5:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //PasswordRequired = 6,
                        case 6:
                            data.formData.passwordErrorText = "*";
                            data.formData.passwordValid = false;
                            break;
                        //ConfirmPasswordRequired = 7,
                        case 7:
                            data.formData.confirmPasswordErrorText = "*";
                            data.formData.confirmPasswordValid = false;
                            break;
                        //ConfirmPasswordMismatch = 8
                        case 8:
                            data.formData.confirmPasswordErrorText = t("ErrorPasswordConfirmMismatch");
                            data.formData.confirmPasswordValid = false;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_RESET_PASSWORD__RESET_PASSWORD_FAIL,
                data
            });
            dispatch(setLoading(false));
        });
    }
}