import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, Container, Breadcrumbs, Typography, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    getWebAccountUser, confirmEmail, updateFormFirstName, updateFormLastName, updateFormPhoneNumber, updateFormPassword, updateFormConfirmPassword
} from '../../actions/confirmWebAccountUserEmail';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';

const ConfirmWebAccountUserEmail = () => {

    const { t, i18n } = useTranslation();
    const { user, token, unsetUser } = useUser();
    const location = window.location;
    const history = useHistory();
    const loading = useSelector(state => state.global.loading);
    const webAccountUser = useSelector(state => state.confirmWebAccountUserEmail.webAccountUser);
    const generalErrorText = useSelector(state => state.confirmWebAccountUserEmail.generalErrorText);
    const formData = useSelector(state => state.confirmWebAccountUserEmail.formData);
    const success = useSelector(state => state.confirmWebAccountUserEmail.success);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    let query = new URLSearchParams(location.search);
    let userId = query.get("userId");
    let code = query.get("code");
    //console.log("location", location);

    useEffect(() => {
        console.log("userId", userId);
        console.log("code", code);
        //dispatch(confirmEmail(userId, code, history, t));
        dispatch(getWebAccountUser(userId, history, token, unsetUser, t, language));
    }, [userId, code]);

    return (
        <Container>
            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("ConfirmEmail")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            {success ? <Alert style={{ marginTop: 10 }} severity="success">{t("ConfirmEmailSubtitle")}</Alert> : null}
            {
                webAccountUser && !success ?
                    <form noValidate={true}>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled
                                fullWidth
                                type="email"
                                label={t("EMail")}
                                value={webAccountUser.email} />
                        </div>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="text"
                                error={!!formData.firstNameErrorText}
                                label={t("FirstName")}
                                helperText={formData.firstNameErrorText}
                                value={formData.firstName}
                                onChange={event => dispatch(updateFormFirstName(event.target.value, t))} />
                        </div>
                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="text"
                                error={!!formData.lastNameErrorText}
                                label={t("LastName")}
                                helperText={formData.lastNameErrorText}
                                value={formData.lastName}
                                onChange={event => dispatch(updateFormLastName(event.target.value, t))} />
                        </div>
                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                type="text"
                                error={!!formData.phoneNumberErrorText}
                                label={t("PhoneNumber")}
                                helperText={formData.phoneNumberErrorText}
                                value={formData.phoneNumber}
                                onChange={event => dispatch(updateFormPhoneNumber(event.target.value, t))} />
                        </div>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                error={!!formData.passwordErrorText}
                                type="password"
                                label={t("Password")}
                                helperText={formData.passwordErrorText || t("PasswordInstructions")}
                                value={formData.password}
                                onChange={event => dispatch(updateFormPassword(event.target.value, t))} />
                        </div>

                        <div style={{ marginTop: 10, maxWidth: 300 }}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                error={!!formData.confirmPasswordErrorText}
                                type="password"
                                label={t("ConfirmPassword")}
                                helperText={formData.confirmPasswordErrorText}
                                value={formData.confirmPassword}
                                onChange={event => dispatch(updateFormConfirmPassword(formData.password, event.target.value, t))} />
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <Button variant="contained" color="primary" disabled={loading || !formData.isValid} onClick={() =>
                                dispatch(confirmEmail(userId, code, formData.firstName, formData.lastName, formData.phoneNumber,
                                    formData.password, formData.confirmPassword, history, t, language))}>{t("Register")}</Button>
                        </div>
                    </form> :
                    null
            }
        </Container>
    );
}

export default ConfirmWebAccountUserEmail;