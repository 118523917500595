import axios from 'axios';

export function getManagementSummary(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get('/api/management/getManagementSummary', config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.userCount, response.data.alarmSystemCount);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}
export function getAllUsers(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get('/api/management/getAllUsers', config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.users);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getAllAlarmSystems(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get('/api/management/getAllAlarmSystems', config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.alarmSystems);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getAllLicenses(token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get('/api/management/getAllLicenses', config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.licenses);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}

export function getAlarmSystemTransactions(id, token, language, successCallback, failCallback, unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`, "Accept-Language": language }
    };
    axios.get(`/api/management/getAlarmSystemTransactions/${id}`, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.transactions, response.data.alarmSystem, response.data.license);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response", error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}