import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    useMediaQuery, Grid, Button, Fab, TextField, Container, List, ListSubheader, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction,
    FormControl, FormLabel, FormGroup, FormControlLabel, FormHelperText, Checkbox,
    IconButton, Typography, Breadcrumbs, Link, Divider, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Drawer, TablePagination, InputLabel, Input, InputAdornment
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Alert, Pagination } from '@material-ui/lab';
import { Edit as EditIcon, FilterList as FilterListIcon, Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import { getLicenses, updateDisplayAlarmSystems } from '../../actions/managementAlarmSystems';
import * as constants from '../../constants';
import useUser from '../../user/useUser';
import TableViewAlarmSystems from './TableViewAlarmSystems';

const ManagementAlarmSystems = () => {

    const dispatch = useDispatch();

    const loading = useSelector(state => state.global.loading);
    const alarmSystems = useSelector(state => state.managementAlarmSystems.alarmSystems);
    const licenses = useSelector(state => state.managementAlarmSystems.licenses);
    const displayAlarmSystems = useSelector(state => state.managementAlarmSystems.displayAlarmSystems);
    const generalErrorText = useSelector(state => state.managementAlarmSystems.generalErrorText);

    const { token, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getLicenses(displayAlarmSystems.searchText, displayAlarmSystems.sort, displayAlarmSystems.alarmSystemId, history, token, unsetUser, t, language));
    }, [dispatch, token]);


    //const notificationTypeLookup = Object.assign({}, constants.NOTIFICATION_TYPES.map(n => ({ [n.type]: t(`MonitoringType${n.name}`) })));


    return (
        <React.Fragment>
            <Container>
                <Breadcrumbs style={{ marginTop: 20 }}>
                    <Link color="inherit" component={RouterLink} to={constants.ROUTE_MANAGEMENT}>{t("Management")}</Link>
                    <Typography color="textPrimary">{t("AlarmSystems")}</Typography>
                </Breadcrumbs>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}
            </Container>

            <Container style={{ marginTop: 10 }} maxWidth={false}>
                <Paper style={{ maxWidth: '100%', padding: 5 }}>

                    <div style={{ display: "flex", flexGrow: 1, justifyContent: "space-between", alignItems: "stretch" }}>
                        <div style={{ flexGrow: 0 }}>

                        </div>
                        <div style={{ width: 150, flexGrow: 0 }}>
                            <FormControl>
                                <Input
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={displayAlarmSystems.searchText ?
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => dispatch(updateDisplayAlarmSystems(alarmSystems, licenses, '', displayAlarmSystems.sort, displayAlarmSystems.alarmSystemId, t))}>
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment> :
                                        null
                                    }
                                    value={displayAlarmSystems.searchText}
                                    onChange={event => dispatch(updateDisplayAlarmSystems(alarmSystems, licenses, event.target.value, displayAlarmSystems.sort, displayAlarmSystems.alarmSystemId, t))}
                                />
                            </FormControl>
                        </div>
                    </div>

                    <TableViewAlarmSystems />
                </Paper>
            </Container>

        </React.Fragment>
    );
}

export default ManagementAlarmSystems;