import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    loading: false,
    features: {
        monitoring: false,
        configuration: false,
        templates: false,
        paymentMethod: false
    }
};

const global = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_GLOBAL__SET_LOADING:
            newState = update(state, { loading: {$set: action.data.loading}});
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default global;