import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Container, Paper, Button, TableContainer, Table, TableBody, TableCell, TableRow, TableHead, TableFooter, TableSortLabel } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import * as constants from '../../constants';
import equal from 'fast-deep-equal';
import moment from 'moment';
import { getUsers, updateDisplayUsers } from '../../actions/managementUsers';
import useUser from '../../user/useUser';
import { flexbox } from '@material-ui/system';

const TableViewUsers = () => {
    
    const dispatch = useDispatch();
    const loading = useSelector(state => state.global.loading);
    const users = useSelector(state => state.managementUsers.users);
    const displayUsers = useSelector(state => state.managementUsers.displayUsers);
    const generalErrorText = useSelector(state => state.managementUsers.generalErrorText);


    const { token, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    const tableRef = useRef(null);
    const [tableLeft, setTableLeft] = useState(0);
    useEffect(() => {
        if (tableRef.current) {
            //console.log("tableRef", tableRef.current);
            setTableLeft(tableRef.current.left);
        }
    }, [tableRef.current]);

    const userTypeLookup = {};
    for (let u of constants.USER_TYPES) {
        userTypeLookup[u.type] = t(`UserType${u.name}`);
    }    
    console.log("userTypeLookup", userTypeLookup);

    const take = 20;
    const count = Math.ceil(users.length / take);
    const paginationPage = Math.floor(displayUsers.userId / take) + 1;

    //const end = moment();
    //console.log("start render", end.diff(start));
    
    return <div>
        <TableContainer ref={tableRef}>
            <Table size="small" padding="checkbox">
                <TableHead>
                    <TableRow>

                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayUsers(users, displayUsers.searchText, {
                                    by: constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE,
                                    direction: displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE && displayUsers.sort.direction === "asc" ? "desc" : "asc"
                                }, displayUsers.userId, t))}
                                direction={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE ? displayUsers.sort.direction : "asc"}
                                active={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE}>{t("LastLoginDate")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayUsers(users, displayUsers.searchText, {
                                    by: constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE,
                                    direction: displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE && displayUsers.sort.direction === "asc" ? "desc" : "asc"
                                }, displayUsers.userId, t))}
                                direction={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE ? displayUsers.sort.direction : "asc"}
                                active={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_LOGIN_DATE}>{t("LastLoginTime")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayUsers(users, displayUsers.searchText, {
                                    by: constants.MANAGEMENT_USERS_SORT_BY_EMAIL,
                                    direction: displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_EMAIL && displayUsers.sort.direction === "asc" ? "desc" : "asc"
                                }, displayUsers.userId, t))}
                                direction={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_EMAIL ? displayUsers.sort.direction : "asc"}
                                active={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_EMAIL}>{t("EMail")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayUsers(users, displayUsers.searchText, {
                                    by: constants.MANAGEMENT_USERS_SORT_BY_FIRST_NAME,
                                    direction: displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_FIRST_NAME && displayUsers.sort.direction === "asc" ? "desc" : "asc"
                                }, displayUsers.userId, t))}
                                direction={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_FIRST_NAME ? displayUsers.sort.direction : "asc"}
                                active={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_FIRST_NAME}>{t("FirstName")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayUsers(users, displayUsers.searchText, {
                                    by: constants.MANAGEMENT_USERS_SORT_BY_LAST_NAME,
                                    direction: displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_NAME && displayUsers.sort.direction === "asc" ? "desc" : "asc"
                                }, displayUsers.userId, t))}
                                direction={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_NAME ? displayUsers.sort.direction : "asc"}
                                active={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_LAST_NAME}>{t("LastName")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayUsers(users, displayUsers.searchText, {
                                    by: constants.MANAGEMENT_USERS_SORT_BY_PHONE_NUMBER,
                                    direction: displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_PHONE_NUMBER && displayUsers.sort.direction === "asc" ? "desc" : "asc"
                                }, displayUsers.userId, t))}
                                direction={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_PHONE_NUMBER ? displayUsers.sort.direction : "asc"}
                                active={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_PHONE_NUMBER}>{t("PhoneNumber")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayUsers(users, displayUsers.searchText, {
                                    by: constants.MANAGEMENT_USERS_SORT_BY_USER_TYPE,
                                    direction: displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_USER_TYPE && displayUsers.sort.direction === "asc" ? "desc" : "asc"
                                }, displayUsers.userId, t))}
                                direction={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_USER_TYPE ? displayUsers.sort.direction : "asc"}
                                active={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_USER_TYPE}>{t("UserType")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayUsers(users, displayUsers.searchText, {
                                    by: constants.MANAGEMENT_USERS_SORT_BY_STATUS,
                                    direction: displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_STATUS && displayUsers.sort.direction === "asc" ? "desc" : "asc"
                                }, displayUsers.userId, t))}
                                direction={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_STATUS ? displayUsers.sort.direction : "asc"}
                                active={displayUsers.sort.by === constants.MANAGEMENT_USERS_SORT_BY_STATUS}>{t("WebAccountUserStatus")}</TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        displayUsers.users
                            .slice((paginationPage - 1) * take, paginationPage * take)
                            //.filter(r => r.tableId === tableView.id && r.id >= rowId && r.id < rowId + take)
                            //.slice(rowId, rowId + take)
                            //.filter(r => r.id >= rowId && r.id < rowId + take)
                            .map((u, i) => <TableRow key={i}>
                                <TableCell style={{ maxWidth: 100 }}>{u.lastLoginDate ? moment(u.lastLoginDate).format("DD/MM/YYYY") : ""}</TableCell>
                                <TableCell style={{ maxWidth: 100 }}>{u.lastLoginDate ? moment(u.lastLoginDate).format("HH:mm:ss") : ""}</TableCell>
                                <TableCell style={{ maxWidth: 100 }}>{u.email}</TableCell>
                                <TableCell style={{ maxWidth: 100 }}>{u.firstName}</TableCell>
                                <TableCell style={{ maxWidth: 100 }}>{u.lastName}</TableCell>
                                <TableCell style={{ maxWidth: 100 }}>{u.phoneNumber}</TableCell>
                                <TableCell style={{ maxWidth: 100 }}>{userTypeLookup[u.UserType]}</TableCell>
                                <TableCell style={{ maxWidth: 100 }}>{!u.active ? t("UserNotActive") :
                                    (!u.emailConfirmed ? t("EmailNotConfirmed") : t("Active"))}</TableCell>
                            </TableRow>)
                    }
                </TableBody>
            </Table>
        </TableContainer>

        {/*<div style={{position: "fixed", bottom: 0, display: "flex", justifyContent: "center"}}>*/}
        <div style={{ position: "fixed", bottom: 0, left: tableLeft, zIndex: 100 }}>
            <Paper>
                <Pagination
                    style={{ marginTop: 10, marginBottom: 10 }}
                    size="small"
                    color="primary"
                    disabled={loading}
                    count={count}
                    page={paginationPage}
                    onChange={(event, page) => dispatch(updateDisplayUsers(users, displayUsers.searchText, displayUsers.sort, (page - 1) * take, t))} />
            </Paper>
        </div>
    </div>
}

export default TableViewUsers;