import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    TextField, Button, LinearProgress, FormControl, Typography, Grid, Paper
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { enableLicense, enableFreeLicense, openLicenseDialog, openLicenseTranzilaDialog, updateFormLicenseNumberOfSystems } from '../../actions/alarmSystemLicense';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import EditableTextField from '../common/EditableTextField';
import * as constants from '../../constants';
import moment from 'moment';
import { getTextsForPermission } from '../../helpers/textHelpers';





const LicenseDialog = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const license = useSelector(state => state.alarmSystemLicense.license);
    const licenseDialog = useSelector(state => state.alarmSystemLicense.licenseDialog);
    //const licenseOptions = useSelector(state => state.alarmSystemLicense.licenseOptions);
    const generalErrorText = useSelector(state => state.alarmSystemLicense.generalErrorText);
    const shekels = useSelector(state => state.alarmSystemLicense.monthlyShekelsAmmountPerSystem);
    let userID;
    let f_numberofPaidSystmens;
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    //if (/*!alarmSystem ||*/ !licenseDialog.permission)
//return null;
   // let permission = licenseDialog.permission;
    //let permissionOption = licenseOptions.permissions.find(p => p.name === permission.name);
    //if (!permissionOption)
    //    return null;
   
    if (license)
    {
        userID = license.userId;
        f_numberofPaidSystmens = license.NumberOfPaidSystems;
    }

    let { statusText, buyButtonText, buyText, showFreeButton } = getTextsForPermission(null, t);
    
    return (
        <Dialog
            disableBackdropClick={loading}
            open={licenseDialog.isOpen}
            onClose={() => dispatch(openLicenseDialog(false))}>
            <DialogTitle>{t(`userID${userID}`)}</DialogTitle>
            <DialogContent>

                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}

                {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{/*generalErrorText*/userID}</Alert> : null}
                {userID ? <Alert style={{ marginTop: 10 }} severity="error">{userID}</Alert> : null}
                <Typography variant="subtitle1">{statusText}</Typography>

                <div style={{ maxWidth: 500, marginTop: 20, marginBottom: 20 }}>
                    {
                        //showFreeButton ?
                            <Paper style={{ padding: 20 }}>
                                <Typography variant="subtitle1">{t("EnableFreeLicenseDescription", { first: 2/*permission.freeMonths*/ })}</Typography>
                                <div style={{ marginTop: 10 }}>
                                    <Button variant="contained" disabled={loading} color="primary"
                                    onClick={() => dispatch(enableLicense(userID, f_numberofPaidSystmens , history, token, unsetUser, t, language))}>{t("EnableFreeLicense")}</Button>
                                </div>
                            </Paper>// :
                           // null
                    }

                    <Paper style={{ marginTop: 20, padding: 20 }}>
                        <Typography variant="subtitle1">{buyText}</Typography>
                        <Typography variant="subtitle2">{`${(f_numberofPaidSystmens / 100).toFixed(2)} ${t("LicensePriceUnitSign")}`}</Typography>
                        <div style={{ marginTop: 10 }}>
                            <EditableTextField
                                disabled={false}
                                fullWidth
                                type="text"
                                error={null}
                                label={t("NumberOfPaidSystems")}
                                helperText={null}                               
                                value={license ? license.NumberSystemsToAdd : "0"}
                                onChange={event => dispatch(updateFormLicenseNumberOfSystems(event.target.value, shekels, t))}/>

                        </div>


                        <div style={{ marginTop: 30 }}>
                            <Button variant="contained" disabled={loading} color="primary"
                                onClick={() => {
                                    dispatch(openLicenseDialog(false));
                                    dispatch(openLicenseTranzilaDialog(true));
                                }}>{buyButtonText}</Button>
                        </div>
                    </Paper>
                </div>

            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => dispatch(openLicenseDialog(false, null))} color="primary">
                    {t("Cancel")}
                </Button>
                {/*<Button disabled={loading}
                    onClick={() => dispatch(deleteAlarmSystem(alarmSystem.id, history, token, unsetUser, t, language))}
                    color="primary">
                    {t("Remove")}
                </Button>*/}
            </DialogActions>
        </Dialog>
    );
}

export default LicenseDialog;