import * as constants from '../constants';
import network from '../network';
import { validateEmail, validateMac, validatePhoneNumber } from '../validators';
import { setLoading } from './global';

export function updateFormFirstName(firstName, t) {
    return (dispatch) => {
        let formData = {
            firstName: firstName,
            firstNameErrorText: null,
            firstNameValid: false
        };
        if (!firstName)
            formData.firstNameErrorText = "*";
        else
            formData.firstNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormLastName(lastName, t) {
    return (dispatch) => {
        let formData = {
            lastName: lastName,
            lastNameErrorText: null,
            lastNameValid: false
        };
        if (!lastName)
            formData.lastNameErrorText = "*";
        else
            formData.lastNameValid = true;
        dispatch({ type: constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormPhoneNumber(phoneNumber, t) {
    return (dispatch) => {
        let formData = {
            phoneNumber: phoneNumber,
            phoneNumberErrorText: null,
            phoneNumberValid: false
        };
        if (phoneNumber && !validatePhoneNumber(phoneNumber))
            formData.phoneNumberErrorText = t("ErrorPhoneNumberInvalidCharacters");
        else
            formData.phoneNumberValid = true;
        dispatch({ type: constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormEmail(email, t) {
    return (dispatch) => {
        let formData = {
            email: email,
            emailErrorText: null,
            emailValid: false
        };
        if (!email)
            formData.emailErrorText = "*";
        else if (!validateEmail(email))
            formData.emailErrorText = t("ErrorEmailInvalid");
        else
            formData.emailValid = true;
        dispatch({ type: constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__UPDATE_FORM_DATA, data: { formData } });
    }
}
export function updateFormUserType(userType, t) {
    return (dispatch) => {
        let formData = {
            userType,
            userTypeErrorText: null,
            userTypeValid: false
        };
        if (![
            constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE,
            constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM
        ].includes(userType))
            formData.userTypeErrorText = "*";
        else
            formData.userTypeValid = true;
        dispatch({ type: constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__UPDATE_FORM_DATA, data: { formData } });
    }
}

export function registerWebAccountUser(email, firstName, lastName, phoneNumber, userType, history, location, token, unsetUser, t, language) {
    return (dispatch) => {
        dispatch(setLoading(true));

        const callbackUrl = `${location.origin}${constants.ROUTE_ACCOUNT_CONFIRM_WEB_ACCOUNT_USER_EMAIL}`;
        network.registerWebAccountUser(email, firstName, lastName, phoneNumber, userType, callbackUrl, token, language, (user) => {
            dispatch({
                type: constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__REGISTER_WEB_ACCOUNT_USER_SUCCESS,
                data: { user }
            });
            dispatch(setLoading(false));
            history.replace(constants.ROUTE_ACCOUNT_USERS);
        }, (errorCodes) => {
            let data = {
                generalErrorText: null,
                formData: {
                    firstNameErrorText: null,
                    firstNameValid: true,
                    lastNameErrorText: null,
                    lastNameValid: true,
                    phoneNumberErrorText: null,
                    phoneNumberValid: true,
                    emailErrorText: null,
                    emailValid: true
                }
            };
            if (errorCodes) {
                for (let errorCode of errorCodes) {
                    switch (errorCode) {
                        //GeneralError = 1,
                        case 1:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //UnexpectedError = 2,
                        case 2:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //MissingParameters = 3,
                        case 3:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                        //EmailRequired = 4,
                        case 4:
                            data.formData.emailErrorText = "*";
                            data.formData.emailValid = false;
                            break;
                        //FirstNameRequired = 5,
                        case 5:
                            data.formData.firstNameErrorText = "*";
                            data.formData.firstNameValid = false;
                            break;
                        //LastNameRequired = 6,
                        case 6:
                            data.formData.lastNameErrorText = "*";
                            data.formData.lastNameValid = false;
                            break;
                        //EmailAlreadyExists = 7,
                        case 7:
                            data.generalErrorText = t("ErrorIdentityDuplicateUserName", { first: email });
                            break;
                        //InvalidEmail = 8,
                        case 8:
                            data.formData.emailErrorText = t("ErrorEmailInvalid");
                            data.formData.emailValid = false;
                            break;
                        //InvalidPhoneNumber = 9,
                        case 9:
                            data.formData.phoneNumberErrorText = t("ErrorPhoneNumberInvalidCharacters");
                            data.formData.phoneNumberValid = false;
                            break;
                        default:
                            data.generalErrorText = `${t("ErrorGeneral")} (${errorCode})`;
                            break;
                    }
                }
            } else {
                data.generalErrorText = t("ErrorGeneral");
            }
            dispatch({
                type: constants.ACTION_TYPE_ADD_WEB_ACCOUNT_USER__REGISTER_WEB_ACCOUNT_USER_FAIL,
                data
            });
            dispatch(setLoading(false));
        }, () => {
            unsetUser();
            dispatch(setLoading(false));
            history.push(constants.ROUTE_ACCOUNT_LOGIN);
        });
    }
}

