import axios from 'axios';

export function getGeneralSettings(token,language,successCallback,failCallback,unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`,"Accept-Language": language }
    };
    axios.get('/api/generalSettings/getGeneralSettings',config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback(response.data.licenseSettings,response.data.vatSettings);
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response",error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}


export function setGeneralSettings(licenseSettings,vatSettings,token,language,successCallback,failCallback,unauthorizedCallback) {
    const config = {
        headers: { Authorization: `Bearer ${token}`,"Accept-Language": language }
    };

    const data = { licenseSettings,vatSettings };
    axios.post('/api/generalSettings/setGeneralSettings', data, config)
        .then(response => {
            console.log(response);

            if (response.data.success) {
                successCallback();
            } else {
                failCallback(response.data.errorCodes);
            }
        }).catch(error => {
            console.error("error.response",error.response);
            // if 401 UNAUTHORIZED, log out
            if (error.response.status === 401) {
                unauthorizedCallback();
            } else {
                failCallback();
            }
        });
}