import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function RTL(props) {
    return props.flip ? (
        <StylesProvider jss={jss}>
            {props.children}
        </StylesProvider>
    ) : (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        );
}
export default RTL;