import React, { useEffect } from 'react';
import * as constants from '../constants';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, setUserFromStorage, unsetUser } from '../actions/user';
import moment from 'moment';

const useUser = () => {
    const isUserInitialized = useSelector(state => state.user.isUserInitialized);
    const user = useSelector(state => state.user.user);
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isUserInitialized)
            dispatch(setUserFromStorage());
    }, [isUserInitialized]);

    return {
        isUserInitialized,
        user,
        token,
        isAuthenticated: user && user.userType > 0 ,
        isWebAccountAdmin: user && (user.userType === constants.USER_TYPE_WEB_ACCOUNT_ADMIN
                                    || user.userType === constants.USER_TYPE_PORTAL_ADMIN),
        isWebAccountUser: user && [
            constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE,
            constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM,
            constants.USER_TYPE_PORTAL_USER,
            constants.USER_TYPE_PORTAL_ADMIN
        ].indexOf(user.userType) >= 0,
        isWebAccountUserMonitor: user && [
            constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE,
            constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM,
            constants.USER_TYPE_WEB_ACCOUNT_ADMIN,
            constants.USER_TYPE_PORTAL_USER,
            constants.USER_TYPE_PORTAL_ADMIN
        ].indexOf(user.userType) >= 0,
        isWebAccountUserConfigurationRead: user && [
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_READ,
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE,
            constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM,
            constants.USER_TYPE_WEB_ACCOUNT_ADMIN,
            constants.USER_TYPE_PORTAL_USER,
            constants.USER_TYPE_PORTAL_ADMIN
        ].indexOf(user.userType) >= 0,
        isWebAccountUserConfigurationWrite: user && [
            constants.USER_TYPE_WEB_ACCOUNT_USER_CONFIGURATION_WRITE,
            constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM,
            constants.USER_TYPE_WEB_ACCOUNT_ADMIN,
            constants.USER_TYPE_PORTAL_USER,
            constants.USER_TYPE_PORTAL_ADMIN
        ].indexOf(user.userType) >= 0,
        isWebAccountUserSystem: user && [
            constants.USER_TYPE_WEB_ACCOUNT_USER_SYSTEM,
            constants.USER_TYPE_WEB_ACCOUNT_ADMIN,
            constants.USER_TYPE_PORTAL_USER,
            constants.USER_TYPE_PORTAL_ADMIN
        ].indexOf(user.userType) >= 0,
        isPortalAdmin: user && user.userType === constants.USER_TYPE_PORTAL_ADMIN,
        isPortalUser: user && user.userType === constants.USER_TYPE_PORTAL_USER,
        setUser: (user, token) => dispatch(setUser(user, token)),
        setUserFromStorage: () => setTimeout(() => dispatch(setUserFromStorage()), 2000),
        unsetUser: () => dispatch(unsetUser())
    };
}

export default useUser;