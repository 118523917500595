import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    generalErrorText: null,
    formData: {
        email: '',
        emailErrorText: null,
        emailValid: false,
        isValid: false
    }
};

const forgotPassword = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_FORGOT_PASSWORD__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.emailValid
                    }
                }
            })
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_FORGOT_PASSWORD__SEND_RESET_PASSWORD_EMAIL_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_FORGOT_PASSWORD__SEND_RESET_PASSWORD_EMAIL_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: {
                        $set: newState.formData.emailValid
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default forgotPassword;