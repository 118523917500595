import * as constants from '../constants';
import update from 'immutability-helper';
import LicenseTranzilaSuccess from '../components/alarmSystems/LicenseTranzilaSuccess';
import { setUserFromStorage } from '../actions/user';
const INITIAL_STATE = {
    generalErrorText: null,
    succeddedText: null,
    //installerLicenseOptions: [],
    //userLicenseOptions: [],
    licenseOptions: null,
    alarmSystem: null,
    license: null,
    formData: {
        NumberOfSystems: 0,
        PriceToPay: 0,
        AdditionalInvoiceData: "",
        PricePerPanel:0,
        isChecked: true,
        userApprovedMoreThen1: false,
        UserAprroveAlreadyBought: false
    },
    vat: 0,
    numberOfCurrentPaidSystems: 0,
    emailResponse:null,
    //dataViewNames: [],
    licenseDialog: {
        isOpen: false,
        permission: null
       
    },
    licenseTranzilaDialog: {
        isOpen: false,
        isfree_2311: false
      //  permission: null
    },
    freeMonths: 1,
    monthlyShekelsAmmountPerSystem: 7,
    monthlyDollarsAmmountPerSystem: 2,
    monthlyEurosAmmountPerSystem: 2

};

const alarmSystemLicense = (state = INITIAL_STATE, action) => {
    
    let newState;
    let a;
    switch (action.type) {
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_DIALOG:
            newState = update(state, {
                licenseDialog: {
                    isOpen: { $set: action.data.isOpen },
                    permission: { $set: action.data.permission }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__OPEN_LICENSE_TRANZILA_DIALOG:
            
            newState = update(state, {
                licenseTranzilaDialog: {
                    isOpen: { $set: action.data.isOpen }
                    //isfree_2311: { $set: action.data.isfree_2311}
                    //permission: { $set: action.data.permission }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_NUMBER_OF_REQUIRED_SYSTEMS:
            newState = update(state, {
                formData: { $set: action.data.formData }
                
                    //permission: { $set: action.data.permission }
                            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__UPDATE_GENERALCOMMENT:
            newState = update(state, {
                formData: { $set: action.data.formData }

                //permission: { $set: action.data.permission }
            });
            console.log(action.type, state, newState);
            return newState;
        //case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_SUCCESS:
        //    newState = update(state, {
        //        generalErrorText: { $set: null },
        //        dataViewNames: { $set: action.data.dataViewNames }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        //case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_FAIL:
        //    newState = update(state, {
        //        generalErrorText: { $set: action.data.generalErrorText },
        //        dataViewNames: { $set: [] }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSE_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                //installerLicenseOptions: { $set: action.data.installerLicenseOptions },
                //userLicenseOptions: { $set: action.data.userLicenseOptions }
                license: { $set: action.data.license },
                vat: { $set: action.data.vat },
                emailResponse: { $set: action.data.emailResponse },
                freeMonths: { $set: action.data.freeMonths },
                monthlyShekelsAmmountPerSystem: { $set: action.data.monthlyShekelsAmmountPerSystem },
                monthlyDollarsAmmountPerSystem: { $set: action.data.monthlyDollarsAmmountPerSystem },
                monthlyEurosAmmountPerSystem: { $set: action.data.monthlyEurosAmmountPerSyste}
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_LICENSE_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                //installerLicenseOptions: { $set: null },
                //userLicenseOptions: { $set: null }
                license: { $set: null },
                vat: { $set: 0 }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_ALARM_SYSTEM_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                alarmSystem: { $set: action.data.alarmSystem }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__GET_ALARM_SYSTEM_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                alarmSystem: { $set: null }
            });
            console.log(action.type, state, newState);
            return newState;
        
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                license: { $set: action.data.license },
                licenseDialog: { $set: INITIAL_STATE.licenseDialog }
            });
            console.log(action.type, state, newState);
            
            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FREE_SUCCESS:
            newState = update(state, {
             //   generalErrorText: { $set: action.data.updatedNumOfSystems },
              //  license: { $set: action.data.license },
               // licenseDialog: { $set: INITIAL_STATE.licenseDialog },
                numberOfCurrentPaidSystems: { $set: action.data.updatedNumOfSystems },
                succeddedText: { $set: "FreeAddedsuccessfully"}
               
            });
           
            //LicenseTranzilaSuccess();
            console.log(action.type, state, newState);

            return newState;
        case constants.ACTION_TYPE_ALARM_SYSTEM_LICENSE__ENABLE_LICENSE_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                licenseDialog: { $set: INITIAL_STATE.licenseDialog },            
            });
            
            console.log(action.type, state, newState);
            return newState;

        default:
            return state;
    }
}

export default alarmSystemLicense;