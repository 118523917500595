import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';
import useUser from '../../user/useUser';
import WebAccountSwitch from './WebAccountSwitch';
import LicenseTranzilaSuccess from '../alarmSystems/LicenseTranzilaSuccess';
import LicenseTranzilaFail from '../alarmSystems/LicenseTranzilaFail';
import PaymentMethodSuccess from '../account/PaymentMethodSuccess';
//import PaymentMethodFail from '../account/PaymentMethodFail';
import TopMenu from '../topMenu/TopMenu';
import Footer from '../Footer';


const MainSwitch = () => {
    const loading = useSelector(state => state.global.loading);

    return <Switch>
       
        <Route path={constants.ROUTE_ALARM_SYSTEMS_LICENSE_TRANZILA_FAIL__ID__TRANSACTION_ID}>
            <LicenseTranzilaFail />
        </Route>
        <Route path={constants.ROUTE_ALARM_SYSTEMS_LICENSE_TRANZILA_SUCCESS__ID_CONFIRMATION_CODE}>
            <LicenseTranzilaSuccess />
        </Route>
        {/* <Route path={constants.ROUTE_ALARM_SYSTEMS_LICENSE_TRANZILA_SUCCESS__ID__TRANSACTION_ID}>
            <LicenseTranzilaSuccess />
        </Route>
          <Route path={constants.ROUTE_ACCOUNT_PAYMENT_METHOD_FAIL}>
            <PaymentMethodFail />
        </Route>*/}
        <Route path={constants.ROUTE_ROOT}>
            <div style={{ paddingBottom: 30 }}>
                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}
                <TopMenu />
                <WebAccountSwitch />
                <Footer />
            </div>
        </Route>
    </Switch>
}

export default MainSwitch;