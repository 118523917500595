import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useRouteMatch, useParams } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Select, Menu, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AccountCircleRounded, Menu as MenuIcon, MenuSharp, AccountCircle as AccountCircleIcon, Settings as SettingsIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
//import logoLtr from '../images/ptmlogo.jpg';
import logoEng from '../../images/pimalogo_transparent_en.png';
import logoHeb from '../../images/pimalogo_transparent_he.png';
import logo from '../../images/ptmlogo.jpg';
import * as constants from '../../constants';
import useUser from '../../user/useUser';
import { openSelectLanguageDialog } from '../../actions/topMenu';
import AccountMenu from './AccountMenu';

const ToolbarFull = ({ activeComponent }) => {
    const [accountAnchorEl, setAccountAnchorEl] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const { t, i18n } = useTranslation();
    const { user, unsetUser, isAuthenticated, isWebAccountUserConfigurationRead, isWebAccountUserConfigurationWrite,
        isPortalAdmin, isPortalUser } = useUser();
    const theme = useTheme();
    const loading = useSelector(state => state.global.loading);
    const features = useSelector(state => state.global.features);
    const dispatch = useDispatch();

    const languages = i18n.languages;
    const language = languages[0];
    const isHebrew = language === "he";
    //const language = strings.getLanguage();
    //const languages = ["en", "de", "el", "es", "he", "ru"];
    //console.log("match", match);
    //console.log("params", match.params);
    //console.log("location", location);
    const matchAlarmSystems = useRouteMatch({ path: constants.ROUTE_ALARM_SYSTEMS, exact: true });
    const matchAlarmSystemsDetails_id = useRouteMatch({ path: constants.ROUTE_ALARM_SYSTEMS_DETAILS__ID__OPTIONAL_TAB_INDEX, exact: true });
    const matchAlarmSystemsLicense_id = useRouteMatch({ path: constants.ROUTE_ALARM_SYSTEMS_LICENSE__ID, exact: true });
    const matchAlarmSystemsTransactionResult_id = useRouteMatch({ path: constants.ROUTE_ALARM_SYSTEMS_TRANSACTION_RESULT__ID__TRANSACTION_ID, exact: true });
    const matchMonitoring_optionalId = useRouteMatch({ path: constants.ROUTE_MONITORING__OPTIONAL_ID, exact: true });
    const matchMonitoring_id = useRouteMatch({ path: constants.ROUTE_MONITORING__ID, exact: true });
    const matchConfiguration_id = useRouteMatch({ path: constants.ROUTE_CONFIGURATION__ID, exact: true });
    const matchAccountLogin = useRouteMatch({ path: constants.ROUTE_ACCOUNT_LOGIN, exact: true });
    const matchAccountRegister = useRouteMatch({ path: constants.ROUTE_ACCOUNT_REGISTER, exact: true });
    const matchTemplates = useRouteMatch({ path: constants.ROUTE_TEMPLATES, exact: true });
    const matchManagement = useRouteMatch({ path: constants.ROUTE_MANAGEMENT, exact: true });
    const matchManagementUsers = useRouteMatch({ path: constants.ROUTE_MANAGEMENT_USERS, exact: true });
    const matchManagementAlarmSystems = null;// useRouteMatch({ path: constants.ROUTE_MANAGEMENT_ALARM_SYSTEMS,exact: true });
    const matchManagementGeneralSettings = useRouteMatch({ path: constants.ROUTE_MANAGEMENT_GENERAL_SETTINGS,exact: true });
   
    let uID = null;
    if (user)
     uID = user.id;
    let id = null;
    if (matchAlarmSystemsDetails_id)
        id = matchAlarmSystemsDetails_id.params.id;
    else if (matchAlarmSystemsLicense_id)
        id = matchAlarmSystemsLicense_id.params.id;
    else if (matchAlarmSystemsTransactionResult_id)
        id = matchAlarmSystemsTransactionResult_id.params.id;
    else if (matchMonitoring_id)
        id = matchMonitoring_id.params.id;
    else if (matchConfiguration_id)
        id = matchConfiguration_id.params.id;

    return <React.Fragment>
        <Toolbar>
            <a href="/home" style={{ width: 100, marginLeft: 10, marginRight: 10 }}>
                {/*<img height={35} alt="PIMA TOTAL MANAGER" src={isHebrew ? logoHeb : logoEng} />*/}
                <img height={35} alt="PIMA TOTAL MANAGER" src={logo} />
            </a>
            {
                isAuthenticated && uID ?
                    <a href="/home" color="black" style={{ textDecoration: 'none', fontWeight: "bold", width: 100, marginLeft: 10, marginRight: 10 }} >
                        {t("HomeScreen")}
                    </a> : null
            }
          
            <div style={{ flex: 1 }} />
            <IconButton disabled={loading} edge="end" color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
                <AccountCircleRounded />
            </IconButton>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)} keepMounted>

                {
                    !isAuthenticated ?
                        <MenuItem disabled={loading} style={{ backgroundColor: "unset" }}
                            selected={false}
                            onClick={() => setAnchorEl(null)}
                            component={RouterLink} to={constants.ROUTE_ACCOUNT_LOGIN}>{t("LogIn")}</MenuItem> : null

                }
                {
                    isAuthenticated ? null :
                        <MenuItem disabled={loading} style={{fontWeight: "bold",  backgroundColor: "unset" }}
                            selected={false}
                            onClick={() => setAnchorEl(null)}
                            component={RouterLink} to={constants.ROUTE_ACCOUNT_REGISTER}>{t("Register")}</MenuItem>

                }
                {
                    isAuthenticated?
                        <MenuItem disabled={loading} style={{ backgroundColor: "unset"  }}
                        selected={false}
                        onClick={() => setAnchorEl(null)}
                            component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS_LICENSE__ID.replace(":id", uID)}>{t("License")}</MenuItem> :
                        null
                    
                }
            </Menu>



            {/*<Select style={{marginLeft: 5, marginRight: 5}} value={language} onChange={event => {
                            i18n.changeLanguage(event.target.value);
                        }}>
                            {languages.map((l, i) => <MenuItem key={i} value={l}>{l}</MenuItem>)}
                        </Select>*/}
            <Button disabled={loading} color="inherit"
                onClick={() => dispatch(openSelectLanguageDialog(true))}>{language}</Button>
            {/*<Button color="inherit" onClick={() => setUser(1, "aaa@aa.com", "dfdfdf")}>set User</Button>
                        <Button color="inherit" onClick={() => unsetUser()}>uset User</Button>*/}
            {isAuthenticated ?
                <Button disabled={loading}
                    onClick={() => {
                        unsetUser();
                        //  setAnchorEl(null);
                    }}>{t("LogOut")}</Button> : null
            }









            {/*   <AccountMenu anchorEl={accountAnchorEl} setAnchorEl={setAccountAnchorEl} />*/}
        </Toolbar>

        {/*
            (isPortalAdmin || isPortalUser) ?
                <Toolbar variant="dense" style={{ backgroundColor: "beige" }}>
                    <div style={{ width: 100, marginLeft: 10, marginRight: 10 }} />

                    <Button disabled={loading} color="inherit" style={matchManagement ? { fontWeight: "bold" } : null}
                        component={RouterLink} to={constants.ROUTE_MANAGEMENT}>{t("Management")}</Button>
                    <Button disabled={loading} color="inherit" style={matchManagementUsers ? { fontWeight: "bold" } : null}
                        component={RouterLink} to={constants.ROUTE_MANAGEMENT_USERS}>{t("Users")}</Button>
                    <Button disabled={loading} color="inherit" style={matchManagementAlarmSystems ? { fontWeight: "bold" } : null}
                        component={RouterLink} to={constants.ROUTE_MANAGEMENT_ALARM_SYSTEMS}>{t("AlarmSystems")}</Button>
                    <Button disabled={loading} color="inherit" style={matchManagementGeneralSettings ? { fontWeight: "bold" } : null}
                        component={RouterLink} to={constants.ROUTE_MANAGEMENT_GENERAL_SETTINGS}>{t("GeneralSettings")}</Button>
                </Toolbar> :
                null
        */}
    </React.Fragment>
}

export default ToolbarFull;
