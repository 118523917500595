import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useMediaQuery, Container, Paper, Button, List, ListItem, ListItemText, ListItemIcon,
    TableContainer, Table, TableBody, TableCell, TableRow, TableHead, TableFooter, TableSortLabel, Link,
    Dialog, DialogTitle, DialogContent, DialogActions, FormControl
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import * as constants from '../../constants';
import equal from 'fast-deep-equal';
import moment from 'moment';
import { updateDisplayTransactions } from '../../actions/managementAlarmSystemTransactions';
import useUser from '../../user/useUser';
import { flexbox } from '@material-ui/system';
import { getTextsForPermission } from '../../helpers/textHelpers';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@material-ui/icons';
import EditableTextField from '../common/EditableTextField';

const TableViewTransactions = () => {

    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const dispatch = useDispatch();
    const loading = useSelector(state => state.global.loading);
    const transactions = useSelector(state => state.managementAlarmSystemTransactions.transactions);
    const alarmSystem = useSelector(state => state.managementAlarmSystemTransactions.alarmSystem);
    const license = useSelector(state => state.managementAlarmSystemTransactions.license);
    const displayTransactions = useSelector(state => state.managementAlarmSystemTransactions.displayTransactions);
    const generalErrorText = useSelector(state => state.managementAlarmSystemTransactions.generalErrorText);


    const { token, unsetUser } = useUser();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];

    const tableRef = useRef(null);
    const [tableLeft, setTableLeft] = useState(0);
    useEffect(() => {
        if (tableRef.current) {
            //console.log("tableRef", tableRef.current);
            setTableLeft(tableRef.current.left);
        }
    }, [tableRef.current]);

    //const macLicenseLookup = {};
    //for (let l of licenses) {
    //    macLicenseLookup[l.mac] = l;
    //}
    //console.log("macLicenseLookup", macLicenseLookup);

    const take = 20;
    const count = Math.ceil(transactions.length / take);
    const paginationPage = Math.floor(displayTransactions.transactionId / take) + 1;

    //const end = moment();
    //console.log("start render", end.diff(start));

    return <div>

        {selectedTransaction ?
            <Dialog
                disableBackdropClick={loading}
                open={!!selectedTransaction}
                onClose={() => setSelectedTransaction(null)}>
                <DialogTitle>{t(`License${selectedTransaction.permissionName}`)}</DialogTitle>
                <DialogContent>

                    {/*<Typography variant="subtitle1">{(a.notifyConfirmationStatus === constants.NOTIFY_CONFIRMATION_STATUS_NONE) ?
                    t("NotifyConfirmationStatusNone") :
                    ((a.notifyConfirmationStatus === constants.NOTIFY_CONFIRMATION_STATUS_CONFIRMED_SUCCESS) ? t("NotifyConfirmationStatusSuccess") : t("NotifyConfirmationStatusError"));
                    }</Typography>*/}

                    <div style={{ maxWidth: 300, marginTop: 20 }}>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("Date")}
                                value={moment(selectedTransaction.transactionDate).format("DD/MM/YYYY HH:mm:ss")} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("NotifyConfirmationStatus")}
                                value={(selectedTransaction.notifyConfirmationStatus === constants.NOTIFY_CONFIRMATION_STATUS_NONE) ?
                                    t("NotifyConfirmationStatusNone") :
                                    ((selectedTransaction.notifyConfirmationStatus === constants.NOTIFY_CONFIRMATION_STATUS_CONFIRMED_SUCCESS) ? t("NotifyConfirmationStatusSuccess") : t("NotifyConfirmationStatusError"))} />
                        </FormControl>
                        {/*<FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("License")}
                                value={t(`License${selectedTransaction.permissionName}`)} />
                        </FormControl>*/}
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("Sum")}
                                value={`${selectedTransaction.tranzilaResponse.sum} ${t("LicensePriceUnitSign")}`} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("TranzilaResponseCcno")}
                                value={`************${selectedTransaction.tranzilaResponse.ccno}`} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("TranzilaResponseId")}
                                value={selectedTransaction.tranzilaResponse.myid} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("TranzilaResponseContact")}
                                value={selectedTransaction.tranzilaResponse.contact} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("TranzilaResponseConfirmationCode")}
                                value={selectedTransaction.tranzilaResponse.confirmationCode} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("EMail")}
                                value={selectedTransaction.tranzilaResponse.email} />
                        </FormControl>                       
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("PhoneNumber")}
                                value={selectedTransaction.tranzilaResponse.phone} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("Address")}
                                value={selectedTransaction.tranzilaResponse.address} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 10 }}>
                            <EditableTextField
                                readOnly={true}
                                disabled={loading}
                                fullWidth
                                type="text"
                                label={t("City")}
                                value={selectedTransaction.tranzilaResponse.city} />
                        </FormControl>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => setSelectedTransaction(null)} color="primary">
                        {t("Cancel")}
                    </Button>
                    {/*<Button disabled={loading}
                    onClick={() => dispatch(deleteAlarmSystem(alarmSystem.id, history, token, unsetUser, t, language))}
                    color="primary">
                    {t("Remove")}
                </Button>*/}
                </DialogActions>
            </Dialog> :
            null
        }

        <TableContainer ref={tableRef}>
            <Table size="small" padding="checkbox">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayTransactions(transactions, displayTransactions.searchText, {
                                    by: constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE,
                                    direction: displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE && displayTransactions.sort.direction === "asc" ? "desc" : "asc"
                                }, displayTransactions.transactionId, t))}
                                direction={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE ? displayTransactions.sort.direction : "asc"}
                                active={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE}>{t("Date")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayTransactions(transactions, displayTransactions.searchText, {
                                    by: constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE,
                                    direction: displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE && displayTransactions.sort.direction === "asc" ? "desc" : "asc"
                                }, displayTransactions.transactionId, t))}
                                direction={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_DATE ? displayTransactions.sort.direction : "asc"}
                                active={false}>{t("Time")}</TableSortLabel>
                        </TableCell>
                        {/*<TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayTransactions(transactions, displayTransactions.searchText, {
                                    by: constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC,
                                    direction: displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC && displayTransactions.sort.direction === "asc" ? "desc" : "asc"
                                }, displayTransactions.transactionId, t))}
                                direction={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC ? displayTransactions.sort.direction : "asc"}
                                active={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEMS_SORT_BY_MAC}>{t("MAC")}</TableSortLabel>
                        </TableCell>*/}
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayTransactions(transactions, displayTransactions.searchText, {
                                    by: constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_PERMISSION_NAME,
                                    direction: displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_PERMISSION_NAME && displayTransactions.sort.direction === "asc" ? "desc" : "asc"
                                }, displayTransactions.transactionId, t))}
                                direction={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_PERMISSION_NAME ? displayTransactions.sort.direction : "asc"}
                                active={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_PERMISSION_NAME}>{t("License")}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={() => dispatch(updateDisplayTransactions(transactions, displayTransactions.searchText, {
                                    by: constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_CONFIRMATION_STATUS,
                                    direction: displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_CONFIRMATION_STATUS && displayTransactions.sort.direction === "asc" ? "desc" : "asc"
                                }, displayTransactions.transactionId, t))}
                                direction={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_CONFIRMATION_STATUS ? displayTransactions.sort.direction : "asc"}
                                active={displayTransactions.sort.by === constants.MANAGEMENT_ALARM_SYSTEM_TRANSACTIONS_SORT_BY_CONFIRMATION_STATUS}>{t("NotifyConfirmationStatus")}</TableSortLabel>
                        </TableCell>
                        <TableCell>{t("Sum")}</TableCell>
                        <TableCell>{t("Details")}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        displayTransactions.transactions
                            .slice((paginationPage - 1) * take, paginationPage * take)
                            //.filter(r => r.tableId === tableView.id && r.id >= rowId && r.id < rowId + take)
                            //.slice(rowId, rowId + take)
                            //.filter(r => r.id >= rowId && r.id < rowId + take)
                            .map((a, i) => {
                                //let license = macLicenseLookup[a.mac];
                                //let notificationsPermission = license ? license.permissions.find(p => p.name === "Notifications") : null;
                                //let controlPermission = license ? license.permissions.find(p => p.name === "Control") : null;
                                let status = (a.notifyConfirmationStatus === constants.NOTIFY_CONFIRMATION_STATUS_NONE) ?
                                    t("NotifyConfirmationStatusNone") :
                                    ((a.notifyConfirmationStatus === constants.NOTIFY_CONFIRMATION_STATUS_CONFIRMED_SUCCESS) ? t("NotifyConfirmationStatusSuccess") : t("NotifyConfirmationStatusError"));

                                return <TableRow key={i}>
                                    <TableCell style={{ maxWidth: 100 }}>{moment(a.transactionDate).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell style={{ maxWidth: 100 }}>{moment(a.transactionDate).format("HH:mm:ss")}</TableCell>
                                    <TableCell style={{ maxWidth: 100 }}>{t(`License${a.permissionName}`)}</TableCell>
                                    <TableCell style={{ maxWidth: 100 }}>{status}</TableCell>
                                    <TableCell style={{ maxWidth: 100 }}>{`${a.tranzilaResponse.sum} ${t("LicensePriceUnitSign")}`}</TableCell>
                                    <TableCell style={{ maxWidth: 100 }}>
                                        <Button disabled={loading} color="primary"
                                            onClick={() => setSelectedTransaction(a)}>{t("Details")}</Button>
                                    </TableCell>

                                    {/*<TableCell style={{ maxWidth: 200 }}>
                                        <List dense>
                                            {
                                                license ? license.permissions.map(p => {
                                                    let { statusText } = getTextsForPermission(p, t);

                                                    return <ListItem key={p.name}>
                                                        <ListItemText primary={t(`License${p.name}`)} secondary={statusText} />
                                                        <ListItemIcon>
                                                        {
                                                            p.state === constants.PERMISSION_STATE_VALID_ACTIVATED ||
                                                                p.state === constants.PERMISSION_STATE_VALID_NOT_ACTIVATED ||
                                                                p.state === constants.PERMISSION_STATE_VALID_FREE ?
                                                                <CheckCircleIcon color="primary" /> :
                                                                <CancelIcon color="error" />
                                                            }
                                                            </ListItemIcon>
                                                    </ListItem>

                                                    return <div key={p.name} style={{ marginTop: 20, marginBottom: 20 }}>
                                                        <Paper style={{ padding: 20 }}>
                                                            <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center" }}>
                                                                <div style={{ margin: 20 }}>
                                                                    {
                                                                        p.state === constants.PERMISSION_STATE_VALID_ACTIVATED ||
                                                                            p.state === constants.PERMISSION_STATE_VALID_NOT_ACTIVATED ||
                                                                            p.state === constants.PERMISSION_STATE_VALID_FREE ?
                                                                            <CheckCircleIcon color="primary" /> :
                                                                            <CancelIcon color="error" />
                                                                    }
                                                                </div>

                                                                <div style={{ margin: 20, flexGrow: 1 }}>
                                                                    <Typography variant="subtitle1">{t(`License${p.name}`)}</Typography>
                                                                    <Typography variant="subtitle2">{statusText}</Typography>
                                                                </div>
                                                                {
                                                                    showButton ?
                                                                        <div style={{ margin: 20 }}>
                                                                            <Button variant="contained" disabled={loading} color="primary"
                                                                                onClick={() => dispatch(openLicenseDialog(true, p))}>{buttonText}</Button>
                                                                        </div> :
                                                                        null
                                                                }
                                                            </div>
                                                        </Paper>
                                                    </div>
                                                }) :
                                                    null
                                            }
                                        </List>
                        </TableCell>*/}

                                </TableRow>
                            })
                    }
                </TableBody>
            </Table>
        </TableContainer>

        {/*<div style={{position: "fixed", bottom: 0, display: "flex", justifyContent: "center"}}>*/}
        <div style={{ position: "fixed", bottom: 0, left: tableLeft, zIndex: 100 }}>
            <Paper>
                <Pagination
                    style={{ marginTop: 10, marginBottom: 10 }}
                    size="small"
                    color="primary"
                    disabled={loading}
                    count={count}
                    page={paginationPage}
                    onChange={(event, page) => dispatch(updateDisplayTransactions(transactions, displayTransactions.searchText, displayTransactions.sort, (page - 1) * take, t))} />
            </Paper>
        </div>
    </div>
}

export default TableViewTransactions;