import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    successMessage: null,
    generalErrorText: null,
    webAccountUser: null,
    //dataViewNames: [],
    deleteDialog: {
        isOpen: false
    },
    sendConfirmationEmailDialog: {
        isOpen: false,
        success: false
    },
    formData: {
        //firstName: '',
        //firstNameErrorText: null,
        //firstNameValid: false,
        //lastName: '',
        //lastNameErrorText: null,
        //lastNameValid: false,
        //phoneNumber: '',
        //phoneNumberErrorText: null,
        //phoneNumberValid: true,
        //email: '',
        //emailErrorText: null,
        //emailValid: false,
        userType: constants.USER_TYPE_WEB_ACCOUNT_USER_MONITOR,
        userTypeErrorText: null,
        userTypeValid: true,
        isValid: false,
        isDirty: false
    }
};

const webAccountUserDetails = (state = INITIAL_STATE, action) => {

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: { $set: newState.formData.userTypeValid },
                    isDirty: { $set: true }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__OPEN_DELETE_DIALOG:
            newState = update(state, {
                deleteDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__OPEN_SEND_EMAIL_CONFIRMATION_DIALOG:
            newState = update(state, {
                sendConfirmationEmailDialog: {
                    isOpen: { $set: action.data.isOpen },
                    success: { $set: false }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        //case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_SUCCESS:
        //    newState = update(state, {
        //        generalErrorText: { $set: null },
        //        dataViewNames: { $set: action.data.dataViewNames }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        //case constants.ACTION_TYPE_EDIT_ALARM_SYSTEM__GET_DATA_VIEW_NAMES_FAIL:
        //    newState = update(state, {
        //        generalErrorText: { $set: action.data.generalErrorText },
        //        dataViewNames: { $set: [] }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccountUser: { $set: action.data.webAccountUser },
                formData: {
                    $set: {
                        userType: action.data.webAccountUser.userType,
                        isValid: true,
                        isDirty: false
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__GET_WEB_ACCOUNT_USER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                webAccountUser: { $set: null }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_WEB_ACCOUNT_USER_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccountUser: { $set: action.data.webAccountUser },
                formData: {
                    $set: {
                        userType: action.data.webAccountUser.userType,
                        isValid: true,
                        isDirty: false
                    }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__UPDATE_WEB_ACCOUNT_USER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                formData: { $merge: action.data.formData }
            });
            newState = update(newState, {
                formData: {
                    isValid: { $set: newState.formData.userTypeValid }
                }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__SEND_EMAIL_CONFIRMATION_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                sendConfirmationEmailDialog: {
                    success: { $set: true }
                }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__SEND_EMAIL_CONFIRMATION_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__ACTIVATE_WEB_ACCOUNT_USER_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                webAccountUser: { $set: action.data.webAccountUser }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__ACTIVATE_WEB_ACCOUNT_USER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState);
            return newState;        

        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__DELETE_WEB_ACCOUNT_USER_SUCCESS:
            newState = INITIAL_STATE;
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_WEB_ACCOUNT_USER_DETAILS__DELETE_WEB_ACCOUNT_USER_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default webAccountUserDetails;