import * as constants from '../constants';
import update from 'immutability-helper';
import moment from 'moment';

const INITIAL_STATE = {
    alarmSystem: null,
    //configuration: null,
    //dataView: null,
    pageId: 0,
    tableId: 0,
    rowId: 0,
    cellId: 0,
    //rowId: null,
    //columnId: null,


//const configurationState = {
//    pages: [],
//    tables: [],
//    rows: [],
//    cells: [],
//    parameters: [],
//    modifiedParameterIndices: [],
//    validationErrorParameterIndices: [],
//    excludedParameterIndices: [],
//    errorParameterIndices: []
//};

//const parameter = {
//    identifier: number,
//    order: number,
//    bit: number,
//    value: string,
//    modified: boolean, // if true, write even if value == newValue
//    read: boolean,
//    config: boolean,
//    paramInfoParameterType: number,
//    paramInfoLength: number,
//    pageId: number,
//    tableId: number,
//    rowId: number,
//    cellId: number,
//    newValue: string,
//    validationErrorText: string,
//    excluded: boolean,
//    errorText: string
//}
    configuration: null,
    //parameters: [],
    //dirtyParameters: [], // {identifier: 1, order: 1, bit: 0} - parameters to write
    //excludedParameters: [],
    generalErrorText: null,
    //parameterError: null,
    pairDialog: {
        isOpen: false,
        formData: {
            pairingCode: '',
            pairingCodeErrorText: null,
            pairingCodeValid: false,
            isValid: false
        }
    },
    unpairDialog: {
        isOpen: false
    },
    readDialog: {
        isOpen: false,
        formData: {
            code: '',
            codeErrorText: null,
            codeValid: false,
            isValid: false
        }
    },
    writeDialog: {
        isOpen: false,
        formData: {
            code: '',
            codeErrorText: null,
            codeValid: false,
            isValid: false
        }
    },
    loadTemplateDialog: {
        isOpen: false,
        templates: [],
        dataViewNames: [],
        languages: [],
        formData: {
            dataViewName: '',
            dataViewNameErrorText: null,
            dataViewNameValid: false,
            language: '',
            languageErrorText: null,
            languageValid: false,
            templateId: '',
            templateIdErrorText: null,
            templateIdValid: false,
            isDefaultTemplateValid: false,
            isWebAccountTemplateValid: false
        }
    },
    saveAsTemplateDialog: {
        isOpen: false,
        formData: {
            name: '',
            nameErrorText: null,
            nameValid: false,
            isValid: false
        }
    },
    clearDialog: {
        isOpen: false
    },
    parameterErrorsDialog: {
        isOpen: false
    },
};



function setParameterIndex(dataView, parameters) {
    let page;
    let table;
    let row;
    let cell;
    let parameterIndex;
    for (let p = 0; p < dataView.pageViewList.length; p++) {
        page = dataView.pageViewList[p];
        for (let t = 0; t < page.tableViewList.length; t++) {
            table = page.tableViewList[t];
            for (let r = 0; r < table.rowViewList.length; r++) {
                row = table.rowViewList[r];
                for (let c = 0; c < row.cellMetadataList.length; c++) {
                    cell = row.cellMetadataList[c];
                    cell.parameterIndex = -1;
                    if (cell.identifier > 0) {
                        parameterIndex = parameters.findIndex(prm => prm.identifier === cell.identifier && prm.order === cell.order && prm.bit === cell.bitNumber);
                        if (parameterIndex >= 0)
                            cell.parameterIndex = parameterIndex;
                    }
                }
            }
        }
    }
}

function getPageIdAndTableId(parameter, dataView) {
    let page;
    let table;
    let row;
    let cell;
    for (let p = 0; p < dataView.pageViewList.length; p++) {
        page = dataView.pageViewList[p];
        for (let t = 0; t < page.tableViewList.length; t++) {
            table = page.tableViewList[t];
            for (let r = 0; r < table.rowViewList.length; r++) {
                row = table.rowViewList[r];
                for (let c = 0; c < row.cellMetadataList.length; c++) {
                    cell = row.cellMetadataList[c];
                    if (parameter.identifier === cell.identifier && parameter.order === cell.order && parameter.bit === cell.bitNumber)
                        return { pageId: p, tableId: t };
                }
            }
        }
    }
    return null;
}

const configuration = (state = INITIAL_STATE, action) => {
    const start = moment();

    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_CONFIGURATION__OPEN_PAIR_DIALOG:
            newState = update(state, {
                pairDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__OPEN_UNPAIR_DIALOG:
            newState = update(state, {
                unpairDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__OPEN_READ_DIALOG:
            newState = update(state, {
                readDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__OPEN_WRITE_DIALOG:
            newState = update(state, {
                writeDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__OPEN_LOAD_TEMPLATE_DIALOG:
            newState = update(state, {
                loadTemplateDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__OPEN_CLEAR_DIALOG:
            newState = update(state, {
                clearDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__OPEN_SAVE_AS_TEMPLATE_DIALOG:
            newState = update(state, {
                saveAsTemplateDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__OPEN_PARAMETER_ERRORS_DIALOG:
            newState = update(state, {
                parameterErrorsDialog: {
                    isOpen: { $set: action.data.isOpen }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        //case constants.ACTION_TYPE_CONFIGURATION__GET_CONFIGURATION_SUCCESS:
        //    newState = update(state, {
        //        alarmSystem: { $set: action.data.alarmSystem },
        //        configuration: { $set: action.data.configuration },
        //        dataView: { $set: action.data.dataView },
        //        //pageId: { $set: Number.isInteger(state.pageId) ? state.pageId : 0 },
        //        //tableId: { $set: Number.isInteger(state.tableId) ? state.tableId : 0 },
        //        //paginationPage: { $set: Number.isInteger(state.paginationPage) ? state.paginationPage : 1 },
        //        //rowId: null,
        //        //columnId: null,
        //        dirtyParameters: { $set: [] },
        //        generalErrorText: { $set: null }
        //    });
        //    console.log(action.type, state, newState, moment().diff(start));
        //    return newState;
        //case constants.ACTION_TYPE_CONFIGURATION__GET_CONFIGURATION_FAIL:
        //    newState = update(state, {
        //        alarmSystem: { $set: null },
        //        configuration: { $set: null },
        //        dataView: { $set: null },
        //        dirtyParameters: { $set: [] },
        //        generalErrorText: { $set: action.data.generalErrorText },
        //        //loadTemplateDialog: {
        //        //    isOpen: { $set: false }
        //        //}
        //    });
        //    console.log(action.type, state, newState, moment().diff(start));
        //    return newState;
        case constants.ACTION_TYPE_CONFIGURATION__GET_ALARM_SYSTEM_SUCCESS:
            newState = update(state, {
                alarmSystem: { $set: action.data.alarmSystem },
                generalErrorText: { $set: null }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__GET_ALARM_SYSTEM_FAIL:
            newState = update(state, {
                alarmSystem: { $set: null },
                generalErrorText: { $set: action.data.generalErrorText }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__GET_WEB_ACCOUNT_TEMPLATES_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                loadTemplateDialog: {
                    templates: { $set: action.data.templates }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__GET_WEB_ACCOUNT_TEMPLATES_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                loadTemplateDialog: {
                    templates: { $set: [] }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__GET_DEFAULT_TEMPLATES_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                loadTemplateDialog: {
                    dataViewNames: { $set: action.data.dataViewNames },
                    languages: { $set: action.data.languages }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__GET_DEFAULT_TEMPLATES_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                loadTemplateDialog: {
                    dataViewNames: { $set: [] },
                    languages: { $set: [] },
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__UPDATE_PAIR_FORM_DATA:
            newState = update(state, {
                pairDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                pairDialog: {
                    formData: {
                        isValid: {
                            $set: newState.pairDialog.formData.pairingCodeValid
                        }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__UPDATE_READ_FORM_DATA:
            newState = update(state, {
                readDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                readDialog: {
                    formData: {
                        isValid: { $set: newState.readDialog.formData.codeValid }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__UPDATE_WRITE_FORM_DATA:
            newState = update(state, {
                writeDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                writeDialog: {
                    formData: {
                        isValid: { $set: newState.writeDialog.formData.codeValid }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__UPDATE_LOAD_TEMPLATE_FORM_DATA:
            newState = update(state, {
                loadTemplateDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                loadTemplateDialog: {
                    formData: {
                        isDefaultTemplateValid: {
                            $set: newState.loadTemplateDialog.formData.dataViewNameValid &&
                                newState.loadTemplateDialog.formData.languageValid
                        },
                        isWebAccountTemplateValid: {
                            $set: newState.loadTemplateDialog.formData.templateIdValid
                        }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__UPDATE_SAVE_AS_TEMPLATE_FORM_DATA:
            newState = update(state, {
                saveAsTemplateDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                saveAsTemplateDialog: {
                    formData: {
                        isValid: { $set: newState.saveAsTemplateDialog.formData.nameValid }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__READ_SUCCESS:
            newState = update(state, {
                readDialog: { $set: INITIAL_STATE.readDialog },
                alarmSystem: { $set: action.data.alarmSystem },
                configuration: { $set: action.data.configuration },
                //dataView: { $set: action.data.dataView },
                //pageId: { $set: Number.isInteger(state.pageId) ? state.pageId : 0 },
                //tableId: { $set: Number.isInteger(state.tableId) ? state.tableId : 0 },
                //paginationPage: { $set: Number.isInteger(state.paginationPage) ? state.paginationPage : 1 },
                //rowId: null,
                //columnId: null,
                //dirtyParameters: { $set: [] },
                generalErrorText: { $set: null }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__READ_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                readDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                readDialog: {
                    formData: {
                        isValid: { $set: newState.readDialog.formData.codeValid }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
            //newState = update(state, {
            //    generalErrorText: { $set: action.data.generalErrorText },
            //    readDialog: {
            //        formData: { $merge: action.data.formData }
            //    }
            //});
            //newState = update(newState, {
            //    readDialog: {
            //        formData: {
            //            isValid: { $set: newState.readDialog.formData.codeValid }
            //        }
            //    }
            //});
            //console.log(action.type, state, newState, moment().diff(start));
            //return newState;

        case constants.ACTION_TYPE_CONFIGURATION__WRITE_SUCCESS:
            newState = update(state, {
                configuration: { $set: action.data.configuration },
                generalErrorText: { $set: null },
                writeDialog: { $set: INITIAL_STATE.writeDialog }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
            //newState = update(state, {
            //    writeDialog: { $set: INITIAL_STATE.writeDialog },
            //    excludedParameters: { $set: action.data.excludedParameters || [] },
            //    dirtyParameters: { $set: [] },
            //    generalErrorText: { $set: null },
            //    configuration: {
            //        parameters: {
            //            $apply: arr => arr.map(p => {
            //                const dirtyParameter = state.dirtyParameters.find(dp => dp.identifier === p.identifier && dp.order === p.order && dp.bit === p.bit);
            //                if (dirtyParameter) {
            //                    const excluded = action.data.excludedParameters ?
            //                        action.data.excludedParameters.some(ep => ep.identifier === p.identifier && ep.order === p.order && ep.bit === p.bit) :
            //                        false;
            //                    if (!excluded)
            //                        return Object.assign({}, p, { value: dirtyParameter.value });
            //                }
            //                return p;
            //            })
            //        }
            //    }
            //});
            //console.log(action.type, state, newState, moment().diff(start));
            //return newState;
        case constants.ACTION_TYPE_CONFIGURATION__WRITE_FAIL:
            newState = update(state, {
                configuration: {$set: action.data.configuration},
                generalErrorText: { $set: action.data.generalErrorText },
                writeDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                writeDialog: {
                    formData: {
                        isValid: { $set: newState.readDialog.formData.codeValid }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
            //newState = update(state, {
            //    generalErrorText: { $set: action.data.generalErrorText },
            //    parameterError: { $set: action.data.parameterError },
            //    dirtyParameters: {
            //        $apply: (arr) => {
            //            if (!action.data.parameterValidationErrors)
            //                return arr;
            //            const newArr = [];
            //            for (const dp of arr) {
            //                const error = action.data.parameterValidationErrors.find(p => p.identifier === dp.identifier &&
            //                    p.order === dp.order && p.bit === dp.bit);
            //                if (error) {
            //                    newArr.push({
            //                        ...dp,
            //                        parameterErrorText: error.parameterErrorText
            //                    })
            //                } else {
            //                    newArr.push(dp);
            //                }
            //            }
            //            return newArr;
            //        }
            //    },
            //    writeDialog: {
            //        formData: { $merge: action.data.formData }
            //    }
            //});
            //newState = update(newState, {
            //    writeDialog: {
            //        formData: {
            //            isValid: { $set: newState.readDialog.formData.codeValid }
            //        }
            //    }
            //});
            //console.log(action.type, state, newState, moment().diff(start));
            //return newState;

        case constants.ACTION_TYPE_CONFIGURATION__SAVE_AS_TEMPLATE_SUCCESS:
            newState = update(state, {
                configuration: { $set: action.data.configuration },
                generalErrorText: { $set: null },
                saveAsTemplateDialog: { $set: INITIAL_STATE.saveAsTemplateDialog }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__SAVE_AS_TEMPLATE_FAIL:
            newState = update(state, {
                configuration: { $set: action.data.configuration },
                generalErrorText: { $set: action.data.generalErrorText },
                saveAsTemplateDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                saveAsTemplateDialog: {
                    formData: {
                        isValid: { $set: newState.saveAsTemplateDialog.formData.nameValid }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_SUCCESS:
            newState = update(state, {
                alarmSystem: { $set: action.data.alarmSystem },
                configuration: { $set: action.data.configuration },
                //dataView: { $set: action.data.dataView },
                //pageId: { $set: Number.isInteger(state.pageId) ? state.pageId : 0 },
                //tableId: { $set: Number.isInteger(state.tableId) ? state.tableId : 0 },
                //paginationPage: { $set: Number.isInteger(state.paginationPage) ? state.paginationPage : 1 },
                //rowId: null,
                //columnId: null,
                //dirtyParameters: { $set: [] },
                //dirtyParameters: {
                //    $set: action.data.configuration.parameters.filter(p => p.modified).map(p => {
                //        const pageTableId = getPageIdAndTableId(p, action.data.dataView);
                //        return {
                //            identifier: p.identifier,
                //            order: p.order,
                //            bit: p.bit,
                //            value: p.value,
                //            parameterErrorText: null,
                //            pageId: pageTableId.pageId,
                //            tableId: pageTableId.tableId
                //        }
                //    })
                //},
                generalErrorText: { $set: null },
                loadTemplateDialog: {
                    isOpen: { $set: false },
                    formData: {
                        $merge: {
                            dataViewName: '',
                            dataViewNameErrorText: null,
                            dataViewNameValid: false,
                            //language: '',
                            //languageErrorText: null,
                            //languageValid: false,
                            templateId: '',
                            templateIdErrorText: null,
                            templateIdValid: false,
                            isDefaultTemplateValid: false,
                            isWebAccountTemplateValid: false
                        }
                        //formData: { $set: INITIAL_STATE.loadTemplateDialog.formData }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__LOAD_TEMPLATE_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                loadTemplateDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                loadTemplateDialog: {
                    formData: {
                        isDefaultTemplateValid: {
                            $set: newState.loadTemplateDialog.formData.dataViewNameValid &&
                                newState.loadTemplateDialog.formData.languageValid
                        },
                        isWebAccountTemplateValid: {
                            $set: newState.loadTemplateDialog.formData.templateIdValid
                        }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        
        case constants.ACTION_TYPE_CONFIGURATION__PAIR_SUCCESS:
            newState = update(state, {
                alarmSystem: { $set: action.data.alarmSystem },
                pairDialog: { $set: INITIAL_STATE.pairDialog },
                generalErrorText: { $set: null }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__PAIR_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                pairDialog: {
                    formData: { $merge: action.data.formData }
                }
            });
            newState = update(newState, {
                pairDialog: {
                    formData: {
                        isValid: { $set: newState.pairDialog.formData.pairingCodeValid }
                    }
                }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__UNPAIR_SUCCESS:
            newState = update(state, {
                alarmSystem: { $set: action.data.alarmSystem },
                unpairDialog: { $set: INITIAL_STATE.unpairDialog },
                generalErrorText: { $set: null }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        case constants.ACTION_TYPE_CONFIGURATION__UNPAIR_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                //unpairDialog: {
                //    formData: { $merge: action.data.formData }
                //}
            });
            //newState = update(newState, {
            //    pairDialog: {
            //        formData: {
            //            isValid: { $set: newState.pairDialog.formData.pairingCodeValid }
            //        }
            //    }
            //});
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        //case constants.ACTION_TYPE_CONFIGURATION__CLEAR_SUCCESS:
        //    newState = update(state, {
        //        configuration: { $set: null },
        //        clearDialog: { $set: INITIAL_STATE.clearDialog },
        //        generalErrorText: { $set: null }
        //    });
        //    console.log(action.type, state, newState, moment().diff(start));
        //    return newState;
        //case constants.ACTION_TYPE_CONFIGURATION__CLEAR_FAIL:
        //    newState = update(state, {
        //        generalErrorText: { $set: action.data.generalErrorText }
        //    });
        //    console.log(action.type, state, newState, moment().diff(start));
        //    return newState;
        case constants.ACTION_TYPE_CONFIGURATION__CLEAR:
            newState = update(state, {
                configuration: { $set: null },
                clearDialog: { $set: INITIAL_STATE.clearDialog },
                generalErrorText: { $set: null }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;

        case constants.ACTION_TYPE_CONFIGURATION__BROWSE_CONFIGURATION:
            newState = update(state, {
                pageId: { $set: action.data.pageId },
                tableId: { $set: action.data.tableId },
                rowId: { $set: action.data.rowId },
                cellId: { $set: action.data.cellId }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
        ////case constants.ACTION_TYPE_CONFIGURATION__GO_TO_PAGE:
        ////    newState = update( state, {
        ////        pageId: { $set: action.data.pageId },
        ////        tableId: {$set: action.data.pageId === state.pageId ? state.tableId : 0},
        ////        paginationPage: { $set: action.data.pageId === state.pageId ? state.paginationPage : 1 }
        ////    });
        ////    console.log(action.type, state, newState, moment().diff(start));
        ////    return newState;
        ////case constants.ACTION_TYPE_CONFIGURATION__GO_TO_TABLE:
        ////    newState = update( state, {
        ////        tableId: { $set: action.data.tableId },
        ////        paginationPage: { $set: action.data.tableId === state.tableId ? state.paginationPage : 1 }
        ////    });
        ////    console.log(action.type, state, newState, moment().diff(start));
        ////    return newState;
        ////case constants.ACTION_TYPE_CONFIGURATION__GO_TO_PAGINATION_PAGE:
        ////    newState = update(state, {
        ////        paginationPage: { $set: action.data.tableId === state.tableId ? state.paginationPage : 1 }
        ////    });
        ////    console.log(action.type, state, newState, moment().diff(start));
        ////    return newState;
        case constants.ACTION_TYPE_CONFIGURATION__UPDATE_VALUE:
            newState = update(state, {
                configuration: { $set: action.data.configuration }
            });
            console.log(action.type, state, newState, moment().diff(start));
            return newState;
            //const index = state.dirtyParameters.findIndex(p => p.identifier === action.data.identifier &&
            //    p.order === action.data.order && p.bit === action.data.bit);

            //const dirtyParameter = {
            //    identifier: action.data.identifier,
            //    order: action.data.order,
            //    bit: action.data.bit,
            //    value: action.data.value,
            //    parameterErrorText: action.data.parameterErrorText,
            //    pageId: action.data.pageId,
            //    tableId: action.data.tableId,
            //    rowId: action.data.rowId
            //};

            //if (index >= 0) {
            //    newState = update(state, {
            //        dirtyParameters: { [index]: { $set: dirtyParameter } }
            //    });
            //} else {
            //    newState = update(state, {
            //        dirtyParameters: {
            //            $push: [dirtyParameter]
            //        }
            //    });
            //}
            //console.log(action.type, state, newState, moment().diff(start));
            //return newState;

        //case constants.ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_SUCCESS:
        //    return state;
        //case constants.ACTION_TYPE_CONFIGURATION__UPDATE_PARAMETERS_FAIL:
        //    newState = update(state, {
        //        generalErrorText: { $set: action.data.generalErrorText },
        //        dirtyParameters: {
        //            $apply: (arr) => {
        //                if (!action.data.parameterValidationErrors)
        //                    return arr;
        //                const newArr = [];
        //                for (const dp of arr) {
        //                    const error = action.data.parameterValidationErrors.find(p => p.identifier === dp.identifier &&
        //                        p.order === dp.order && p.bit === dp.bit);
        //                    if (error) {
        //                        newArr.push({
        //                            ...dp,
        //                            parameterErrorText: error.parameterErrorText
        //                        })
        //                    } else {
        //                        newArr.push(dp);
        //                    }
        //                }
        //                return newArr;
        //            }
        //        }
        //    });
        //    console.log(action.type, state, newState, moment().diff(start));
        //    return newState;
        //case constants.ACTION_TYPE_CONFIGURATION__CANCEL_UPDATE_PARAMETERS:
        //    newState = update(state, {
        //        dirtyParameters: { $set: [] }
        //    });
        //    console.log(action.type, state, newState, moment().diff(start));
        //    return newState;
        default:
            return state;
    }

}

export default configuration;