import * as constants from '../constants';
import update from 'immutability-helper';

const INITIAL_STATE = {
    alarmSystems: [],
    notifications: [],
    generalErrorText: null,
    displayNotifications: {
        notifications: [],
        notificationId: 0,
        searchText: '',
        sort: {
            by: constants.MONITORING_SORT_BY_DATE,
            direction: "desc"
        },
    },
    formData: {
        macs: [],
        notificationTypes: [],
        dateRange: null
    }
};

const monitoring = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case constants.ACTION_TYPE_MONITORING__UPDATE_FORM_DATA:
            newState = update(state, {
                formData: { $merge: action.data.formData }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MONITORING__UPDATE_DISPLAY_NOTIFICATIONS:
            newState = update(state, {
                displayNotifications: { $set: action.data.displayNotifications }
            });
            console.log(action.type, state, newState);
            return newState;
        //case constants.ACTION_TYPE_MONITORING__UPDATE_SEARCH_TEXT:
        //    newState = update(state, {
        //        searchText: { $set: action.data.searchText }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        //case constants.ACTION_TYPE_MONITORING__UPDATE_SORT:
        //    newState = update(state, {
        //        sort: { $set: action.data.sort }
        //    });
        //    console.log(action.type, state, newState);
        //    return newState;
        case constants.ACTION_TYPE_MONITORING__GET_ALARM_SYSTEMS_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                alarmSystems: { $set: action.data.alarmSystems }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MONITORING__GET_ALARM_SYSTEMS_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                alarmSystems: { $set: [] }
            });
            console.log(action.type, state, newState);
            return newState;

        case constants.ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_SUCCESS:
            newState = update(state, {
                generalErrorText: { $set: null },
                notifications: { $set: action.data.notifications }
            });
            console.log(action.type, state, newState);
            return newState;
        case constants.ACTION_TYPE_MONITORING__GET_NOTIFICATIONS_FAIL:
            newState = update(state, {
                generalErrorText: { $set: action.data.generalErrorText },
                notifications: { $set: [] }
            });
            console.log(action.type, state, newState);
            return newState;
        default:
            return state;
    }
}

export default monitoring;